import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import useFetch from '/src/hooks/api/fetch'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import Layout from '/src/ui/core/layouts/layout'
import PlanningGrid from '/src/ui/domain/planning/planning_grid'

export default function PlanningPage() {
  const [templates, setTemplates] = useState()
  const [globalSubproject] = useStore('subproject')
  
  const { fetch, loading } = useFetch()

  useEffect(() => {
    const params = {
      query: { where: { template_type: 'progress_service' } }
    }

    fetch('eav_templates', params, {
      useParse: true,
      useLoading: true,
      onSuccess: ({ data }) => setTemplates(data)
    })
  }, [globalSubproject])

  return (
    <Layout key={globalSubproject.id}>
      {loading ? <ThreeDotsLoader /> : <PlanningGrid templates={templates} />}
    </Layout>
  )
}
