import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'

const isRetrievingProgressSummary = (dataItem) => {
  return dataItem && (dataItem.progress_summary !== undefined || dataItem.virtualKey !== undefined)
}

export const isProgressColumnNotEditable = (column, dataItem, statuses) => {
  if (column.description !== 'progress' && column.description !== 'actual_progress') return false

  const level = isPresent(dataItem?.progress_summary) ? 'progress' : 'progress_service'
  const levelStatuses = Object.values(statuses || {})
  const statusId = `${level}_status_id`
  const canceledStatus = levelStatuses.find((e) => e.i18n_id === 'canceled')
  const doneStatus = levelStatuses.find((e) => e.i18n_id === 'done')

  return (
    dataItem.virtualKey !== undefined ||
    dataItem[statusId] === canceledStatus?.id ||
    dataItem[statusId] === doneStatus?.id ||
    dataItem?.quantity === 0
  )
}

export function includeActualProgressOnDataSource(dataSource) {
  if (isBlank(dataSource)) return undefined
  return {
    ...dataSource,
    data:
      dataSource?.data?.map((dataItem) => ({
        ...dataItem,
        actual_progress: dataItem?.progress_summary?.actual_progress,
        progress_dirty: false
      })) ?? []
  }
}

export const isDone = (progress = {}, statuses = {}) => {
  const { progress_status_id: progressStatusId } = progress || {}
  return (statuses || {})[progressStatusId]?.i18n_id === 'done'
}

export default class ProgressModel {
  constructor() {
    this.paramName = ProgressModel.paramName
    this.singularName = ProgressModel.singularName
  }

  static paramName = 'progress'

  static singularName = I18n.t('performance.title')

  name = I18n.t('progresses.title')

  templateType = 'progress'

  route = 'progresses'

  url = 'performances'

  complementaryRoute = 'progresses/slim'

  flexible = true

  parentModels = ['request', 'scope']

  canEditRowStatuses = ['in_progress']

  gridColumnOrderOffset = 3

  watchFields = [
    'progress_status_id',
    'request.reason',
    'request.comments',
    'progress_summary.actual_hours',
    'progress_summary.actual_progress',
    'progress_summary.actual_quantity',
    'progress_summary.progress_hours'
  ]

  initialColumns = [
    {
      id: 1,
      description: 'id',
      title: I18n.t('progresses.id'),
      hideOnGrid: true,
      hideOnForm: true,
      width: 100,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request_id',
      title: I18n.t('requests.id'),
      hideOnForm: true,
      width: 150,
      type: 'integer'
    },
    {
      id: 3,
      description: 'request',
      title: I18n.t('requests.id'),
      orderOnForm: 1,
      foreignKey: 'requests',
      columnSize: 'width-100',
      foreignAttribute: 'request_id',
      hideOnGrid: true,
      required: true,
      searchFields: ['id', 'reason', 'discipline][description', 'comments'],
      searchExtraQuery: {
        where: {
          '[request_statuses][i18n_id]': ['in_planning', 'in_performance']
        }
      },
      searchRoute: 'requests',
      textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
      textField: 'reason',
      type: 'search'
    },
    {
      id: 18,
      description: 'request.reason',
      field: 'request.reason',
      title: I18n.t('requests.reason'),
      forceUseDescription: true,
      hideOnForm: true,
      editable: false,
      filterable: true,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem.request?.reason),
      type: 'string'
    },
    {
      id: 17,
      description: 'request.comments',
      field: 'request.comments',
      title: I18n.t('requests.comments'),
      forceUseDescription: true,
      filterable: true,
      hideOnForm: true,
      editable: false,
      readOnly: true,
      isRetrieving: (dataItem) => isBlank(dataItem.request?.reason),
      type: 'string'
    },
    {
      id: 16,
      description: 'work_description',
      title: I18n.t('progresses.work_description'),
      hideOnGrid: true,
      orderOnForm: 2,
      allowOnEditableGrid: false,
      type: 'rich_text'
    },
    {
      id: 4,
      description: 'number',
      title: '#',
      hideOnForm: true,
      readOnly: true,
      width: 100,
      type: 'string'
    },
    {
      id: 5,
      description: 'flag',
      title: I18n.t('progresses.flag'),
      hideOnForm: true,
      readOnly: true,
      sortable: false,
      isRetrieving: (dataItem) => !isRetrievingProgressSummary(dataItem),
      width: 150,
      type: 'string'
    },
    {
      id: 6,
      description: 'description',
      title: I18n.t('progresses.description'),
      hideOnForm: true,
      readOnly: true,
      width: 150,
      filterable: false,
      onSort: (columns) => getFirstStringOrFormula(columns),
      type: 'string'
    },
    {
      id: 7,
      description: 'label',
      title: I18n.t('progresses.label'),
      hideOnForm: true,
      editable: false,
      width: 150,
      type: 'string'
    },
    {
      id: 8,
      description: 'PF',
      title: I18n.t('progresses.pf'),
      hideOnForm: true,
      editable: false,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !isRetrievingProgressSummary(dataItem),
      type: 'string'
    },
    {
      id: 9,
      description: 'progress',
      title: I18n.t('progresses.progress'),
      hideOnForm: true,
      width: 200,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !isRetrievingProgressSummary(dataItem),
      type: 'percentage'
    },
    {
      id: 10,
      description: 'delta_quantity',
      title: I18n.t('progresses.delta_qty'),
      hideOnForm: true,
      editable: false,
      width: 200,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !isRetrievingProgressSummary(dataItem),
      type: 'string'
    },
    {
      id: 11,
      description: 'delta_hours',
      title: I18n.t('progresses.delta_hours'),
      hideOnForm: true,
      editable: false,
      width: 350,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !isRetrievingProgressSummary(dataItem),
      type: 'string'
    },
    {
      id: 12,
      description: 'quantity',
      title: I18n.t('scopes.quantity'),
      orderOnForm: 3,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 13,
      description: 'crews',
      title: I18n.t('scopes.crews'),
      orderOnForm: 4,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 14,
      description: 'crew_size',
      title: I18n.t('scopes.crew_size'),
      orderOnForm: 5,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    },
    {
      id: 15,
      description: 'working_hours',
      title: I18n.t('scopes.working_hours'),
      orderOnForm: 6,
      editable: false,
      readOnly: true,
      hideOnForm: false,
      width: 200,
      type: 'formula_service',
      footer: true
    }
  ]

  columns = includeDateColumns(this.initialColumns)
}
