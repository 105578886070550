import React from 'react'

import { MdCalculate, MdOutlineGridView } from 'react-icons/md'
import I18n from '/src/utils/translations'

const addDimensionMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('scaffolding_parts.menu_items.dimensions'),
    icon: <MdCalculate />,
    visible,
    onClick: onClick
  }
}

const addModificationMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('scaffolding_parts.menu_items.modifications'),
    icon: <MdCalculate />,
    visible,
    onClick: onClick
  }
}

const dismantleMenuItem = (visible, onClick) => {
  return {
    text: I18n.t('scaffolding_parts.menu_items.dismantle'),
    icon: <MdOutlineGridView />,
    visible,
    onClick: (_, dataItem) => onClick(dataItem)
  }
}

export { addDimensionMenuItem, addModificationMenuItem, dismantleMenuItem }
