import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import lodash from 'lodash'
import RequestModel from '/src/models/request'
import I18n from '/src/utils/translations'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import Layout from '/src/ui/core/layouts/layout'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import LoadingCover from '/src/ui/core/layouts/loading_cover'
import RequestsGrid from '/src/ui/domain/requests/requests_grid'
import RecycleRequestPopup from '/src/ui/domain/requests/recycle_request_popup'
import DisciplineCheckPopup from '/src/ui/domain/requests/discipline_check_popup'
import ScheduleRequestPopup from '/src/ui/domain/requests/schedule_request_popup'
import RequestsPageSidePanel from '/src/ui/domain/requests/requests_page_side_panel'
import AuthorizationRequestPopup from '/src/ui/domain/requests/authorization_request_popup'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useRequestContextMenu from '/src/ui/domain/requests/request_context_menu'
import useRequestInitialFetches from '/src/hooks/use_request_initial_fetches'
import useConfigureWorkPackageFile from '/src/ui/domain/requests/popups/configure_work_package_file'
import useInspectionAcceptancePopup from '/src/ui/domain/inspections/inspection_acceptance_popup'
import { normalizeDateToString } from '/src/utils/project_formatter'
import { isEmpty } from '/src/utils/object'
import {
  subRequestToEditableGrid,
  editColumnsOnEditableGrid,
  foreignDropToEditableGrid,
  multilineTextToEditableGrid,
  subAreaToEditableGrid,
  fillDefaultToEditableGrid
} from '/src/utils/columns_formatter'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import useModel from '/src/ui/core/forms/model_hook'
import '/src/static/css/core/grid/grid.css'

const model = new RequestModel()

export default function RequestsPage({ match }) {
  const [requestModel, onChangeColumn] = useModel(model, ['skip_scoping', 'estimates_authorization'])
  const [showLoading, setShowLoading] = useState(false)
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [selectedRequest, setSelectedRequest] = useState()
  const [requestStatuses] = useStore('request_statuses')

  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [recycleDataItem, setRecycleDataItem] = useState({})
  const [scheduleDataItem, setScheduleDataItem] = useState({})
  const [authorizeDataItem, setAuthorizeDataItem] = useState({})
  const [disciplineCheckDataItem, setDisciplineCheckDataItem] = useState({})
  const [openWorkPackagePopup, printWorkPackagePopup] = useConfigureWorkPackageFile()

  const [contextMenuItems, sidePanelMenuItems] = useRequestContextMenu({
    showConfirmation,
    setRecycleDataItem,
    setScheduleDataItem,
    setAuthorizeDataItem,
    openWorkPackagePopup,
    setDisciplineCheckDataItem
  })

  const [
    requestTemplate,
    sectionsColumns,
    templateId,
    contract
  ] = useRequestInitialFetches(requestModel, onChangeColumn)

  const renderInspectionAcceptanceModal = useInspectionAcceptancePopup()

  const foreignEntities = requestModel.columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])
  const batchedEntities = useEntitiesCache(foreignEntities)

  const onCreateNewItem = (newDataItem) => ({
    ...newDataItem,
    eav_template_id: templateId,
    skip_scoping: contract?.skip_scoping ?? requestModel.skip_scoping,
    estimates_authorization: contract?.estimates_authorization ?? requestModel.estimates_authorization,
    service_desired_date: normalizeDateToString(new Date()),
    request_date: normalizeDateToString(new Date()),
    elevation: 0
  })

  const isRequestEditable = (row) => {
    if (!requestStatuses || isEmpty(requestStatuses)) return false
    if (row.request_status_id === undefined) return true

    return !requestModel.cannotEditStatuses.includes(requestStatuses[row.request_status_id].i18n_id)
  }

  const isRequestColumnEditable = (dataItem) => {
    return isRequestEditable(dataItem)
  }

  const editableGridProps = {
    topEntity: {
      model: requestModel,
      onCreateNewItem,
      shouldAllowCell: (_, dataItem) => isRequestColumnEditable(dataItem),
      isRowEditable: (row) => !row.id || isRequestEditable(row)
    },
    allowCreate: true,
    allowDelete: true,
    recoverSettings: () => selectedRequest
  }

  const [editableGrid, setInEditMode, editableGridColumns, editableGridDataSource] = useEditableGrid(editableGridProps)

  const handleCloseSidePanel = useCallback(() => {
    setColumnStyles(SIDE_PANEL_CLOSED)
    setSelectedRequest()
  }, [])

  const onGridColumns = useCallback(
    (columns) => {
      const columnsToEdit = [
        'reason',
        'comments',
        'information',
        'service_desired_date',
        'discipline',
        'area',
        'subarea'
      ]
      const foreignDrops = ['discipline', 'area', 'subarea']
      let gridColumns = [...columns]
      gridColumns = subAreaToEditableGrid(gridColumns)
      gridColumns = editColumnsOnEditableGrid(gridColumns, columnsToEdit)
      gridColumns = foreignDropToEditableGrid(gridColumns, foreignDrops, batchedEntities)
      gridColumns = multilineTextToEditableGrid(gridColumns)
      gridColumns = fillDefaultToEditableGrid(gridColumns, 'skip_scoping', requestModel.skip_scoping)
      gridColumns = fillDefaultToEditableGrid(
        gridColumns,
        'estimates_authorization',
        requestModel.estimates_authorization
      )

      editableGridColumns.set(subRequestToEditableGrid(gridColumns))
    },
    [batchedEntities, editableGridColumns?.set, requestModel?.estimates_authorization]
  )

  const onGridDataSource = useCallback(
    (dataSource) => {
      const { isEqual } = lodash
      editableGridDataSource.set(dataSource)

      const newSelectedItem = dataSource.data.filter((item) => item.id === selectedRequest?.id)[0]
      if (!newSelectedItem) handleCloseSidePanel()
      else if (!isEqual(selectedRequest, newSelectedItem)) {
        setSelectedRequest(newSelectedItem)
      }
    },
    [editableGridDataSource, selectedRequest, handleCloseSidePanel]
  )

  useEffect(() => {
    if (!editableGridDataSource.get || !selectedRequest) return
    if (editableGridDataSource.get.loading) return

    const hasSelectedItem = editableGridDataSource.get.data.find(
      (item) => selectedRequest && item.id === selectedRequest.id
    )

    if (!hasSelectedItem) handleCloseSidePanel()
  }, [editableGridDataSource?.get?.loading, selectedRequest?.id])

  const onRowClick = useCallback((e) => {
    setSelectedRequest((prevSelectedRequest) => {
      const isSidePanelOpened = prevSelectedRequest && prevSelectedRequest.id === e.dataItem.id
      const nextSidePanelDataItem = isSidePanelOpened ? undefined : e.dataItem

      if (nextSidePanelDataItem) {
        setShowLoading(true)
        setColumnStyles(SIDE_PANEL_OPENED)
        return nextSidePanelDataItem
      }

      setColumnStyles(SIDE_PANEL_CLOSED)
      return null
    })
  }, [])

  useBus(
    BusEvents.EXITING_EDITABLE_MODE,
    ({ payload }) => {
      const item = payload()
      if (!item) return

      setShowLoading(true)
      setSelectedRequest({ ...item })
      setColumnStyles(SIDE_PANEL_OPENED)
    },
    []
  )

  return (
    <React.Fragment>
      {renderConfirmation()}
      <AuthorizationRequestPopup dataItem={authorizeDataItem} />
      <ScheduleRequestPopup dataItem={scheduleDataItem} />
      {!isEmpty(recycleDataItem) && <RecycleRequestPopup dataItem={recycleDataItem} />}
      {!isEmpty(disciplineCheckDataItem) && (
        <DisciplineCheckPopup
          dataItem={disciplineCheckDataItem}
          setDataItem={setDisciplineCheckDataItem}
          templateId={templateId}
          requestTemplate={requestTemplate}
        />
      )}
      {printWorkPackagePopup}
      <LoadingCover show={showLoading} text={I18n.t('main_dialog.loading_title')} />
      <Layout key={match.params.id} showNewButton>
        {renderInspectionAcceptanceModal()}
        {editableGrid(
          <React.Fragment>
            <LayoutPanels wrapperClass="side-panel-wrapper no-template no-tabs-above" columnStyles={columnStyles}>
              <div className={selectedRequest ? 'shadowed-page' : 'unshadowed-page'}>
                <RequestsGrid
                  onRowClick={onRowClick}
                  selectedRequest={selectedRequest}
                  onGridDataSource={onGridDataSource}
                  contextMenuItems={contextMenuItems}
                  editableGridFunctions={{
                    setInEditMode,
                    onGridColumns,
                    editableGridDataSource
                  }}
                />
              </div>
              <>
                {selectedRequest && (
                  <RequestsPageSidePanel
                    dataItem={selectedRequest}
                    sections={sectionsColumns}
                    onClose={handleCloseSidePanel}
                    contextMenuItems={sidePanelMenuItems}
                    onBlocksBuild={() => setShowLoading(false)}
                  />
                )}
              </>
            </LayoutPanels>
          </React.Fragment>
        )}
      </Layout>
    </React.Fragment>
  )
}

RequestsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      collection: PropTypes.string
    })
  })
}

RequestsPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined,
      collection: undefined
    })
  })
}
