/* eslint-disable max-lines-per-function */
import React, { useCallback, useState, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import RequestModel from '/src/models/request'
import InspectionModel from '/src/models/inspection'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import RequestEstimatesIcon from '/src/ui/core/icons/request_estimates_icon'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import FilterPinnedRequests from '/src/ui/core/icons/filter_pinned_requests'
import NewButton from '/src/ui/core/icons/new_button'
import {
  importMenuItem,
  exportMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  printListMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import QuickPinIcon from '/src/ui/core/icons/quick_pin_icon'
import useConfigurePrintPDF from '/src/ui/core/popups/configure_print_pdf'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import useGeneralFilterServices from '/src/ui/domain/requests/general_filter_services'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import EstimateFormModal from '/src/ui/domain/estimates/estimate_form_modal'
import RequestFormModal from '/src/ui/domain/requests/request_form_modal'
import ChooseInspectionTemplateModal from '/src/ui/domain/requests/choose_inspection_template_modal'
import InspectionFormModal from '/src/ui/domain/inspections/inspection_form_modal'
import useRequestSummaries from '/src/ui/domain/requests/request_summaries'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'

export default function RequestsGrid({
  onRowClick,
  selectedRequest,
  contextMenuItems,
  onGridDataSource,
  editableGridFunctions
}) {
  const requestModel = new RequestModel()
  const inspectionModelParamName = InspectionModel.paramName
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [inspectionTemplate, setInspectionTemplate] = useState({})
  const [openImport, setOpenImport] = useState(false)
  const [subproject] = useStore('subproject')
  const templateId = subproject.request_eav_template_id
  const exportParams = { entity: 'requests', templateId, isApi: true }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)
  const modelName = requestModel?.paramName
  const { filter: requestFilter, gridTitle } = useRequestGridFilter({
    route: requestModel.route,
    templateId,
    modelName
  })

  const { filter, filterLabel, filterIcons, clearAllGeneralFilters } = useGeneralFilterServices()

  const gridFilters = useMemo(() => [...(filter || []), ...requestFilter], [filter, requestFilter])

  const { selectedRequests } = useGetPinnedRequests()
  const hasRequestPinned = Object.keys(selectedRequests ?? {}).length > 0

  const {
    loading: summaryLoading,
    includeSummariesOnDataSource
  } = useRequestSummaries()

  const { setInEditMode, onGridColumns, editableGridDataSource } = editableGridFunctions
  const [openConfigurationPopup, printGridPopup, isPrintable, printList] = useConfigurePrintPDF(
    gridColumns,
    gridDataSource,
    requestModel
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(templateId)

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    printListMenuItem(printList, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick),
    importMenuItem(setOpenImport),
    exportMenuItem(linkExport)
  ]

  const selectedRows = useMemo(
    () => (gridDataSource ? gridDataSource.data.filter(({ row_selected }) => row_selected) : []),
    [gridDataSource]
  )

  const clearAllFiltersCallback = useCallback(() => {
    clearAllGeneralFilters()
  }, [clearAllGeneralFilters])

  const icons = [
    'batch_assigner',
    <RequestEstimatesIcon key="request-estimates-icon" selectedRequests={selectedRows} />,
    <InspectionsIcon key="inspections-icon" modelName={requestModel?.paramName} />,
    selectedRows.length !== 0 && <VerticalDivider />,
    hasRequestPinned && <FilterPinnedRequests hasRequestPinned />,
    <QuickPinIcon />,
    <VerticalDivider />,
    ...filterIcons,
    editableGridDataSource && editableGridDataSource.get && !editableGridDataSource.get.loading && (
      <BulkEditingIcon onClick={() => setInEditMode('top')} />
    ),
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton />
  ]

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  const onSetGridColumns = (columns) => {
    setGridColumns(columns)
    if (onGridColumns) onGridColumns(columns)
  }

  const onSetGridDataSource = (dataSource) => {
    setGridDataSource(dataSource)
    if (onGridDataSource && !summaryLoading) onGridDataSource(dataSource)
  }

  const onFirstDataSourceFinish = (dataSource) => {
    includeSummariesOnDataSource(dataSource)
  }

  return (
    <React.Fragment>
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      {openImport && (
        <PopupImportFile modelRoute={requestModel.route} templateId={templateId} onClose={onClosePopupImport} />
      )}
      <SimpleGrid
        labels={[filterLabel()]}
        icons={icons}
        model={requestModel}
        onGridColumns={onSetGridColumns}
        onFilterUpdate={setExportFilters}
        sort={[{ field: 'id', dir: 'desc' }]}
        filter={gridFilters}
        clearAllFiltersCallback={clearAllFiltersCallback}
        contextMenuItems={contextMenuItems}
        gridTitle={gridTitle}
        loadFlexColumns
        templateId={templateId}
        onDataSource={onSetGridDataSource}
        onFirstDataSourceFinish={onFirstDataSourceFinish}
        columnCellFactory={<CellFactory type={requestModel.paramName} />}
        selectedItem={selectedRequest}
        selectFiltering={false}
        onRowClick={onRowClick}
        selecting
        havePinInGrid={true}
      />
      <EstimateFormModal />
      <RequestFormModal requestModel={requestModel} />
      <ChooseInspectionTemplateModal
        modelName={inspectionModelParamName}
        setInspectionTemplate={setInspectionTemplate}
        inspectedType="Request"
      />
      <InspectionFormModal modelParamName={inspectionModelParamName} template={inspectionTemplate} />
    </React.Fragment>
  )
}

RequestsGrid.propTypes = {
  onRowClick: PropTypes.func,
  selectedRequest: PropTypes.object,
  editableGridFunctions: PropTypes.oneOfType([PropTypes.object])
}

RequestsGrid.defaultProps = {
  onRowClick: () => { },
  selectedRequest: undefined,
  editableGridFunctions: {}
}
