import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MdMoreVert } from 'react-icons/md'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import I18n from '/src/utils/translations'
import '/src/static/css/popup_anchored.css'

export default function GridRowContextMenu({ dataItem, items, isTableData }) {
  const [active, setActive] = useState(false)
  const popupButtonRef = useRef(null)

  const isProgressCompleted = dataItem.progress_service_status_id === 5

  const itemsMapped = items.map((item) => {
    let { text } = item

    const progressHistoryText = I18n.t('progress_histories.progress_history')
    const addNewProgressText = I18n.t('progresses.history')

    if (item.text === addNewProgressText || item.text === progressHistoryText) {
      text = isProgressCompleted ? progressHistoryText : addNewProgressText
    }
    return { ...item, text, dataItem: { ...dataItem } }
  })

  const itemsVisible = itemsMapped.filter((item) => {
    if (item.visible) return item.visible(item.dataItem)
    return true
  })

  const contextMenuButtonClassName = () => {
    const classes = ['context-menu-button']

    if (active) classes.push('active')
    if (itemsVisible.length === 0) classes.push('disabled')

    return classes.join(' ')
  }

  const contextMenu = (
    <PopupMenu
      items={itemsVisible}
      setActive={setActive}
      popupButtonRef={popupButtonRef}
      disabled={itemsVisible.length === 0}
    >
      <button
        ref={popupButtonRef}
        type="button"
        data-testid="grid-context-menu-button"
        className={contextMenuButtonClassName()}
        disabled={itemsVisible.length === 0}
      >
        <MdMoreVert />
      </button>
    </PopupMenu>
  )

  return isTableData ? <td>{contextMenu}</td> : contextMenu
}

GridRowContextMenu.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired,
  isTableData: PropTypes.bool
}

GridRowContextMenu.defaultProps = {
  isTableData: true
}
