import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import '/src/static/css/core/grid/clear_all_filters_buttons.css'

export default function ClearAllFiltersButton({ onClick, visibililty, modelParamName }) {
  if (visibililty === false) return null

  const clearAllFilters = () => {
    onClick()
    dispatch({
      type: BusEvents.CLEAN_GRID_FILTERS,
      paramName: modelParamName
    })
    dispatch({
      type: BusEvents.SELECTED_ROWS,
      triggeredModelName: modelParamName,
      payload: []
    })
  }

  return (
    <div className="clear-filters-buttons" onClick={clearAllFilters}>
      {I18n.t('actions.clear_all_filters')}
    </div>
  )
}

ClearAllFiltersButton.propTypes = {
  onClick: PropTypes.func,
  visibililty: PropTypes.bool,
  modelParamName: PropTypes.string.isRequired
}

ClearAllFiltersButton.defaultProps = {
  onClick: () => {},
  visibililty: false
}
