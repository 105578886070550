import React from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { ReactComponent as Weight } from '/public/static/svg/weight'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function WeightLabel({ value }) {
  const normalizedValue = value ?? 0
  const [globalProject] = useStore('project')
  const className = 'weight-label'

  return (
    <CustomTooltip parentTitle>
      <div className={className} title={I18n.t('scaffoldings.total_weight_message')}>
        <Weight />
        <span>{formatNumberWithPrecision(normalizedValue, globalProject)} t</span>
      </div>
    </CustomTooltip>
  )
}

WeightLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

WeightLabel.defaultProps = {
  value: null,
}
