import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useFetchById from '/src/hooks/api/fetch_by_id'
import useGridFilterSelection from '/src/hooks/grid_filter_selection'
import useRowRenderPerformanceGrids from '/src/ui/domain/progresses/row_render_performance_grids'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import StatusFilterLabel from '/src/ui/core/grid/statuses_filter_label'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import GridFilterIcon from '/src/ui/core/icons/grid_filter_icon'
import NewButton from '/src/ui/core/icons/new_button'
import useProgressServicesDateFilter from '/src/ui/domain/progress_services/progress_services_date_filter'
import ProgressModel from '/src/models/progress'
import { buildBackURL } from '/src/utils/url'
import { updateRequestFilter } from '/src/utils/constants/request'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import {
  printMenuItem,
  clearTemplatePreferencesMenuItem,
  printListMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import QuickPinIcon from '/src/ui/core/icons/quick_pin_icon'
import useConfigurePrintPDF from '/src/ui/core/popups/configure_print_pdf'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import useProgressServicesStatusFilter from '/src/ui/domain/progress_services/progress_services_status_filter'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import { injectProperty } from '/src/utils/object'
import { EDITABLE_SERVICES_STATUSES } from '/src/utils/constants/progress_services'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function ProgressServicesGrid({
  parentItem,
  selectedItem,
  onRowClick,
  contextMenuItems,
  filtered,
  template,
  labels,
  model,
  setInEditMode,
  onGridColumns,
  onDataSource,
  showGrid,
  isPlanningGrid
}) {
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const { requestId: requestIdPin } = useGetPinnedRequests()
  const history = useHistory()
  const isPlanning = model.path === 'plannings'
  const templateId = template ? template.id : undefined
  const templateFiler = isPlanning ? templateId : parentItem ? templateId : undefined
  const defaultFilter = template
    ? [
        {
          type: 'where',
          column: 'eav_template_id',
          operator: IsEqualOperator,
          value: templateId
        }
      ]
    : []

  const { filter: requestFilter, gridTitle: requestTitle } = useRequestGridFilter({
    route: model.path,
    templateId: templateFiler,
    parent: 'progress'
  })

  const [filter, setFilter] = useState(isPlanning ? defaultFilter.concat(requestFilter) : defaultFilter)

  const [requestId, setRequestId] = useState()

  const gridTitle = useMemo(() => {
    return requestIdPin ? requestTitle : model.name
  }, [requestIdPin, requestTitle])

  const [selectedDateFilterOption, setSelectedDateFilterOption] = useState(I18n.t('progress_services.date_filter.all'))
  const [selectedStatusFilterOption, setSelectedStatusFilterOption] = useState()
  const request = useFetchById('requests', requestId)
  const onRowRender = useRowRenderPerformanceGrids('progress_service')

  const onFilterSelection = useGridFilterSelection(setFilter)

  const {
    statusFilterButton,
    clearStatusFilters,
    resetStatusFilters,
    hasStatusFilter
  } = useProgressServicesStatusFilter(filter, setFilter, setSelectedStatusFilterOption)

  const beforeReload = () => {
    if (isPlanning) return
    const location = new ProgressModel().url
    const backURLConfig = {
      eavTemplateId: parentItem.eav_template_id,
      selectItemId: parentItem.id
    }
    history.push(`/${location}?${buildBackURL(backURLConfig)}`)
  }

  const expandedGridDataSource = {
    ...gridDataSource,
    data: injectProperty(gridDataSource?.data, 'request', request)
  }

  const [openConfigurationPopup, printGridPopup, isPrintable, printList] = useConfigurePrintPDF(
    gridColumns,
    expandedGridDataSource,
    model
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(
    templateId,
    beforeReload
  )

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    printListMenuItem(printList, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick)
  ]

  const { dateFilterButton, resetDateFilters, hasDateFilter } = useProgressServicesDateFilter({
    filter,
    onFilter: setFilter,
    onSelectedOptionChange: setSelectedDateFilterOption
  })

  const gridFilters = useMemo(() => {
    return updateRequestFilter(filter, requestFilter)
  }, [filter, requestFilter])

  const hasFilterApplied = useMemo(() => {
    return hasStatusFilter || hasDateFilter
  }, [hasStatusFilter, hasDateFilter, gridFilters, isPlanning])

  const clearAllFiltersCallback = useCallback(() => {
    const clearAllFilters = () => {
      if (!parentItem) {
        setFilter(defaultFilter)
      } else {
        setFilter(defaultFilter.concat({ type: 'where', column: 'progress_id', value: parentItem.id }))
      }
    }

    clearAllFilters()
    resetDateFilters()
    resetStatusFilters()
  }, [resetStatusFilters, resetDateFilters, parentItem, defaultFilter, setFilter])

  const statusI18n = (statusId) => {
    const statusObject = progressServiceStatuses && progressServiceStatuses[statusId]
    return statusObject && statusObject.i18n_id
  }

  const batchAssignerIcon = {
    type: 'batch_assigner',
    visibility: (records) => {
      return records.every((record) => {
        const i18nStatus = statusI18n(record.progress_service_status_id)
        return EDITABLE_SERVICES_STATUSES.includes(i18nStatus)
      })
    }
  }

  const icons = [
    batchAssignerIcon,
    <GridFilterIcon template={template} model="ProgressService" onFilterSelection={onFilterSelection} />,
    dateFilterButton,
    <InspectionsIcon key="inspections-icon" modelName={model.paramName} />,
    isPlanningGrid && <QuickPinIcon />,
    <VerticalDivider />,
    statusFilterButton,
    gridDataSource && <BulkEditingIcon onClick={() => setInEditMode(isPlanning ? 'top' : 'bottom')} />,
    <MoreActionsIcon items={moreActionsMenuItems} />,
    !isPlanning ? <NewButton modelName={model.paramName} /> : undefined
  ]

  useEffect(() => {
    if (parentItem && parentItem.request_id) setRequestId(parentItem.request_id)
  }, [parentItem])

  useEffect(() => {
    if (!parentItem) {
      setFilter(defaultFilter)
    } else {
      setFilter(defaultFilter.concat({ type: 'where', column: 'progress_id', value: parentItem.id }))
    }
  }, [parentItem, template])

  const dateFilterLabel = () => (
    <div key="progress-services-date-filter" className="filter-label">
      {selectedDateFilterOption}
    </div>
  )

  const statusFilterLabel = useCallback(
    () =>
      selectedStatusFilterOption &&
      selectedStatusFilterOption.length > 0 && (
        <StatusFilterLabel
          key="progress-services-status-filter"
          options={selectedStatusFilterOption}
          onClearFilters={clearStatusFilters}
        />
      ),
    [selectedStatusFilterOption]
  )

  const onSetGridColumns = (columns) => {
    setGridColumns(columns)
    if (onGridColumns) onGridColumns(columns)
  }

  const onSetGridDataSource = (dataSource) => {
    setGridDataSource(dataSource)
    if (onDataSource) onDataSource(dataSource)
  }

  if (template) translateFormulaServiceColumns(model, template.id)

  return showGrid || !filtered || filter.length > 1 ? (
    <div className="subgrid" data-testid="progress-service-grid">
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <SimpleGrid
        loadFlexColumns
        model={model}
        sort={[{ field: 'id', dir: 'desc' }]}
        filter={gridFilters}
        clearAllFiltersCallback={clearAllFiltersCallback}
        clearAllFiltersVisibility={hasFilterApplied}
        labels={labels.concat([dateFilterLabel(), statusFilterLabel()])}
        contextMenuItems={contextMenuItems}
        gridTitle={gridTitle}
        icons={icons}
        templateId={templateId}
        columnCellFactory={<CellFactory type={model.paramName} opts={{ request }} />}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onRowRender={onRowRender}
        onGridColumns={onSetGridColumns}
        onDataSource={onSetGridDataSource}
        selectFiltering={false}
        additionalEntities={[{ foreignKey: 'progress_service_statuses' }]}
        selecting
      />
    </div>
  ) : null
}

ProgressServicesGrid.propTypes = {
  parentItem: PropTypes.oneOfType([PropTypes.object]),
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  setInEditMode: PropTypes.func,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired,
  filtered: PropTypes.bool,
  showGrid: PropTypes.bool,
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
  labels: PropTypes.arrayOf(PropTypes.object),
  model: PropTypes.oneOfType([PropTypes.object]),
  requestIdPin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPlanningGrid: PropTypes.bool
}

ProgressServicesGrid.defaultProps = {
  parentItem: undefined,
  selectedItem: undefined,
  onRowClick: () => {},
  setInEditMode: () => {},
  filtered: true,
  showGrid: false,
  labels: [],
  isPlanningGrid: false
}
