import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@progress/kendo-react-tooltip'
import '/src/static/css/core/grid/grid.css'

export default function BasicGridHeader({ gridTitle, itemsQuantity, labels, children, extraLabel}) {
  return (
    <div className="entity-grid-header">
      <div className="entity-grid-header-default">
        <div className="name-labels">
          <div className="entity-grid-name">
            <span className="grid-title">
              <Tooltip anchorElement="target" align="left" parentTitle>
                <span title={gridTitle}>{gridTitle}</span>
              </Tooltip>
            </span>
            {itemsQuantity !== undefined && (
              <span key="count-label" className="count-label">
                {itemsQuantity}
              </span>
            )}
          </div>

          {extraLabel !== null && extraLabel !== undefined && (
              <div>{extraLabel}</div>
            )}

          <div key="labels" className="grid-labels">
            {labels}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

BasicGridHeader.propTypes = {
  gridTitle: PropTypes.string.isRequired,
  itemsQuantity: PropTypes.number,
  labels: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]),
  extraLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

BasicGridHeader.defaultProps = {
  itemsQuantity: undefined,
  labels: [],
  extraLabel: null,
  children: undefined
}
