import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { MdTraffic } from 'react-icons/md'
import I18n from '/src/utils/translations'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import CircleStatusFilterPopup from '/src/ui/core/popups/circle_status_filter_popup'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function CircleStatusFilterIcon({
  labelClassName,
  statuses,
  statusDescription,
  isStatusSelected,
  onClearFilters,
  onChangeFilter,
  popupOpened,
  setPopupOpened,
  children
}) {
  const popupButtonRef = useRef(null)
  const isFilterSelected = statuses.some((status) => status.filtered)

  return (
    <CustomTooltip position="bottom" parentTitle>
      <PopupAnchored
        body={
          <CircleStatusFilterPopup
            labelClassName={labelClassName}
            statuses={statuses}
            statusDescription={statusDescription}
            isStatusSelected={isStatusSelected}
            setPopupOpened={setPopupOpened}
            onClearFilters={onClearFilters}
            onChangeFilter={onChangeFilter}
          >
            {children}
          </CircleStatusFilterPopup>
        }
        popupButtonRef={popupButtonRef}
        forceOpen={popupOpened}
        setForceOpen={setPopupOpened}
        onClick={() => setPopupOpened(!popupOpened)}
      >
        <button
          id="open-filter-popup-button"
          data-testid="status-filter-button"
          type="button"
          title={I18n.t('actions.status_filter')}
          className={`btn-icon grid-header-icon
          ${popupOpened ? 'active' : ''}
          ${isFilterSelected ? 'selected-filter' : ''}`}
          ref={popupButtonRef}
        >
          <MdTraffic />
        </button>
      </PopupAnchored>
    </CustomTooltip>
  )
}

CircleStatusFilterIcon.propTypes = {
  labelClassName: PropTypes.string,
  statuses: PropTypes.array,
  statusDescription: PropTypes.func,
  isStatusSelected: PropTypes.func,
  onClearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  popupOpened: PropTypes.bool,
  setPopupOpened: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

CircleStatusFilterIcon.defaultProps = {
  labelClassName: '',
  statuses: [],
  statusDescription: (_) => '',
  isStatusSelected: (_) => false,
  onClearFilters: () => {},
  onChangeFilter: () => {},
  popupOpened: false,
  setPopupOpened: (_) => {},
  children: <div />
}
