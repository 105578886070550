// eslint-disable-next-line
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { formatCurrency, normalizeDateToString } from '/src/utils/project_formatter'

const cannotEditStatuses = ['on_hold', 'pending_authorization', 'authorized', 'done']

export function updateEstimateCost(estimate, services) {
  const totalPrice = services.reduce((acc, service) => {
    const {
      application_price: applicationPrice,
      materials_price: materialsPrice,
      equipment_price: equipmentPrice,
      other_price: otherPrice
    } = service
    const price = applicationPrice + materialsPrice + equipmentPrice + otherPrice
    return acc + price
  }, 0)

  return { ...estimate, estimate_cost: totalPrice }
}

export const updateEstimatesWithConstruction = (estimates, requests, constructionServices) => {
  return Object.entries(estimates).reduce((acc, [requestId, estimate]) => {
    const request = requests.find((request) => Number(request.id) === Number(requestId))
    if ([6, 7, 8].includes(request.request_status_id)) {
      const services = constructionServices[requestId] || []
      acc[String(requestId)] = updateEstimateCost(estimate, services)
      return acc
    }
    acc[String(requestId)] = estimate
    return acc
  }, {})
}

export function estimateIsReadOnly(estimate, estimateStatuses) {
  const statusId = estimate.estimate_status_id
  const status = estimateStatuses[statusId]

  if (!statusId || !status) return false

  return status && cannotEditStatuses.includes(status.i18n_id)
}

export function estimatePrice(estimate, project) {
  const price = estimate.estimate_cost || 0

  return formatCurrency(price, project)
}

export function estimatesPrice(estimates, project) {
  const price = estimates.reduce((acc, estimate) => {
    return acc + (estimate.estimate_cost || 0)
  }, 0)

  return formatCurrency(price, project)
}

export function getEstimateByRequest(estimates) {
  return estimates.reduce((acc, estimate) => {
    acc[estimate.request_id] = estimate
    return acc
  }, {})
}

export default class EstimateModel {
  constructor() {
    this.paramName = EstimateModel.paramName
  }

  static paramName = 'estimate'

  name = I18n.t('estimate.title')

  singularName = I18n.t('estimate.title')

  route = 'estimates'

  flexible = false

  cannotEditStatuses = cannotEditStatuses

  columns = [
    {
      id: 1,
      description: 'estimate_date',
      title: I18n.t('requests.schedule_form.date'),
      columnSize: 'width-100',
      type: 'date',
      required: true,
      default: normalizeDateToString(new Date())
    },
    {
      id: 2,
      description: 'responsible_id',
      title: I18n.t('inspections.responsible_id'),
      foreignKey: 'employees',
      foreignAttribute: 'responsible_id',
      searchFields: ['name', 'last_name'],
      searchExtraQuery: { where: { '[work_positions][operational]': 1 } },
      searchRoute: 'employees',
      columnSize: 'width-100',
      textDisplayFields: ['full_name'],
      textField: 'full_name',
      type: 'search',
      required: true
    }
  ]
}
