import { IntlService } from '@progress/kendo-react-intl'
import I18n from '/src/utils/translations'
import { normalizeDateToString, formattedLocaleDate } from '/src/utils/project_formatter'
import { notifyInfo } from '/src/ui/core/dialogs/notifications'

export const getCurrentDate = () => new Date().toISOString().slice(0, 10)
export const getPrevCurrentDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().slice(0, 10)
}

export const isDateRange = (value) => {
  const { start, end } = { ...value }

  if (!start || !end) return false
  if (isValueData(start) && isValueData(end)) return true
  return false
}

export const isPartialISODate = (str) => {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(str)) return false

  const date = new Date(`${str}T00:00:00.000Z`)

  try {
    return date.toISOString().slice(0, 10) === str
  } catch {
    return false
  }
}

export const isValueData = (value) => {
  return value instanceof Date || isPartialISODate(value) || isDateRange(value)
}

/**
 * Set the ISO datetime string millisseconds to 001
 *
 * @param datetime - the datetime string in ISO format
 * @returns { string } - a datetime string with 1 millisseconds in ISO format
 */
export const setToOneMillissecond = (datetime) =>
  `${datetime.substr(0, datetime.length - 4)}001${datetime.substr(datetime.length - 1)}`

export const addOneSecond = (datetime) => {
  const dateObj = new Date(datetime);
  dateObj.setSeconds(dateObj.getSeconds() + 1);
  return dateObj.toISOString();
};

/**
 * Add a desired number of days to a date object
 *
 * @param date - the date to add the days
 * @param days { integer } - the quantity of the days to be added to the date object
 * @returns { date } - a new date object
 */
export const addDays = (date, days) => {
  if (typeof days !== 'number') {
    throw new TypeError('You should provide a number as a parameter')
  }

  const newDate = new Date(date)
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

/**
 * Generate dates from a start date to a stop date
 *
 * @param startDate { date } - the start date object
 * @param stopDate { date } - the stop date object
 * @returns { array } - an array from a startDate to a stopDate
 */
export const createDateRange = (startDate, stopDate) => {
  if (!(startDate instanceof Date && stopDate instanceof Date)) {
    throw new TypeError('You should provide date objects as parameter(s)')
  }

  const dateArray = []
  let currentDate = startDate

  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate))
    currentDate = addDays(currentDate, 1)
  }

  return dateArray
}

/**
 * It will create a date range filter readable by the query reader on the API
 * @param referenceDay
 * @returns array of filters
 */
export const createSingleDateRangeFilter = (referenceDay) => {
  if (!(referenceDay instanceof Date)) return []

  const referenceDayNormalized = normalizeDateToString(referenceDay)
  const earliestDate = normalizeDateToString(new Date(1970, 0, 1))
  const latestDate = normalizeDateToString(new Date(3020, 0, 1))

  return [
    { column: 'start_date', type: 'between', value: [earliestDate, referenceDayNormalized] },
    { column: 'end_date', type: 'between', value: [referenceDayNormalized, latestDate] }
  ]
}

export const createSingleDateFilter = (referenceDay) => {
  if (!(referenceDay instanceof Date)) return []

  const referenceDayNormalized = normalizeDateToString(referenceDay)

  return [{ type: 'progress', value: referenceDayNormalized }]
}

/**
 *  * It will create a date range filter readable
 *  by the query reader on the API based on the reference Day
 * @param referenceDay
 * @returns array of filters
 */
const createWeekDateRangeFilter = (referenceDay, startOnMonday) => {
  if (!(referenceDay instanceof Date)) return []

  let weekDay = referenceDay.getDay()
  if (startOnMonday) {
    weekDay = weekDay === 0 ? 6 : weekDay - 1
  }
  let weekStart = new Date(new Date(referenceDay).setDate(referenceDay.getDate() - weekDay))
  let weekEnd = new Date(new Date(weekStart).setDate(weekStart.getDate() + 6))

  weekStart = normalizeDateToString(weekStart)
  weekEnd = normalizeDateToString(weekEnd)

  return { weekStart, weekEnd }
}

export const betweenDatesFilter = (referenceDay, startOnMonday = false) => {
  if (!(referenceDay instanceof Date)) return []

  const { weekStart, weekEnd } = createWeekDateRangeFilter(referenceDay, startOnMonday)

  const earliestDate = normalizeDateToString(new Date(1970, 0, 1))
  const latestDate = normalizeDateToString(new Date(3020, 0, 1))

  return [
    { column: 'start_date', type: 'between', value: [earliestDate, weekEnd] },
    { column: 'end_date', type: 'between', value: [weekStart, latestDate] }
  ]
}

export const rangeDateFilter = (referenceDay, startOnMonday = false) => {
  if (!(referenceDay instanceof Date)) return []

  const { weekStart, weekEnd } = createWeekDateRangeFilter(referenceDay, startOnMonday)

  return [{ type: 'progress', value: [weekStart, weekEnd] }]
}

/**
 * Converts javascript date to only date string
 * @param jsDate (Date())
 * @returns date string in format YYYY-MM-DD from new Date() object.
 */
export const dateToYYYYMMDD = (jsDate) => {
  const month = jsDate.getMonth() + 1
  const monthText = month < 10 ? `0${month}` : month
  return `${jsDate.getFullYear()}-${monthText}-${jsDate.getDate()}`
}

/**
 * Converts date or date range input to string
 * @param date
 * @returns String date in project format OR error message
 */
export const parseDateToString = (date, project) => {
  try {
    if (isDateRange(date)) {
      const start = { project, parser: new IntlService(), value: date.start }
      const end = { project, parser: new IntlService(), value: date.end }

      return I18n.t('form.inputs.date_range.between', {
        start: formattedLocaleDate(start),
        end: formattedLocaleDate(end)
      })
    }

    const opts = { project, parser: new IntlService(), value: date }
    return formattedLocaleDate(opts)
  } catch (e) {
    notifyInfo(I18n.t('filtering.invalid_date'))
    return I18n.t('filtering.invalid_value')
  }
}
