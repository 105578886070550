import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { MdCheckCircleOutline, MdErrorOutline, MdPrint } from 'react-icons/md'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { estimateIsReadOnly } from '/src/models/estimate'
import ActionButton from '/src/ui/core/buttons/action_button'
import InputBoolean from '/src/ui/core/inputs/input_boolean'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/estimates/estimate.css'

export default function EstimateFormState({
  statuses,
  children,
  estimates,
  useConstructionPrices,
  setUseConstructionPrices,
  hasRequestsInConstruction
}) {
  const isEstimateEmpty = estimates.length === 0

  const allEstimatesAreReadOnly = estimates.every((estimate) => {
    return estimateIsReadOnly(estimate, statuses)
  })

  const renderPrintButton = () => (
    <ActionButton
      icon={<MdPrint />}
      title={I18n.t('estimate_services.print')}
      onClick={() => dispatch({ type: BusEvents.PRINT_PDF })}
      className={'estimate-state__print-button button-action'}
    />
  )

  const constructionPricesToggle = () => {
    const inputProps = {
      id: 'use-construction-prices-toggle',
      value: useConstructionPrices,
      onChange: (_, value) => setUseConstructionPrices(value),
    }

    return (
      <div className="estimate-state__input-boolean">
        <span>{I18n.t('estimate.use_construction_prices')}</span>
        <InputBoolean inputProps={inputProps}/>
      </div>
    )
  }

  if (!isEstimateEmpty && !allEstimatesAreReadOnly)
    return (
      <Fragment>
        {children}
        <div className="estimate-state__bottom-bar">
          {renderPrintButton()}
          {hasRequestsInConstruction && constructionPricesToggle()}
        </div>
      </Fragment>
    )

  const icon = isEstimateEmpty ? <MdErrorOutline /> : <MdCheckCircleOutline />
  const messageKey = isEstimateEmpty ? 'estimate.no_estimate_message' : 'estimate.info_message'
  const className = isEstimateEmpty ? 'empty-estimate-state' : 'approved-estimate-state'

  return (
    <Fragment>
      <div className="estimate-state">
        <div className={`${className}`}>{icon}</div>
        {`${I18n.t(messageKey)}`}
      </div>
      <div className="estimate-state__bottom-bar--right">
        {hasRequestsInConstruction && constructionPricesToggle()}
        {renderPrintButton()}
      </div>
    </Fragment>
  )
}

EstimateFormState.propTypes = {
  estimates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      estimate_status_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  useConstructionPrices: PropTypes.bool.isRequired,
  setUseConstructionPrices: PropTypes.func.isRequired,
  hasRequestsInConstruction: PropTypes.bool.isRequired
}

EstimateFormState.defaultProps = {
  estimates: [],
  statuses: {},
  children: null
}
