import { store } from 'react-context-hook'
import { batchEntities } from '/src/utils/constants/batch_entities'
import { CustomI18n } from '/src/utils/custom_i18n'

export default function useClearBatchEntities() {
  const clearBatchEntities = () => {
    batchEntities.forEach((entityName) => {
      store.set(entityName, {})
      store.set('last_sync', {})
    })
    CustomI18n.clearStore()
    CustomI18n.clearCache()
  }

  return clearBatchEntities
}
