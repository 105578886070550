import React, { useRef } from 'react'
import { MdFormatIndentIncrease } from 'react-icons/md'
import PropTypes from 'prop-types'
import PopupMenu from '/src/ui/core/popups/popup_menu'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

// eslint-disable-next-line max-lines-per-function
export default function DateFilter({
  items, position, title
}) {
  const popupButtonRef = useRef(null)

  return (
    <PopupMenu items={items} popupButtonRef={popupButtonRef}>
        <CustomTooltip position={position} parentTitle>
          <button
            id="open-filter-popup-button"
            type="button"
            className="btn-icon grid-header-icon"
            data-testid="date-filter-button"
            title={title}
            ref={popupButtonRef}
          >
            <MdFormatIndentIncrease />
          </button>
        </CustomTooltip>
      </PopupMenu>
    )
}

DateFilter.propTypes = {
  items: PropTypes.array.isRequired,
  position: PropTypes.string,
  title: PropTypes.string.isRequired
}

DateFilter.defaultProps = {
  items: [],
  position: 'bottom'
}
