import { useState, useEffect } from 'react'
import useFetch from '/src/hooks/api/fetch'

export default function useFetchSections(templateId, useLoading = false) {
  const [sections, setSections] = useState(null)
  const [fetchLoading, setFetchLoading] = useState(true)

  const { fetch } = useFetch()

  useEffect(() => {
    setFetchLoading(true)
    if (!templateId) return
    const params = {
      query: { where: { eav_template_id: templateId } },
      additionalResource: { path: 'eav_columns' }
    }

    fetch('eav_sections', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        setSections(data?.filter((section) => section.eav_columns.length) ?? [])
        setFetchLoading(false)
      }
    })
  }, [templateId])

  if (useLoading) return [sections, fetchLoading]

  return sections
}
