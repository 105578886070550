import { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFetch from '/src/hooks/api/fetch'
import useTranslations from '/src/hooks/api/translations'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { includeDefaultValuesOnModel } from '/src/utils/columns_formatter'

export default function useRequestInitialFetches(requestModel, onChangeColumn) {
  const [requestTemplate, setRequestTemplate] = useState(null)
  const [sectionsColumns, setSectionsColumns] = useState([])
  const [contract, setContract] = useState(null)

  const [templateId, setTemplateId] = useQueryParam('eav_template_id', NumberParam)

  const [subproject] = useStore('subproject')
  const loadingTranslations = useTranslations()
  const { fetch } = useFetch()

  useEffect(() => {
    const initialFetch = async () => {
      const requestTemplateId = subproject.request_eav_template_id
      setTemplateId(requestTemplateId, 'replaceIn')

      const contractParams = { query: { where: { id: subproject.project_contract_id } } }
      const templateParams = {
        query: {
          where: {
            id: requestTemplateId,
            template_type: 'request'
          }
        }
      }

      const sectionsParams = {
        additionalResource: { path: 'eav_columns' },
        query: { where: { eav_template_id: requestTemplateId } }
      }

      const contractsData = fetch('project_contracts', contractParams, { useParse: true })
      const templateData = fetch('eav_templates', templateParams, { useParse: true })
      const sectionsData = fetch('eav_sections', sectionsParams, { useParse: true })

      const [
        { data: contracts },
        { data: template },
        { data: sections }
      ] = await Promise.all([
        contractsData,
        templateData,
        sectionsData
      ])

      setRequestTemplate(template[0])

      const newSectionsColumns = filterVisibleOnWeb(sections)
      setSectionsColumns(newSectionsColumns)

      if (!contracts?.[0]?.id) return

      setContract(contracts[0])
      includeDefaultValuesOnModel(contracts[0], requestModel, onChangeColumn)
    }

    if (!subproject?.id || !subproject.request_eav_template_id || loadingTranslations) return

    initialFetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subproject?.id, loadingTranslations])

  return [requestTemplate, sectionsColumns, templateId, contract]
}
