import useFetch from '/src/hooks/api/fetch'
import { notifySuccess, notifyError } from '/src/ui/core/dialogs/notifications'
import I18n from '/src/utils/translations'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { useCallback } from 'react'

// eslint-disable-next-line max-lines-per-function
export default function useRejectRequest(showConfirmation) {
  const { fetch } = useFetch()

  const showRejectConfirmation = useCallback((order) => {
    const rejectSuccess = () => {
      notifySuccess(
        I18n.t('notification.action_success', {
          action: I18n.t('actions.past.reject'),
          model: I18n.t('requests.title'),
          id: order.request_id
        })
      )
      return dispatch(BusEvents.RELOAD_GRID)
    }

    const rejectParams = {
      title: I18n.t('requests.confirmation_modal.reject_title'),
      description: I18n.t('requests.confirmation_modal.reject_description'),
      actionButtonText: I18n.t('actions.reject'),
      cancelButtonText: I18n.t('actions.cancel'),
      buttonColor: '#B33939',
      onSuccess: () => rejectSuccess,
      requestParams: {
        resourcePath: 'orders',
        requestAction: 'UPDATE',
        httpAction: 'put',
        resourceId: order.id,
        additionalResource: { path: 'reject' }
      }
    }

    return showConfirmation(rejectParams)
  }, [showConfirmation])

  const rejectAction = useCallback((dataItem) => {
    dispatch(BusEvents.SHOW_LOADING_DIALOG)

    const params = {
      query: { where: { request_id: dataItem.id, 'order_statuses][description': 'pending' } }
    }

    fetch('orders', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        dispatch(BusEvents.HIDE_DIALOG)

        if (!data.length) {
          notifyError(I18n.t('requests.notification.missing_order_error'))
          return
        }

        showRejectConfirmation(data[0])
      }
    })
  }, [showRejectConfirmation])

  return rejectAction
}
