import { useEffect, useState } from 'react'
import useRequestsPinned from '/src/hooks/requests_pinned'

export default function useFetchPinnedRequests() {
  const [hasFetchedPinnedRequests, setHasFetchedPinnedRequests] = useState(false)
  const { fetchPinnedRequests } = useRequestsPinned()

  useEffect(() => {
    if (!hasFetchedPinnedRequests) fetchPinnedRequests(setHasFetchedPinnedRequests)
  }, [hasFetchedPinnedRequests])

}
