import I18n from '/src/utils/translations'
import { isEmpty } from '/src/utils/object'

export const CustomI18n = {}

const convertLocaleApiKey = (key) => key.replace(/\./, '.index.')
const getTranslations = () => {
  if (!CustomI18n.cachedTranslations || isEmpty(CustomI18n.cachedTranslations)) {
    try {
      const data = JSON.parse(localStorage.getItem('translations'));
      if (data !== null && typeof data === 'object' && !Array.isArray(data))
        CustomI18n.cachedTranslations = data
      else {
        CustomI18n.cachedTranslations = {}
      }
    } catch (e) {
      CustomI18n.cachedTranslations = {}
    }
  }
  return CustomI18n.cachedTranslations
}

CustomI18n.t = (key, options = {}) => {
  const translations = getTranslations()
  const convertedKey = convertLocaleApiKey(key)
  const customTranslation = translations[convertedKey]

  if (customTranslation) {
    return customTranslation.replace(/%\{(\w+)\}/g, (_, variable) => options[variable] ?? `%{${variable}}`)
  }

  return I18n.t(key, options)
}

CustomI18n.clearCache = () => {
  if (!CustomI18n.cachedTranslations) return
  CustomI18n.cachedTranslations = {}
}

CustomI18n.clearStore = () => {
  localStorage.setItem('translations', '{}')
}
