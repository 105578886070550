import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import ProgressForm from '/src/ui/domain/progresses/progress_form'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'

export default function ProgressFormModal({ model, templateId }) {
  const { requestIds } = useGetPinnedRequests()
  return (
    <GridFormModal modelName={model.paramName}>
      <ProgressForm templateId={templateId} requestIds={requestIds} />
    </GridFormModal>
  )
}

ProgressFormModal.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]),
  templateId: PropTypes.number
}

ProgressFormModal.defaultProps = {
  model: {},
  templateId: 0
}
