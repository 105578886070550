import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ScaffoldingPartDetailSummary from '/src/ui/domain/scaffoldings/scaffolding_part_detail_summary'
import GeneralBlock from '/src/ui/core/blocks/general_block'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import { transformSectionsWithColumns, filterSectionOnSidePanel } from '/src/models/concerns/transform_sections'
import ScaffoldingPartCellFactory from '/src/ui/domain/scaffoldings/scaffolding_part_cell_factory'
import ScaffoldingPartActionsFeed from '/src/ui/domain/scaffoldings/scaffolding_part_actions_feed'
import '/src/static/css/model_side_panel.css'

const isColumnVisible = (column) => !column.hideOnSidePanel

export default function ScaffoldingPartsSidePanel({ parentDataItem, dataItem, onClose, contextMenuItems }) {
  const scaffoldingPartSections = useMemo(() => {
    const { sections, columns } = new ScaffoldingPartModel()
    return transformSectionsWithColumns(isColumnVisible, filterSectionOnSidePanel(sections), columns)
  }, [])

  const blocks = useMemo(() => {
    const generalBlocks = scaffoldingPartSections.map(({ id, title, columns }) => ({
      component: (
        <GeneralBlock
          model={{
            columns: columns.filter(({ visible_on_web }) => visible_on_web)
          }}
          dataItem={dataItem}
          columnFactory={<ScaffoldingPartCellFactory dataItem={dataItem} />}
        />
      ),
      title: title,
      type: 'section',
      key: id
    }))
    const summaryBlock = parentDataItem
      ? [
          {
            component: (
              <ScaffoldingPartDetailSummary
                dataItem={dataItem}
                scaffolding={parentDataItem}
                contextMenuItems={contextMenuItems}
              />
            ),
            title: '',
            type: 'track'
          }
        ]
      : []
    const actionBlock = {
      component: <ScaffoldingPartActionsFeed parentDataItem={parentDataItem} scaffoldingPart={dataItem} />,
      title: I18n.t('scaffolding_parts.scaffolding_part_detail.actions'),
      type: 'section'
    }
    return [...summaryBlock, ...generalBlocks, actionBlock]
  }, [parentDataItem, dataItem])

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('scaffolding_parts.scaffolding_part_detail.side_panel_title')}
        closable
        onClose={onClose}
        blocks={blocks}
      />
    </div>
  )
}

ScaffoldingPartsSidePanel.propTypes = {
  parentDataItem: PropTypes.oneOfType([PropTypes.object]),
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func.isRequired
}

ScaffoldingPartsSidePanel.defaultProps = {
  parentDataItem: undefined,
  dataItem: undefined
}
