import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'

export default function PrintListPDFFrontPage({ modelName, templateTitle, storeValues }) {
  return (
    <div className="print-list-pdf-header">
      <div className="print-list-pdf-header__content">
        <h1 className="print-list-pdf-header__title">
          {modelName} {I18n.t('estimate.pdf_header.report')}
        </h1>
        <hr className="print-list-pdf-header__divider" />

        <div className="print-list-pdf-header__info">
          <p className="print-list-pdf-header__info-item">
            <strong>{I18n.t('estimate.pdf_header.project')}: </strong>
            {storeValues.project?.description || 'N/A'}
          </p>
          <p className="print-list-pdf-header__info-item">
            <strong>{I18n.t('estimate.pdf_header.subproject')}: </strong>
            {storeValues.subproject?.description || 'N/A'}
          </p>
          <p className="print-list-pdf-header__info-item">
            <strong>{I18n.t('estimate.pdf_header.template')}: </strong>
            {templateTitle || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  )
}

PrintListPDFFrontPage.propTypes = {
  modelName: PropTypes.string.isRequired,
  templateTitle: PropTypes.string,
  storeValues: PropTypes.shape({
    project: PropTypes.object,
    subproject: PropTypes.object
  }).isRequired,
  scale: PropTypes.number.isRequired
}

PrintListPDFFrontPage.defaultProps = {
  templateTitle: undefined,
  scale: 0.5
}
