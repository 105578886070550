/* eslint-disable max-lines-per-function */
import { useCallback } from 'react'
import { store, useStore } from 'react-context-hook'
import lodash from 'lodash'
import useFetch from '/src/hooks/api/fetch'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useRequestsPinned(modelName) {
  const { fetch } = useFetch()
  const [subprojectFilters] = useStore('subproject_filters')

  const { selectedRequests } = useGetPinnedRequests()

  const isRequestFilled = useCallback((request) => {
    const { id, discipline_id, reason } = request
    return isPresent(id) && isPresent(discipline_id) && isPresent(reason)
  }, [])

  const isRequestsFilled = useCallback(() => {
    return Object.values(selectedRequests).every(isRequestFilled)
  }, [selectedRequests, isRequestFilled])

  const transformFilterableRequests = useCallback((requests) => {
    return requests.reduce((prevRequests, request) => {
      prevRequests[request.id] = request
      return prevRequests
    }, {})
  }, [])

  const fetchRequests = useCallback(
    (requestIds, loadedRequests) => {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: requestIds.join(',') } }
      }

      fetch('requests/slim', params, {
        onSuccess: ({ data }) => {
          const prevSubprojectFilters = store.get('subproject_filters') || {}
          store.set('subproject_filters', {
            ...prevSubprojectFilters,
            is_filter_active: prevSubprojectFilters?.is_filter_active,
            selected_requests: transformFilterableRequests([...loadedRequests, ...data.data])
          })
        }
      })
    },
    [fetch]
  )

  const updatePinnedRequests = useCallback(
    (requests) => {
      const prevSubprojectFilters = store.get('subproject_filters')

      const { isEqual } = lodash
      const newPinnedRequests = requests.map((request) =>
        prevSubprojectFilters?.selected_requests && prevSubprojectFilters.selected_requests[request.id]
          ? prevSubprojectFilters.selected_requests[request.id]
          : request
      )

      if (isEqual(newPinnedRequests, Object.keys(prevSubprojectFilters.selected_requests || {}))) return
      const idsNotFetched = newPinnedRequests.filter((request) => !isRequestFilled(request)).map(({ id }) => id)

      if (idsNotFetched.length > 0) {
        fetchRequests(idsNotFetched, newPinnedRequests)
      } else {
        store.set('subproject_filters', {
          ...prevSubprojectFilters,
          selected_requests: transformFilterableRequests(newPinnedRequests)
        })
        dispatch({ type: BusEvents.SELECTED_ROWS, triggeredModelName: modelName, payload: [] })
      }
    },
    [subprojectFilters]
  )

  const clearRequests = useCallback(
    (shouldCallApi = false) => {
      if (shouldCallApi) {
        const params = {
          requestAction: 'DELETE',
          httpAction: 'delete',
          data: { request_ids: [] }
        }

        fetch('user_pinned_requests/delete_pinned_requests', params)
      }
      store.set('subproject_filters', {})
    },
    []
  )

  const unpinRequests = useCallback(
    (requestIds) => {
      const currentSubprojectFilters = store.get('subproject_filters') || {}
      const nextSelectedRequests = { ...currentSubprojectFilters.selected_requests }
      requestIds.forEach((id) => delete nextSelectedRequests[id])

      const hasRequestPinned = !lodash.isEmpty(nextSelectedRequests)
      const isFilterActive = hasRequestPinned ? currentSubprojectFilters?.is_filter_active : false

      const params = {
        requestAction: 'DELETE',
        httpAction: 'delete',
        data: { request_ids: requestIds }
      }

      fetch('user_pinned_requests/delete_pinned_requests', params, {
        onSuccess: ({ data }) => {
          updatePinnedRequests(data.data.pinned_request_ids.map((requestId) => ({ id: requestId })))
        }
      })

      dispatch({ type: BusEvents.SELECTED_ROWS, triggeredModelName: modelName, payload: [] })
      store.set('subproject_filters', {
        ...currentSubprojectFilters,
        selected_requests: nextSelectedRequests,
        is_filter_active: isFilterActive
      })
    },
    []
  )

  const putPinnedRequests = useCallback(
    (requests) => {
      const params = {
        requestAction: 'UPDATE',
        httpAction: 'put',
        data: { request_ids: requests.map((request) => request.id) }
      }
      
      fetch('user_pinned_requests/pin_requests', params, {
        onSuccess: ({ data }) => {
          updatePinnedRequests(data.data.pinned_request_ids.map((requestId) => ({ id: requestId })))
          return
        }
      })
      if (!requests.filter((request) => !isRequestFilled(request)).length) updatePinnedRequests(requests)
    },
    [updatePinnedRequests]
  )

  const fetchPinnedRequests = useCallback((setHasFetchedPinnedRequests) => {
    setHasFetchedPinnedRequests(true)
    const params = {
      requestAction: 'READ',
      httpAction: 'get'
    }

    fetch('user_pinned_requests', params, {
      useParse: true,
      onSuccess: ({ data }) => {
        const requestIds = data.pinned_request_ids

        if (requestIds.length) {
          updatePinnedRequests(requestIds.map((requestId) => ({ id: requestId })))
        } else clearRequests()

        dispatch({ type: BusEvents.PINNED_REQUESTS_FETCH_SUCCESS })
      }
    })
  }, [fetch, updatePinnedRequests, clearRequests])

  return {
    clearRequests,
    unpinRequests,
    setPinnedRequests: putPinnedRequests,
    isRequestsFilled,
    fetchPinnedRequests
  }
}
