/* eslint-disable max-len */
import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import I18n from '/src/utils/translations'
import {
  MdEdit,
  MdClose,
  MdReplay,
  MdDelete,
  MdCalculate,
  MdChecklist,
  MdDisabledByDefault
} from 'react-icons/md'
import { FaCheckDouble } from 'react-icons/fa'
import DuplicateIcon from '/src/ui/core/icons/duplicate_icon'
import {
  editModalMenuItem,
  duplicateModalMenuItem,
  createInspectionModalMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import InspectionModel from '/src/models/inspection'
import { normalizeDateToString } from '/src/utils/project_formatter'
import useScaffoldingConfirmationPopup from '/src/ui/domain/scaffoldings/scaffolding_confirmation_popup'
import {
  dismantleMenuItem,
  addDimensionMenuItem,
  addModificationMenuItem
} from '/src/ui/domain/scaffoldings/scaffolding_parts_menu_entries'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/panels/side_panel.css'

const findStatuses = (statuses, statusId) => {
  if (!Object.keys(statuses).length) return false
  return statuses[statusId] && statuses[statusId].i18n_id
}

const successBtnColor = '#607D8B'
const inspectionModelParamName = InspectionModel.paramName

const actionParams = (opts) => {
  const {
    id,
    extraData,
    httpAction,
    scaffoldingAction, 
    scaffolding_status_id,
    additionalResourcePath
  } = opts

  return {
    resourcePath: 'scaffoldings',
    scaffoldingAction: scaffoldingAction || 'UPDATE',
    httpAction: httpAction || 'put',
    resourceId: id,
    additionalResource: {
      path: additionalResourcePath
    },
    data: {
      scaffolding_status_id,
      ...extraData
    }
  }
}

const partActionParams = (opts) => {
  const { id, httpAction, scaffoldingPartAction } = opts

  return {
    resourcePath: 'scaffolding_parts',
    scaffoldingPartAction: scaffoldingPartAction || 'UPDATE',
    httpAction: httpAction || 'put',
    resourceId: id
  }
}

export default function useScaffoldingContextMenu(
  showConfirmation,
  openScaffoldingDismantlePopup,
  openScaffoldingPartDismantlePopup,
  scaffoldingModel,
  scaffoldingPartModel,
  isSelectedScaffoldingDismantled,
  onAddDimensionMenuClick,
  onAddModificationMenuClick
) {
  const showActionConfirmation = useScaffoldingConfirmationPopup(showConfirmation)
  const [user] = useStore('user')
  const [statuses] = useStore('scaffolding_statuses')

  const history = useHistory()

  const dismantlePossibleStatus = scaffoldingModel.dismantleAllowedStatus
  const inspectionPossibleStatus = scaffoldingModel.inspectionAllowedStatuses

  const contextMenuItems = useMemo(
    () => [
      editModalMenuItem(
        (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        (_, dataItem) => {
          dispatch({
            type: BusEvents.OPEN_GRID_FORM_MODAL,
            modelName: scaffoldingModel.paramName,
            payload: { dataItem, formType: 'edit' }
          })
        }
      ),
      duplicateModalMenuItem(
        (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        (_, dataItem) => {
          dispatch({
            type: BusEvents.OPEN_GRID_FORM_MODAL,
            modelName: scaffoldingModel.paramName,
            payload: { dataItem, formType: 'duplicate' }
          })
        }
      ),
      {
        text: I18n.t('actions.duplicate_with_parts'),
        icon: <DuplicateIcon />,
        onClick: (_, dataItem) => {
          showActionConfirmation(
            dataItem,
            actionParams({
              id: dataItem.id,
              additionalResourcePath: 'duplicate_with_parts',
              httpAction: 'POST'
            }),
            {
              actionName: 'duplicate_with_parts',
              buttonColor: successBtnColor,
              actionText: I18n.t('scaffoldings.confirmation_modal.duplicate_with_parts_confirmation'),
              cancelText: I18n.t('scaffoldings.confirmation_modal.duplicate_with_parts_cancel')
            }
          )
        },
        visible: (dataItem) =>
          findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled' &&
          dataItem?.scaffolding_part_count > 0
      },
      {
        text: I18n.t('actions.ready_to_dismantle'),
        icon: <MdDisabledByDefault />,
        onClick: (_, dataItem) => {
          showActionConfirmation(
            dataItem,
            actionParams({
              id: dataItem.id,
              additionalResourcePath: 'ready_to_dismantle'
            }),
            {
              actionName: 'ready_to_dismantle',
              buttonColor: successBtnColor,
              actionText: I18n.t('scaffoldings.confirmation_modal.ready_to_dismantle_confirmation'),
              cancelText: I18n.t('scaffoldings.confirmation_modal.ready_to_dismantle_cancel')
            }
          )
        },
        visible: (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) === 'erected'
      },
      {
        text: I18n.t('actions.dismantle'),
        icon: <MdClose />,
        onClick: (_, dataItem) => openScaffoldingDismantlePopup(dataItem),
        visible: (dataItem) => dismantlePossibleStatus.includes(findStatuses(statuses, dataItem.scaffolding_status_id))
      },
      {
        text: I18n.t('actions.revert_to_erected'),
        icon: <MdReplay />,
        onClick: (_, dataItem) => {
          showActionConfirmation(
            dataItem,
            actionParams({
              id: dataItem.id,
              additionalResourcePath: 'revert'
            }),
            {
              actionName: 'revert',
              buttonColor: successBtnColor,
              actionText: I18n.t('scaffoldings.confirmation_modal.revert_confirmation'),
              cancelText: I18n.t('scaffoldings.confirmation_modal.revert_cancel')
            }
          )
        },
        visible: (dataItem) =>
          findStatuses(statuses, dataItem.scaffolding_status_id) === 'ready_to_dismantle' ||
          findStatuses(statuses, dataItem.scaffolding_status_id) === 'dismantled'
      },
      createInspectionModalMenuItem(
        (dataItem) => inspectionPossibleStatus.includes(findStatuses(statuses, dataItem.scaffolding_status_id)),
        (_, dataItem) => {
          dispatch({
            type: BusEvents.OPEN_MODAL,
            triggeredModelName: inspectionModelParamName,
            payload: {
              dataItem: {
                responsible_id: user.employee_id,
                inspect_date: normalizeDateToString(new Date()),
                ...dataItem
              }
            }
          })
        }
      ),
      {
        text: I18n.t('actions.delete'),
        icon: <MdDelete />,
        visible: (dataItem) => findStatuses(statuses, dataItem.scaffolding_status_id) !== 'dismantled',
        onClick: (_, dataItem) => {
          showActionConfirmation(dataItem, actionParams({ ...dataItem, httpAction: 'delete' }), {
            actionName: 'delete'
          })
        }
      }
    ],
    [statuses, showActionConfirmation, scaffoldingModel.paramName, user]
  )

  const sidePanelMenuItems = [
    {
      text: I18n.t('actions.create_inspection'),
      icon: <MdChecklist />,
      onClick: (e, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_MODAL,
          triggeredModelName: inspectionModelParamName,
          payload: {
            dataItem: {
              responsible_id: user?.employee_id,
              inspect_date: normalizeDateToString(new Date()),
              ...dataItem
            }
          }
        })
      }
    },
    {
      text: I18n.t('requests.request_detail.inspections'),
      icon: <FaCheckDouble />,
      onClick: (e, dataItem) => {
        history.push(`/inspections?inspected_id=${dataItem.id}&inspected_type=scaffolding`)
      }
    }
  ]

  const subGridContextMenuItems = [
    editModalMenuItem(
      () => !isSelectedScaffoldingDismantled,
      (_, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scaffoldingPartModel.paramName,
          payload: { dataItem }
        })
    ),
    duplicateModalMenuItem(
      () => !isSelectedScaffoldingDismantled,
      () => {}
    ),
    addDimensionMenuItem(() => !isSelectedScaffoldingDismantled, onAddDimensionMenuClick),
    addModificationMenuItem(
      (dataItem) => !isSelectedScaffoldingDismantled && dataItem.total > 0,
      onAddModificationMenuClick
    ),
    dismantleMenuItem(
      (dataItem) => !isSelectedScaffoldingDismantled && dataItem.total > 0,
      openScaffoldingPartDismantlePopup
    ),
    {
      text: I18n.t('actions.delete'),
      icon: <MdDelete />,
      visible: () => !isSelectedScaffoldingDismantled,
      onClick: (_, dataItem) => {
        showActionConfirmation(dataItem, partActionParams({ ...dataItem, httpAction: 'delete' }), {
          actionName: 'delete'
        })
      }
    }
  ]

  const subGridSidePanelMenuItems = [
    {
      text: I18n.t('scaffolding_parts.menu_items.modifications'),
      icon: <MdCalculate />,
      onClick: () => {
        dispatch({
          type: BusEvents.OPEN_SIDE_PANEL_FORM,
          formType: 'new',
          actionType: 'modification'
        })
      },
      visible: (dataItem) => dataItem.total > 0
    },
    {
      text: I18n.t('requests.request_detail.edit'),
      icon: <MdEdit />,
      onClick: (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scaffoldingPartModel.paramName,
          payload: { dataItem }
        })
      }
    }
  ]

  return [
    contextMenuItems,
    sidePanelMenuItems, 
    subGridContextMenuItems,
    subGridSidePanelMenuItems
  ]
}
