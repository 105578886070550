import React from 'react'
import PropTypes from 'prop-types'
import { IntlService } from '@progress/kendo-react-intl'
import I18n from '/src/utils/translations'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { formattedLocaleDate } from '/src/utils/project_formatter'
import '/src/static/css/core/popups/print_list_pdf.css'

export default function PrintListPDFPageTemplate({ model, numItems, pageNum, totalPages, scale, storeValues }) {
  const scaleClass = `print-list-pdf-header__footer scale-${scale.toString().replace('.', '')}`
  const title = I18n.t('dialogs.configure_print_grid_file.pdf_file.title', {
    modelName: model.name,
    numItems,
    pageNum,
    totalPages
  })
  const reportDate = new Date().toISOString()

  const opts = {
    project: storeValues.project,
    parser: new IntlService(),
    value: reportDate
  }

  return (
    <div className="grid-pdf-file">
      {pageNum === 1 && (
        <React.Fragment>
          <img className="kaefer-logo-full" src="/static/svg/kaefer-logo.svg" alt="" />
          <p className={scaleClass}>
            <strong>{I18n.t('estimate.pdf_header.report_date')}</strong>{' '}
            <FaRegCalendarAlt style={{ color: '#455A64' }} /> {formattedLocaleDate(opts)}
          </p>
        </React.Fragment>
      )}

      <img className="dpms-logo-full" src="/static/svg/logo-red.svg" alt="" />

      {pageNum !== 1 && <div className="grid-pdf-title">{title}</div>}
    </div>
  )
}

PrintListPDFPageTemplate.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  numItems: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
}
