import React from 'react'
import PropTypes from 'prop-types'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'
import PrintListPDFOtherColumns from '/src/ui/core/grid/pdf_print/print_list_pdf_other_columns'


export default function PrintListPDFSections({ item, project, sections, orderedComments, shouldPrintHiddenColumns }) {
  if (!sections?.length) return null

  return (
    <div className='print-list-pdf-sections'>
      {sections.map((section) => {
        const { title, id, eav_columns: columns } = section
        const orderedColumns = sortArrayOfObjectsByNumber(columns, 'position')
        const filteredColumns = orderedColumns.filter((column) => column.column_type?.description !== 'picture')

        if (!filteredColumns.length) return null

        return (
          <div key={`section-${id}`} className='print-list-pdf-sections__wrapper'>
            <div className='print-list-pdf-sections__header'>
              {title}
            </div>

            <PrintListPDFOtherColumns
              item={item}
              project={project}
              columns={filteredColumns}
              orderedComments={orderedComments}
              shouldPrintHiddenColumns={shouldPrintHiddenColumns}
            />
          </div>
        )
      })}
    </div>
  )
}

PrintListPDFSections.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]).isRequired,
  project: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      position: PropTypes.number,
      eav_columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          title: PropTypes.string,
          description: PropTypes.string,
          position: PropTypes.number,
          column_type: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            description: PropTypes.string
          })
        })
      )
    })
  ).isRequired,
  orderedComments: PropTypes.shape({
    value: PropTypes.string,
    index: PropTypes.number
  })
}

PrintListPDFSections.defaultProps = {
  orderedComments: undefined,
  shouldPrintHiddenColumns: true
}
