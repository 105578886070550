import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import classNames from 'classnames'
import { MdCheck } from 'react-icons/md'
import { createSingleDateFilter, rangeDateFilter } from '/src/utils/calendar'
import DateFilter from '/src/ui/core/popups/date_filter'
import DateColumn from '/src/ui/core/grid/flexible_column/date'

const cleanFilter = (filter) => {
  return filter.filter((f) => !['progress'].includes(f.type))
}

export default function useProgressesDateFilter({ onFilter, onSelectedOptionChange }) {
  const [selectedOption, setSelectedOption] = useState('all')
  const [project] = useStore('project')
  const startOnMonday = !project?.start_day_of_week || project.start_day_of_week === 'mon'

  const resetDateFilters = useCallback(() => {
    onSelectedOptionChange(null)
    setSelectedOption('all')
  }, [setSelectedOption, onFilter, onSelectedOptionChange])

  const hasDateFilter = useMemo(() => selectedOption !== 'all', [selectedOption])

  const extractDateRange = (filterWeek) => {
    const startDate = filterWeek[0].value[0]
    const endDate = filterWeek[0].value[1]

    return (
      <span>
        {`${I18n.t('progress_services.date_filter.week')}: `}
        <DateColumn value={startDate} />
        {` ${I18n.t('progress_services.date_filter.to')} `}
        <DateColumn value={endDate} />
      </span>
    )
  }

  const changeFilter = useCallback((filterOption) => {
    let filters = []
    const today = new Date()
    let selectedFilter = ''

    switch (filterOption) {
      case 'today':
        filters = createSingleDateFilter(today)
        selectedFilter = I18n.t('progress_services.date_filter.today')
        break
      case 'current_week':
        filters = rangeDateFilter(today, startOnMonday)
        selectedFilter = extractDateRange(filters)
        break
      case 'previous_week':
        const previousWeek = new Date();
        previousWeek.setDate(today.getDate() - 7);
        filters = rangeDateFilter(previousWeek, startOnMonday)
        selectedFilter = extractDateRange(filters)
        break
      default:
        filters = []
        selectedFilter = null
    }

    setSelectedOption(filterOption)
    onSelectedOptionChange(selectedFilter)
    onFilter((f) => [...cleanFilter(f), ...filters])
  }, [startOnMonday, setSelectedOption, onSelectedOptionChange, onFilter])

  const items = useMemo(() => (
    ['all', 'today', 'current_week', 'previous_week'].map((option) => ({
      text: I18n.t(`progresses.date_filter.${option}`),
      className: classNames({ 'date-filter-item': true, selected: selectedOption === option }),
      icon: selectedOption === option ? <MdCheck /> : <span />,
      onClick: () => changeFilter(option)
    }))
  ), [changeFilter, selectedOption])

  const dateFilterButton = useMemo(
    () => (
      <DateFilter
        items={items}
        parentTitle
        title={I18n.t('progresses.date_filter_info')}
      />
    ),
    [items]
  )

  return {
    dateFilterButton,
    resetDateFilters,
    hasDateFilter
  }
}
