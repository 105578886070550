import React, { useMemo } from 'react'
import GridRowContextMenu from '/src/ui/core/grid/grid_row_context_menu'
import PropTypes from 'prop-types'

export default function GridRow({ row, dataItem, contextMenuItems, watchFields, trProps, columns, columnsContent }) {
  const CurrentRow = useMemo(() => {
    if (contextMenuItems) {
      const gridRowMenu = (
        <GridRowContextMenu
          key={`${dataItem?.id}-context-menu`}
          dataItem={dataItem}
          items={contextMenuItems}
        />
      )

      row.props.children.splice(0, 1, gridRowMenu)
    }

    return React.cloneElement(row, { ...trProps }, row.props.children)
  }, [...watchFields, trProps.selected, columns, contextMenuItems, columnsContent]);

  return CurrentRow;
}

GridRow.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object, PropTypes.element]).isRequired,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ),
  watchFields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  columnsContent: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])),
  trProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
  columns: PropTypes.string
}

GridRow.defaultProps = {
  contextMenuItems: undefined,
  watchFields: [],
}
