import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdAddCircle, MdEdit, MdCheck } from 'react-icons/md'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import FilterStepsModal from '/src/ui/core/popups/filter_steps_modal'
import '/src/static/css/core/inputs/input_contract_service.css'

export default function InputContractService({ inputProps }) {
  const { onChange, dataItem, readOnly, control } = inputProps
  const disciplineId = control.getValues('discipline_id')

  const [subproject] = useStore('subproject')
  const { fetch } = useFetch()

  const [open, setOpen] = useState(false)
  const [templateId, setTemplateId] = useState()
  const [stepFilters, setStepFilters] = useState()
  const [contractService, setContractService] = useState(() => dataItem?.contract_service || null)

  const ALLOWED_COLUMN_TYPES = [
    'string',
    'integer',
    'decimal',
    'drop',
    'lookup',
    'formula',
    'percentage',
    'date',
    'time',
    'drop_formula'
  ]

  const isAllowedAndVisibleColumn = (column) => {
    return ALLOWED_COLUMN_TYPES.includes(column.column_type.description) && column.visible_on_web
  }

  const getContractTemplateId = async() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: {
          subproject_id: subproject.id,
          template_type: 'contract_service'
        }
      }
    }

    let templateNumber

    await fetch('eav_templates', params, {
      onSuccess: ({ data }) => {
        templateNumber = data.data[0].id
      }
    })

    return templateNumber
  }

  useEffect(() => {
    if (!subproject || !subproject.id) return

    const fetchContractServices = () => {
      const serviceParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { discipline_id: disciplineId } }
      }

      fetch('contract_services', serviceParams, {
        onSuccess: ({ data }) => {
          if (data.data.length === 1) {
            setContractService(data.data[0])
            onChange('contract_service_id', data.data[0].id)
          }
        }
      })
    }

    fetchContractServices()


    // EXTRAIR NUM HOOK

    const fetchContractServiceColumns = () => {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: {
            '[eav_template][template_type]': 'contract_service'
          },
          order: { position: 'asc' }
        }
      }

      fetch('eav_columns', params, {
        onSuccess: async ({ data }) => {
          const { data: columns } = data
          const eavTemplateId = columns[0]?.eav_template_id || await getContractTemplateId()
          const visibleColumns = columns.filter(isAllowedAndVisibleColumn)

          const newStepFilters = visibleColumns.map(({ description, title }) => ({
            filterAttribute: description,
            title,
            query: { where: { discipline_id: disciplineId, eav_template_id: eavTemplateId } }
          }))

          const descriptionFilter = {
            filterAttribute: 'description',
            title: I18n.t('form.inputs.contract_service.subtitle'),
            query: { where: { discipline_id: disciplineId, eav_template_id: templateId } }
          }
          setStepFilters([...newStepFilters, descriptionFilter])
          setTemplateId(eavTemplateId)
        }
      })
    }

    fetchContractServiceColumns()
  }, [subproject])


  const onDone = (values) => {
    if (values.description) {
      setContractService(values.selectedObj)
      onChange('contract_service_id', values.selectedObj.id)
    }
    setOpen(false)
  }

  const openModal = () => {
    const newStepFilters = stepFilters.map((filter) => {
      return {
        ...filter,
        query: { where: { discipline_id: disciplineId, eav_template_id: templateId } }
      }
    })

    setStepFilters(newStepFilters)
    setOpen(true)
  }

  return (
    <React.Fragment>
      <div className="contract-service">
        <div className={`contract-service-body${contractService ? ' filled' : ''}`}>
          {contractService ? (
            <React.Fragment>
              <div className="description">{contractService.description}</div>
              {!readOnly && (
                <React.Fragment>
                  <div className="contract-service-check">
                    <MdCheck />
                  </div>
                  <div className="contract-service-edit" onClick={openModal}>
                    <MdEdit />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div className="add-service" onClick={openModal}>
              <MdAddCircle />
              <button type="button" className="add-service-button">
                {I18n.t('form.inputs.contract_service.add')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="error-label" />
      {open && (
        <FilterStepsModal
          filters={stepFilters}
          route="contract_services"
          onClose={() => setOpen(false)}
          onDone={onDone}
          skipLast
        />
      )}
    </React.Fragment>
  )
}

InputContractService.propTypes = {
  inputProps: PropTypes.shape({
    tag: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    template: PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  }).isRequired
}
