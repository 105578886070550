import { useMemo, useRef, useEffect } from 'react'
import { store, useStore } from 'react-context-hook'
import lodash from 'lodash'

export default function useEntitiesCache(additionalEntities) {
  const [disciplines] = useStore('disciplines')
  const [units] = useStore('units')
  const [servicePackages] = useStore('service_packages')
  const [areas] = useStore('areas')
  const [subareas] = useStore('subareas')
  const [requestStatuses] = useStore('request_statuses')
  const [inspectStatuses] = useStore('inspect_statuses')
  const [formulas] = useStore('formulas')
  const [employees] = useStore('employees')
  const [fileCategory] = useStore('file_category')
  const [scaffoldingTypes] = useStore('scaffolding_types')
  const [areaNoises] = useStore('area_noises')
  const [loadClasses] = useStore('load_classes')
  const [scaffoldingStatuses] = useStore('scaffolding_statuses')
  const [reportTemplates] = useStore('report_templates')
  const [reportTemplatesRoles] = useStore('report_templates_roles')

  const prevEntities = useRef()
  const { isEqual } = lodash

  const returnEntities = useMemo(() => {
    const entities = {
      disciplines,
      units,
      servicePackages,
      areas,
      subareas,
      requestStatuses,
      inspectStatuses,
      formulas,
      employees,
      fileCategory,
      scaffoldingTypes,
      areaNoises,
      loadClasses,
      scaffoldingStatuses,
      reportTemplates,
      reportTemplatesRoles
    }

    if (additionalEntities) {
      additionalEntities.forEach((entity) => {
        entities[entity.foreignKey] = store.get(entity.foreignKey)
      })
    }

    if (isEqual(prevEntities.current, entities)) {
      return prevEntities.current
    }

    prevEntities.current = entities

    return entities
  }, [
    disciplines,
    units,
    servicePackages,
    areas,
    subareas,
    requestStatuses,
    inspectStatuses,
    formulas,
    employees,
    fileCategory,
    scaffoldingTypes,
    areaNoises,
    loadClasses,
    scaffoldingStatuses,
    reportTemplates,
    reportTemplatesRoles,
    additionalEntities
  ])

  useEffect(() => {
    return () => {
      prevEntities.current = null
    }
  }, [])

  return returnEntities
}
