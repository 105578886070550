import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { MdViewStream } from 'react-icons/md'
import { useQueryParams, NumberParam } from 'use-query-params'
import queryString from 'query-string'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'

export default function ClusteredIcon() {
  const [queryParams] = useQueryParams({ eav_template_id: NumberParam })
  const { requestId } = useGetPinnedRequests()
  const url = useParams()

  const urlQuery = { ...queryParams, request_id: requestId }

  const isScopesPage = url.resource === 'scopes'
  const isClustersPage = url.collection === 'clusters'

  const visible = isScopesPage
  const highlight = visible && isClustersPage
  const redirect = visible && !isClustersPage ? '/clusters' : ''

  return visible && requestId ? (
    <CustomTooltip position="bottom" parentTitle>
      <Link
        to={`/scopes${redirect}?${queryString.stringify(urlQuery)}`}
        className={`btn-icon grid-header-icon ${highlight ? 'highlight' : ''}`}
        title={I18n.t('scopes.clusters.title')}
      >
        <MdViewStream />
      </Link>
    </CustomTooltip>
  ) : null
}
