/* eslint-disable import/prefer-default-export */
/* eslint-disable max-lines-per-function */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent } from '@progress/kendo-react-grid'
import I18n from '/src/utils/translations'
import { isBlankOrFalse } from '/src/utils/boolean_refinements'

// This function aims to get the popup vertical position
// The calculation can change depending on the browser and the value can be negative or positive
const checkIsSubgridByPosition = (popupHeight) => {
  if ((popupHeight < 0 && popupHeight > -300) || (popupHeight > 0 && popupHeight > 300)) return true
  return false
}

const filterScaffoldingColumns = (showScaffolding, columns) => {
  if (!showScaffolding) return columns.filter((column) => column.description !== 'tag_number')
  else return columns
}

export default function ColumnMenuVisibility({ columns, onColumnsSubmit, onCloseMenu, onExpand, isOpen }) {
  const [subproject] = useStore('subproject')
  const [selectorColumns, setColumns] = useState(filterScaffoldingColumns(subproject.show_scaffolding, columns))
  const [isSubgrid, setIsSubgrid] = useState(false)

  const onToggleColumn = (id) => {
    setColumns(
      selectorColumns.map((column, index) => {
        return index === id ? { ...column, hide: !column.hide } : column
      })
    )
  }

  const onSubmit = (event, value) => {
    if (event) {
      event.preventDefault()
    }
    const test = value || selectorColumns
    onColumnsSubmit(test)
    if (onCloseMenu) onCloseMenu()
  }

  const onReset = (event) => {
    event.preventDefault()
    const allColumns = selectorColumns.map((column) => {
      return {
        ...column,
        hide: false
      }
    })

    setColumns(allColumns)
    onSubmit(event, allColumns)
  }

  const onMenuItemClick = () => {
    if (onExpand) onExpand()
  }

  const oneVisibleColumn = () => {
    const hideOnGrid = selectorColumns.filter((column) => column.hideOnGrid).length
    const visibleColumns = selectorColumns.filter((column) => isBlankOrFalse(column.hide)).length
    return visibleColumns - hideOnGrid === 1
  }

  const popupRef = useCallback((popupData) => {
    if (popupData)
      setIsSubgrid(checkIsSubgridByPosition(popupData.getBoundingClientRect().top))
  }, [setIsSubgrid])


  return (
    <div ref={popupRef} className={`column-menu-visibility ${isOpen ? 'selected' : ''}`}>
      <GridColumnMenuItemGroup>
        <GridColumnMenuItem title={I18n.t('grid.menu.visibility')} iconClass="k-i-columns" onClick={onMenuItemClick} />
        <GridColumnMenuItemContent show={isOpen}>
          <div className="k-column-list-wrapper">
            <form onSubmit={(e) => onSubmit(e)} onReset={onReset}>
              <div className={`k-column-list${isSubgrid ? '-subgrid' : ''}`}>
                {selectorColumns.map(
                  (column, index) =>
                    !column.hideOnGrid && (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="column-item">
                        <span>
                          <input
                            id={`column-visiblity-show-${index}`}
                            className="k-checkbox"
                            type="checkbox"
                            readOnly
                            disabled={isBlankOrFalse(column.hide) && oneVisibleColumn()}
                            checked={isBlankOrFalse(column.hide)}
                            onClick={() => onToggleColumn(index)}
                          />
                          <label
                            htmlFor={`column-visiblity-show-${index}`}
                            className="k-checkbox-label"
                            style={{ userSelect: 'none' }}
                          >
                            {column.title}
                          </label>
                        </span>
                      </div>
                    )
                )}
              </div>
              <div className="k-columnmenu-actions">
                <button type="reset" className="k-button">
                  {I18n.t('actions.reset')}
                </button>
                <button type="submit" className="k-button k-primary">
                  {I18n.t('actions.save')}
                </button>
              </div>
            </form>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  )
}

ColumnMenuVisibility.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  onColumnsSubmit: PropTypes.func,
  onCloseMenu: PropTypes.func,
  onExpand: PropTypes.func,
  isOpen: PropTypes.bool
}

ColumnMenuVisibility.defaultProps = {
  columns: undefined,
  onColumnsSubmit: undefined,
  onCloseMenu: undefined,
  onExpand: undefined,
  isOpen: false
}
