import I18n from '/src/utils/translations'

export const removeSelectedFilter = (filter, { column, operator }) => {
  return filter.filter((item) => item.column !== column || item.operator !== operator)
}

export const addFilter = (filterObject, source) => {
  return [...source, filterObject]
}

export const statusDescription = (status, namespace) => {
  const statusTitle = I18n.t(`${namespace}.statuses.${status.i18n_id}`)
  return statusTitle.length <= 20 ? statusTitle : `${statusTitle.substring(0, 20)}...`
}

export const getFilteredStatusesNames = (statuses, namespace) => {
  return statuses
    .filter((status) => status.filtered)
    .map((status) => I18n.t(`${namespace}.statuses.${status.i18n_id}`))
}

export function handleSpecialFilters(filterIds, specialFilter, specialFilterObject, selectedStatus, updatedFilter) {
  const isFilteringSpecial = filterIds.includes(specialFilter.id);
  filterIds = filterIds.filter((id) => id !== specialFilter.id);

  let originalFilter = [...updatedFilter];

  if (selectedStatus.i18n_id === specialFilter.i18n_id) {
    updatedFilter = removeSelectedFilter(updatedFilter, specialFilterObject);
    const whereNotFilter = originalFilter.find(f => f.type === 'not' && f.column === 'scope_id');
    if (whereNotFilter && !updatedFilter.some(f => f.type === 'not' && f.column === 'scope_id')) {
      updatedFilter.push(whereNotFilter);
    }
  }

  if (isFilteringSpecial) {
    updatedFilter = addFilter(specialFilterObject, updatedFilter);
  }
  return { filterIds, updatedFilter };
}

export function resetFilters(statuses, specialFilters, onSelectedOptionChange, setStatuses) {
  const resetStatuses = statuses.map((status) => ({
    ...status,
    filtered: false
  }))

  specialFilters.forEach((filter) => {
    filter.filtered = false
  })

  setStatuses(resetStatuses)
  onSelectedOptionChange(getFilteredStatusesNames(resetStatuses, 'progress'))
}

export function clearFilters(filter, columnsToRemove, resetCallback, onFilter) {
  resetCallback()
  const cleanedFilters = filter.filter((filterItem) => !columnsToRemove.includes(filterItem.column))
  onFilter(cleanedFilters)
}
