import { useStore } from 'react-context-hook'
import { isEmpty } from '/src/utils/object'
import { useCallback, useMemo } from 'react'

export default function useGetPinnedRequests() {
  const [subprojectFilters] = useStore('subproject_filters')

  const selectedRequests = useMemo(
    () =>  subprojectFilters?.selected_requests || {},
    [subprojectFilters]
  )

  const requestId = selectedRequests && !isEmpty(selectedRequests) ? Object.values(selectedRequests)[0].id : undefined

  const requestIds =
    selectedRequests && !isEmpty(selectedRequests) ? Object.values(selectedRequests).map(({ id }) => id) : undefined

  const hasRequestWithDisciplineId = useCallback(
    (disciplineId) => Object.values(selectedRequests).some(({ discipline_id }) => discipline_id === disciplineId),
    [selectedRequests]
  )

  return {
    requestId,
    requestIds,
    selectedRequests,
    hasRequestWithDisciplineId,
    isFilterActive: subprojectFilters?.is_filter_active
  }
}
