import React, { useState, useRef } from 'react'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import QuickPinPopup from '/src/ui/core/popups/quick_pin_popup'
import { ReactComponent as TableEye } from '/public/static/svg/table-eye'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function QuickPinIcon() {
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const { selectedRequests } = useGetPinnedRequests()
  const popupButtonRef = useRef()
  const requestCount = Object.keys(selectedRequests).length
  const displayCount = requestCount > 9 ? '9+' : requestCount

  useBus(BusEvents.PINNED_REQUESTS_FETCH_SUCCESS, () => setLoading(false))

  return (
    <QuickPinPopup setPopupOpen={setPopupOpen} popupButtonRef={popupButtonRef}>
    <CustomTooltip position='bottom' parentTitle>
      <div className='quick-pin-wrapper'>
        <button
          ref={popupButtonRef}
          className={`btn-icon grid-header-icon quick-pin ${isPopupOpen ? 'selected' : ''}`}
          title={I18n.t('grid.quick_pin_popup.quick_pin')}
          data-testid="quick-pin-icon"
        >
          <TableEye />
        </button>
        {requestCount > 0 && !loading && <span className='quick-pin-badge'>{displayCount}</span>}
      </div>
    </CustomTooltip>
  </QuickPinPopup>
  )
}
