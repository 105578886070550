import React, { useState, useEffect } from 'react'
import I18n from '/src/utils/translations'
import useSyncBatch from '/src/hooks/api/sync_batch'
import ContractItemBlock from '/src/ui/domain/blocks/contract_item_block'

export default function useContractItemBlock(dataItem) {
  const [contractItemBlock, setContractItemBlock] = useState({
    component: <React.Fragment key="contract_service_block" />,
    title: I18n.t('estimate_services.contract_service'),
    type: 'general'
  })
  const [units, setUnits] = useState()

  const batchedEntities = {
    units: { get: units, set: setUnits }
  }

  const { loading } = useSyncBatch(batchedEntities)

  useEffect(() => {
    if (loading || !units || !dataItem) return
    const contractService = dataItem.contract_service

    if (!contractService) {
      return
    }

    const newDataItem = {
      ...dataItem,
      contract_service: contractService,
      unit: units[contractService.unit_id]
    }

    setContractItemBlock({
      component: <ContractItemBlock dataItem={newDataItem} templateType="estimate_services" />,
      title: I18n.t('estimate_services.contract_service'),
      type: 'general'
    })
  }, [loading, units, dataItem])

  return contractItemBlock
}
