import React from 'react'
import { MdChat } from 'react-icons/md'
import PropTypes from 'prop-types'
import { getSizeByType } from '/src/utils/constants/fields'
import PrintListPDFCell from '/src/ui/core/grid/pdf_print/print_list_pdf_cell'

export default function PrintListPDFOtherColumns({
  item,
  project,
  columns,
  orderedComments,
  shouldPrintHiddenColumns
}) {
  if (!columns?.length) return null

  const visibleColumnsInPrintList = shouldPrintHiddenColumns
    ? columns
    : columns.filter((column) => column.visible_on_web)

  return (
    <div className='print-list-pdf__other-columns'>
      {visibleColumnsInPrintList.map((column) => {
        const { web_form_width: formWidth, column_type: columnType } = column
        const commentIndex = orderedComments?.[column.description]?.index
        const columnWidthClass = formWidth ? `width-${formWidth}` : getSizeByType(columnType.description)

        return (
          <div className={`print-list-pdf__other-column ${columnWidthClass}`} key={column.description}>
            <div className='print-list-pdf__column-title'>
              <span>{column.title}</span>
              {commentIndex && (
                <div className='print-list-pdf__column-comment'>
                  <MdChat size={15} style={{ color: '#607d8b' }} />
                  <span>{commentIndex}</span>
                </div>
              )}
            </div>
            <div className='print-list-pdf__column-value'>
              <PrintListPDFCell item={item} column={column} project={project} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

PrintListPDFOtherColumns.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    column_type: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string
    })
  })),
  project: PropTypes.oneOfType([PropTypes.object]).isRequired,
  orderedComments: PropTypes.shape({
    description: PropTypes.shape({
      value: PropTypes.string,
      index: PropTypes.number
    })
  })
}

PrintListPDFOtherColumns.defaultProps = {
  item: undefined,
  columns: undefined,
  orderedComments: undefined,
  shouldPrintHiddenColumns: true
}
