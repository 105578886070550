import { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { camelize, pluralize } from 'inflected'
import useBatchEntities from '/src/hooks/api/batch_entities'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import usePaginatedFetch from '/src/hooks/api/paginated_fetch'
import useFetch from '/src/hooks/api/fetch'
import { filterAllowedEavColumns } from '/src/models/concerns/eav_column'
import { indexify } from '/src/utils/array'
import { isPresent } from '/src/utils/boolean_refinements'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import useFetchPinnedRequests from '/src/hooks/fetch_pinned_requests'
import BusEvents from '/src/hooks/bus/bus_events'

const DEFAULT_DATA_SOURCE = { data: [], total: 0 }

const eavColumnsParams = (templateId) => ({
  where: {
    eav_template_id: templateId,
    visible_on_web: 1
  },
  order: { position: 'asc' }
})

const dataSourceParams = ({ sorting, tabbing, queryFilters, paging, additionalParam }) => {
  const order =
    sorting &&
    sorting.reduce((currentOrder, { field, realSort, dir }) => ({ ...currentOrder, [realSort || field]: dir }), {})

  const queryWhere = queryFilters.where || {}
  const whereFilters = tabbing ? { eav_template_id: tabbing, ...queryWhere } : queryWhere

  return {
    requestAction: 'READ',
    httpAction: 'get',
    query: { ...queryFilters, where: whereFilters, order, paging },
    additionalParam
  }
}

/* eslint-disable max-lines-per-function */
export default function useFetchGrid({
  model,
  paging,
  queryFilters,
  sorting,
  onFirstDataSourceFinish,
  onColumnsFetch,
  tabbing,
  flexible,
  templateId,
  additionalEntities
}) {
  const { fetch } = useFetch()
  const modelRef = useRef(model)

  const [loading, setLoading] = useState(true)

  const [dataSource, setDataSource] = useState(DEFAULT_DATA_SOURCE)
  const [complementData, setComplementData] = useState(DEFAULT_DATA_SOURCE)
  const [fetchHasError, setFetchHasError] = useState(false)
  const loadingBatch = useBatchEntities(additionalEntities)
  const batchedEntities = useEntitiesCache()

  useFetchPinnedRequests()

  const onFlexibleColumnFetch = useCallback((data) => filterAllowedEavColumns(data, templateId), [templateId])

  const [flexibleColumns, fetchFlexibleColumns, isPaginatingColumns] = usePaginatedFetch(
    'eav_columns',
    onFlexibleColumnFetch
  )

  const filteredEavColumns = useMemo(() => {
    if (loading) return []

    if (!isPaginatingColumns && flexibleColumns && onColumnsFetch) {
      const modelColumns = modelRef.current.columns
      onColumnsFetch([...modelColumns, ...flexibleColumns])
    }

    return flexibleColumns
  }, [loading, flexibleColumns, isPaginatingColumns, onColumnsFetch])

  const gridData = useMemo(() => {
    if (!complementData || complementData.total === 0) return dataSource
    if (!dataSource || dataSource.total === 0) return complementData
    const indexifiedComplement = indexify(complementData.data, 'id')
    const complementedData = dataSource.data.map((item) => ({ ...indexifiedComplement[item.id], ...item }))
    return { data: complementedData, total: complementData.total }
  }, [dataSource, complementData])

  useBus(
    BusEvents.UPDATE_GRID_DATA_SOURCE,
    ({ payload }) => {
      const { modelName, onSetDataSource } = payload
      if (modelName !== model.paramName) return
      setDataSource(onSetDataSource)
    },
    []
  )

  const fetchColumns = useCallback(() => {
    fetchFlexibleColumns(true, eavColumnsParams(templateId || tabbing))
  }, [tabbing, templateId, fetchFlexibleColumns])

  const onDataSourceFetch = useCallback(
    (data) => {
      data.forEach((entity) => {
        Object.keys(entity).forEach((key) => {
          const foreignKeyEnds = '_id'
          if (key.endsWith(foreignKeyEnds)) {
            const equivalent = { responsibles: 'employees' }
            const foreignEntity = key.substr(0, key.length - foreignKeyEnds.length)
            const batchKey = camelize(pluralize(foreignEntity), false)
            const batchOrEquivalent = batchedEntities[batchKey] || batchedEntities[equivalent[batchKey]]
            const isEntityUndefined = !batchOrEquivalent

            if (isEntityUndefined || Object.keys(batchOrEquivalent).length === 0 || isPresent(entity[foreignEntity]))
              return

            if (batchOrEquivalent[entity[key]]) entity[foreignEntity] = batchOrEquivalent[entity[key]]
          }
        })
      })
    },
    [batchedEntities]
  )

  const fetchMainRoute = useCallback(() => {
    const additionalParam = modelRef.current.additionalGridParam
    const params = dataSourceParams({ paging, queryFilters, sorting, tabbing, additionalParam })
    const gridRoute =  modelRef.current.gridRoute || modelRef.current.route

    fetch(gridRoute, params, {
      onSuccess: (responseData) => {
        const { data } = responseData.data
        onDataSourceFetch(data)

        setFetchHasError(false)
        setDataSource(responseData.data)
        if (onFirstDataSourceFinish) onFirstDataSourceFinish(responseData.data)
        setLoading(false)
      },
      onError: () => setFetchHasError(true)
    })
  }, [paging, queryFilters, sorting, tabbing, fetch, onDataSourceFetch])

  const fetchComplementaryRoute = useCallback(() => {
    if (!modelRef.current.complementaryRoute) return

    const params = dataSourceParams({ paging, queryFilters, sorting, tabbing })

    fetch(modelRef.current.complementaryRoute, params, {
      onSuccess: (responseData) => {
        const { data } = responseData.data
        onDataSourceFetch(data)
        setComplementData(responseData.data)
        setLoading(false)
      }
    })
  }, [paging, queryFilters, sorting, tabbing, fetch, onDataSourceFetch])

  const fetchGrid = useCallback(() => {
    setLoading(true)

    setDataSource(DEFAULT_DATA_SOURCE)
    setComplementData(DEFAULT_DATA_SOURCE)

    if (flexible) fetchColumns()

    fetchMainRoute()
    fetchComplementaryRoute()
  }, [flexible, fetchColumns, fetchMainRoute, fetchComplementaryRoute])

  useEffect(() => {
    if (!loadingBatch) fetchGrid()
  }, [loadingBatch, fetchGrid])

  return {
    loading,
    responseData: gridData,
    flexibleColumnsData: filteredEavColumns,
    errors: fetchHasError,
    read: fetchGrid
  }
}
