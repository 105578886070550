import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from '/src/utils/object'
import { MdPushPin } from 'react-icons/md'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import '/src/static/css/popup_anchored.css'


export default function GridRowPinIcon({ id }) {
  const { selectedRequests } = useGetPinnedRequests()
  const { unpinRequests, setPinnedRequests } = useRequestsPinned()

  const isSelected = id in selectedRequests

  const handleClick = (id) => {
    if (!isSelected) {
      setPinnedRequests([{ id }])
    } else {
      unpinRequests([id])
    }
  }

  const pinIcon = (
    <MdPushPin
      onClick={() => handleClick(id)}
      className={`pin-icon ${isSelected ? 'selected' : ''}`}
      data-testid='pin-grid-icon'
      disabled={!isEmpty(selectedRequests)} />
  )

  return <td>{pinIcon}</td>
}

GridRowPinIcon.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  isTableData: PropTypes.bool,
}

GridRowPinIcon.defaultProps = {
  onClick: () => { },
  style: {},
  isTableData: true,
}
