/* eslint-disable */
// File generated by i18n-js on 2025-04-01 03:20:03 +0000

import I18n from 'i18n-js';

I18n.translations || (I18n.translations = {});
I18n.translations = {
  "de": {
    "actions": {
      "alternate_view": "Alternative Ansicht",
      "approve": "Genehmigen",
      "back_to": "Zurück zu %{page}",
      "bulk_editing": "Massenbearbeitung",
      "cancel": "Abbrechen",
      "choose_entity": "Wählen Sie %{entity}",
      "clear": "Entfernen",
      "clear_all_filters": "Alle Filter löschen",
      "confirm": "Bestätigen",
      "create": "Erstellen",
      "create_inspection": "Inspektion erstellen",
      "delete": "Löschen",
      "discard": "Verwerfen",
      "dismantle": "Abbauen",
      "done": "Fertig",
      "duplicate": "Duplizieren",
      "duplicate_services": "Duplikatdienste",
      "duplicate_with_parts": "Mit Teilen duplizieren",
      "edit": "Bearbeiten",
      "export": "Exportieren nach .xls",
      "export_template": "Vorlage .xls herunterladen",
      "filter_pinned_work_orders": "Angeheftete Arbeitsaufträge filtern",
      "flag_filter": "Flaggenfilter",
      "generate_work_package": "Arbeitspaket generieren",
      "import": "Importieren aus .xls",
      "import_file": "Datei importieren",
      "loading": "Laden",
      "more": "Mehr",
      "new": "Erstelle neu",
      "next": "Weiter",
      "no": "Nein",
      "past": {
        "cancel": "Verworfen",
        "complete": "Abgeschlossen",
        "confirm": "Bestätigt",
        "delete": "Gelöscht",
        "destroy": "Gelöscht",
        "dismantle": "demontiert",
        "duplicate_with_parts": "Mit seinen Teilen dupliziert",
        "ready_to_dismantle": "bereit zum Abbau",
        "reject": "Abgelehnt",
        "revert": "rückgängig gemacht",
        "update": "Aktualisiert"
      },
      "perform_discipline_check": "Führen Sie eine Disziplinarüberprüfung durch",
      "pin_request": "Filtern Sie nach ausgewählten Anfragen",
      "prev": "Zurück",
      "ready": "Bereit",
      "ready_for_discipline": "Bereit für den Disziplinarcheck",
      "ready_to_dismantle": "Bereit zum Abbau",
      "recycle": "Recyceln",
      "reject": "Ablehnen",
      "remove_recycle": "Entfernen Sie „Recycling“.",
      "reset": "Zurücksetzen",
      "retrieving": "Abrufen...",
      "revert": "Rückgängig machen",
      "revert_to_erected": "Zurück zu Errichtet",
      "save": "Speichern",
      "save_button_keyboard": "Speichern (Strg + Eingabe)",
      "save_exit": "Speichern und schließen",
      "save_new": "Weitere hinzufügen",
      "saving": "Speichern...",
      "schedule": "Zeitplan",
      "show_all_work_orders": "Alle Arbeitsaufträge anzeigen",
      "skip": "Überspringen",
      "status_filter": "Statusfilter",
      "unpin_request": "Filter nach Anfragen entfernen",
      "unpin_selected_request": "Filter von %{numSelectedRequests} ausgewählten Anfragen entfernen",
      "unsaved_changes": "Nicht gespeicherte Zeilen",
      "upload_file": "Datei hochladen",
      "upload_picture": "Bild hochladen",
      "yes": "Ja"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Es ist nicht möglich, einen geclusterten Arbeitsauftrag zu duplizieren",
      "cant_duplicate_progress": "Die Dienstleistungen können aufgrund der folgenden Fehler nicht dupliziert werden...",
      "cant_duplicate_request": "Vorbereitungen, Kostenvoranschläge und Dienstleistungen können aufgrund der folgenden Fehler nicht dupliziert werden...",
      "cant_duplicate_scope": "Die Dienstleistungen können nicht dupliziert werden, da folgende Fehler auftreten...",
      "only_estimate_service_copy": "Es wird versucht, nur das Element \"Kalkulation\" zu kopieren.",
      "only_progress_copy": "Es wird versucht, nur den Progress zu kopieren.",
      "only_request_copy": "Es wird nur versucht, den Arbeitsauftrag zu kopieren.",
      "only_scope_copy": "Es wird versucht, nur das Element „Arbeitsvorbereitung“ zu kopieren."
    },
    "cancel_modal": {
      "cancel": "Nicht jetzt",
      "description": "Möchten Sie %{model} \"%{id}\" wirklich stornieren?",
      "ok": "Ja Abbrechen",
      "title": "Artikel stornieren?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Nein, abbrechen",
        "description": "Sind Sie sicher, dass Sie alle %{model}-Datensätze entfernen möchten?",
        "ok": "Ja, alle entfernen",
        "title": "%{model} entfernen?"
      },
      "success_message": "%{model} wurde erfolgreich entfernt!",
      "title": "Datensätze entfernen"
    },
    "clear_template_preferences": {
      "cancel": "Nein, stornieren",
      "description": "Möchten Sie wirklich alle Rastereinstellungen für diese Vorlage löschen?",
      "ok": "Ja, Rastereinstellungen löschen",
      "title": "Rastereinstellungen löschen"
    },
    "collections": {
      "clusters": "Cluster",
      "create_by_package": "Nach Paket erstellen",
      "duplicate": "Duplizieren",
      "edit": "Bearbeiten",
      "estimate": "Schätzen",
      "new": "Neu",
      "schedule": "Zeitplan",
      "sub_requests": "Unteranträge",
      "view": "Ansicht"
    },
    "complete_modal": {
      "cancel": "Nicht jetzt",
      "description": "Möchten Sie %{model} wirklich als abgeschlossen markieren?",
      "ok": "Ja, als erledigt markieren",
      "title": "Als abgeschlossen markieren?"
    },
    "complete_progress_modal": {
      "cancel": "Stornieren",
      "complete": "Fertigstellen",
      "description": "Möchten Sie dieses Bauelement wirklich fertigstellen? Diese Aktion kann nicht rückgängig gemacht werden.",
      "title": "Fertigstellung des Bauelements bestätigen"
    },
    "confirmation_modal": {
      "cancel": "Abbrechen",
      "description": "Diese Aktion kann nicht rückgängig gemacht werden!",
      "ok": "OK",
      "title": "Sind Sie sicher?"
    },
    "contract_services": {
      "title": "Vertragsleistungen"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Datenblattdetails"
      },
      "description": "Datenblätter",
      "id": "ID",
      "item": "Element",
      "title": "Datenblätter"
    },
    "delete_attachment_modal": {
      "cancel": "Nein, Anhang behalten",
      "delete_successful": "Anhang erfolgreich gelöscht",
      "description": "Möchten Sie diesen Anhang wirklich löschen?",
      "ok": "Ja, Anhang löschen",
      "title": "Anhang löschen?"
    },
    "delete_modal": {
      "cancel": "Nein, verwerfen",
      "description": "Sind Sie sicher, dass Sie %{model} \"%{id}\" löschen möchten?",
      "ok": "Ja, löschen",
      "title": "Element löschen?"
    },
    "delete_picture_modal": {
      "cancel": "Nein, behalte das Bild",
      "delete_successful": "Bild erfolgreich gelöscht",
      "description": "Möchten Sie dieses Bild wirklich löschen?",
      "ok": "Ja, Bild löschen",
      "title": "Foto löschen"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Annahme"
      },
      "column_comment": {
        "add_comment": "Klicken Sie hier, um einen Kommentar hinzuzufügen",
        "cancel": "Stornieren",
        "comment": "Kommentar",
        "subtitle": "Kommentar hinzufügen/bearbeiten"
      },
      "columns_batch_assigner": {
        "actions": "Aktionen",
        "add_action": "Aktion hinzufügen",
        "add_to_column": "Zur Spalte hinzufügen",
        "add_value_to": "%{value} hinzufügen zu",
        "column": "Spalte",
        "done": "Aktion speichern",
        "duplicate_services": "Dienste Duplizieren",
        "header": "Batch bearbeiten",
        "multiple_success": "%{numberOfItems} Elemente wurden aktualisiert.",
        "new_value": "Neuer Wert",
        "single_success": "1 Element wurde aktualisiert.",
        "subtitle": "Den ausgewählten Spalten %{numberOfColumns} Werte zuweisen",
        "title": "Aktionen erstellen",
        "update": "Aktualisieren"
      },
      "configure_print_grid_file": {
        "error_max_columns": "Sie können nur bis zu %{maxColumns} Spalten auswählen.",
        "generate": "Datei generieren",
        "header": "Raster drucken",
        "paper_size_title": "Papier größe",
        "pdf_file": {
          "information": "Information",
          "pictures": "Bilder",
          "title": "Raster %{modelName} - %{numItems} Elemente - Seite %{pageNum} von %{totalPages}"
        },
        "select_columns_title": "Zu druckende Spalten",
        "selected_columns": "Sie haben %{numSelectedColumns} von %{maxColumns} ausgewählt.",
        "subtitle": "Wählen Sie bis zu %{maxColumns} Spalten zum Drucken aus",
        "title": "Datei zum Drucken konfigurieren"
      },
      "configure_print_list_file": {
        "big_picture_title": "Bilder über die gesamte Seitenbreite drucken?",
        "font_size_title": "Schriftgröße auswählen",
        "header": "Liste drucken",
        "hide_columns_title": "Spalten einbeziehen, die ausgeblendet sind",
        "large": "Groß",
        "medium": "Medium",
        "page_break_title": "1 Artikel pro Seite drucken?",
        "small": "Klein"
      },
      "configure_work_package_file": {
        "attached_documents": "Angehängte Dokumente",
        "attachments": "Anhänge",
        "estimates": "Schätzungen",
        "estimates_price": "Schätzungen Preis",
        "generate": "Generieren",
        "header": "Arbeitspaket generieren",
        "hse_r": "HSE-R-Bewertung",
        "inspection_page_breaker": "Drucken Sie eine Inspektion pro Seite",
        "inspection_pictures": "Fügen Sie Bilder und Unterschriften hinzu",
        "inspections": "Alle zugehörigen Inspektionen",
        "subtitle": "Wählen Sie die Abschnitte aus, die Sie drucken möchten",
        "title": "Arbeitspaketdatei konfigurieren",
        "work_description": "Arbeitsbeschreibung"
      },
      "duplicate_services": {
        "title": "Dienste Duplizieren"
      }
    },
    "discard_modal": {
      "cancel": "Weiter bearbeiten",
      "description": "Sind Sie sicher, dass Sie Ihre Änderungen verwerfen möchten?",
      "ok": "Ja, verwerfen",
      "title": "Änderungen verwerfen"
    },
    "eav_templates": {
      "title": "Schablonen"
    },
    "empty_state": {
      "columns": "Säulen",
      "empty": "Kein %{model}",
      "no_attachments": "Sie haben noch keine Anhänge hochgeladen",
      "no_columns": "Es ist keine Bildspalte verfügbar",
      "no_discipline_template": "Es wurden keine Vorlagen mit gefilterter Arbeitsauftragsdisziplin gefunden.",
      "no_estimate_service_template": "Für die gewählte Arbeitsvorbereitungsvorlage wurde keine Kostenvoranschlagsvorlage gefunden",
      "no_pictures": "Sie haben in dieser Spalte noch keine Bilder hochgeladen",
      "no_unselect": "Es ist nicht möglich, die angeheftete Anfrage auf dieser Seite abzuwählen. Sie können dies nur auf der Seite „Arbeitsaufträge“ oder durch Abmelden tun.",
      "not_created": "Sie haben noch kein %{model} erstellt",
      "pictures": "Bilder"
    },
    "environment": {
      "project": {
        "select_project": "Projekt auswählen..."
      },
      "select_project_subproject": "Wählen Sie Projekt und Subprojekt",
      "subproject": {
        "select_subproject": "Subprojekt auswählen…"
      }
    },
    "estimate": {
      "accept": "Kalkulation annehmen",
      "all_estimates_approved": "Alle Kostenvoranschläge wurden erfolgreich genehmigt.",
      "application": "Kosten der Anwendung",
      "back": "Alle Arbeitsaufträge",
      "budget_hours": "Budget-Stunden",
      "confirmation": {
        "cancel": "Nein, Abbrechen",
        "confirm": "Ja, bestätigen",
        "description": "Sind Sie sicher, dass Sie diesen Kostenvoranschlag für diesen Arbeitsauftrag genehmigen möchten?",
        "title": "Bestellung bestätigen"
      },
      "description": "Sie haben %{number} Dienstleistungen für die Kasse. Bitte überprüfen Sie die Details unten.",
      "equipment": "Kosten der Ausrüstung",
      "estimate_details": "Details zur Kalkulation",
      "estimate_success": "Kalkulation erfolgreich genehmigt",
      "info_message": "Diese Kalkulation wurde bereits genehmigt. Er ist nur zum Lesen verfügbar.",
      "material": "Materialkosten",
      "no_estimate_message": "Mit diesem Arbeitsauftrag ist kein Kostenvoranschlag verknüpft.",
      "norm_hours": "Norm-Stunden",
      "others": "Sonstige Kosten",
      "pdf_file": {
        "title": "Schätzungsdetails – Seite %{pageNum} von %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Disziplin",
        "functional_location": "Technischer Platz",
        "jobcard_status": "Jobkartenstatus",
        "ke_priority": "KE-Priorität",
        "main_area_location": "Hauptgebiet und Standort",
        "main_work_center": "Hauptarbeitsplatz",
        "maintenance_plant": "Instandhaltungswerk",
        "not_defined": "Noch nicht definiert",
        "order_finish": "Auftragsabschluss",
        "order_start": "Bestellstart",
        "priority_sequence": "Prioritätsreihenfolge",
        "project": "Projekt",
        "project_type": "Projekttyp",
        "report": "Bericht",
        "report_date": "Berichtsdatum",
        "responsible_engineer": "Verantwortlicher Ingenieur",
        "status_completed": "Vollendet",
        "status_issued_for_approval": "Zur Genehmigung ausgestellt",
        "status_issued_for_construction": "Ausgestellt für den Bau",
        "subproject": "Teilprojekt",
        "system": "System",
        "template": "Vorlage",
        "title": "Kostenvoranschlag für den Arbeitsauftrag",
        "work_order": "Arbeitsauftrag"
      },
      "pdf_summary": {
        "title": "Schätzungen Zusammenfassung"
      },
      "save_draft": "Entwurf speichern",
      "service_details": "Leistungsdetails",
      "some_estimates_not_approved": "Einige Kostenvoranschläge konnten nicht genehmigt werden. Überprüfen Sie die Fehlerprotokolle.",
      "team_target_hours": "Team-Soll-Stunden",
      "title": "Check-out Dienst",
      "tooltip": {
        "application": "Anwendung",
        "equipment": "Ausrüstung",
        "material": "Material",
        "others": "Sonstiges"
      },
      "total_approved": "Insgesamt genehmigt",
      "total_pending": "Insgesamt ausstehend",
      "total_request": "Gesamtkosten",
      "use_construction_prices": "Baupreise einbeziehen?"
    },
    "estimate_services": {
      "application_price": "Anwendungspreis",
      "automatically_prices": "Automatisch berechnete Preise",
      "back": "Alle Arbeitsvorbereitungen",
      "budget_target_hours": "Budget-Soll-Stunden",
      "choose_modal": {
        "package": "Einzeldetailpaket",
        "package_description": "Erstellung mehrerer Dienste basierend auf demselben Detail",
        "single": "Einzelnes Detail",
        "single_description": "Erstellung von nur einer Dienstleistung",
        "title": "Neu erstellen"
      },
      "contract_service": "Vertragsleistung",
      "crew_size": "Größe des Teams",
      "crews": "Anzahl der Teams",
      "description": "Kostenvoranschlagsdetails",
      "description_label": "Beschreibung",
      "discipline": "Fachgebiet",
      "equipment_price": "Equipmentpreis",
      "estimate": "Kalkulation",
      "estimate_services_detail": {
        "side_panel_title": "Details der Dienstleistung"
      },
      "factor": "Faktor",
      "flag": "Flagge",
      "id": "ID",
      "item_no": "Artikelnr.",
      "materials_price": "Materialpreis erstellen",
      "norm_hours": "Norm-Stunden",
      "other_price": "Sonstiger Preis",
      "prices": "Preise",
      "print": "Drucken",
      "quantity": "Menge",
      "service": "Dienstleistung",
      "service_no": "Service-Nr.",
      "service_package": "Dienstleistungspaket",
      "statuses": {
        "inspected_flag": "Inspektion ausstehend"
      },
      "team_target_hours": "Team-Soll-Stunden",
      "title": "Kalkulation",
      "title_packageable": "Kalkulation für Dienstleistungen nach Dienstleistungspaket",
      "total": "Gesamt",
      "unit": "Einheit",
      "weight": "Gewicht",
      "working_hours": "Arbeitsstunden"
    },
    "fields": {
      "created_at": "Erstellt",
      "image_not_found": "Bild nicht gefunden",
      "na": "K.A.",
      "signature": "Unterschrift.Jpg",
      "updated_at": "Aktualisiert"
    },
    "filter_steps_modal": {
      "filter_result": "Auswahl-Ergebnis",
      "result_subtitle": "Zusammenfassung Ihrer Auswahl",
      "step": "Schritt",
      "subtitle": "Bitte wählen Sie %{title}"
    },
    "filtering": {
      "between": " das ist zwischen",
      "contains": " die Folgendes enthält ",
      "eq": " die gleich ist mit ",
      "invalid_date": "Um richtig zu filtern, müssen Startdatum und Enddatum ausgefüllt",
      "invalid_value": "Ungültiger Wert",
      "neq": " die nicht gleich ist, mit"
    },
    "forgot_password": {
      "back_to_login": "Zurück zur Anmeldung",
      "button": "Senden Sie mir Anweisungen zum Zurücksetzen des Passworts",
      "feedback": "Eine Nachricht mit Anweisungen zum Zurücksetzen Ihres Passworts wurde an die angegebene E-Mail-Adresse gesendet.",
      "placeholder": "Deine E-Mail",
      "subtitle": "Bitte geben Sie Ihre E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten.",
      "title": "Haben Sie Ihr Passwort vergessen?"
    },
    "form": {
      "back": "Alle %{entity}",
      "cancel": "Abbrechen",
      "create_entity": "%{entity} erstellen",
      "create_error": " %{entity} konnte nicht erstellt werden",
      "create_success": " %{entity} wurde erfolgreich erstellt",
      "duplicate_entity": "Duplizieren %{entity}",
      "duplicate_success": "%{entity} und sämtliche Abhängigkeiten wurden erfolgreich dupliziert!",
      "general_information": "Allgemeine Informationen",
      "hide": "Ausblenden",
      "inputs": {
        "attachment": {
          "add_error": "Fehler beim Hinzufügen der Datei",
          "add_evidence": "Nachweis der Kundenautorisierung hinzufügen",
          "allowed_extensions": "Die zulässigen Erweiterungen sind",
          "drag_drop": "Dateien durch Ziehen und Ablegen hinzufügen",
          "invalid_extension": "Dateityp nicht erlaubt.",
          "max_size": "Die Datei muss weniger als 20 MB groß sein.",
          "maximum_num_uploads_error": "Sie können nur bis zu %{max_num} Dateien pro flexible",
          "one_file": "Laden Sie jeweils eine Datei hoch",
          "or_drop_file_here": "oder Datei hier ablegen",
          "remove_error": "Entfernen fehlgeschlagen",
          "remove_success": "Entfernt Erfolgreich",
          "select_files": "Dateien auswählen",
          "submit": "Datei hochladen",
          "upload_error": "Hochladen fehlgeschlagen",
          "upload_success": "Erfolgreich hochgeladen"
        },
        "contract_service": {
          "add": "Vertragsleistung hinzufügen",
          "subtitle": "Vertragsleistung"
        },
        "datasheet_filter": {
          "add_filter": "Filter hinzufügen",
          "title": "Datenblatt-Filter"
        },
        "date_range": {
          "between": "%{start} bis %{end}",
          "end": "Endtermin",
          "end_simplified": "Ende",
          "start": "Starttermin",
          "start_simplified": "Anfang"
        },
        "drop_formula": {
          "formula_select": "Bitte wählen Sie eine Formel aus..."
        },
        "errors": {
          "date_greater_than_max": "Das Datum muss am oder vor %{max} liegen",
          "date_less_than_min": "Das Datum muss am oder nach %{min} liegen",
          "drop_formula_result": "%{field} berechnetes Formelergebnis",
          "mandatory": "%{field} ist erforderlich.",
          "not_found": "%{value}' wurde in der Referenz nicht gefunden. Wählen Sie eine neue aus."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "oder per Drag & Drop",
              "select_file": "Zum Hochladen klicken"
            },
            "note": {
              "extensions": "Die Datei muss in %{extensions} sein",
              "max_size": "Maximale Dateigröße %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Bild"
          },
          "upload": {
            "error": {
              "invalid_picture": "Ungültiger Typ / Größe",
              "multiple_fails": "Alle angehängten Dateien haben einen ungültigen Typ/eine ungültige Größe",
              "multiple_files": "Einige der angehängten Bilder haben einen ungültigen Typ/eine ungültige Größe. Das Hochladen der gültigen Bilder",
              "title": "Hochladen fehlgeschlagen"
            },
            "success": {
              "multiple_files": "Mehrere Dateien wurden erfolgreich hochgeladen",
              "title": "Upload erfolgreich"
            }
          }
        },
        "formula": {
          "description": "Dies ist eine Formelspalte, das Ergebnis wird automatisch aktualisiert",
          "icon": "Klicken Sie hier, um die Gleichung anzuzeigen",
          "result": "Ergebnis"
        },
        "gps": {
          "save_location": "Ort speichern",
          "search": "Standort suchen...",
          "without_location": "Ohne Standort"
        },
        "multiple_entries": {
          "add": "Weitere hinzufügen",
          "empty_entry": {
            "scaffolding_type_id": "Wählen Sie einen Gerüsttyp aus, um Aktionen hinzuzufügen"
          },
          "empty_inputs": "Die Eingaben von %{input} werden in %{model} benötigt",
          "required": "Das Feld \"%{field}\" ist ein Pflichtfeld und muss ausgefüllt werden"
        },
        "numeric": {
          "between": "Der Wert sollte zwischen %{min} und %{max} liegen",
          "greater": "Der Wert sollte größer als %{min} sein",
          "lower": "Der Wert sollte niedriger als %{max} sein"
        },
        "picture": {
          "comment_placeholder": "Schreiben Sie einen Kommentar (optional)",
          "edition_only": "Nur in der Ausgabe verfügbar",
          "no_image_on_clipboard": "Es wurden keine Bilddaten in Ihrer Zwischenablage gefunden. Bitte kopieren Sie zuerst ein Bild.",
          "remove_error": "Entfernen fehlgeschlagen",
          "remove_success": "Erfolgreich entfernt",
          "upload_error": "Hochladen fehlgeschlagen",
          "upload_success": "Erfolgreich hochgeladen"
        },
        "placeholder": "Geben Sie hier %{field} ein",
        "value_selector": {
          "apply": "Anwenden",
          "done": "Fertig",
          "search": "Hier etwas suchen...",
          "waiting": "... Warten"
        }
      },
      "items": "Artikel",
      "loading": "Laden...",
      "loading_calculations": "Berechnen von Sichtbarkeiten, erforderlichen Spalten und Standardwerten",
      "loading_contract": "Vertragsinformationen werden geladen...",
      "schedule_entity": "Terminieren %{entity}",
      "schedule_success": "Ihre Anfrage zur Planung von %{entity} wurde erfolgreich abgeschlossen",
      "show": "Anzeigen",
      "subtitle": "Bitte treffen Sie eine Auswahl zu diesem Filter: %{entity}",
      "subtitle_error": "Das Formular konnte nicht geladen werden, überprüfen Sie Ihre",
      "update_entity": "Bearbeiten %{entity}",
      "update_error": " %{entity} konnte nicht aktualisiert werden",
      "update_success": " %{entity} wurde erfolgreich aktualisiert"
    },
    "formula_modal": {
      "back_button_text": "Zurück zum Formular",
      "calculate": "Berechnen Sie",
      "cancel": "Abbrechen",
      "mandatory": "Sie müssen die Werte für die folgenden Felder eingeben: %{fields}",
      "ok": "Formel speichern"
    },
    "grid": {
      "all": "Alle",
      "clear_filters": "Filter entfernen",
      "editable": {
        "error": {
          "mandatory": "Mindestens ein Pflichtfeld wurde leer gelassen"
        },
        "missing_required": "%{number} fehlende Pflichtzellen in dieser Zeile",
        "title": "Bearbeitungsmodus",
        "update": {
          "error": "%{quantity} konnte nicht gespeichert werden. Überprüfen Sie den Grund auf der linken Seite jeder hervorgehobenen Zeile",
          "success": "%{quantity} Datensätze wurden erfolgreich gespeichert"
        }
      },
      "empty_after_filtering": "Es gibt keine Datensätze für die angewandten Filter",
      "failed_saving_preferences": "Einstellungen wurden nicht gespeichert!",
      "flag_filter": "Flag-Filter",
      "grid_filters": {
        "menu_title": "Benutzerdefinierte Filter"
      },
      "menu": {
        "visibility": "Spalten"
      },
      "of": "Von",
      "other": "Andere",
      "productivity_factor": "Produktivitätsfaktor",
      "quick_pin_popup": {
        "all_work_orders": "Alle OTs",
        "clear_all": "Alles löschen",
        "no_pinned_requests": "Sie haben noch keine Arbeitsaufträge angepinnt",
        "pinned_work_orders": "Angeheftete OTs",
        "quick_pin": "Schneller Pin",
        "search_and_pin": "OT suchen und anpinnen"
      },
      "saved_preferences": "Gerettet!",
      "saving_preferences": "Einstellungen speichern...",
      "select_items": "Elemente auswählen",
      "selected": "Ausgewählt",
      "selecteds": "Ausgewählt",
      "status_filter": "Statusfilter",
      "totals": "Summen"
    },
    "history_form": {
      "create_title": "Einen neuen Fortschritt hinzufügen",
      "edit_title": "Bearbeiten Sie Ihren Fortschritt",
      "subtitle": "Geben Sie für Ihre Aktivität einen Fortschritt nach Menge oder Prozentsatz ein."
    },
    "history_popup": {
      "back": "Zurück",
      "comments": "Kommentare",
      "crew_size": "Teamgröße",
      "daily_hours": "Tägliche Stunden",
      "date": "Datum",
      "form_create_progress_service_title": "Erstellen Sie eine Artikelhistorie für Bauleistungen",
      "form_create_progress_title": "Verlauf des Fortschrittselements erstellen",
      "form_edit_progress_service_title": "Bearbeiten Sie den Verlauf der Baudienstleistungsartikel",
      "form_edit_progress_title": "Verlauf des Fortschrittselements bearbeiten",
      "form_error_body": "Alle Pflichtfelder des Formulars müssen ausgefüllt werden.",
      "form_error_title": "Das Verlaufsformular konnte nicht gespeichert werden",
      "form_subtitle": "Fügen Sie einen Verlauf nach Menge oder Prozentsatz für Ihre Aktivität",
      "modal_create_title": "Verlauf erstellen",
      "modal_edit_title": "Verlauf bearbeiten",
      "number_of_crews": "Anzahl der Teams",
      "number_of_workers": "Anzahl der Arbeiter",
      "other_value": "Anderer Wert",
      "percentage": "Prozentsatz",
      "progress_by": "Fortschritt durch",
      "quantity": "Menge",
      "singular": "Verlauf",
      "working_hours": "Arbeitszeit"
    },
    "home": {
      "available_on": "verfügbar auf",
      "banner_description": "Verwalten von Aufgaben, Verfolgen von Prozessen und Erzielen der besten Ergebnisse über eine einzige Plattform",
      "create_request": {
        "text": "Als Erstes müssen Sie vom Kunden im DPMS einen Arbeitsauftrag mit den Details zu Ihrem Auftrag erstellen.",
        "title": "Erstellen Sie einen Arbeitsauftrag"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Erste Schritte",
      "home_title": "Startseite",
      "inspection": {
        "text": "Führen Sie Qualitätskontrollen vor Ort durch und erfassen Sie Beeinträchtigungen.",
        "title": "Inspektion"
      },
      "inspection_calendar": {
        "calendar": "Kalender",
        "table": "Tisch",
        "title": "Inspektionsplan"
      },
      "performance": {
        "text": "Erfassen Sie Fortschritt und Zeit basierend auf den geplanten Aktivitäten jedes Teams und messen Sie die Produktivität.",
        "title": "Konstruktion"
      },
      "scoping": {
        "text": "Bereiten Sie einen Arbeitsauftrag vom Kunden vor. Ordnen Sie Leistungen und Servicepakete aus Ihrem Vertrag zu.",
        "title": "Arbeitsvorbereitung"
      },
      "welcome_user": "Willkommen, %{user}"
    },
    "importation_drop": {
      "choose_error": "Wählen Sie ein Element"
    },
    "importation_popup": {
      "file_upload_message": "Click or drag file to this area to upload",
      "importation_error": "Der Import ist fehlgeschlagen. Prüfen Sie den Grund auf der Seite Jobs.",
      "importation_success": "Imprt Erfolg",
      "importation_timeout": "Der Import dauert zu lange. Prüfen Sie den Status auf der Seite Aufträge.",
      "instructions": "Files must be a .xls and no larger than xxmb.<br> Your .xls file should contain the column - Date, Client, Project, Task, Notes....<br> The order of the columns should be same as the order of the template.<br> Date should be in - YYYY/MM/DD.",
      "requirements": "Anforderungen",
      "submit_spreadsheet": "Tabellenkalkulation einreichen"
    },
    "inspections": {
      "acceptance": "Annahme",
      "acceptance_error": {
        "error_description": "Die Inspektion - %{item} konnte nicht aktualisiert werden, da %{reason}",
        "title": "Wir können nicht abschließen"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "Sie können dies später bei Bedarf rückgängig machen.",
          "reject": "Möchten Sie diese Inspektion(en) wirklich ablehnen?"
        },
        "inspection_acceptance": "Inspektionsannahme",
        "items": "%{items} Einträge",
        "reject": "Ablehnen",
        "subtitle": "Geben Sie Informationen über die Inspektion(en) an, um mit der Abnahme fortzufahren."
      },
      "actions": {
        "approve": "Genehmigen",
        "more": "Mehr",
        "reject": "Ablehnen"
      },
      "choose_modal": {
        "schedule": "Planen Sie Inspektionsgegenstände",
        "schedule_description": "Planen Sie eine oder mehrere Inspektionen basierend auf",
        "single": "Einzelner Inspektionsgegenstand",
        "single_description": "Erstellung von nur einer Inspektion",
        "title": "Erstellen Sie eine Inspektion als:"
      },
      "description": "Inspektion",
      "description_title": "Beschreibung",
      "every": "Jede",
      "id": "Kennung",
      "inspect_date": "Inspektionstermin",
      "inspect_status_id": "Status",
      "inspected": "Geprüft",
      "inspected_detail": "Geprüftes Detail",
      "inspected_item": "Geprüfter Artikel",
      "inspected_modules": {
        "estimate_services": "Kostenvoranschlagsdetails",
        "performance": "Konstruktion",
        "progress": "Konstruktion",
        "progress_services": "Konstruktionsdetails",
        "request": "Arbeitsauftrag",
        "scaffolding": "Gerüst",
        "scope": "Arbeitsvorbereitung"
      },
      "inspected_services": "Geprüfte Leistungen",
      "inspected_track": {
        "area": "Bereich",
        "comments": "Kommentare",
        "description": "Beschreibung",
        "item": "Artikel %{item}",
        "no_load": "Der geprüfte Gleisblock konnte nicht geladen werden: Fehlende Daten",
        "number_of_tags": "Anzahl der Tags",
        "reason": "Grund",
        "subarea": "Teilbereich"
      },
      "inspection_block": {
        "inspection_date": "Inspektionsdatum",
        "missing_date": "Fehlendes Datum",
        "not_selected_yet": "Noch nicht ausgewählt",
        "responsible": "Verantwortlich"
      },
      "inspections_detail": {
        "side_panel_title": "Details der Inspektion"
      },
      "module": "Modul",
      "notifications": {
        "approve_successful": "Die Inspektion wurde erfolgreich genehmigt",
        "reject_successful": "Die Inspektion wurde erfolgreich abgelehnt"
      },
      "pending_inspections": "Ausstehende Inspektionen",
      "recurrency_interval": "Wiederholungsintervall",
      "related_inspections": {
        "missing_date": "Fehlendes Datum",
        "no_related": "Keine zugehörigen Inspektionen gefunden",
        "no_responsible": "Keine Verantwortung",
        "title": "Zugehörige Inspektionen"
      },
      "repeat": "Wiederholung",
      "repeat_every_value": {
        "day": "Tag",
        "month": "Monat",
        "week": "Woche",
        "year": "Jahr"
      },
      "repeats_on": "Wiederholung am",
      "request_id": "DPMSID",
      "responsible_id": "Verantwortlich",
      "schedule_detail": "Zeitplan Detail",
      "schedule_form": {
        "acceptance_step": "Akzeptanz definieren (optional)",
        "detailed_item_step": "Wählen Sie die Position für Inspektions-Aufmaß/Durchführung",
        "detailed_template_step": "Wählen Sie die Vorlage für Inspektions-Aufmaß/Durchführung",
        "further_step_information": "Die flexiblen Felder können nach dem Schritt der Wiederholungserstellung individuell ausgefüllt werden",
        "general_step": "Allgemeine Informationen zur Inspektion definieren",
        "hints": "Für monatliche und jährliche Wiederholungen plant das System die Inspektion passend zum Starttermin, das im Feld Intervall angegeben ist",
        "monthly_hint": "Monatlich wiederholen an jedem Tag %{day}",
        "recurrence_step": "Wiederholung"
      },
      "scheduled_exception": "Geplant",
      "select_module_item": "Wählen Sie ein Modulelement aus, um seine Details hier anzuzeigen",
      "statuses": {
        "approved": "Genehmigt",
        "completed": "Abgeschlossen",
        "overdue": "Überfällig",
        "pending": "Ausstehend",
        "progress": "In Bearbeitung",
        "rejected": "Abgelehnt",
        "scheduled": "Geplant"
      },
      "template": "Vorlage",
      "title": "Inspektionen"
    },
    "inspects": {
      "statuses": {
        "approved": "Genehmigt",
        "completed": "Abgeschlossen",
        "pending": "Ausstehend",
        "progress": "In Bearbeitung",
        "rejected": "Abgelehnt"
      }
    },
    "kendo": {
      "upload": "Klicken oder ziehen Sie die Dateien zum Hochladen hierher"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Heute"
      },
      "dateinput": {
        "decrement": "Wert vermindern",
        "increment": "Wert erhöhen"
      },
      "datepicker": {
        "toggleCalendar": "Kalender umschalten"
      },
      "daterangepicker": {
        "end": "Ende",
        "separator": 0,
        "start": "Anfang",
        "swapStartEnd": "Start- und Endwerte vertauschen"
      },
      "datetimepicker": {
        "cancel": "Abbrechen",
        "date": "Datum",
        "set": "Einstellen",
        "time": "Uhrzeit",
        "toggleDateTimeSelector": "Datum/Uhrzeit-Auswahl umschalten"
      },
      "dropdowns": {
        "clear": "Entfernen",
        "nodata": "Keine Daten gefunden"
      },
      "editor": {
        "addColumnAfter": "Spalte auf der rechten Seite hinzufügen",
        "addColumnBefore": "Spalte auf der linken Seite hinzufügen",
        "addRowAfter": "Zeile unten hinzufügen",
        "addRowBefore": "Zeile oben hinzufügen",
        "alignCenter": "Text zentrieren",
        "alignJustify": "Ausrichten",
        "alignLeft": "Text links ausrichten",
        "alignRight": "Text rechtsbündig ausrichten",
        "bold": "Fettdruck",
        "bulletList": "Ungeordnete Liste einfügen",
        "createTable": "Eine Tabelle erstellen",
        "deleteColumn": "Spalte löschen",
        "deleteRow": "Zeile löschen",
        "deleteTable": "Tabelle löschen",
        "fontName": "Schriftartenname",
        "fontSize": "Schriftgröße",
        "format": "Format",
        "hyperlink": "Hyperlink einsetzen",
        "hyperlink-dialog-cancel": "Absagen",
        "hyperlink-dialog-content-address": "Webadresse",
        "hyperlink-dialog-content-newwindow": "Link in neuem Fenster öffnen",
        "hyperlink-dialog-content-title": "Titel",
        "hyperlink-dialog-insert": "Einfügung",
        "hyperlink-dialog-title": "Hyperlink einsetzen",
        "image": "Bild einfügen",
        "image-address": "Webadresse",
        "image-altText": "Alternativer Text",
        "image-cancel": "Absagen",
        "image-dialog-title": "Bild einfügen",
        "image-height": "Höhe (px)",
        "image-insert": "Einfügung",
        "image-title": "Titel",
        "image-width": "Breite (Pixel)",
        "indent": "Einzug",
        "insertTableHint": "Erstellen Sie eine 0 x 1-Tabelle",
        "italic": "Kursiv",
        "mergeCells": "Zellen verbinden",
        "orderedList": "Geordnete Liste einfügen",
        "outdent": "Ausrücken",
        "redo": "Wiederholen",
        "splitCell": "Geteilte Zelle",
        "strikethrough": "Durchgestrichen",
        "subscript": "Index",
        "superscript": "Hochgestellt",
        "underline": "Unterstreichen",
        "undo": "Rückgängig machen",
        "unlink": "Entfernen Sie den Hyperlink",
        "viewHtml": "HTML anzeigen",
        "viewHtml-cancel": "Absagen",
        "viewHtml-dialog-title": "HTML anzeigen",
        "viewHtml-update": "Aktualisieren"
      },
      "filter": {
        "addExpression": "Ausdruck hinzufügen",
        "addGroup": "Gruppe hinzufügen",
        "afterOperator": "Ist danach",
        "afterOrEqualOperator": "Ist nach oder gleich",
        "andLogic": "Und",
        "beforeOperator": "Ist vor",
        "beforeOrEqualOperator": "Ist vor oder gleich",
        "close": "Nah dran",
        "containsOperator": "Enthält",
        "endsWithOperator": "Endet mit",
        "eqOperator": "Ist gleich",
        "gtOperator": "Ist größer als",
        "gteOperator": "Ist größer als oder gleich",
        "isEmptyOperator": "Ist leer",
        "isFalse": "Ist nein",
        "isNotEmptyOperator": "Ist nicht leer",
        "isNotNullOperator": "Ist nicht null",
        "isNullOperator": "Ist Null",
        "isTrue": "Ist ja",
        "ltOperator": "Ist weniger als",
        "lteOperator": "Ist kleiner oder gleich",
        "notContainsOperator": "Beinhaltet nicht",
        "notEqOperator": "Ist ungleich zu",
        "orLogic": "Oder",
        "startsWithOperator": "Beginnt mit"
      },
      "grid": {
        "filterAfterOperator": "Ist danach",
        "filterAfterOrEqualOperator": "Ist nach oder gleich",
        "filterBeforeOperator": "Ist vor",
        "filterBeforeOrEqualOperator": "Ist vor oder gleich",
        "filterBetweenOperator": "Ist zwischen",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Entfernen",
        "filterContainsOperator": "Enthält",
        "filterEndsWithOperator": "Endet mit",
        "filterEqOperator": "Ist gleich",
        "filterGtOperator": "Ist größer als",
        "filterGteOperator": "Ist größer als oder gleich",
        "filterIsEmptyOperator": "Ist leer",
        "filterIsFalse": "Ist nein",
        "filterIsNotEmptyOperator": "Ist nicht leer",
        "filterIsNotNullOperator": "Ist nicht null",
        "filterIsNullOperator": "Ist Null",
        "filterIsTrue": "Ist ja",
        "filterLtOperator": "Ist weniger als",
        "filterLteOperator": "Ist kleiner oder gleich",
        "filterNotContainsOperator": "Beinhaltet nicht",
        "filterNotEqOperator": "Ist ungleich zu",
        "filterStartsWithOperator": "Beginnt mit",
        "filterSubmitButton": "Filtern",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Ziehen Sie eine Spaltenüberschrift und legen Sie sie hier ab,",
        "noRecords": "Keine Aufzeichnungen verfügbar.",
        "pagerFirstPage": "Gehen Sie zur ersten Seite",
        "pagerInfo": "0 - 1 von 2 Artikeln",
        "pagerItemsPerPage": "Elemente pro Seite",
        "pagerLastPage": "Gehen Sie zur letzten Seite",
        "pagerNextPage": "Gehen Sie zur nächsten Seite",
        "pagerOf": "Von",
        "pagerPage": "Buchseite",
        "pagerPreviousPage": "Gehen Sie zur vorherigen Seite"
      },
      "numerictextbox": {
        "decrement": "Wert verringern",
        "increment": "Wert steigern"
      },
      "pager": {
        "firstPage": "Gehen Sie zur ersten Seite",
        "info": "0 - 1 von 2 Artikeln",
        "itemsPerPage": "Elemente pro Seite",
        "lastPage": "Gehen Sie zur letzten Seite",
        "nextPage": "Gehen Sie zur nächsten Seite",
        "of": "Von",
        "page": "Buchseite",
        "previousPage": "Gehen Sie zur vorherigen Seite"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "den ganzen Tag",
        "allEvents": "Alle Veranstaltungen",
        "dateTitle": "Datum",
        "dayViewTitle": "Tag",
        "deleteConfirmation": "Möchten Sie dieses Ereignis wirklich löschen?",
        "deleteDialogTitle": "Ereignis löschen",
        "deleteOccurrence": "Aktuelles Vorkommen löschen",
        "deleteRecurringConfirmation": "Möchten Sie nur dieses Ereignis oder die ganze",
        "deleteRecurringDialogTitle": "Wiederkehrendes Element löschen",
        "deleteSeries": "Serie löschen",
        "deleteTitle": "Löschen",
        "editorCancel": "Absagen",
        "editorDelete": "Löschen",
        "editorEventAllDay": "Ganztägige Veranstaltung",
        "editorEventDescription": "Beschreibung",
        "editorEventEnd": "Ende",
        "editorEventEndTimeZone": "Endzeitzone",
        "editorEventSeparateTimeZones": "Ende in einer anderen Zeitzone",
        "editorEventStart": "Anfang",
        "editorEventStartTimeZone": "Zeitzone starten",
        "editorEventTimeZone": "Zeitzone angeben",
        "editorEventTitle": "Titel",
        "editorOccurrence": "Aktuelles Vorkommen bearbeiten",
        "editorRecurringConfirmation": "Möchten Sie nur dieses Ereignis oder die ganze",
        "editorRecurringDialogTitle": "Wiederkehrendes Element bearbeiten",
        "editorSave": "Speichern",
        "editorSeries": "Bearbeiten Sie die Serie",
        "editorTitle": "Vorfall",
        "editorValidationEnd": "Die Endzeit muss nach der Startzeit liegen.",
        "editorValidationRequired": "Feld ist erforderlich.",
        "editorValidationStart": "Die Startzeit muss vor der Endzeit liegen.",
        "eventTitle": "Vorfall",
        "monthViewTitle": "Monat",
        "nextTitle": "Weiter",
        "noEvents": "keine Ereignisse",
        "previousTitle": "Vorherige",
        "recurrenceEditorDailyInterval": "Tage)",
        "recurrenceEditorDailyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorEndAfter": "Nach",
        "recurrenceEditorEndLabel": "Ende",
        "recurrenceEditorEndNever": "Niemals",
        "recurrenceEditorEndOccurrence": "Vorkommen",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "Täglich",
        "recurrenceEditorFrequenciesMonthly": "Monatlich",
        "recurrenceEditorFrequenciesNever": "Niemals",
        "recurrenceEditorFrequenciesWeekly": "Wöchentlich",
        "recurrenceEditorFrequenciesYearly": "Jährlich",
        "recurrenceEditorMonthlyDay": "Tag",
        "recurrenceEditorMonthlyInterval": "Monat(E)",
        "recurrenceEditorMonthlyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorMonthlyRepeatOn": "Wiederholen Sie auf",
        "recurrenceEditorOffsetPositionsFirst": "Zuerst",
        "recurrenceEditorOffsetPositionsFourth": "Vierte",
        "recurrenceEditorOffsetPositionsLast": "Letzte",
        "recurrenceEditorOffsetPositionsSecond": "Zweite",
        "recurrenceEditorOffsetPositionsThird": "Dritte",
        "recurrenceEditorRepeat": "Wiederholen",
        "recurrenceEditorWeekdaysDay": "Tag",
        "recurrenceEditorWeekdaysWeekday": "Wochentag",
        "recurrenceEditorWeekdaysWeekendday": "Wochenend Tag",
        "recurrenceEditorWeeklyInterval": "Wochen)",
        "recurrenceEditorWeeklyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorWeeklyRepeatOn": "Wiederholen Sie auf",
        "recurrenceEditorYearlyInterval": "Jahre)",
        "recurrenceEditorYearlyOf": "Von",
        "recurrenceEditorYearlyRepeatEvery": "Wiederhole jeden",
        "recurrenceEditorYearlyRepeatOn": "Wiederholen Sie auf",
        "showFullDay": "Ganztägig zeigen",
        "showWorkDay": "Öffnungszeiten anzeigen",
        "timeTitle": "Zeit",
        "timelineViewTitle": "Zeitleiste",
        "today": "Heute",
        "weekViewTitle": "Woche",
        "workWeekViewTitle": "Arbeitswoche"
      },
      "sortable": {
        "noData": "Keine Daten"
      },
      "timepicker": {
        "cancel": "Absagen",
        "now": "Jetzt",
        "selectNow": "Wählen Sie Jetzt aus",
        "set": "Satz",
        "toggleClock": "Uhr umschalten",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Ist danach",
        "filterAfterOrEqualOperator": "Ist nach oder gleich",
        "filterBeforeOperator": "Ist vor",
        "filterBeforeOrEqualOperator": "Ist vor oder gleich",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Entfernen",
        "filterContainsOperator": "Enthält",
        "filterEndsWithOperator": "Endet mit",
        "filterEqOperator": "Ist gleich",
        "filterGtOperator": "Ist größer als",
        "filterGteOperator": "Ist größer als oder gleich",
        "filterIsEmptyOperator": "Ist leer",
        "filterIsFalse": "Ist nein",
        "filterIsNotEmptyOperator": "Ist nicht leer",
        "filterIsNotNullOperator": "Ist nicht null",
        "filterIsNullOperator": "Ist Null",
        "filterIsTrue": "Ist ja",
        "filterLtOperator": "Ist weniger als",
        "filterLteOperator": "Ist kleiner oder gleich",
        "filterNotContainsOperator": "Beinhaltet nicht",
        "filterNotEqOperator": "Ist ungleich zu",
        "filterStartsWithOperator": "Beginnt mit",
        "noRecords": "Keine Aufzeichnungen verfügbar"
      },
      "upload": {
        "cancel": "Absagen",
        "clearSelectedFiles": "Entfernen",
        "dropFilesHere": "oder Datei hier ablegen",
        "files": "Dateien",
        "headerStatusUploaded": "Fertig",
        "headerStatusUploading": "Hochladen...",
        "invalidFileExtension": "Dateityp nicht erlaubt.",
        "invalidFiles": "Ungültige Datei(en). Bitte überprüfen Sie die Anforderungen für",
        "invalidMaxFileSize": "Dateigröße zu groß.",
        "invalidMinFileSize": "Dateigröße zu klein.",
        "remove": "Entfernen",
        "retry": "Wiederholen",
        "select": "Datei aussuchen",
        "total": "Gesamt",
        "uploadSelectedFiles": "Hochladen"
      }
    },
    "loading_batch_entities": {
      "page_of": "Seite %{page} von %{total}",
      "title": "Seiten abrufen"
    },
    "login": {
      "again": "Um fortzufahren, melden Sie sich bitte erneut an",
      "continue_mykaefer": "Weiter mit MyKaefer",
      "copyright": "®%{year} Alle Rechte vorbehalten. Kaefer®",
      "forgot_password": "Passwort vergessen?",
      "iframe_info": "Zugriff auf Seite anfordern: %{link}",
      "log_in": "Einloggen",
      "password": "Kennwort",
      "title_1": "Ein leistungsstarkes Werkzeug",
      "title_2": "für die Projektverwaltung",
      "username": "Benutzername",
      "welcome_back": "Willkommen zurück"
    },
    "main_dialog": {
      "failure_message": "Ihre Anfrage konnte nicht abgeschlossen werden",
      "failure_title": "Fehlgeschlagen",
      "loading_message": "Bitte warten Sie, während wir alles für Sie vorbereiten!",
      "loading_title": "Wird geladen...",
      "service_title": "Warten auf den Abschluss des Auftrags ...",
      "success_message": "Ihre Anfrage war erfolgreich! Sie können jetzt mit Ihrer Arbeit fortfahren.",
      "success_title": "Erfolgreich!"
    },
    "notification": {
      "access_denied": "Zugriff verweigert",
      "access_denied_message": "Sie haben keine Berechtigung, diese Aktion auszuführen. Weitere Informationen erhalten Sie von Ihrem Administrator.",
      "action_success": "%{model} %{id} war %{action}",
      "bad_formula_setup": "Es gibt ein Setup-Problem mit der Formel '%{title}', bitte",
      "correct_subproject": "Wählen Sie das richtige Teilprojekt dieses Elements aus",
      "dismiss_all": "Alle entlassen",
      "error": "Fehler",
      "error_cookies": "Wir konnten keine Kommunikation zwischen den verschiedenen Quellen herstellen. Überprüfen Sie die Cookie-Einstellungen in Ihrem Browser und deaktivieren Sie \"Third-Party Cookie blocking\".",
      "error_job": "Fehlgeschlagen! Überprüfen Sie den Grund auf der Seite Jobs.",
      "error_msg": "Entschuldigung! Etwas ist schief gelaufen!",
      "info": "Info",
      "large_file_body": "Bitte wenden Sie einige Filter an, um die Ausgabedatei kleiner",
      "large_file_title": "Das Raster enthält mehr als 1000 Datensätze",
      "page_denied": "Die Seite konnte nicht geladen werden.",
      "please": "Bitte",
      "select_subproject": "Wählen Sie ein Projekt und ein Teilprojekt aus, um fortzufahren",
      "success": "Erfolg",
      "success_msg": "Aktion mit Erfolg ausgeführt!",
      "warning": "Warnung"
    },
    "orders": {
      "add_evidence": "Fügen Sie einen Nachweis der Client-Autorisierung hinzu",
      "attachment": "Anhang",
      "comments": "Kommentare",
      "date": "Datum",
      "drag_and_drop": "Fügen Sie Dateien per Drag-and-Drop hinzu",
      "recipient": "Empfänger",
      "responsible": "Verantwortlich",
      "title": "Bestellungen"
    },
    "performance": {
      "title": "Konstruktion"
    },
    "performances": {
      "title": "Konstruktionen"
    },
    "performances_wip": {
      "performance_item": "Baugegenstand",
      "title": "Konstruktion"
    },
    "planning": {
      "item_no": "Element-Nr.",
      "service_no": "Service-Nr.",
      "title": "Planung",
      "view": "Planungsansicht"
    },
    "plannings": {
      "title": "Planung"
    },
    "print_grid": {
      "title": "Drucken Sie das Raster"
    },
    "print_list": {
      "loading": "Ihr Dokument wird erstellt. Bitte warten Sie einen Moment.",
      "title": "Liste drucken"
    },
    "progress": {
      "statuses": {
        "canceled": "Abgesagt",
        "done": "Erledigt",
        "extra": "Extra",
        "in_progress": "Im Gange",
        "pending": "Ausstehend",
        "revised": "Überarbeitet"
      }
    },
    "progress_histories": {
      "add_new_progress": "Fügen Sie einen neuen Fortschritt hinzu",
      "empty_history": "Es ist kein zugehöriges Verlaufselement vorhanden und es können keine neuen Elemente erstellt werden.",
      "hours": "Std",
      "production_factor": "PF",
      "progress": "Fortschritt",
      "progress_badge": "Artikel %{number}",
      "progress_history": "Fortschrittsverlauf",
      "progress_service_badge": "Dienst %{number}",
      "progress_service_history": "Leistungsverlauf",
      "progress_service_title": "Leistungsverlauf",
      "progress_title": "Artikelhistorien",
      "quantity": "Menge",
      "quantity_empty_history": "Es gibt kein Historienelement und es ist nicht möglich, Historien",
      "subtitle": "Verwalten Sie den täglichen Fortschritt basierend auf den von Ihrem"
    },
    "progress_services": {
      "actual_div_hours": "Ist-/Team-Sollstunden",
      "actual_div_quantity": "Ist-/Sollmenge",
      "actual_hours": "IST-Stunden",
      "actual_quantity": "Ist-Menge",
      "application_price": "Anwendungspreis",
      "back": "Alle Konstruktionen",
      "budget_target_hours": "Budgetzielstunden",
      "canceled": "Storniert",
      "choose_modal": {
        "package": "Einzeldetailpaket",
        "package_description": "Erstellung mehrerer Dienste basierend auf demselben Detail",
        "single": "Einzelnes Detail",
        "single_description": "Erstellung nur eines Details",
        "title": "Neu erstellen"
      },
      "contract_service": "Vertragsleistung",
      "contribution": "Beitrag",
      "contribution_info": "Werte automatisch berechnet",
      "crew": "Team",
      "crew_size": "Teamgröße",
      "crews": "Teams",
      "date_filter": {
        "all": "Alle",
        "to": "Zu",
        "today": "Heute",
        "tomorrow": "Morgen",
        "week": "Woche",
        "yesterday": "Gestern"
      },
      "date_filter_info": "Datumsfilter",
      "delta_hours": "Delta-Stunden",
      "delta_qtt": "Delta-Menge",
      "description": "Bauservice",
      "discipline": "Disziplin",
      "end_date": "Endtermin",
      "equipment_price": "Equipmentpreis",
      "factor": "Faktor",
      "flag": "Flagge",
      "id": "ID",
      "materials_price": "Materialpreis",
      "no_crews": "Anzahl der Teams",
      "no_workers": "Anzahl der Arbeiter",
      "norm_hours": "Normstunden",
      "not_planned": "Nicht geplant",
      "other_price": "Sonstiger Preis",
      "package_factor": "Faktor",
      "people": "Personen",
      "person": "Person",
      "pf": "Fs",
      "pf_doing_bad": "Fortschritt nicht wie geplant",
      "pf_doing_good": "Fortschritt nach Plan!",
      "pf_doing_neutral": "Fortschritt konnte nicht berechnet werden",
      "planned": "Geplant",
      "planning_chart": {
        "actual": "IST-",
        "actual_qty": "IST-Menge",
        "date": "Datum",
        "forecasted": "Voraussichtlich",
        "forecasted_qty": "Prognostizierte Menge",
        "target": "Ziel",
        "target_qty": "Zielmenge",
        "title": "Planungstabelle"
      },
      "planning_timeline": {
        "day": "Tag",
        "day_advance": "Tag im Voraus",
        "day_delayed": "Tag verzögert",
        "days": "Tage",
        "days_advance": "Tage im Voraus",
        "days_delayed": "Tage verzögert",
        "end_date": "Endtermin",
        "forecasted": "Voraussichtlich",
        "on_time": "Pünktlich",
        "remaining_days": "Verbleibende Tage",
        "start_date": "Starttermin",
        "title": "Zeitplanung"
      },
      "progress": "Fortschritt",
      "progress_services_detail": {
        "side_panel_title": "Details des Dienstes"
      },
      "quantity": "Menge",
      "responsible": "Verantwortlich",
      "scoped_quantity": "Vorbereitete Menge",
      "service": "Dienstleistung",
      "service_package": "Dienstleistungspaket",
      "service_planning": "Teamplanung",
      "service_progress": "Dienstleistungsfortschritt",
      "service_weight": "Gewicht der Dienstleistung",
      "start_date": "Starttermin",
      "statuses": {
        "canceled": "Abgesagt",
        "done": "Abgeschlossen",
        "extra": "Extra",
        "in_planning": "Nicht geplant",
        "inspected_flag": "Inspektion ausstehend",
        "planned": "Geplant"
      },
      "team_target_hours": "Team-Sollstunden",
      "title": "Bauleistungen",
      "title_packageable": "Bauleistungen nach Leistungspaket",
      "total": "Gesamt",
      "total_hours": "Gesamtstunden",
      "unit": "Einheit",
      "worker": "Arbeiter",
      "workers": "Arbeitskräfte",
      "working_hours": "Arbeitszeit"
    },
    "progresses": {
      "actual_hours": "Tatsächliche Stunden",
      "cancel": "Absagen",
      "choose_modal": {
        "single": "Ein weiterer Baugegenstand",
        "single_description": "Erstellung von nur einem Bauelement"
      },
      "complete": "Vervollständigen",
      "complete_services": "Komplette Dienstleistungen",
      "current_hours": "Aktuelle Öffnungszeiten",
      "current_qty": "Aktuelle Menge",
      "date_filter": {
        "all": "Alle",
        "current_week": "Aktuelle Woche",
        "previous_week": "Vorherige Woche",
        "today": "Heute"
      },
      "date_filter_info": "Datumsfilter",
      "delta_hours": "Δ Stunden",
      "delta_qty": "Δ Menge",
      "description": "Beschreibung",
      "edit_work_description": "Arbeitsbeschreibung bearbeiten",
      "extra": "Extra",
      "flag": "Flagge",
      "history": "Neuen Fortschritt hinzufügen",
      "id": "ICH WÜRDE",
      "item": "Element",
      "label": "Etikett",
      "more_details": "Mehr erfahren",
      "pf": "PF",
      "planned_hours": "Geplante Stunden",
      "planned_qty": "Geplante Menge",
      "preview_unavailable": "Keine Vorschau verfügbar",
      "progress": "Fortschritt",
      "progress_status_restriction": "Der Fortschritt sollte nicht den Status „Abgebrochen“ oder „Fertig“ haben",
      "progresses_detail": {
        "side_panel_title": "Konstruktionsdetails"
      },
      "remaining_hours": "Verbleibende Stunden",
      "request_status_restriction": "Der Arbeitsauftrag sollte den Status „In Planung“ oder „In Bau“ haben",
      "revised": "Überarbeitet",
      "success_work_description_edition": "Die Stellenbeschreibung wurde erfolgreich bearbeitet",
      "title": "Konstruktionen",
      "work_description": "Arbeitsbeschreibung"
    },
    "projects": {
      "workdays": {
        "fri": "Fr.",
        "mon": "Mo.",
        "sat": "Sa.",
        "sun": "So.",
        "thu": "Do.",
        "tue": "Di.",
        "wed": "Mi."
      }
    },
    "requests": {
      "actual_hours": "IST-Stunden",
      "actual_progress": "IST-Fortschritt",
      "all": "Alle",
      "are_you_sure_complete": "Sind Sie sicher, dass Sie diesen Arbeitsauftrag abschließen?",
      "are_you_sure_complete_no_history": "Sind Sie sicher, dass Sie einen Arbeitsauftrag ohne vorgegebenen Fortschritt abschließen können?",
      "are_you_sure_deletion": "Sind Sie sicher, dass Sie den Arbeitsauftrag löschen möchten?",
      "are_you_sure_revertion": "Sind Sie sicher, dass Sie den Vorgang rückgängig machen und damit die Daten, die Sie in diesem Modul eingegeben haben, verlieren wollen?",
      "area": "Bereich",
      "authorization_pending": "Autorisierung ausstehend",
      "authorize_form": {
        "modal_title": "Genehmigung",
        "subtitle": "Geben Sie einige Informationen zum Arbeitsauftrag an, um mit der Genehmigung fortzufahren",
        "title": "Arbeitsauftrag genehmigen"
      },
      "canceled": "Abgebrochen",
      "clustered": "Geclustert",
      "comments": "Kommentare",
      "confirmation_modal": {
        "cancel_description": "Der Arbeitsauftrag wird storniert",
        "cancel_title": "Arbeitsauftrag stornieren?",
        "complete_description": "Dieser Arbeitsauftrag wird abgeschlossen",
        "complete_title": "Vollständiger Arbeitsauftrag?",
        "confirm_action": "Fahren Sie mit „Arbeitsvorbereitungen importieren“ fort",
        "confirm_cancel": "Nicht jetzt",
        "confirm_confirmation": "Ja, ich möchte bestätigen",
        "confirm_description": "Möchten Sie den Arbeitsauftrag bestätigen?",
        "confirm_title": "Arbeitsauftragsbestätigung",
        "delete_description": "Die Entfernungsaktion kann nicht rückgängig gemacht werden.",
        "delete_title": "Arbeitsauftrag löschen?",
        "reject_description": "Dieses Element kehrt in den Status „In Vorbereitung“ zurück",
        "reject_title": "Arbeitsauftrag ablehnen?",
        "remove_recycle_description": "Dieses Element kehrt in den Status „In Scoping“ zurück und verliert das Recycle-Flag.",
        "remove_recycle_title": "Klares Recycling?",
        "revert_description": "Das Rückgängig machen ist endgültig. Sind Sie sicher?",
        "revert_title": "Arbeitsauftrag wiederherstellen?"
      },
      "confirmed": "Bestätigt",
      "create_inspection_modal": {
        "category_subtitle": "Wählen Sie eine Option aus der Kategorie %{category_name}",
        "loading": "Inspektionsvorlagen werden geladen...",
        "no_templates": "Es gibt keine Inspektionsvorlagen.",
        "plural_title": "Inspektionen erstellen",
        "standalone_subtitle": "Wählen Sie eine der folgenden Optionen, um fortzufahren",
        "title": "Wählen Sie Inspektionsvorlage"
      },
      "creation_form": {
        "proceed": "Weiter zu Schritt 2"
      },
      "creation_page": {
        "add_template": "Vorlage hinzufügen",
        "first_step": "Arbeitsauftrag erstellen",
        "second_step": "Arbeitsvorbereitungen importieren (optional)",
        "third_step": "Dienstleistungen aus Paket anwenden (optional)"
      },
      "description": "Arbeitsauftrag",
      "discipline": "Gewerk",
      "discipline_check": {
        "check_terms": "Disziplinprüfungsbedingungen",
        "perform": "Führen Sie eine Disziplinarüberprüfung durch",
        "ready_for_discipline": "Bereit für den Disziplinarcheck",
        "ready_for_review": "Der Arbeitsauftrag steht zur disziplinarischen Überprüfung bereit",
        "set_as_ready": "Stellen Sie den Arbeitsauftrag auf „Bereit zur Disziplinarprüfung“ ein",
        "title": "Disziplinarkontrolle"
      },
      "discipline_check_item": "WO01 – Bereit für den Disziplinarcheck",
      "discipline_check_no_comment": "Kein Kommentar hinzugefügt",
      "done": "Fertig",
      "eav_template": "Vorlage",
      "eav_template_id": "Vorlage-ID",
      "edit_dialog": {
        "reason": "Name der Unteranforderung"
      },
      "elevation": "Höhenangaben",
      "errors": {
        "not_found": "Der Arbeitsauftrag mit der ID: %{id} konnte nicht gefunden werden"
      },
      "estimates_authorization": "Autorisierung der Kalkulation erforderlich",
      "flags": {
        "discipline_check": "WO01 - Disziplinarkontrolle",
        "discipline_check_done": "WO01 – Disziplinprüfung: Genehmigt",
        "discipline_check_pending": "WO01 – Disziplinprüfung: Ausstehend",
        "discipline_check_rejected": "WO01 – Disziplinprüfung: Abgelehnt",
        "recycle": "WO03 - Recyceln"
      },
      "forecast_end_date": "Voraussichtlicher Endtermin",
      "id": "DPMSID",
      "in_performance": "Im Aufbau",
      "in_planning": "In Planung",
      "in_scoping": "In Vorbereitung",
      "information": "Zusätzliche Informationen",
      "inspection_date": "Inspektionsdatum",
      "no_comment": "Kein Kommentar",
      "notification": {
        "authorization_success": "Der Arbeitsauftrag wurde erfolgreich autorisiert",
        "deleting_sub_request": "Die Unteranforderung %{id} wird gelöscht. Bitte überprüfen",
        "discipline_check_approved": "Disziplinarkontrolle erfolgreich genehmigt",
        "discipline_check_rejected": "Disziplinarprüfung erfolgreich abgelehnt Prüfung bereit",
        "discipline_check_success": "Der Arbeitsauftrag ist erfolgreich zur disziplinarischen",
        "missing_order_error": "Mit diesem Arbeitsauftrag ist kein ausstehender Auftrag verknüpft",
        "recycle_success": "Der Arbeitsauftrag wurde erfolgreich recycelt"
      },
      "on_hold": "in Wartestellung",
      "progress": "Fortschritt",
      "progress_end_date": "Fortschritt Endtermin",
      "progress_responsible": "Verantwortlich für den Fortschritt",
      "progress_responsible_id": "Verantwortlich für den Fortschritt",
      "progress_start_date": "Datum des Beginns des Fortschritts",
      "reason": "Referenz",
      "recycle_form": {
        "label": "Vernunft – Disziplin",
        "modal_title": "Recyceln",
        "subtitle": "Geben Sie Informationen an, um diesen Arbeitsauftrag für das Recycling zu kennzeichnen",
        "title": "Recycling-Anfrage"
      },
      "recycling_item": "WO03 – Zum Client recyceln",
      "reference": "Verweis",
      "registered": "Registriert",
      "remove_recycle_modal": {
        "cancel_description": "Nein, behalte die Flagge",
        "confirm_description": "Ja klar",
        "success_notification": "Das Flag für „Recycle“ wurde auf %{reason} – %{discipline} entfernt"
      },
      "request_date": "Datum des Arbeitsauftrags",
      "request_detail": {
        "edit": "Bearbeiten",
        "estimates": "Kostenvoranschläge",
        "inspections": "Inspektionen",
        "performance": "Konstruktion",
        "planning": "Planung",
        "scopes": "Arbeitsvorbereitungen",
        "show": "Anzeigen",
        "side_panel_title": "Details zur Anfrage"
      },
      "request_end_date": "Enddatum des Arbeitsauftrags",
      "request_responsible": "Verantwortlich für Arbeitsauftrag",
      "request_responsible_id": "Verantwortlich für Arbeitsauftrag",
      "request_start_date": "Startdatum des Arbeitsauftrags",
      "responsible": "Verantwortlich",
      "schedule": "Arbeitsauftragsplan",
      "schedule_form": {
        "date": "Datum",
        "days": "%{number} Tage",
        "performance": "Konstruktion",
        "progress": "Fortschritt",
        "request": "Arbeitsauftrag",
        "responsible": "Verantwortlich",
        "schedule": "Zeitplan",
        "scoping": "Arbeitsvorbereitung",
        "subtitle": "Um einen Zeitplan für diesen Arbeitsauftrag zu erstellen, benötigen wir zunächst einige zusätzliche Informationen.",
        "timecode": "Timecode",
        "title": "Arbeitsauftragsplan"
      },
      "scope_end_date": "Enddatum der Arbeitsvorbereitung",
      "scope_responsible": "Verantwortlich für die Arbeitsvorbereitung",
      "scope_responsible_id": "Verantwortlich für die Arbeitsvorbereitung",
      "scope_start_date": "Startdatum der Arbeitsvorbereitung",
      "scopes": "Arbeitsvorbereitungen",
      "service_desired_date": "Gewünschter Termin",
      "skip_scoping": "Überspringen Sie die Arbeitsvorbereitungsphase",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Ausstehend",
        "canceled": "Abgesagt",
        "clustered": "Geclustert",
        "confirmed": "In Vorbereitung",
        "deleting": "Löschen",
        "done": "Fertig",
        "in_performance": "Im Aufbau",
        "in_planning": "Im Aufbau",
        "in_scoping": "In Vorbereitung",
        "on_hold": "In Wartestellung",
        "registered": "Erstellt"
      },
      "sub_request": "Sub-Anfrage",
      "sub_requests": "Teil-Anforderungen",
      "subarea": "Teilbereich",
      "team_target_hours": "Team Soll-Stunden",
      "title": "Arbeitsanweisungen",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - Überarbeitetes %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "Nein, abbrechen",
      "description": "Sind Sie sicher, dass Sie die Inspektion „%{id}“ rückgängig machen möchten?",
      "ok": "Ja, zurück zu „Ausstehend“.",
      "success_description": "Erfolgreich auf „Ausstehend“ zurückgesetzt.",
      "success_title": "Die Inspektion wurde rückgängig gemacht",
      "title": "Artikel zurücksetzen?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Alternativer Text",
        "by_url": "Nach URL",
        "height": "Höhe (px)",
        "image": "Bild",
        "title": "Titel",
        "upload": "Hochladen",
        "web_address": "Webadresse",
        "width": "Breite (px)",
        "window_title": "Bildausgabe"
      }
    },
    "scaffolding_part_action": {
      "comment": "Kommentar",
      "date": "Datum",
      "height": "Höhe",
      "length": "Länge",
      "modification_type": {
        "dismantle": "Abbauen",
        "erect": "Aufrecht",
        "erectDismantle": "Auf-/Abbau"
      },
      "singular": "Gerüstbauteil Aktion",
      "title": "Gerüstbauteil Aktion",
      "total": "Gesamt",
      "width": "Breite",
      "work_dismantle": "Demontage in einem anderen Arbeitsauftrag",
      "work_order": {
        "choose_wo": "Arbeitsauftrag auswählen"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "Gebaut",
        "created_by": "Erstellt von",
        "dismantle": "Dimension entfernt",
        "erect": "Dimension hinzugefügt",
        "modify": "Geändert",
        "total": "Gesamt",
        "total_dismantle": "Gerüst abgebaut"
      },
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nein, abbrechen",
        "delete_confirmation": "Ja, löschen",
        "delete_description": "Möchten Sie die Dimension %{total} %{dimension} wirklich löschen?",
        "delete_title": "Aktion löschen?"
      },
      "date": "Datum",
      "duplicate_title": "Aktion duplizieren",
      "edit_title": "Aktion bearbeiten",
      "feed": {
        "empty": "Für dieses Gerüstbauteil wurden keine Aktionen erstellt"
      },
      "height": "Höhe",
      "length": "Länge",
      "modification_type": {
        "dismantle": "Abbauen",
        "erect": "Aufrecht",
        "erectDismantle": "Auf-/Abbau"
      },
      "singular": "Gerüstbauteil Aktion",
      "timestamp_badges": {
        "new": "Neu",
        "updated": "Aktualisiert"
      },
      "title": "Gerüstbauteil Aktion",
      "total": "Gesamt",
      "width": "Breite",
      "work_dismantle": "Demontage in einem anderen Arbeitsauftrag",
      "work_order": {
        "choose_wo": "Arbeitsauftrag auswählen"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Aktion von insgesamt %{total}%{dimension} aus der Liste entfernt",
        "title": "Hinzufügen einer Dimension"
      },
      "back": "Alle Gerüstteile",
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nein, abbrechen",
        "delete_confirmation": "Ja, löschen",
        "delete_description": "Möchten Sie %{type} mit %{total} %{dimension} löschen?",
        "delete_title": "Teil %{type} löschen?"
      },
      "description": "Beschreibung",
      "dismantle_modal": {
        "cancel": "Nein, abbrechen",
        "confirm": "Ja, demontieren",
        "confirmation_message": "Möchten Sie dieses Teil demontieren?",
        "date": "Datum",
        "errors": {
          "date_greater_max": "Das Abbaudatum muss heute oder früher sein."
        },
        "success_message": "Der Gerüstteil wurde erfolgreich abgebaut",
        "title": "Demontageteil",
        "work_order": "Arbeitsauftrag"
      },
      "erect_method": "Methode",
      "erect_methods": {
        "os": "Über Meer",
        "standard": "Standard",
        "tt": "Seilzugang über dem Meer"
      },
      "id": "AUSWEIS",
      "initial_date": "Anfangsdatum",
      "menu_items": {
        "dimensions": "Hinzufügen einer Dimension",
        "dismantle": "Abbauen",
        "modifications": "Diesen Teil ändern"
      },
      "operation_dismantle": "Operation demontieren",
      "operation_erect": "Betrieb aufrecht",
      "scaffolding_part_detail": {
        "actions": "Aktionen",
        "side_panel_title": "Details zum Gerüstteil"
      },
      "scaffolding_type_id": "Gerüsttyp",
      "scaffolding_types": {
        "covering": "Plane",
        "dop": "DOP",
        "hanging_scaffolding": "Hängend",
        "patch_boards": "Patchboards",
        "railing": "Temporäres Geländer",
        "rolling_scaffolding": "Mobile",
        "roof_over_roof": "Dach über Dach",
        "scaffolding_for_lift": "Hebevorrichtung",
        "scaffolding_net": "Gerüstnetz",
        "shelf": "Regal",
        "staircase_tower": "Treppenturm",
        "standard_scaffolding": "Standard",
        "stool": "Hocker",
        "support": "Unterstützung",
        "system_covering": "Systemabdeckung",
        "tent": "Zelt",
        "tent_frame": "Zeltrahmen"
      },
      "singular": "Gerüstteil",
      "title": "Gerüstteil",
      "total": "Gesamt",
      "type_filter": "Typfilter",
      "weight": "Gewicht"
    },
    "scaffoldings": {
      "area_id": "Bereich",
      "area_noise_id": "Flächenlärm",
      "area_noises": {
        "db_100_105": "100–105 dB – 2 Stunden Verweilzeit",
        "db_105_110": "105–110 dB – 1/2 Stunde Verweilzeit",
        "db_85_90": "85–90 dB – 12 Stunden Verweilzeit",
        "db_90_100": "90–100 dB – 6 Stunden Verweilzeit",
        "db_less_85": "< 85 dB – Keine Einschränkungen"
      },
      "back": "Alle Gerüste",
      "buttons_form": {
        "create_part": "Erstellen eines Teils"
      },
      "client": "Klient",
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nein, abbrechen",
        "delete_confirmation": "Ja, löschen",
        "delete_description": "Möchten Sie das Gerüst-TAG %{tag} löschen?",
        "delete_title": "Gerüst löschen",
        "dismantle_cancel": "Nicht jetzt",
        "dismantle_confirmation": "Ja, ich möchte abbauen",
        "dismantle_description": "Möchten Sie das Gerüst TAG %{tag} abbauen?",
        "dismantle_title": "Gerüstabbau",
        "duplicate_with_parts_cancel": "Nicht jetzt",
        "duplicate_with_parts_confirmation": "Ja, ich möchte mit den Teilen duplizieren",
        "duplicate_with_parts_description": "Möchten Sie das Gerüst von TAG %{tag} mit seinen Teilen duplizieren?",
        "duplicate_with_parts_title": "Gerüst mit Teilen duplizieren",
        "ready_to_dismantle_cancel": "Nicht jetzt",
        "ready_to_dismantle_confirmation": "Ja, ich möchte wechseln",
        "ready_to_dismantle_description": "Möchten Sie den Status von TAG %{tag} auf „Bereit zur Demontage“ ändern?",
        "ready_to_dismantle_title": "Gerüst bereit zum Abbau",
        "revert_cancel": "Nicht jetzt",
        "revert_confirmation": "Ja, ich möchte wechseln",
        "revert_description": "Möchten Sie den Status von TAG %{tag} auf „Errichtet“ ändern?",
        "revert_title": "Gerüststatus auf „Aufgebaut“ zurücksetzen"
      },
      "contact_person": "Ansprechpartner",
      "description": "Beschreibung",
      "description_of_deviation": "Beschreibung der Abweichung",
      "deviation_improved": "Abweichung verbessertes Datum",
      "dismantle_compensation_format": "Vergütungsformat abbauen",
      "dismantle_date": "Demontagedatum",
      "dismantle_modal": {
        "cancel": "Nein, abbrechen",
        "confirm": "Ja, demontieren",
        "confirmation_message": "Wollen Sie dieses Gerüst abbauen?",
        "date": "Datum",
        "errors": {
          "date_greater_max": "Das Abbaudatum muss heute oder früher sein."
        },
        "success_message": "Das Gerüst wurde erfolgreich abgebaut",
        "title": "Gerüstabbau",
        "work_order": "Arbeitsauftrag"
      },
      "erect_compensation_format": "Erect-Vergütungsformat",
      "erect_date": "Erektionsdatum",
      "erect_with_deviation": "Aufrichten mit Abweichung",
      "id": "AUSWEIS",
      "inspection": {
        "delayed": "Verzögert",
        "missing_initial": "Fehlen"
      },
      "inspection_control": "Inspektion",
      "installation": "Installation",
      "load_class_id": "Klasse laden",
      "load_classes": {
        "kg_150": "2 - 150 kg/m2",
        "kg_200": "3 - 200 kg/m2",
        "kg_300": "4 - 300 kg/m2",
        "kg_450": "5 - 450 kg/m2",
        "kg_600": "6 - 600 kg/m2",
        "kg_75": "1 - 75 kg/m2",
        "not_applicable": "0 – Nicht anwendbar"
      },
      "main_work_order_dismantle_id": "Arbeitsauftrag Demontage",
      "main_work_order_erect_id": "Arbeitsauftrag aufrecht",
      "missing_initial_control": "Erstkontrolle",
      "missing_request": "Fehlender Arbeitsauftrag",
      "number_of_tags": "Anzahl der Tags",
      "operation_dismantle": "Operation Abbau",
      "operation_erect": "Betrieb Aufrichten",
      "pictures": "Bild(er)",
      "project_number": "Projektreferenz",
      "qr_code": "QR-Code",
      "reason": "Arbeitsauftragsgrund",
      "scaffolding_detail": {
        "side_panel_title": "Gerüstdetails"
      },
      "scaffolding_status_id": "Status",
      "sections": {
        "details": "Einzelheiten",
        "deviations": "Abweichungen",
        "general": "Allgemeine Informationen",
        "pictures": "Bild(er)"
      },
      "singular": "Gerüst",
      "statuses": {
        "created": "Erstellt",
        "dismantled": "Demontiert",
        "erected": "Aufgerichtet",
        "ready_to_dismantle": "Bereit zur Demontage"
      },
      "subarea_id": "Unterbereich",
      "tag": "ETIKETT",
      "tag_number": "Tag-Nummer",
      "tag_removed": "Tag entfernt",
      "telephone_radio": "Telefon - Radio",
      "title": "Gerüstprotokoll",
      "total_m": "Gesamtm",
      "total_m2": "Gesamt m2",
      "total_m3": "Insgesamt m3",
      "total_weight_message": "Gesamtgewicht des aufgestellten Gerüstes",
      "user_of_scaffolding": "Benutzer von Gerüsten",
      "weight": "Gewicht",
      "wind_exposed": "Dem Wind ausgesetzt"
    },
    "scopes": {
      "add": "Arbeitsvorbereitungselement hinzufügen",
      "choose_modal": {
        "single": "Ein weiterer Arbeitsvorbereitungspunkt",
        "single_description": "Erstellung nur einer Arbeitsvorbereitungsposition"
      },
      "clusters": {
        "title": "Arbeitsvorbereitungscluster"
      },
      "count": "Anzahl",
      "crew_size": "Teamgröße",
      "crews": "Anzahl der Teams",
      "date": "Datum",
      "description": "Arbeitsvorbereitung",
      "duplicate_services": {
        "other_request": "Ein weiterer Arbeitsauftrag",
        "other_request_description": "Auf einen Arbeitsvorbereitungsposten in einem anderen Arbeitsauftrag duplizieren",
        "same_request": "Gleicher Arbeitsauftrag",
        "same_request_description": "Wählen Sie das Arbeitsvorbereitungselement",
        "success_notification": "Die Leistungen der Arbeitsvorbereitung wurden erfolgreich dupliziert",
        "title": "Duplikatdienste"
      },
      "edit_work_description": "Arbeitsbeschreibung bearbeiten",
      "hours": "Stunden (h)",
      "id": "ID",
      "item": "Artikel",
      "label": "Etikett",
      "label_assigner": {
        "success": "Ihr Arbeitsvorbereitungslabel wurde erfolgreich zugewiesen! Sie können Ihren Plan jetzt fortsetzen."
      },
      "label_error": "Etiketten konnten nicht abgerufen werden",
      "labels_info": "Arbeitsvorbereitungsetiketten",
      "more_details": "Mehr sehen",
      "popup_label": {
        "create_label": "Etikett erstellen",
        "labels": "Etiketten:",
        "name_label": "Name des Etiketts",
        "new_label": "Neues Label"
      },
      "preview_unavailable": "Keine Vorschau vorhanden",
      "quantity": "Menge",
      "remaining_hours": "Verbleibende Stunden",
      "remaining_quantity": "Verbleibende Menge",
      "request_status_restriction": "Der Arbeitsauftrag sollte den Status „Bestätigt“ oder „In Vorbereitung“ haben",
      "scopes_detail": {
        "side_panel_title": "Details zur Arbeitsvorbereitung"
      },
      "success_work_description_edition": "Die Stellenbeschreibung wurde erfolgreich bearbeitet",
      "title": "Arbeitsvorbereitungen",
      "unit": "Einheit",
      "work_description": "Arbeitsbeschreibung",
      "working_hours": "Arbeitszeit"
    },
    "search": {
      "loading": "Laden...",
      "no_result_found": "Kein Ergebnis gefunden",
      "placeholder": "Filtern..."
    },
    "service_description": "Leistungsbeschreibung",
    "settings": {
      "title": "Einstellungen"
    },
    "settings_shortcuts": "Abkürzungen für Einstellungen",
    "shortcuts": {
      "access_control": "Zugriffskontrolle",
      "areas": "Bereiche und Teilbereiche",
      "areas_subareas": "Bereiche und Unterbereiche",
      "assets": "Vermögenswerte",
      "background_jobs": "Hintergrundjobs",
      "branches": "Niederlassungen",
      "cities": "Städte",
      "client_sites": "Kunden-Standorte",
      "clients": "Kunden",
      "clients_info": "Kunden-Infos",
      "columns_renaming": "Spalten umbenennen",
      "companies": "Unternehmen",
      "contract_services": "Vertragsleistungen",
      "contracts": "Verträge",
      "countries": "Länder",
      "customer_sites": "Kundenseiten",
      "customers": "Kunden",
      "disciplines": "Disziplinen",
      "employees": "Mitarbeiter",
      "equipaments": "Ausrüstungen",
      "equipments": "Ausrüstungen",
      "formulas": "Formeln",
      "location": "Standort",
      "materials": "Materialien",
      "matrices": "Matrizen",
      "measures": "Maße",
      "mobile_details": "Mobile Details",
      "position_names": "Positionsnamen",
      "preferences": "Präferenzen",
      "project_assets": "Vermögenswerte",
      "project_contracts": "Verträge",
      "projects": "Projekte und Teilprojekte",
      "projects_contracts": "Projekte und Verträge",
      "projects_subprojects": "Projekte und Teilprojekte",
      "provinces": "Staaten / Provinzen",
      "regions": "Regionen",
      "routes": "Routen",
      "specialities": "Spezialitäten",
      "staff": "Personal",
      "states_provinces": "Staaten / Provinzen",
      "templates": "Vorlagen",
      "translations": "Spaltenumbenennung",
      "users": "Nutzer",
      "visible_fields": "Mobile Details",
      "work_positions": "Positionsnamen"
    },
    "side_menu": {
      "collapse": "Zusammenbruch",
      "contracts": "Verträge",
      "datasheets": "Datenblätter",
      "expand": "Expandieren",
      "report": "Berichte",
      "search": "Suche",
      "settings": "Einstellungen",
      "template": {
        "asset": "Anlage",
        "contract_service": "Vertragsleistungen",
        "datasheet": "Datenblatt",
        "estimate_service": "Kalkulation",
        "inspection": "Inspektion",
        "performance": "Konstruktion",
        "report": "Berichte",
        "request": "Arbeitsanweisungen",
        "scoping": "Arbeitsvorbereitung",
        "valuation": "Bewertung"
      },
      "templates": "Vorlagen"
    },
    "side_panel": {
      "close": "Seitenwand schließen",
      "collapse": "Seitenwand einklappen",
      "expand": "Erweitern Sie den Seitenbereich",
      "tabs": {
        "category_filter": "Kategorienfilter",
        "gallery": "Galerie",
        "info": "Die Info",
        "inspections": "Inspektionen",
        "no_categories": "Es sind keine Kategorien verfügbar",
        "no_templates": "Es sind keine Vorlagen verfügbar",
        "preview_details": "Vorschaudetails",
        "progress": "Fortschritt",
        "template_filter": "Vorlagenfilter"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "Alle Unteranfragen",
        "back": "Alle Arbeitsvorbereitungen",
        "title": "Unteranfragen"
      },
      "left_panel": {
        "add_scoping": "Arbeitsvorbereitungselement hinzufügen",
        "allocation": "Zuweisung",
        "cards_header_title": "Arbeitsvorbereitungen",
        "summary": "Zusammenfassung",
        "total_allocation": "Gesamte Zuweisung"
      },
      "scope_card": {
        "scope": "Arbeitsvorbereitung"
      }
    },
    "tabs": {
      "error": "Tabs können nicht geladen werden"
    },
    "templates": {
      "asset": "Vermögenswerte",
      "contract_service": "Vertragsleistung",
      "datasheet": "Datenblätter",
      "estimate_service": "Kalkulation",
      "inspection": "Inspektionen",
      "no_template": "Sie müssen eine Vorlage erstellen",
      "progress": "Konstruktion",
      "progress_service": "Fortschritt",
      "report": "Berichte",
      "request": "Arbeitsanweisungen",
      "scoping": "Arbeitsvorbereitung",
      "title": "Vorlagen für %{type}"
    },
    "tickets": {
      "icon_alt": "Ein Ticket-Symbol",
      "title": "Eintrittskarten"
    },
    "timeline": {
      "created": "Erstellt",
      "day_ago_singular": "X__vor 0 Tagen",
      "day_until_singular": "Innerhalb von %{daysUntil} Tagen",
      "days_ago": "X__vor 0 Tagen",
      "days_until": "Innerhalb von %{daysUntil} Tagen",
      "discipline_check_done": "Disziplinprüfung: Erledigt",
      "discipline_check_pending": "Disziplinarprüfung: Ausstehend",
      "discipline_check_rejected": "Disziplinprüfung: Abgelehnt",
      "estimate_authorized": "Schätzung: Autorisiert",
      "estimate_done": "Schätzung: Abgeschlossen",
      "estimate_on_hold": "Schätzung: Angehalten",
      "estimate_pending": "Schätzung: Ausstehend",
      "estimate_pending_authorization": "Schätzung: Autorisierung ausstehend",
      "estimate_progress": "Schätzung: In Bearbeitung",
      "performance": "Konstruktion",
      "progress": "Fortschritt",
      "recycle_done": "Recyceln: Fertig",
      "recycle_recycling": "Recyceln: Recycling",
      "recycling": "Recycling",
      "rejected": "Abgelehnt",
      "request": "Arbeitsauftrag",
      "responsible": "Verantwortlich",
      "schedule": "Zeitplan",
      "scoping": "Arbeitsvorbereitung",
      "title": "Zeitplan für Arbeitsaufträge",
      "today": "Heute"
    },
    "top_menu": {
      "authorization": "Autorisierung",
      "datasheets": "Datenblätter",
      "eav_templates": "Schablonen",
      "estimates": "Kalkulation",
      "inspections": "Inspektionen",
      "no_datasheets": "Keine Datenblätter gefunden",
      "no_reports": "Keine Berichte gefunden",
      "performances": "Konstruktion",
      "plannings": "Planung",
      "reports": "Berichte",
      "requests": "Arbeitsanweisungen",
      "scaffoldings": "Gerüstprotokoll",
      "scopes": "Arbeitsvorbereitung",
      "workflow": "Arbeitsablauf"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Über Version",
      "clean_preferences": "Einstellungen reinigen",
      "logout": "Abmelden",
      "notification": {
        "clean_preferences": "Einstellungen aufgeräumt",
        "error": "Fehler beim Versuch, Einstellungen zu bereinigen"
      },
      "preferences": "Rastereinstellungen",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Profil aktualisieren"
    },
    "work_order_attachments": {
      "subtitle": "Sehen Sie sich die Dokumente des entsprechenden Arbeitsauftrags an, um",
      "title": "Anhänge für Arbeitsaufträge"
    }
  },
  "en": {
    "actions": {
      "alternate_view": "Alternate View",
      "approve": "Approve",
      "back_to": "Back to %{page}",
      "bulk_editing": "Bulk Editing",
      "cancel": "Cancel",
      "choose_entity": "Choose %{entity}",
      "clear": "Clear",
      "clear_all_filters": "Clear All Filters",
      "confirm": "Confirm",
      "create": "Create",
      "create_inspection": "Create Inspection",
      "delete": "Delete",
      "discard": "Discard",
      "dismantle": "Dismantle",
      "done": "Done",
      "duplicate": "Duplicate",
      "duplicate_services": "Duplicate Services",
      "duplicate_with_parts": "Duplicate with Parts",
      "edit": "Edit",
      "export": "Export to .xls",
      "export_template": "Download template .xls",
      "filter_pinned_work_orders": "Filter Pinned Work Orders",
      "flag_filter": "Flag Filter",
      "generate_work_package": "Generate Work Package",
      "import": "Import from .xls",
      "import_file": "Import file",
      "loading": "Loading",
      "more": "More",
      "new": "Create New",
      "next": "Next",
      "no": "No",
      "past": {
        "cancel": "canceled",
        "complete": "completed",
        "confirm": "confirmed",
        "delete": "deleted",
        "destroy": "deleted",
        "dismantle": "dismantled",
        "duplicate_with_parts": "Duplicated with its parts",
        "ready_to_dismantle": "ready to dismantle",
        "reject": "rejected",
        "revert": "reverted",
        "update": "updated"
      },
      "perform_discipline_check": "Perform Discipline Check",
      "pin_request": "Filter by Selected Requests",
      "prev": "Prev",
      "ready": "Ready",
      "ready_for_discipline": "Ready for Discipline Check",
      "ready_to_dismantle": "Ready to dismantle",
      "recycle": "Recycle",
      "reject": "Reject",
      "remove_recycle": "Remove Recycle",
      "reset": "Reset",
      "retrieving": "Retrieving...",
      "revert": "Revert",
      "revert_to_erected": "Revert to Erected",
      "save": "Save",
      "save_button_keyboard": "Save (Ctrl + Enter)",
      "save_exit": "Save and Exit",
      "save_new": "Add another",
      "saving": "Saving...",
      "schedule": "Schedule",
      "show_all_work_orders": "Show All Work Orders",
      "skip": "Skip",
      "status_filter": "Status Filter",
      "unpin_request": "Remove Filter of All Requests",
      "unpin_selected_request": "Remove Filter of %{numSelectedRequests} Selected Requests",
      "unsaved_changes": "Unsaved Rows",
      "upload_file": "Upload file",
      "upload_picture": "Upload picture",
      "yes": "Yes"
    },
    "activerecord": {
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        }
      }
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Is not possible to duplicate a Clustered Work Order",
      "cant_duplicate_progress": "Cannot duplicate the Services because of the following errors...",
      "cant_duplicate_request": "Cannot duplicate Preparations, Estimate and Services because of the following errors...",
      "cant_duplicate_scope": "Cannot duplicate the Services because of the following errors...",
      "only_estimate_service_copy": "Only the Estimate Detail item is trying to be copied.",
      "only_progress_copy": "Only the Progress is trying to be copied.",
      "only_request_copy": "Only the Work Order is trying to be copied.",
      "only_scope_copy": "Only the Work Preparation item is trying to be copied."
    },
    "cancel_modal": {
      "cancel": "Not now",
      "description": "Are you sure you'd like to cancel the %{model} \"%{id}\"?",
      "ok": "Yes, cancel",
      "title": "Cancel item?"
    },
    "clear_records": {
      "modal": {
        "cancel": "No, cancel",
        "description": "Are you sure you'd like to clear all %{model} records?",
        "ok": "Yes, clear all",
        "title": "Clear %{model}?"
      },
      "success_message": "%{model} cleared successfully!",
      "title": "Clear records"
    },
    "clear_template_preferences": {
      "cancel": "No, cancel",
      "description": "Are you sure you’d like to clear all grid preferences to this template?",
      "ok": "Yes, clear grid preferences",
      "title": "Clear grid preferences"
    },
    "collections": {
      "clusters": "Clusters",
      "create_by_package": "Create by package",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "estimate": "Estimate",
      "new": "New",
      "schedule": "Schedule",
      "sub_requests": "Sub Requests",
      "view": "View"
    },
    "complete_modal": {
      "cancel": "Not now",
      "description": "Are you sure you'd like to mark %{model} as completed?",
      "ok": "Yes, mark as completed",
      "title": "Mark as completed?"
    },
    "complete_progress_modal": {
      "cancel": "Cancel",
      "complete": "Complete",
      "description": "Are you sure you want to complete this Construction item? This action cannot be undone.",
      "title": "Confirm Construction Item Completion"
    },
    "confirmation_modal": {
      "cancel": "Cancel",
      "description": "This action cannot be undone!",
      "ok": "Ok",
      "title": "Are you sure?"
    },
    "contract_services": {
      "title": "Contract Services"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Datasheet Details"
      },
      "description": "Datasheet",
      "id": "ID",
      "item": "item",
      "title": "Datasheets"
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "delete_attachment_modal": {
      "cancel": "No, keep the attachment",
      "delete_successful": "Attachment deleted successfully",
      "description": "Are you sure you'd like to delete this attachment?",
      "ok": "Yes, delete the attachment",
      "title": "Delete Attachment?"
    },
    "delete_modal": {
      "cancel": "No, cancel",
      "description": "Are you sure you'd like to delete the %{model} \"%{id}\"?",
      "ok": "Yes, delete",
      "title": "Delete item?"
    },
    "delete_picture_modal": {
      "cancel": "No, keep picture",
      "delete_successful": "Picture deleted successfully",
      "description": "Are you sure you'd like to delete this picture?",
      "ok": "Yes, delete picture",
      "title": "Delete Picture"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Acceptance"
      },
      "column_comment": {
        "add_comment": "Click to add a comment",
        "cancel": "Cancel",
        "comment": "Comment",
        "subtitle": "Add/edit comment"
      },
      "columns_batch_assigner": {
        "actions": "Actions",
        "add_action": "Add action",
        "add_to_column": "Add to Column",
        "add_value_to": "Add %{value} to",
        "column": "Column",
        "done": "Save Action",
        "header": "Batch edit",
        "multiple_success": "%{numberOfItems} items were updated.",
        "new_value": "New value",
        "single_success": "1 item was updated.",
        "subtitle": "Assign values to the %{numberOfColumns} selected column(s)",
        "title": "Create Actions",
        "update": "Update"
      },
      "configure_print_grid_file": {
        "error_max_columns": "You can only select up to %{maxColumns} columns.",
        "generate": "Generate File",
        "header": "Print Grid",
        "paper_size_title": "Paper Size",
        "pdf_file": {
          "information": "Information",
          "pictures": "Pictures",
          "title": "Grid %{modelName} - %{numItems} items - Page %{pageNum} of %{totalPages}"
        },
        "select_columns_title": "Columns to Print",
        "selected_columns": "You have selected %{numSelectedColumns} out of %{maxColumns}.",
        "subtitle": "Select up to %{maxColumns} columns to print",
        "title": "Configure file to print"
      },
      "configure_print_list_file": {
        "big_picture_title": "Print images using the width of the page?",
        "font_size_title": "Select font size",
        "header": "Print List",
        "hide_columns_title": "Include hidden columns",
        "large": "Large",
        "medium": "Medium",
        "page_break_title": "Print 1 item per page?",
        "small": "Small"
      },
      "configure_work_package_file": {
        "attached_documents": "Attached Documents",
        "attachments": "Attachments",
        "estimates": "Estimates",
        "estimates_price": "Estimates Price",
        "generate": "Generate",
        "header": "Generate Work Package",
        "hse_r": "HSE-R Assessment",
        "inspection_page_breaker": "Print an inspection per page",
        "inspection_pictures": "Include Pictures and Signatures",
        "inspections": "All Related Inspections",
        "subtitle": "Select the sections that you want to print",
        "title": "Configure work package file",
        "work_description": "Work Description"
      },
      "duplicate_services": {
        "title": "Duplicate Services"
      }
    },
    "discard_modal": {
      "cancel": "Keep editing",
      "description": "Are you sure you'd like to discard your changes?",
      "ok": "Yes, discard",
      "title": "Discard Changes"
    },
    "eav_templates": {
      "title": "Templates"
    },
    "empty_state": {
      "columns": "columns",
      "empty": "No %{model}",
      "no_attachments": "You haven't uploaded any attachments yet",
      "no_columns": "There is no image column available",
      "no_discipline_template": "No templates with the work order discipline filtered were found.",
      "no_estimate_service_template": "No estimate service template for the selected work preparation template was found",
      "no_pictures": "You haven't uploaded any pictures in this column yet",
      "no_unselect": "It is not possible to unselect the pinned request on this page. You can only do so on Work Orders page or by logging out.",
      "not_created": "You have not created any %{model} yet",
      "pictures": "pictures"
    },
    "environment": {
      "project": {
        "select_project": "Select Project..."
      },
      "select_project_subproject": "Select project and subproject",
      "subproject": {
        "select_subproject": "Select Subproject..."
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      }
    },
    "estimate": {
      "accept": "Accept Estimate",
      "all_estimates_approved": "All estimates were successfully approved.",
      "application": "Application cost",
      "back": "All Work Orders",
      "budget_hours": "Budget Hours",
      "confirmation": {
        "cancel": "No, Cancel",
        "confirm": "Yes, Confirm",
        "description": "Are you sure you want to authorize this estimate for this Work Order?",
        "title": "Confirm Order"
      },
      "description": "You have %{number} services for checkout. Please check details below.",
      "equipment": "Equipment cost",
      "estimate_details": "Estimation Details",
      "estimate_success": "Estimate successfully approved",
      "info_message": "This estimate has already been approved. It is available for read only.",
      "material": "Material cost",
      "no_estimate_message": "There is no Estimate associated with this work order.",
      "norm_hours": "Norm Hours",
      "others": "Other cost",
      "pdf_file": {
        "title": "Estimation Details - Page %{pageNum} of %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Discipline",
        "functional_location": "Func.Loc",
        "jobcard_status": "Jobcard Status",
        "ke_priority": "KE Priority",
        "main_area_location": "Main Area & Location",
        "main_work_center": "Main Work Center",
        "maintenance_plant": "Maintenance Plant",
        "not_defined": "Not defined yet",
        "order_finish": "Order Finish",
        "order_start": "Order Start",
        "priority_sequence": "Priority Sequence",
        "project": "Project",
        "project_type": "Project type",
        "report": "Report",
        "report_date": "Report Date",
        "responsible_engineer": "Responsible Engineer",
        "status_completed": "Completed",
        "status_issued_for_approval": "Issued for approval",
        "status_issued_for_construction": "Issued for construction",
        "subproject": "Subproject",
        "system": "System",
        "template": "Template",
        "title": "Work Order Estimate",
        "work_order": "Work Order"
      },
      "pdf_summary": {
        "title": "Estimates Summary"
      },
      "save_draft": "Save Draft",
      "service_details": "Service Details",
      "some_estimates_not_approved": "Some estimates could not be approved. Check the error logs.",
      "team_target_hours": "Team Target Hours",
      "title": "Checkout Service",
      "tooltip": {
        "application": "Application",
        "equipment": "Equipment",
        "material": "Material",
        "others": "Others"
      },
      "total_approved": "Total approved",
      "total_pending": "Total pending",
      "total_request": "Total cost",
      "use_construction_prices": "Include Construction Prices?"
    },
    "estimate_services": {
      "application_price": "Application Price",
      "automatically_prices": "Automatically calculated prices",
      "back": "All Work Preparations",
      "budget_target_hours": "Budget Target Hours",
      "choose_modal": {
        "package": "Single Detail Package",
        "package_description": "Creation of several services based on the same detail",
        "single": "Single Detail",
        "single_description": "Creation of only one detail",
        "title": "Create New"
      },
      "contract_service": "Contract Service",
      "crew_size": "Crew Size",
      "crews": "Number of Crews",
      "description": "Estimate Detail",
      "description_label": "Description",
      "discipline": "Discipline",
      "equipment_price": "Equipment Price",
      "estimate": "Estimate",
      "estimate_services_detail": {
        "side_panel_title": "Service's details"
      },
      "factor": "Factor",
      "flag": "Flag",
      "id": "ID",
      "item_no": "Item No.",
      "materials_price": "Materials Price",
      "norm_hours": "Norm Hours",
      "other_price": "Other Price",
      "prices": "Prices",
      "print": "Print",
      "quantity": "Quantity",
      "service": "Service",
      "service_no": "Service No.",
      "service_package": "Service Package",
      "statuses": {
        "inspected_flag": "Inspection Pending"
      },
      "team_target_hours": "Team Target Hours",
      "title": "Estimate Details",
      "title_packageable": "Estimate Details by Service Package",
      "total": "Total",
      "unit": "Unit",
      "weight": "Weight",
      "working_hours": "Working Hours"
    },
    "fields": {
      "created_at": "Created",
      "image_not_found": "Image Not Found",
      "na": "N/A",
      "signature": "Signature.jpg",
      "updated_at": "Updated"
    },
    "filter_steps_modal": {
      "filter_result": "Filter Result",
      "result_subtitle": "Summary of your selections",
      "step": "Step",
      "subtitle": "Please select %{title}"
    },
    "filtering": {
      "between": " that is between ",
      "contains": " that contains ",
      "eq": " that is equal to ",
      "invalid_date": "To filter correctly, start date and end date must be filled",
      "invalid_value": "invalid value",
      "neq": " that is not equal to "
    },
    "forgot_password": {
      "back_to_login": "Back to login",
      "button": "Send me reset password instructions",
      "feedback": "A message with instructions to reset your password has been sent to the provided email.",
      "placeholder": "Your email",
      "subtitle": "Please enter your email address to receive password reset instructions.",
      "title": "Forgot your password?"
    },
    "form": {
      "back": "All %{entity}",
      "cancel": "Cancel",
      "create_entity": "Create %{entity}",
      "create_error": "%{entity} could not be created",
      "create_success": "%{entity} was successfully created",
      "duplicate_entity": "Duplicate %{entity}",
      "duplicate_success": "The %{entity} and its dependencies were successfully duplicated!",
      "general_information": "General information",
      "hide": "Hide",
      "inputs": {
        "attachment": {
          "add_error": "Error adding File",
          "add_evidence": "Add evidence of the client authorization",
          "allowed_extensions": "The allowed extensions are",
          "drag_drop": "Add files using drag and drop",
          "invalid_extension": "File type not allowed.",
          "max_size": "The file must be less than %{max_size}",
          "maximum_num_uploads_error": "You can only load up to %{max_num} files per flexible column.",
          "one_file": "Upload one file at a time",
          "or_drop_file_here": "or drop file here",
          "remove_error": "Failed to Remove",
          "remove_success": "Removed Successfully",
          "select_files": "Select Files",
          "submit": "Submit your attachment",
          "upload_error": "Upload Failed",
          "upload_success": "Uploaded Successfully"
        },
        "contract_service": {
          "add": "Add a Contract Service",
          "subtitle": "Contract Service"
        },
        "datasheet_filter": {
          "add_filter": "Add Filter",
          "title": "Datasheet Filter"
        },
        "date_range": {
          "between": "%{start} until %{end}",
          "end": "End Date",
          "end_simplified": "End",
          "start": "Start Date",
          "start_simplified": "Start"
        },
        "drop_formula": {
          "formula_select": "Please, select a formula..."
        },
        "errors": {
          "date_greater_than_max": "Date should be on or before %{max}",
          "date_less_than_min": "Date should be on or after %{min}",
          "drop_formula_result": "%{field} calculated formula result",
          "mandatory": "%{field} is required.",
          "not_found": "'%{value}' was not found in the reference. Select a new one."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "or drag and drop",
              "select_file": "Click to upload"
            },
            "note": {
              "extensions": "File must be in %{extensions}",
              "max_size": "Maximum file size %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Picture"
          },
          "upload": {
            "error": {
              "invalid_picture": "Invalid type / size",
              "multiple_fails": "All files attached have invalid type/size",
              "multiple_files": "Some attached pictures have invalid type/size, uploading the valid ones",
              "title": "Upload failed"
            },
            "success": {
              "multiple_files": "Multiple files were successfully uploaded",
              "title": "Upload success"
            }
          }
        },
        "formula": {
          "description": "This is a formula column, the result is auto refreshed",
          "icon": "click to see the equation",
          "result": "Result"
        },
        "gps": {
          "save_location": "Save Location",
          "search": "Search Location...",
          "without_location": "Without Location"
        },
        "multiple_entries": {
          "add": "Add another",
          "empty_entry": {
            "scaffolding_type_id": "Select a Scaffolding Type to add actions"
          },
          "empty_inputs": "The inputs of %{input} are required in %{model}",
          "required": "The field \"%{field}\" is required and must be filled in"
        },
        "numeric": {
          "between": "Value should be between %{min} and %{max}",
          "greater": "Value should be greater than %{min}",
          "lower": "Value should be lower than %{max}"
        },
        "picture": {
          "comment_placeholder": "Write a comment (optional)",
          "edition_only": "Only available when editing",
          "no_image_on_clipboard": "No image data was found in your clipboard. Please copy an image first.",
          "remove_error": "Failed to Remove",
          "remove_success": "Removed Successfully",
          "upload_error": "Upload Failed",
          "upload_success": "Uploaded Successfully"
        },
        "placeholder": "Enter %{field} here",
        "value_selector": {
          "apply": "Apply",
          "done": "Done",
          "search": "Search something here...",
          "waiting": "... Wait"
        }
      },
      "items": "Items",
      "loading": "Loading...",
      "loading_calculations": "Calculating visibilities, required columns and default values",
      "loading_contract": "Loading contract information...",
      "schedule_entity": "Schedule %{entity}",
      "schedule_success": "Your request to schedule %{entity} has finish successfully",
      "show": "Show",
      "subtitle": "Please provide some basic information about the %{entity}.",
      "subtitle_error": "The form could not be loaded, check your %{entity} or template data.",
      "update_entity": "Edit %{entity}",
      "update_error": "%{entity} could not be updated",
      "update_success": "%{entity} was successfully updated"
    },
    "formula_modal": {
      "back_button_text": "Back to the Form",
      "calculate": "Calculate",
      "cancel": "Cancel",
      "mandatory": "You must fill the values for the following fields: %{fields}",
      "ok": "Save Formula"
    },
    "grid": {
      "all": "All",
      "clear_filters": "Clear filters",
      "editable": {
        "error": {
          "mandatory": "At least one required field was left empty"
        },
        "missing_required": "%{number} missing mandatory cells on this row",
        "title": "Edit Mode",
        "update": {
          "error": "%{quantity} could not be saved. Check the reason on the left side of each row highlighted",
          "success": "%{quantity} records was successfully saved"
        }
      },
      "empty_after_filtering": "There are no records for the applied filters",
      "failed_saving_preferences": "Preferences weren't saved!",
      "flag_filter": "Flag Filter",
      "grid_filters": {
        "menu_title": "Custom Filters"
      },
      "menu": {
        "visibility": "Columns"
      },
      "of": "of",
      "other": "Others",
      "productivity_factor": "Productivity Factor",
      "quick_pin_popup": {
        "all_work_orders": "All WOs",
        "clear_all": "Clear All",
        "no_pinned_requests": "You have not pinned work orders yet",
        "pinned_work_orders": "Pinned WOs",
        "quick_pin": "Quick Pin",
        "search_and_pin": "Search and pin WO"
      },
      "saved_preferences": "Saved!",
      "saving_preferences": "Saving preferences...",
      "select_items": "Select items",
      "selected": "Selected",
      "selecteds": "Selected",
      "status_filter": "Status Filter",
      "totals": "Totals"
    },
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "history_form": {
      "create_title": "Add a new progress",
      "edit_title": "Edit your progress",
      "subtitle": "Insert a progress by quantity or percentage for your activity."
    },
    "history_popup": {
      "back": "Back",
      "comments": "Comments",
      "crew_size": "Crew Size",
      "daily_hours": "Daily Hours",
      "date": "Date",
      "form_create_progress_service_title": "Create construction service item history",
      "form_create_progress_title": "Create progress item history",
      "form_edit_progress_service_title": "Edit construction service item history",
      "form_edit_progress_title": "Edit progress item history",
      "form_error_body": "All required fields on the form must be filled.",
      "form_error_title": "It was not possible to save the history form",
      "form_subtitle": "Insert a history by quantity or percentage for your activity.",
      "modal_create_title": "Create History",
      "modal_edit_title": "Edit History",
      "number_of_crews": "No. of Crews",
      "number_of_workers": "No. of Workers",
      "other_value": "Other Value",
      "percentage": "Percentage",
      "progress_by": "Progress by",
      "quantity": "Quantity",
      "singular": "History",
      "working_hours": "Working Hours"
    },
    "home": {
      "available_on": "available on",
      "banner_description": "Manage tasks, track processes and get the best results using a single platform",
      "create_request": {
        "text": "First things first, you need to create a Work Order from the Client in the DPMS with the details about your order.",
        "title": "Create a work order"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Getting started",
      "home_title": "Home",
      "inspection": {
        "text": "Conduct quality inspections in the field and record hindrances.",
        "title": "Inspection"
      },
      "inspection_calendar": {
        "calendar": "Calendar",
        "table": "Table",
        "title": "Inspection Schedule"
      },
      "performance": {
        "text": "Record Progress and Time based on the planned activities of each team and measure productivity.",
        "title": "Construction"
      },
      "scoping": {
        "text": "Prepare a Work Order from the Client. Assign Services and Service Packages from your contract.",
        "title": "Work Preparation"
      },
      "welcome_user": "Welcome, %{user}"
    },
    "i18n_tasks": {
      "add_missing": {
        "added": {
          "one": "Added %{count} key",
          "other": "Added %{count} keys"
        }
      },
      "cmd": {
        "args": {
          "default_text": "Default: %{value}",
          "desc": {
            "all_locales": "Do not expect key patterns to start with a locale, instead apply them to all locales implicitly.",
            "config": "Override config location",
            "confirm": "Confirm automatically",
            "data_format": "Data format: %{valid_text}.",
            "keep_order": "Keep the order of the keys",
            "key_pattern": "Filter by key pattern (e.g. 'common.*')",
            "locale": "i18n_tasks.common.locale",
            "locale_to_translate_from": "Locale to translate from",
            "locales_filter": "Locale(s) to process. Special: base",
            "missing_types": "Filter by types: %{valid}",
            "nostdin": "Do not read from stdin",
            "out_format": "Output format: %{valid_text}",
            "pattern_router": "Use pattern router: keys moved per config data.write",
            "strict": "Avoid inferring dynamic key usages such as t(\"cats.#{cat}.name\"). Takes precedence over the config setting if set.",
            "translation_backend": "Translation backend (google or deepl)",
            "value": "Value. Interpolates: %%{value}, %%{human_key}, %%{key}, %%{default}, %%{value_or_human_key}, %%{value_or_default_or_human_key}"
          }
        },
        "desc": {
          "add_missing": "add missing keys to locale data, optionally match a pattern",
          "check_consistent_interpolations": "verify that all translations use correct interpolation variables",
          "check_normalized": "verify that all translation data is normalized",
          "config": "display i18n-tasks configuration",
          "cp": "copy the keys in locale data that match the given pattern",
          "data": "show locale data",
          "data_merge": "merge locale data with trees",
          "data_remove": "remove keys present in tree from data",
          "data_write": "replace locale data with tree",
          "eq_base": "show translations equal to base value",
          "find": "show where keys are used in the code",
          "gem_path": "show path to the gem",
          "health": "is everything OK?",
          "irb": "start REPL session within i18n-tasks context",
          "missing": "show missing translations, optionally match a pattern",
          "mv": "rename/merge the keys in locale data that match the given pattern",
          "normalize": "normalize translation data: sort and move to the right files",
          "remove_unused": "remove unused keys",
          "rm": "remove the keys in locale data that match the given pattern",
          "translate_missing": "translate missing keys with Google Translate or DeepL Pro, optionally match a pattern",
          "tree_convert": "convert tree between formats",
          "tree_filter": "filter tree by key pattern",
          "tree_merge": "merge trees",
          "tree_mv_key": "rename/merge/remove the keys matching the given pattern",
          "tree_set_value": "set values of keys, optionally match a pattern",
          "tree_subtract": "tree A minus the keys in tree B",
          "tree_translate": "Google Translate a tree to root locales",
          "unused": "show unused translations"
        },
        "encourage": [
          "Good job!",
          "Well done!",
          "Perfect!"
        ],
        "enum_list_opt": {
          "invalid": "%{invalid} is not in: %{valid}."
        },
        "enum_opt": {
          "invalid": "%{invalid} is not one of: %{valid}."
        },
        "errors": {
          "invalid_format": "invalid format: %{invalid}. valid: %{valid}.",
          "invalid_locale": "invalid locale: %{invalid}",
          "invalid_missing_type": {
            "one": "invalid type: %{invalid}. valid: %{valid}.",
            "other": "unknown types: %{invalid}. valid: %{valid}."
          },
          "pass_forest": "pass locale forest"
        }
      },
      "common": {
        "continue_q": "Continue?",
        "key": "Key",
        "locale": "Locale",
        "n_more": "%{count} more",
        "value": "Value"
      },
      "data_stats": {
        "text": "has %{key_count} keys across %{locale_count} locales. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments, a locale has %{per_locale_avg} keys.",
        "text_single_locale": "has %{key_count} keys in total. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments.",
        "title": "Forest (%{locales})"
      },
      "deepl_translate": {
        "errors": {
          "no_api_key": "Setup DeepL Pro API key via DEEPL_AUTH_KEY environment variable or translation.deepl_api_key in config/i18n-tasks.yml. Get the key at https://www.deepl.com/pro.",
          "no_results": "DeepL returned no results.",
          "specific_target_missing": "You must supply a specific variant for the given target language e.g. en-us instead of en."
        }
      },
      "google_translate": {
        "errors": {
          "no_api_key": "Set Google API key via GOOGLE_TRANSLATE_API_KEY environment variable or translation.google_translate_api_key in config/i18n-tasks.yml. Get the key at https://code.google.com/apis/console.",
          "no_results": "Google Translate returned no results. Make sure billing information is set at https://code.google.com/apis/console."
        }
      },
      "health": {
        "no_keys_detected": "No keys detected. Check data.read in config/i18n-tasks.yml."
      },
      "inconsistent_interpolations": {
        "none": "No inconsistent interpolations found."
      },
      "missing": {
        "details_title": "Value in other locales or source",
        "none": "No translations are missing."
      },
      "openai_translate": {
        "errors": {
          "no_api_key": "Set OpenAI API key via OPENAI_API_KEY environment variable or translation.openai_api_key in config/i18n-tasks.yml. Get the key at https://openai.com/.",
          "no_results": "OpenAI returned no results."
        }
      },
      "remove_unused": {
        "confirm": {
          "one": "%{count} translation will be removed from %{locales}.",
          "other": "%{count} translation will be removed from %{locales}."
        },
        "noop": "No unused keys to remove",
        "removed": "Removed %{count} keys"
      },
      "translate_missing": {
        "translated": "Translated %{count} keys"
      },
      "unused": {
        "none": "Every translation is in use."
      },
      "usages": {
        "none": "No key usages found."
      },
      "yandex_translate": {
        "errors": {
          "no_api_key": "Set Yandex API key via YANDEX_API_KEY environment variable or translation.yandex_api_key in config/i18n-tasks.yml. Get the key at https://tech.yandex.com/translate.",
          "no_results": "Yandex returned no results."
        }
      }
    },
    "importation_drop": {
      "choose_error": "Choose an item"
    },
    "importation_popup": {
      "file_upload_message": "Click or drag file to this area to upload",
      "importation_error": "You can review this message on the jobs page.",
      "importation_success": "Importation success.",
      "importation_timeout": "The importation is taking too long. Check its status in Jobs page.",
      "instructions": "Files must be a .xls and no larger than xxmb.<br> Your .xls file should contain the column - Date, Client, Project, Task, Notes....<br> The order of the columns should be same as the order of the template.<br> Date should be in - YYYY/MM/DD.",
      "requirements": "Requirements",
      "submit_spreadsheet": "Submit your spreadsheet"
    },
    "inspections": {
      "acceptance": "Acceptance",
      "acceptance_error": {
        "error_description": "The Inspection - %{item} couldn't be updated because %{reason}",
        "title": "We can't complete"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "You can revert this later if you need to.",
          "reject": "Are you sure you want to reject this/these inspection(s)?"
        },
        "inspection_acceptance": "Inspection Acceptance",
        "items": "%{items} items",
        "reject": "Reject",
        "subtitle": "Provide information about the inspection(s) to go on with acceptance."
      },
      "actions": {
        "approve": "Approve",
        "more": "More",
        "reject": "Reject"
      },
      "choose_modal": {
        "schedule": "Schedule Inspection Items",
        "schedule_description": "Schedule one or several inspections based on date interval",
        "single": "Single Inspection Item",
        "single_description": "Creation of only one Inspection",
        "title": "Create an Inspection as:"
      },
      "description": "Inspection",
      "description_title": "Description",
      "every": "Every",
      "id": "ID",
      "inspect_date": "Inspection Date",
      "inspect_status_id": "Status",
      "inspected": "Inspected",
      "inspected_detail": "Inspected Detail",
      "inspected_item": "Inspected Item",
      "inspected_modules": {
        "estimate_services": "Estimate Details",
        "performance": "Construction",
        "progress": "Construction",
        "progress_services": "Construction Services",
        "request": "Work Order",
        "scaffolding": "Scaffolding",
        "scope": "Work Preparation"
      },
      "inspected_services": "Inspected Services",
      "inspected_track": {
        "area": "Area",
        "comments": "Comments",
        "description": "Description",
        "item": "Item %{item}",
        "no_load": "Couldn't load Inspected Track Block: Missing Data",
        "number_of_tags": "Number of tags",
        "reason": "Reason",
        "subarea": "Subarea"
      },
      "inspection_block": {
        "inspection_date": "Inspection Date",
        "missing_date": "Missing Date",
        "not_selected_yet": "Not selected yet",
        "responsible": "Responsible"
      },
      "inspections_detail": {
        "side_panel_title": "Inspection's details"
      },
      "module": "Module",
      "notifications": {
        "approve_successful": "The Inspection was successfully approved",
        "reject_successful": "The Inspection was successfully rejected"
      },
      "pending_inspections": "Pending Inspections",
      "recurrency_interval": "Recurrence interval",
      "related_inspections": {
        "missing_date": "Missing Date",
        "no_related": "No Related Inspections found",
        "no_responsible": "No Responsible",
        "title": "Related Inspections"
      },
      "repeat": "Repeat",
      "repeat_every_value": {
        "day": "Day",
        "month": "Month",
        "week": "Week",
        "year": "Year"
      },
      "repeats_on": "Repeat on",
      "request_id": "DPMSID",
      "responsible_id": "Responsible",
      "schedule_detail": "Schedule Detail",
      "schedule_form": {
        "acceptance_step": "Define Acceptance (optional)",
        "detailed_item_step": "Choose Inspection Preparation/Construction Item",
        "detailed_template_step": "Choose Inspection Preparation/Construction Template",
        "further_step_information": "The flexible fields are ready to be filled individualy after the recurrence creation step",
        "general_step": "Define Inspection General Information",
        "hints": "For monthly and yearly recurrences, the system will schedule the inspection matching the start date given on Interval Field",
        "monthly_hint": "Repeat monthly on every day %{day}",
        "recurrence_step": "Recurrence"
      },
      "scheduled_exception": "Scheduled",
      "select_module_item": "Select a module item to view its details here",
      "statuses": {
        "approved": "Approved",
        "completed": "Completed",
        "overdue": "Overdue",
        "pending": "Pending",
        "progress": "In Progress",
        "rejected": "Rejected",
        "scheduled": "Scheduled"
      },
      "template": "Template",
      "title": "Inspections"
    },
    "inspects": {
      "statuses": {
        "approved": "Approved",
        "completed": "Completed",
        "pending": "Pending",
        "progress": "In Progress",
        "rejected": "Rejected"
      }
    },
    "kendo": {
      "upload": "Click or Drag files here to upload"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Today"
      },
      "dateinput": {
        "decrement": "Decrease value",
        "increment": "Increase value"
      },
      "datepicker": {
        "toggleCalendar": "Toggle calendar"
      },
      "daterangepicker": {
        "end": "End",
        "separator": 0,
        "start": "Start",
        "swapStartEnd": "Swap start and end values"
      },
      "datetimepicker": {
        "cancel": "Cancel",
        "date": "Date",
        "set": "Set",
        "time": "Time",
        "toggleDateTimeSelector": "Toggle date-time selector"
      },
      "dropdowns": {
        "clear": "Clear",
        "nodata": "No Data Found"
      },
      "editor": {
        "addColumnAfter": "Add column on the right",
        "addColumnBefore": "Add column on the left",
        "addRowAfter": "Add row below",
        "addRowBefore": "Add row above",
        "alignCenter": "Center text",
        "alignJustify": "Justify",
        "alignLeft": "Align text left",
        "alignRight": "Align text right",
        "bold": "Bold",
        "bulletList": "Insert unordered list",
        "createTable": "Create a table",
        "deleteColumn": "Delete column",
        "deleteRow": "Delete row",
        "deleteTable": "Delete table",
        "fontName": "Font Name",
        "fontSize": "Font Size",
        "format": "Format",
        "hyperlink": "Insert hyperlink",
        "hyperlink-dialog-cancel": "Cancel",
        "hyperlink-dialog-content-address": "Web address",
        "hyperlink-dialog-content-newwindow": "Open link in new window",
        "hyperlink-dialog-content-title": "Title",
        "hyperlink-dialog-insert": "Insert",
        "hyperlink-dialog-title": "Insert hyperlink",
        "image": "Insert image",
        "image-address": "Web address",
        "image-altText": "Alternate text",
        "image-cancel": "Cancel",
        "image-dialog-title": "Insert image",
        "image-height": "Height (px)",
        "image-insert": "Insert",
        "image-title": "Title",
        "image-width": "Width (px)",
        "indent": "Indent",
        "insertTableHint": "Create a 0 x 1 table",
        "italic": "Italic",
        "mergeCells": "Merge cells",
        "orderedList": "Insert ordered list",
        "outdent": "Outdent",
        "redo": "Redo",
        "splitCell": "Split cell",
        "strikethrough": "Strikethrough",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "underline": "Underline",
        "undo": "Undo",
        "unlink": "Remove hyperlink",
        "viewHtml": "View HTML",
        "viewHtml-cancel": "Cancel",
        "viewHtml-dialog-title": "View HTML",
        "viewHtml-update": "Update"
      },
      "filter": {
        "addExpression": "Add Expression",
        "addGroup": "Add Group",
        "afterOperator": "Is after",
        "afterOrEqualOperator": "Is after or equal to",
        "andLogic": "And",
        "beforeOperator": "Is before",
        "beforeOrEqualOperator": "Is before or equal to",
        "close": "Close",
        "containsOperator": "Contains",
        "endsWithOperator": "Ends with",
        "eqOperator": "Is equal to",
        "gtOperator": "Is greater than",
        "gteOperator": "Is greater than or equal to",
        "isEmptyOperator": "Is empty",
        "isFalse": "Is no",
        "isNotEmptyOperator": "Is not empty",
        "isNotNullOperator": "Is not null",
        "isNullOperator": "Is null",
        "isTrue": "Is yes",
        "ltOperator": "Is less than",
        "lteOperator": "Is less than or equal to",
        "notContainsOperator": "Does not contain",
        "notEqOperator": "Is not equal to",
        "orLogic": "Or",
        "startsWithOperator": "Starts with"
      },
      "grid": {
        "filterAfterOperator": "Is after",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "filterBetweenOperator": "Is between",
        "filterBooleanAll": "(All)",
        "filterClearButton": "Clear",
        "filterContainsOperator": "Contains",
        "filterEndsWithOperator": "Ends with",
        "filterEqOperator": "Is equal to",
        "filterGtOperator": "Is greater than",
        "filterGteOperator": "Is greater than or equal to",
        "filterIsEmptyOperator": "Is empty",
        "filterIsFalse": "Is no",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterIsNotNullOperator": "Is not null",
        "filterIsNullOperator": "Is null",
        "filterIsTrue": "Is yes",
        "filterLtOperator": "Is less than",
        "filterLteOperator": "Is less than or equal to",
        "filterNotContainsOperator": "Does not contain",
        "filterNotEqOperator": "Is not equal to",
        "filterStartsWithOperator": "Starts with",
        "filterSubmitButton": "Filter",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Drag a column header and drop it here to group by that column",
        "noRecords": "No records available.",
        "pagerFirstPage": "Go to the first page",
        "pagerInfo": "0 - 1 of 2 items",
        "pagerItemsPerPage": "items per page",
        "pagerLastPage": "Go to the last page",
        "pagerNextPage": "Go to the next page",
        "pagerOf": "of",
        "pagerPage": "Page",
        "pagerPreviousPage": "Go to the previous page"
      },
      "numerictextbox": {
        "decrement": "Decrease value",
        "increment": "Increase value"
      },
      "pager": {
        "firstPage": "Go to the first page",
        "info": "0 - 1 of 2 items",
        "itemsPerPage": "items per page",
        "lastPage": "Go to the last page",
        "nextPage": "Go to the next page",
        "of": "of",
        "page": "Page",
        "previousPage": "Go to the previous page"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "all day",
        "allEvents": "All events",
        "dateTitle": "Date",
        "dayViewTitle": "Day",
        "deleteConfirmation": "Are you sure you want to delete this event?",
        "deleteDialogTitle": "Delete Event",
        "deleteOccurrence": "Delete current occurrence",
        "deleteRecurringConfirmation": "Do you want to delete only this event occurrence or the whole series?",
        "deleteRecurringDialogTitle": "Delete Recurring Item",
        "deleteSeries": "Delete the series",
        "deleteTitle": "Delete",
        "editorCancel": "Cancel",
        "editorDelete": "Delete",
        "editorEventAllDay": "All Day Event",
        "editorEventDescription": "Description",
        "editorEventEnd": "End",
        "editorEventEndTimeZone": "End Time Zone",
        "editorEventSeparateTimeZones": "End in different Time Zone",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start Time Zone",
        "editorEventTimeZone": "Specify Time Zone",
        "editorEventTitle": "Title",
        "editorOccurrence": "Edit current occurrence",
        "editorRecurringConfirmation": "Do you want to edit only this event occurrence or the whole series?",
        "editorRecurringDialogTitle": "Edit Recurring Item",
        "editorSave": "Save",
        "editorSeries": "Edit the series",
        "editorTitle": "Event",
        "editorValidationEnd": "End time must be after Start time.",
        "editorValidationRequired": "Field is required.",
        "editorValidationStart": "Start time must be be before End time.",
        "eventTitle": "Event",
        "monthViewTitle": "Month",
        "nextTitle": "Next",
        "noEvents": "no events",
        "previousTitle": "Previous",
        "recurrenceEditorDailyInterval": "day(s)",
        "recurrenceEditorDailyRepeatEvery": "Repeat every",
        "recurrenceEditorEndAfter": "After",
        "recurrenceEditorEndLabel": "End",
        "recurrenceEditorEndNever": "Never",
        "recurrenceEditorEndOccurrence": "occurrence(s)",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "Daily",
        "recurrenceEditorFrequenciesMonthly": "Monthly",
        "recurrenceEditorFrequenciesNever": "Never",
        "recurrenceEditorFrequenciesWeekly": "Weekly",
        "recurrenceEditorFrequenciesYearly": "Yearly",
        "recurrenceEditorMonthlyDay": "Day",
        "recurrenceEditorMonthlyInterval": "month(s)",
        "recurrenceEditorMonthlyRepeatEvery": "Repeat every",
        "recurrenceEditorMonthlyRepeatOn": "Repeat on",
        "recurrenceEditorOffsetPositionsFirst": "First",
        "recurrenceEditorOffsetPositionsFourth": "Fourth",
        "recurrenceEditorOffsetPositionsLast": "Last",
        "recurrenceEditorOffsetPositionsSecond": "Second",
        "recurrenceEditorOffsetPositionsThird": "Third",
        "recurrenceEditorRepeat": "Repeat",
        "recurrenceEditorWeekdaysDay": "Day",
        "recurrenceEditorWeekdaysWeekday": "Weekday",
        "recurrenceEditorWeekdaysWeekendday": "Weekend Day",
        "recurrenceEditorWeeklyInterval": "week(s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyInterval": "year(s)",
        "recurrenceEditorYearlyOf": "of",
        "recurrenceEditorYearlyRepeatEvery": "Repeat every",
        "recurrenceEditorYearlyRepeatOn": "Repeat on",
        "showFullDay": "Show full day",
        "showWorkDay": "Show business hours",
        "timeTitle": "Time",
        "timelineViewTitle": "Timeline",
        "today": "Today",
        "weekViewTitle": "Week",
        "workWeekViewTitle": "Work Week"
      },
      "sortable": {
        "noData": "No Data"
      },
      "timepicker": {
        "cancel": "Cancel",
        "now": "NOW",
        "selectNow": "Select Now",
        "set": "Set",
        "toggleClock": "Toggle Clock",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Is after",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "filterBooleanAll": "(All)",
        "filterClearButton": "Clear",
        "filterContainsOperator": "Contains",
        "filterEndsWithOperator": "Ends with",
        "filterEqOperator": "Is equal to",
        "filterGtOperator": "Is greater than",
        "filterGteOperator": "Is greater than or equal to",
        "filterIsEmptyOperator": "Is empty",
        "filterIsFalse": "Is no",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterIsNotNullOperator": "Is not null",
        "filterIsNullOperator": "Is null",
        "filterIsTrue": "Is yes",
        "filterLtOperator": "Is less than",
        "filterLteOperator": "Is less than or equal to",
        "filterNotContainsOperator": "Does not contain",
        "filterNotEqOperator": "Is not equal to",
        "filterStartsWithOperator": "Starts with",
        "noRecords": "No records available"
      },
      "upload": {
        "cancel": "Cancel",
        "clearSelectedFiles": "Clear",
        "dropFilesHere": "or drop file here",
        "files": "files",
        "headerStatusUploaded": "Done",
        "headerStatusUploading": "Uploading...",
        "invalidFileExtension": "File type not allowed.",
        "invalidFiles": "Invalid file(s). Please check file upload requirements.",
        "invalidMaxFileSize": "File size too large.",
        "invalidMinFileSize": "File size too small.",
        "remove": "Remove",
        "retry": "Retry",
        "select": "Select file",
        "total": "Total",
        "uploadSelectedFiles": "Upload"
      }
    },
    "loading_batch_entities": {
      "page_of": "Page %{page} of %{total}",
      "title": "Fetching pages"
    },
    "login": {
      "again": "To continue, please log in again",
      "continue_mykaefer": "Continue with MyKaefer",
      "copyright": "®%{year} All Rights Reserved. Kaefer®",
      "forgot_password": "Forgot password?",
      "iframe_info": "Requesting access to page: %{link}",
      "log_in": "Log in",
      "password": "Password",
      "title_1": "A powerful tool",
      "title_2": "for project management",
      "username": "Username",
      "welcome_back": "Welcome back"
    },
    "main_dialog": {
      "failure_message": "Your request could not be completed",
      "failure_title": "Failure",
      "loading_message": "Please wait while we set things up for you!",
      "loading_title": "Loading...",
      "service_title": "Waiting for job to finish...",
      "success_message": "Your request has been successful! You can now continue your job.",
      "success_title": "Successful!"
    },
    "notification": {
      "access_denied": "Access Denied",
      "access_denied_message": "You don't have permission to perform this action. Contact your administrator for more information.",
      "action_success": "%{model} %{id} was %{action}",
      "bad_formula_setup": "There is a setup problem with the formula '%{title}', please check your setup!",
      "correct_subproject": "Select the correct subproject of this item",
      "dismiss_all": "Dismiss All",
      "error": "Error",
      "error_cookies": "We were unable to establish communication between cross sources. Review cookies settings in your browser and disable 'Third-Party Cookie blocking'.",
      "error_job": "Failed! Check the reason in Jobs page.",
      "error_msg": "Sorry! Something went wrong!",
      "info": "Info",
      "large_file_body": "Please apply some filters to make the output file smaller.",
      "large_file_title": "The grid has more than 1000 records",
      "page_denied": "The page could not be loaded.",
      "please": "Please",
      "select_subproject": "Select a Project and Subproject to continue",
      "success": "Success",
      "success_msg": "Action executed with success!",
      "warning": "Warning"
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "orders": {
      "add_evidence": "Add evidence of the client authorization",
      "attachment": "Attachment",
      "comments": "Comments",
      "date": "Date",
      "drag_and_drop": "Add files using drag and drop",
      "recipient": "Recipient",
      "responsible": "Responsible",
      "title": "Orders"
    },
    "performance": {
      "title": "Construction"
    },
    "performances": {
      "title": "Constructions"
    },
    "performances_wip": {
      "performance_item": "Construction Item",
      "title": "Construction"
    },
    "planning": {
      "item_no": "Item No.",
      "service_no": "Service No.",
      "title": "Planning",
      "view": "Planning View"
    },
    "plannings": {
      "title": "Planning"
    },
    "print_grid": {
      "title": "Print the grid"
    },
    "print_list": {
      "loading": "Generating your document. Please wait a moment.",
      "title": "Print list"
    },
    "progress": {
      "statuses": {
        "canceled": "Canceled",
        "done": "Done",
        "extra": "Extra",
        "in_progress": "In progress",
        "pending": "Pending",
        "revised": "Revised"
      }
    },
    "progress_histories": {
      "add_new_progress": "Add a new Progress",
      "empty_history": "There is no associated History item, and new items cannot be created",
      "hours": "Hours",
      "production_factor": "PF",
      "progress": "Progress",
      "progress_badge": "Item %{number}",
      "progress_history": "Progress History",
      "progress_service_badge": "Service %{number}",
      "progress_service_history": "Service History",
      "progress_service_title": "Service histories",
      "progress_title": "Item histories",
      "quantity": "Quantity",
      "quantity_empty_history": "There is no history item and it is not possible to create histories when the quantity is zero",
      "subtitle": "Manage daily progress based on activities planned by your team."
    },
    "progress_services": {
      "actual_div_hours": "Actual / Team Target Hours",
      "actual_div_quantity": "Actual / Target Quantity",
      "actual_hours": "Actual Hours",
      "actual_quantity": "Actual Quantity",
      "application_price": "Application Price",
      "back": "All Constructions",
      "budget_target_hours": "Budget Target Hours",
      "canceled": "Canceled",
      "choose_modal": {
        "package": "Single Detail Package",
        "package_description": "Creation of several services based on the same detail",
        "single": "Single Detail",
        "single_description": "Creation of only one detail",
        "title": "Create New"
      },
      "contract_service": "Contract Service",
      "contribution": "Contribution",
      "contribution_info": "Values automatically calculated",
      "crew": "crew",
      "crew_size": "Crew Size",
      "crews": "crews",
      "date_filter": {
        "all": "All",
        "to": "to",
        "today": "Today",
        "tomorrow": "Tomorrow",
        "week": "Week",
        "yesterday": "Yesterday"
      },
      "date_filter_info": "Date Filter",
      "delta_hours": "Delta Hours",
      "delta_qtt": "Delta Quantity",
      "description": "Construction Service",
      "discipline": "Discipline",
      "end_date": "End Date",
      "equipment_price": "Equipment Price",
      "factor": "Factor",
      "flag": "Flag",
      "id": "ID",
      "materials_price": "Materials Price",
      "no_crews": "No. of Crews",
      "no_workers": "No. of Workers",
      "norm_hours": "Norm Hours",
      "not_planned": "Not Planned",
      "other_price": "Other Price",
      "package_factor": "Factor",
      "people": "people",
      "person": "person",
      "pf": "PF",
      "pf_doing_bad": "You're not doing great",
      "pf_doing_good": "You're doing great!",
      "pf_doing_neutral": "Could not calculate the pf",
      "planned": "Planned",
      "planning_chart": {
        "actual": "Actual",
        "actual_qty": "Actual Qty",
        "date": "Date",
        "forecasted": "Forecasted",
        "forecasted_qty": "Forecasted Qty",
        "target": "Target",
        "target_qty": "Target Qty",
        "title": "Planning Chart"
      },
      "planning_timeline": {
        "day": "day",
        "day_advance": "day in advance",
        "day_delayed": "day delayed",
        "days": "days",
        "days_advance": "days in advance",
        "days_delayed": "days delayed",
        "end_date": "End Date",
        "forecasted": "Forecasted",
        "on_time": "On time",
        "remaining_days": "Remaining Days",
        "start_date": "Start Date",
        "title": "Planning Timeline"
      },
      "progress": "Progress",
      "progress_services_detail": {
        "side_panel_title": "Service's details"
      },
      "quantity": "Quantity",
      "responsible": "Responsible",
      "scoped_quantity": "Prepared Quantity",
      "service": "Service",
      "service_package": "Service Package",
      "service_planning": "Service Planning",
      "service_progress": "Service Progress",
      "service_weight": "Service Weight",
      "start_date": "Start Date",
      "statuses": {
        "canceled": "Canceled",
        "done": "Completed",
        "extra": "Extra",
        "in_planning": "Not Planned",
        "inspected_flag": "Inspection Pending",
        "planned": "Planned"
      },
      "team_target_hours": "Team Target Hours",
      "title": "Construction Services",
      "title_packageable": "Construction Services by Service Package",
      "total": "Total",
      "total_hours": "Total Hours",
      "unit": "Unit",
      "worker": "worker",
      "workers": "workers",
      "working_hours": "Working Hours"
    },
    "progresses": {
      "cancel": "Cancel",
      "choose_modal": {
        "single": "Another construction item",
        "single_description": "Creation of only one construction item"
      },
      "complete": "Complete",
      "complete_services": "Complete Services",
      "current_hours": "Current Hours",
      "current_qty": "Current Quantity",
      "date_filter": {
        "all": "All",
        "current_week": "Current week",
        "previous_week": "Previous week",
        "today": "Today"
      },
      "date_filter_info": "Date Filter",
      "delta_hours": "Δ Hours",
      "delta_qty": "Δ Qty",
      "description": "Description",
      "edit_work_description": "Edit Work Description",
      "extra": "Extra",
      "flag": "Flag",
      "history": "Add new progress",
      "id": "ID",
      "item": "Item",
      "label": "Label",
      "more_details": "see more",
      "pf": "PF",
      "planned_hours": "Planned Hours",
      "planned_qty": "Planned Quantity",
      "preview_unavailable": "No preview available",
      "progress": "Progress",
      "progress_status_restriction": "Progress should not have status Canceled or Done",
      "progresses_detail": {
        "side_panel_title": "Construction Details"
      },
      "remaining_hours": "Remaining Hours",
      "request_status_restriction": "Work Order should have status In Planning or In Construction",
      "revised": "Revised",
      "success_work_description_edition": "The work description was successfully edited",
      "title": "Constructions",
      "work_description": "Work Description"
    },
    "projects": {
      "workdays": {
        "fri": "Fri",
        "mon": "Mon",
        "sat": "Sat",
        "sun": "Sun",
        "thu": "Thu",
        "tue": "Tue",
        "wed": "Wed"
      }
    },
    "requests": {
      "actual_hours": "Actual Hours",
      "actual_progress": "Actual Progress",
      "all": "All",
      "are_you_sure_complete": "Are you sure to complete this work order?",
      "are_you_sure_complete_no_history": "Are you sure to complete a work order without any given progress?",
      "are_you_sure_deletion": "Are you sure you want to delete the work order?",
      "are_you_sure_revertion": "Are you sure you want to revert and by that loose the data that was entered in this Module?",
      "area": "Area",
      "authorization_pending": "Authorization Pending",
      "authorize_form": {
        "modal_title": "Authorization",
        "subtitle": "Provide some information about the work order to go on with approbation",
        "title": "Authorize Work Order"
      },
      "canceled": "Canceled",
      "clustered": "Clustered",
      "comments": "Comments",
      "confirmation_modal": {
        "cancel_description": "The work order will be canceled",
        "cancel_title": "Cancel Work Order?",
        "complete_description": "This work order will be completed",
        "complete_title": "Complete work order?",
        "confirm_action": "Proceed to Import Work Preparations",
        "confirm_cancel": "Not now",
        "confirm_confirmation": "Yes, I want to Confirm",
        "confirm_description": "Do you want to confirm the work order?",
        "confirm_title": "Work Order Confirmation",
        "delete_description": "The removal action cannot be undone. Are you sure?",
        "delete_title": "Delete Work Order?",
        "reject_description": "This item will return to the status In Preparation",
        "reject_title": "Reject Work Order?",
        "remove_recycle_description": "This item will return to the status In Scoping and lose the Recycle flag.",
        "remove_recycle_title": "Clear Recycling?",
        "revert_description": "The revert action cannot be undone. Are you sure?",
        "revert_title": "Revert Work Order?"
      },
      "confirmed": "Confirmed",
      "create_inspection_modal": {
        "category_subtitle": "Select one option from the category %{category_name}",
        "loading": "Loading inspection templates...",
        "no_templates": "There's no inspection template created",
        "plural_title": "Create Inspections",
        "standalone_subtitle": "Select one of the options below to continue",
        "title": "Create Inspection"
      },
      "creation_form": {
        "proceed": "Proceed to Step 2"
      },
      "creation_page": {
        "add_template": "Add template",
        "first_step": "Work Order",
        "second_step": "Import Work Preparations Items (Optional)",
        "third_step": "Apply Service Details (Optional)"
      },
      "description": "Work Order",
      "discipline": "Discipline",
      "discipline_check": {
        "check_terms": "Discipline Check Terms",
        "perform": "Perform Discipline Check",
        "ready_for_discipline": "Ready for Discipline Check",
        "ready_for_review": "Work Order ready for disciplinary review",
        "set_as_ready": "Set the Work Order as ready for discipline check",
        "title": "Discipline Check"
      },
      "discipline_check_item": "WO01 – Ready for Discipline Check",
      "discipline_check_no_comment": "No Comment added",
      "done": "Done",
      "eav_template": "Template",
      "eav_template_id": "Template ID",
      "edit_dialog": {
        "reason": "Subrequest name"
      },
      "elevation": "Elevation",
      "errors": {
        "not_found": "Unable to find Work Order with id: %{id}"
      },
      "estimates_authorization": "Estimates authorization required",
      "flags": {
        "discipline_check": "WO01 - Discipline Check",
        "discipline_check_done": "WO01 – Discipline Check: Approved",
        "discipline_check_pending": "WO01 – Discipline Check: Pending",
        "discipline_check_rejected": "WO01 – Discipline Check: Rejected",
        "recycle": "WO03 - Recycle"
      },
      "forecast_end_date": "Forecasted End Date",
      "id": "DPMSID",
      "in_performance": "In Construction",
      "in_planning": "In Planning",
      "in_scoping": "In Preparation",
      "information": "Additional Information",
      "inspection_date": "Inspection Date",
      "no_comment": "No Comment",
      "notification": {
        "authorization_success": "The work order is successfully authorized",
        "deleting_sub_request": "The Sub Request %{id} is being deleted. Please check the jobs page to learn about its progress",
        "discipline_check_approved": "Discipline Check successfully approved",
        "discipline_check_rejected": "Discipline Check successfully rejected",
        "discipline_check_success": "The work order is successfully ready for disciplinary review",
        "missing_order_error": "There is not any pending order associated with this work order",
        "recycle_success": "The work order is successfully recycled"
      },
      "on_hold": "On Hold",
      "progress": "Progress",
      "progress_end_date": "Progress end date",
      "progress_responsible": "Responsible for Progress",
      "progress_responsible_id": "Responsible for Progress",
      "progress_start_date": "Progress start date",
      "reason": "Reference",
      "recycle_form": {
        "label": "Reason - Discipline",
        "modal_title": "Recycle",
        "subtitle": "Provide information to flag this Work Order for Recycling",
        "title": "Recycle Request"
      },
      "recycling_item": "WO03 - Recycle to Client",
      "reference": "Reference",
      "registered": "Registered",
      "remove_recycle_modal": {
        "cancel_description": "No, keep the flag",
        "confirm_description": "Yes, clear",
        "success_notification": "The flag for Recycle was removed on %{reason} - %{discipline}"
      },
      "request_date": "Date of work order",
      "request_detail": {
        "edit": "Edit",
        "estimates": "Estimates",
        "inspections": "Inspections",
        "performance": "Construction",
        "planning": "Planning",
        "scopes": "Work Preparation",
        "show": "Show",
        "side_panel_title": "Work Order details"
      },
      "request_end_date": "Work Order end date",
      "request_responsible": "Responsible for Work Order",
      "request_responsible_id": "Responsible for Work Order",
      "request_start_date": "Work Order start date",
      "responsible": "Responsible",
      "schedule": "Work Order Schedule",
      "schedule_form": {
        "date": "Date",
        "days": "%{number} days",
        "performance": "Construction",
        "progress": "Progress",
        "request": "Work Order",
        "responsible": "Responsible",
        "schedule": "Schedule",
        "scoping": "Work Preparation",
        "subtitle": "To create a schedule for this work order, we first need some additional information.",
        "timecode": "Timecode",
        "title": "Work Order schedule"
      },
      "scope_end_date": "Work Preparation end date",
      "scope_responsible": "Responsible for Work Preparation",
      "scope_responsible_id": "Responsible for Work Preparation",
      "scope_start_date": "Work Preparation start date",
      "scopes": "Work Preparations",
      "service_desired_date": "Desired Date",
      "skip_scoping": "Skip Work Preparation phase",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Pending",
        "canceled": "Canceled",
        "clustered": "Clustered",
        "confirmed": "In Preparation",
        "deleting": "Deleting",
        "done": "Done",
        "in_performance": "In Construction",
        "in_planning": "In Construction",
        "in_scoping": "In Preparation",
        "on_hold": "On Hold",
        "registered": "Created"
      },
      "sub_request": "Sub Request",
      "sub_requests": "Sub Requests",
      "subarea": "Subarea",
      "team_target_hours": "Team Target Hours",
      "title": "Work Orders",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - Revised %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "No, cancel",
      "description": "Are you sure you'd like to revert the inspection \"%{id}\"?",
      "ok": "Yes, revert to pending.",
      "success_description": "Reverted to pending successfully.",
      "success_title": "The inspection was reverted",
      "title": "Revert item?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Alternate text",
        "by_url": "By URL",
        "height": "Height (px)",
        "image": "Image",
        "title": "Title",
        "upload": "Upload",
        "web_address": "Web address",
        "width": "Width (px)",
        "window_title": "Image Edition"
      }
    },
    "scaffolding_part_action": {
      "comment": "Comment",
      "date": "Date",
      "height": "Height",
      "length": "Length",
      "modification_type": {
        "dismantle": "Dismantle",
        "erect": "Erect",
        "erectDismantle": "Erect/Dismantle"
      },
      "singular": "Scaffolding Part Action",
      "title": "Scaffolding Part Action",
      "total": "Total",
      "width": "Width",
      "work_dismantle": "Dismantle on another work order",
      "work_order": {
        "choose_wo": "Choose Work Order"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "Built",
        "created_by": "Created By",
        "dismantle": "Dimension Removed",
        "erect": "Dimension Added",
        "modify": "Modified",
        "total": "Total",
        "total_dismantle": "Scaffolding Dismantled"
      },
      "comment": "Comment",
      "confirmation_modal": {
        "delete_cancel": "No, cancel",
        "delete_confirmation": "Yes, delete",
        "delete_description": "Are you sure you’d like to delete the dimension of %{total} %{dimension}?",
        "delete_title": "Delete Action?"
      },
      "date": "Date",
      "duplicate_title": "Duplicate action",
      "edit_title": "Edit action",
      "feed": {
        "empty": "No actions created for this Scaffolding Part"
      },
      "height": "Height",
      "length": "Length",
      "modification_type": {
        "dismantle": "Dismantle",
        "erect": "Erect",
        "erectDismantle": "Erect/Dismantle"
      },
      "singular": "Scaffolding Part Action",
      "timestamp_badges": {
        "new": "New",
        "updated": "Updated"
      },
      "title": "Scaffolding Part Action",
      "total": "Total",
      "width": "Width",
      "work_dismantle": "Dismantle on another work order",
      "work_order": {
        "choose_wo": "Choose Work Order"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Action of total %{total}%{dimension} removed from the list",
        "title": "Add a Dimension"
      },
      "back": "All Scaffolding Parts",
      "comment": "Comment",
      "confirmation_modal": {
        "delete_cancel": "No, cancel",
        "delete_confirmation": "Yes, delete",
        "delete_description": "Do you want to delete the %{type} with %{total} %{dimension}?",
        "delete_title": "Delete %{type} part?"
      },
      "description": "Description",
      "dismantle_modal": {
        "cancel": "No, cancel",
        "confirm": "Yes, Dismantle",
        "confirmation_message": "Do you want to dismantle this Part?",
        "date": "Date",
        "errors": {
          "date_greater_max": "Dismantle date must be today or earlier"
        },
        "success_message": "The Scaffolding Part was dismantled with success",
        "title": "Dismantling Part",
        "work_order": "Work Order"
      },
      "erect_method": "Method",
      "erect_methods": {
        "os": "Over sea",
        "standard": "Standard",
        "tt": "Rope access over sea"
      },
      "id": "ID",
      "initial_date": "Initial Date",
      "menu_items": {
        "dimensions": "Add a Dimension",
        "dismantle": "Dismantle",
        "modifications": "Modify this Part"
      },
      "operation_dismantle": "Operation dismantle",
      "operation_erect": "Operation erect",
      "scaffolding_part_detail": {
        "actions": "Actions",
        "side_panel_title": "Scaffolding Part details"
      },
      "scaffolding_type_id": "Scaffolding Type",
      "scaffolding_types": {
        "covering": "Tarpaulin",
        "dop": "DOP",
        "hanging_scaffolding": "Hanging",
        "patch_boards": "Patch Boards",
        "railing": "Temporary Railing",
        "rolling_scaffolding": "Mobile",
        "roof_over_roof": "Roof over roof",
        "scaffolding_for_lift": "Lifting Rig",
        "scaffolding_net": "Scaffolding Net",
        "shelf": "Shelf",
        "staircase_tower": "Staircase Tower",
        "standard_scaffolding": "Standard",
        "stool": "Stool",
        "support": "Support",
        "system_covering": "System Covering",
        "tent": "Tent",
        "tent_frame": "Tent Frame"
      },
      "singular": "Scaffolding Part",
      "title": "Scaffolding Part",
      "total": "Total",
      "type_filter": "Type filter",
      "weight": "Weight"
    },
    "scaffoldings": {
      "area_id": "Area",
      "area_noise_id": "Area Noise",
      "area_noises": {
        "db_100_105": "100-105dB - 2 hour residence time",
        "db_105_110": "105-110dB - 1/2 hour residence time",
        "db_85_90": "85-90dB - 12 hour residence time",
        "db_90_100": "90-100dB - 6 hour residence time",
        "db_less_85": "< 85dB - No restrictions"
      },
      "back": "All Scaffoldings",
      "buttons_form": {
        "create_part": "Create a Part"
      },
      "client": "Client",
      "comment": "Comment",
      "confirmation_modal": {
        "delete_cancel": "No, cancel",
        "delete_confirmation": "Yes, delete",
        "delete_description": "Do you want to delete Scaffolding TAG %{tag}?",
        "delete_title": "Scaffolding delete",
        "dismantle_cancel": "Not now",
        "dismantle_confirmation": "Yes, I want to dismantle",
        "dismantle_description": "Do you want to dismantle Scaffolding TAG %{tag}?",
        "dismantle_title": "Scaffolding dismantle",
        "duplicate_with_parts_cancel": "Not now",
        "duplicate_with_parts_confirmation": "Yes, I want to duplicate with the parts",
        "duplicate_with_parts_description": "Do you want to duplicate the Scaffolding of TAG %{tag} with its parts?",
        "duplicate_with_parts_title": "Duplicate Scaffolding with parts",
        "ready_to_dismantle_cancel": "Not now",
        "ready_to_dismantle_confirmation": "Yes, I want to change",
        "ready_to_dismantle_description": "Do you want to change status of TAG %{tag} to ready to dismantle?",
        "ready_to_dismantle_title": "Scaffolding ready to dismantle",
        "revert_cancel": "Not now",
        "revert_confirmation": "Yes, I want to change",
        "revert_description": "Do you want to change status of TAG %{tag} to erected?",
        "revert_title": "Revert Scaffolding status to erected"
      },
      "contact_person": "Contact Person",
      "description": "Description",
      "description_of_deviation": "Description of Deviation",
      "deviation_improved": "Deviation Improved Date",
      "dismantle_compensation_format": "Dismantle Compensation Format",
      "dismantle_date": "Dismantle Date",
      "dismantle_modal": {
        "cancel": "No, cancel",
        "confirm": "Yes, Dismantle",
        "confirmation_message": "Do you want to dismantle this scaffolding?",
        "date": "Date",
        "errors": {
          "date_greater_max": "Dismantle date must be today or earlier"
        },
        "success_message": "The Scaffolding was dismantled with success",
        "title": "Dismantling Scaffolding",
        "work_order": "Work Order"
      },
      "erect_compensation_format": "Erect Compensation Format",
      "erect_date": "Erect Date",
      "erect_with_deviation": "Erect with Deviation",
      "id": "ID",
      "inspection": {
        "delayed": "Delayed",
        "missing_initial": "Missing"
      },
      "inspection_control": "Inspection",
      "installation": "Installation",
      "load_class_id": "Load Class",
      "load_classes": {
        "kg_150": "2 - 150 kg/m2",
        "kg_200": "3 - 200 kg/m2",
        "kg_300": "4 - 300 kg/m2",
        "kg_450": "5 - 450 kg/m2",
        "kg_600": "6 - 600 kg/m2",
        "kg_75": "1 - 75 kg/m2",
        "not_applicable": "0 - Not Applicable"
      },
      "main_work_order_dismantle_id": "Work Order Dismantle",
      "main_work_order_erect_id": "Work Order Erect",
      "missing_initial_control": "Initial Control",
      "missing_request": "Missing Work Order",
      "number_of_tags": "Number of Tags",
      "operation_dismantle": "Operation Dismantle",
      "operation_erect": "Operation Erect",
      "pictures": "Picture(s)",
      "project_number": "Project Reference",
      "qr_code": "QR Code",
      "reason": "Work Order Reason",
      "scaffolding_detail": {
        "side_panel_title": "Scaffolding details"
      },
      "scaffolding_status_id": "Status",
      "sections": {
        "details": "Details",
        "deviations": "Deviations",
        "general": "General Information",
        "pictures": "Picture(s)"
      },
      "singular": "Scaffolding",
      "statuses": {
        "created": "Created",
        "dismantled": "Dismantled",
        "erected": "Erected",
        "ready_to_dismantle": "Ready to Dismantle"
      },
      "subarea_id": "Subarea",
      "tag": "TAG",
      "tag_number": "Tag Number",
      "tag_removed": "Tag Removed",
      "telephone_radio": "Telephone - Radio",
      "title": "Scaffolding Log",
      "total_m": "Total m",
      "total_m2": "Total m2",
      "total_m3": "Total m3",
      "total_weight_message": "Total Weight of Erected Scaffolding",
      "user_of_scaffolding": "User of Scaffolding",
      "weight": "Weight",
      "wind_exposed": "Wind Exposed"
    },
    "scopes": {
      "add": "Add work preparation item",
      "choose_modal": {
        "single": "Another work preparation item",
        "single_description": "Creation of only one work preparation item"
      },
      "clusters": {
        "title": "Work Preparation Clusters"
      },
      "count": "Count",
      "crew_size": "Crew Size",
      "crews": "Number of Crews",
      "date": "Date",
      "description": "Work Preparation",
      "duplicate_services": {
        "other_request": "Another Work Order",
        "other_request_description": "Duplicate to a Work Preparation Item in Another Work Order",
        "same_request": "Same Work Order",
        "same_request_description": "Select the Work Preparation Item",
        "success_notification": "The Work Preparation's services were successfully duplicated",
        "title": "Duplicate Services"
      },
      "edit_work_description": "Edit Work Description",
      "hours": "Hours (h)",
      "id": "ID",
      "item": "Item",
      "label": "Label",
      "label_assigner": {
        "success": "Your Work Preparations Labels has been assigned successfully! You can continue your plan now."
      },
      "label_error": "Could not fetch labels",
      "labels_info": "Work Preparation Labels",
      "more_details": "see more",
      "popup_label": {
        "create_label": "Create Label",
        "labels": "Labels:",
        "name_label": "Name of Label",
        "new_label": "New Label"
      },
      "preview_unavailable": "No preview available",
      "quantity": "Qty",
      "remaining_hours": "Remaining Hours",
      "remaining_quantity": "Remaining Qty",
      "request_status_restriction": "Work Order should have status Confirmed or In Preparation",
      "scopes_detail": {
        "side_panel_title": "Work Preparation details"
      },
      "success_work_description_edition": "The work description was successfully edited",
      "title": "Work Preparations",
      "unit": "Unit",
      "work_description": "Work Description",
      "working_hours": "Working Hours"
    },
    "search": {
      "loading": "loading...",
      "no_result_found": "No result found",
      "placeholder": "Filter..."
    },
    "service_description": "Service Description",
    "settings": {
      "title": "Settings"
    },
    "settings_shortcuts": "Settings shortcuts",
    "shortcuts": {
      "access_control": "Access Control",
      "areas": "Areas and Subareas",
      "areas_subareas": "Areas and Subareas",
      "assets": "Assets",
      "background_jobs": "Background Jobs",
      "branches": "Branches",
      "cities": "Cities",
      "client_sites": "Client Sites",
      "clients": "Clients",
      "clients_info": "Clients Info",
      "columns_renaming": "Columns Renaming",
      "companies": "Companies",
      "contract_services": "Contract Services",
      "contracts": "Contracts",
      "countries": "Countries",
      "customer_sites": "Client Sites",
      "customers": "Clients",
      "disciplines": "Disciplines",
      "employees": "Staff",
      "equipaments": "Equipments",
      "equipments": "Equipments",
      "formulas": "Formulas",
      "location": "Location",
      "materials": "Materials",
      "matrices": "Matrices",
      "measures": "Measures",
      "mobile_details": "Mobile Details",
      "position_names": "Position Names",
      "preferences": "Preferences",
      "project_assets": "Assets",
      "project_contracts": "Contracts",
      "projects": "Projects and Subprojects",
      "projects_contracts": "Projects & Contracts",
      "projects_subprojects": "Projects and Subprojects",
      "provinces": "States / Provinces",
      "regions": "Regions",
      "routes": "Routes",
      "specialities": "Specialities",
      "staff": "Staff",
      "states_provinces": "States / Provinces",
      "templates": "Templates",
      "translations": "Column Renaming",
      "users": "Users",
      "visible_fields": "Mobile Details",
      "work_positions": "Position Names"
    },
    "side_menu": {
      "collapse": "Collapse",
      "contracts": "Contracts",
      "datasheets": "Datasheets",
      "expand": "Expand",
      "report": "Reports",
      "search": "Search",
      "settings": "Settings",
      "template": {
        "asset": "Assets",
        "contract_service": "Contract Services",
        "datasheet": "Datasheets",
        "estimate_service": "Estimate Details",
        "inspection": "Inspections",
        "performance": "Construction",
        "report": "Reports",
        "request": "Work Orders",
        "scoping": "Work Preparation",
        "valuation": "Valuation"
      },
      "templates": "Templates"
    },
    "side_panel": {
      "close": "Close Side Panel",
      "collapse": "Collapse Side Panel",
      "expand": "Expand Side Panel",
      "tabs": {
        "category_filter": "Category filter",
        "gallery": "Gallery",
        "info": "Info",
        "inspections": "Inspections",
        "no_categories": "There are no categories available",
        "no_templates": "There are no templates available",
        "preview_details": "Preview details",
        "progress": "Progress",
        "template_filter": "Template filter"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "All Sub Requests",
        "back": "All Work Preparations",
        "title": "sub requests"
      },
      "left_panel": {
        "add_scoping": "Add work preparation item",
        "allocation": "Allocation",
        "cards_header_title": "Work Preparations",
        "summary": "Summary",
        "total_allocation": "Total allocation"
      },
      "scope_card": {
        "scope": "Work Preparation"
      }
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "tabs": {
      "error": "Impossible to load tabs"
    },
    "templates": {
      "asset": "Assets",
      "contract_service": "Contract Services",
      "datasheet": "Datasheets",
      "estimate_service": "Estimate Details",
      "inspection": "Inspections",
      "no_template": "You need to create template",
      "progress": "Construction",
      "progress_service": "Valuation",
      "report": "Reports",
      "request": "Work Orders",
      "scoping": "Work Preparation",
      "title": "Templates for %{type}"
    },
    "tickets": {
      "icon_alt": "A ticket icon",
      "title": "Tickets"
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    },
    "timeline": {
      "created": "Created",
      "day_ago_singular": "%{daysAgo} day ago",
      "day_until_singular": "Within %{daysUntil} day",
      "days_ago": "%{daysAgo} days ago",
      "days_until": "Within %{daysUntil} days",
      "discipline_check_done": "Discipline Check: Done",
      "discipline_check_pending": "Discipline Check: Pending",
      "discipline_check_rejected": "Discipline Check: Rejected",
      "estimate_authorized": "Estimate: Authorized",
      "estimate_done": "Estimate: Completed",
      "estimate_on_hold": "Estimate: On Hold",
      "estimate_pending": "Estimate: Pending",
      "estimate_pending_authorization": "Estimate: Authorization Pending",
      "estimate_progress": "Estimate: In Progress",
      "performance": "Construction",
      "progress": "Progress",
      "recycle_done": "Recycle: Done",
      "recycle_recycling": "Recycle: Recycling",
      "recycling": "Recycling",
      "rejected": "Rejected",
      "request": "Work Order",
      "responsible": "Responsible",
      "schedule": "Schedule",
      "scoping": "Work Preparation",
      "title": "Work Order Timeline",
      "today": "Today"
    },
    "top_menu": {
      "authorization": "Authorization",
      "datasheets": "Datasheets",
      "eav_templates": "Templates",
      "estimates": "Estimates",
      "inspections": "Inspections",
      "no_datasheets": "No Datasheets found",
      "no_reports": "No Reports found",
      "performances": "Construction",
      "plannings": "Planning",
      "reports": "Reports",
      "requests": "Work Orders",
      "scaffoldings": "Scaffolding Log",
      "scopes": "Work Preparation",
      "workflow": "Workflow"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "About Version",
      "clean_preferences": "Clean Preferences",
      "logout": "Logout",
      "notification": {
        "clean_preferences": "Preferences cleaned up",
        "error": "Error when trying to clean preferences"
      },
      "preferences": "Grid Preferences",
      "profile": "Profile"
    },
    "users": {
      "edit_title": "Update Profile"
    },
    "work_order_attachments": {
      "subtitle": "See the documents of the related Work Order to prepare your job.",
      "title": "Work Order Attachments"
    }
  },
  "es": {
    "actions": {
      "alternate_view": "Vista alternativa",
      "approve": "Aprobar",
      "back_to": "Volver a %{page}",
      "bulk_editing": "Edición masiva",
      "cancel": "Cancelar",
      "choose_entity": "Elija %{entity}",
      "clear": "Limpiar",
      "clear_all_filters": "Borrar todos los filtros",
      "confirm": "Confirmar",
      "create": "Crear",
      "create_inspection": "Crear inspección",
      "delete": "Eliminar",
      "discard": "Descarte",
      "dismantle": "Desmantelar",
      "done": "Completar",
      "duplicate": "Duplicar",
      "duplicate_services": "Servicios de Duplicación",
      "duplicate_with_parts": "Duplicar con partes",
      "edit": "Editar",
      "export": "Exportar a .xls",
      "export_template": "Descargar plantilla .xls",
      "filter_pinned_work_orders": "Filtrar órdenes de trabajo fijadas",
      "flag_filter": "Filtro de bandera",
      "generate_work_package": "Generar paquete de trabajo",
      "import": "Importar desde .xls",
      "import_file": "Importar archivo",
      "loading": "Cargando",
      "more": "Más",
      "new": "Crear nuevo",
      "next": "próximo",
      "no": "No",
      "past": {
        "cancel": "cancelado",
        "complete": "terminado",
        "confirm": "confirmado",
        "delete": "eliminado",
        "destroy": "destruido",
        "dismantle": "desmantelado",
        "duplicate_with_parts": "Duplicado con sus partes",
        "ready_to_dismantle": "listo para desmantelar",
        "reject": "rechazado",
        "revert": "revirtió",
        "update": "actualizado"
      },
      "perform_discipline_check": "Realizar control de disciplina",
      "pin_request": "Filtrar por solicitudes seleccionadas",
      "prev": "Anterior",
      "ready": "Listo",
      "ready_for_discipline": "Listo para el control de disciplina",
      "ready_to_dismantle": "Listo para desmontar",
      "recycle": "Reciclar",
      "reject": "Rechazar",
      "remove_recycle": "Quitar Reciclar",
      "reset": "Reiniciar",
      "retrieving": "Recuperando...",
      "revert": "Revertir",
      "revert_to_erected": "Volver a erigido",
      "save": "Ahorrar",
      "save_button_keyboard": "Guardar (Ctrl + Enter)",
      "save_exit": "Guardar y Salir",
      "save_new": "Agregar otro",
      "saving": "Ahorro...",
      "schedule": "Programar",
      "show_all_work_orders": "Mostrar todas las órdenes de trabajo",
      "skip": "Omitir",
      "status_filter": "Filtro de estado",
      "unpin_request": "Quitar filtro por solicitudes",
      "unpin_selected_request": "Eliminar filtro de %{numSelectedRequests} solicitudes seleccionadas",
      "unsaved_changes": "Filas no guardadas",
      "upload_file": "Upload archivo",
      "upload_picture": "Subir foto",
      "yes": "Si"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "No es posible duplicar una orden de trabajo agrupada",
      "cant_duplicate_progress": "No se pueden duplicar los Servicios debido a los siguientes errores ...",
      "cant_duplicate_request": "No se pueden duplicar Preparativos, Estimaciones y Servicios debido a los siguientes errores...",
      "cant_duplicate_scope": "No se pueden duplicar los Servicios debido a los siguientes errores ...",
      "only_estimate_service_copy": "Solo se está intentando copiar el elemento de servicio estimado.",
      "only_progress_copy": "Solo se está intentando copiar el progreso.",
      "only_request_copy": "Sólo se intenta copiar la orden de trabajo.",
      "only_scope_copy": "Sólo se intenta copiar el elemento Preparación del trabajo."
    },
    "cancel_modal": {
      "cancel": "Ahora no",
      "description": "¿Está seguro de que desea cancelar %{model} \"%{id}\"?",
      "ok": "Si cancelar",
      "title": "¿Cancelar artículo?"
    },
    "clear_records": {
      "modal": {
        "cancel": "No, cancelar",
        "description": "¿Está seguro de que desea borrar todos los registros %{model}?",
        "ok": "Si, borra todo",
        "title": "¿Borrar %{model}?"
      },
      "success_message": "¡%{model} se borró correctamente!",
      "title": "Limpiar registros"
    },
    "clear_template_preferences": {
      "cancel": "No, cancela",
      "description": "¿Está seguro de que desea borrar todas las preferencias de cuadrícula de esta plantilla?",
      "ok": "Sí, borrar preferencias de cuadrícula",
      "title": "Borrar preferencias de cuadrícula"
    },
    "collections": {
      "clusters": "Agrupaciones",
      "create_by_package": "Crear por paquete",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "estimate": "Estimar",
      "new": "Nuevo",
      "schedule": "Calendario",
      "sub_requests": "Sub solicitudes",
      "view": "Ver"
    },
    "complete_modal": {
      "cancel": "Ahora no",
      "description": "¿Está seguro de que desea marcar %{model} como completado?",
      "ok": "Sí, marcar como completado",
      "title": "¿Marcar como completado?"
    },
    "complete_progress_modal": {
      "cancel": "Cancelar",
      "complete": "Completar",
      "description": "¿Estás seguro de que quieres completar este elemento de Construcción? Esta acción no se puede deshacer.",
      "title": "Confirmar la finalización del elemento de construcción"
    },
    "confirmation_modal": {
      "cancel": "Cancelar",
      "description": "¡Esta acción no se puede deshacer!",
      "ok": "Okay",
      "title": "¿Estás seguro?"
    },
    "contract_services": {
      "title": "Servicios por contrato"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Detalles de la hoja de datos"
      },
      "description": "Hojas de datos",
      "id": "ID",
      "item": "Articulo",
      "title": "Hojas de datos"
    },
    "delete_attachment_modal": {
      "cancel": "No, conserva el archivo adjunto.",
      "delete_successful": "Adjunto eliminado exitosamente",
      "description": "¿Está seguro de que desea eliminar este archivo adjunto?",
      "ok": "Sí, elimina el archivo adjunto.",
      "title": "¿Eliminar archivo adjunto?"
    },
    "delete_modal": {
      "cancel": "No, cancelar",
      "description": "¿Está seguro de que desea eliminar %{model} \"%{id}\"?",
      "ok": "Si, borrar",
      "title": "¿Eliminar elemento?"
    },
    "delete_picture_modal": {
      "cancel": "No, mantén la foto",
      "delete_successful": "Imagen eliminada exitosamente",
      "description": "¿Estás seguro de que deseas eliminar esta imagen?",
      "ok": "Si, borrar foto",
      "title": "Eliminar imagen"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Aceptación"
      },
      "column_comment": {
        "add_comment": "Haga clic para agregar un comentario",
        "cancel": "Cancelar",
        "comment": "Comentario",
        "subtitle": "Agregar/editar comentario"
      },
      "columns_batch_assigner": {
        "actions": "Comportamiento",
        "add_action": "Agregar acción",
        "add_to_column": "Agregar a la columna",
        "add_value_to": "Agregar %{value} a",
        "column": "Columna",
        "done": "Guardar acción",
        "duplicate_services": "Duplicar Servicios",
        "header": "Edición por lotes",
        "multiple_success": "Se actualizaron los elementos %{numberOfItems}.",
        "new_value": "Nuevo valor",
        "single_success": "Se actualizó 1 elemento.",
        "subtitle": "Asignar valores a las %{numberOfColumns} columnas seleccionadas",
        "title": "Crear acciones",
        "update": "Actualizar"
      },
      "configure_print_grid_file": {
        "error_max_columns": "Solo puede seleccionar hasta %{maxColumns} columnas.",
        "generate": "Generar archivo",
        "header": "Imprimir cuadrícula",
        "paper_size_title": "Tamaño de papel",
        "pdf_file": {
          "information": "Información",
          "pictures": "Fotos",
          "title": "Cuadrícula %{modelName} - %{numItems} elementos - Página %{pageNum} de %{totalPages}"
        },
        "select_columns_title": "Columnas para Imprimir",
        "selected_columns": "Ha seleccionado %{numSelectedColumns} de %{maxColumns}.",
        "subtitle": "Seleccione hasta %{maxColumns} columnas para imprimir",
        "title": "Configurar archivo para imprimir"
      },
      "configure_print_list_file": {
        "big_picture_title": "¿Imprimir imágenes utilizando el ancho de la página?",
        "font_size_title": "Seleccione el tamaño de fuente",
        "header": "Lista de impresión",
        "hide_columns_title": "Incluir columnas ocultas",
        "large": "Grande",
        "medium": "Medio",
        "page_break_title": "¿Imprimir 1 artículo por página?",
        "small": "Pequeño"
      },
      "configure_work_package_file": {
        "attached_documents": "Documentos adjuntos",
        "attachments": "Archivos adjuntos",
        "estimates": "Estimados",
        "estimates_price": "Precio estimado",
        "generate": "Generar",
        "header": "Generar paquete de trabajo",
        "hse_r": "Evaluación HSE-R",
        "inspection_page_breaker": "Imprima una inspección por página",
        "inspection_pictures": "Incluir imágenes y firmas",
        "inspections": "Todas las inspecciones relacionadas",
        "subtitle": "Seleccione las secciones que desea imprimir",
        "title": "Configurar el archivo del paquete de trabajo",
        "work_description": "Descripción del trabajo"
      },
      "duplicate_services": {
        "title": "Duplicar Servicios"
      }
    },
    "discard_modal": {
      "cancel": "Sigue editando",
      "description": "¿Está seguro de que desea descartar sus cambios?",
      "ok": "Si, descartar",
      "title": "Descartar los cambios"
    },
    "eav_templates": {
      "title": "Plantillas"
    },
    "empty_state": {
      "columns": "columnas",
      "empty": "No hay %{model}",
      "no_attachments": "Aún no has subido ningún archivo adjunto",
      "no_columns": "No hay ninguna columna de imagen disponible.",
      "no_discipline_template": "No se encontraron plantillas con la disciplina de orden de trabajo filtrada.",
      "no_estimate_service_template": "No se encontró ninguna plantilla de servicio de estimación para la plantilla de preparación del trabajo seleccionada",
      "no_pictures": "Aún no has subido ninguna imagen en esta columna.",
      "no_unselect": "No es posible deseleccionar la solicitud fijada en esta página. Solo puede hacerlo en la página Órdenes de trabajo o cerrando sesión.",
      "not_created": "Aún no ha creado ningún tipo de %{model}",
      "pictures": "fotos"
    },
    "environment": {
      "project": {
        "select_project": "Seleccionar proyecto ..."
      },
      "select_project_subproject": "Seleccionar proyecto y subproyecto",
      "subproject": {
        "select_subproject": "Seleccionar subproyecto ..."
      }
    },
    "estimate": {
      "accept": "Aceptar estimación",
      "all_estimates_approved": "Todas las estimaciones fueron aprobadas con éxito.",
      "application": "Solicitud",
      "back": "Todas las órdenes de trabajo",
      "budget_hours": "Presupuesto",
      "confirmation": {
        "cancel": "No, cancelar",
        "confirm": "Si, confirmar",
        "description": "¿Está seguro de que desea autorizar este presupuesto para esta Orden de trabajo?",
        "title": "Confirmar pedido"
      },
      "description": "Tiene servicios %{number} para pagar. Consulte los detalles a continuación.",
      "equipment": "Equipo",
      "estimate_details": "Detalles de la estimación",
      "estimate_success": "Estimación aprobada con éxito",
      "info_message": "Este servicio de estimación ya ha sido aprobado. Está disponible solo para lectura.",
      "material": "Material",
      "no_estimate_message": "No hay ningún estimado asociado con esta orden de trabajo.",
      "norm_hours": "Horas Norm",
      "others": "Otros",
      "pdf_file": {
        "title": "Detalles de estimación: página %{pageNum} de %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Disciplina",
        "functional_location": "Ubicación Funcional",
        "jobcard_status": "Estado de la tarjeta de trabajo",
        "ke_priority": "Prioridad KE",
        "main_area_location": "Área principal y ubicación",
        "main_work_center": "Centro de trabajo principal",
        "maintenance_plant": "Planta de mantenimiento",
        "not_defined": "Aún no definido",
        "order_finish": "Fin del pedido",
        "order_start": "Inicio del pedido",
        "priority_sequence": "Secuencia de prioridad",
        "project": "Proyecto",
        "project_type": "Tipo de proyecto",
        "report": "Informe",
        "report_date": "Fecha del informe",
        "responsible_engineer": "Ingeniero Responsable",
        "status_completed": "Terminado",
        "status_issued_for_approval": "Emitido para su aprobación",
        "status_issued_for_construction": "Emitido para la construcción",
        "subproject": "Subproyecto",
        "system": "Sistema",
        "template": "Plantilla",
        "title": "Estimación de la orden de trabajo",
        "work_order": "Orden de trabajo"
      },
      "pdf_summary": {
        "title": "Resumen de estimaciones"
      },
      "save_draft": "Guardar borrador",
      "service_details": "Detalles del servicio",
      "some_estimates_not_approved": "Algunas estimaciones no pudieron aprobarse. Verifique los registros de errores.",
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicio de pago",
      "tooltip": {
        "application": "Solicitud",
        "equipment": "Equipo",
        "material": "Material",
        "others": "Otros"
      },
      "total_approved": "Total aprobado",
      "total_pending": "Total pendiente",
      "total_request": "Solicitud total",
      "use_construction_prices": "¿Incluye precios de construcción?"
    },
    "estimate_services": {
      "application_price": "Precio de la aplicación",
      "automatically_prices": "Precios calculados automáticamente",
      "back": "Todos los preparativos del trabajo",
      "budget_target_hours": "Horas objetivo de presupuesto",
      "choose_modal": {
        "package": "Paquete de detalle único",
        "package_description": "Creación de varios servicios basados en un mismo detalle",
        "single": "Detalle único",
        "single_description": "Creación de un solo detalle.",
        "title": "Crear nuevo"
      },
      "contract_service": "Servicio por contrato",
      "crew_size": "Tamaño de la tripulación",
      "crews": "Número de tripulaciones",
      "description": "Descripción",
      "description_label": "Descripción",
      "discipline": "Disciplina",
      "equipment_price": "Precio del equipo",
      "estimate": "Servicio de pago",
      "estimate_services_detail": {
        "side_panel_title": "Detalles del servicio"
      },
      "factor": "Factor",
      "flag": "Bandera",
      "id": "CARNÉ DE IDENTIDAD",
      "item_no": "Artículo Nro.",
      "materials_price": "Precio de los materiales",
      "norm_hours": "Horas Norm",
      "other_price": "Otro precio",
      "prices": "Precios",
      "print": "Imprimir",
      "quantity": "Cantidad",
      "service": "Servicio",
      "service_no": "Servicio No.",
      "service_package": "Paquete de servicios",
      "statuses": {
        "inspected_flag": "Inspección pendiente"
      },
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicios de alcance",
      "title_packageable": "Estimar servicios por paquete de servicios",
      "total": "Total",
      "unit": "Unidad",
      "weight": "Peso",
      "working_hours": "Horas Laborales"
    },
    "fields": {
      "created_at": "Creado",
      "image_not_found": "Imagen no encontrada",
      "na": "N/A",
      "signature": "Firma.jpg",
      "updated_at": "Actualizado"
    },
    "filter_steps_modal": {
      "filter_result": "Resultado del filtro",
      "result_subtitle": "Resumen de sus selecciones",
      "step": "Paso",
      "subtitle": "Por favor seleccione %{title}"
    },
    "filtering": {
      "between": " eso es entre",
      "contains": " que contiene ",
      "eq": " que es igual a ",
      "invalid_date": "Para filtrar correctamente, se debe completar la fecha de inicio y la fecha de finalización",
      "invalid_value": "valor no válido",
      "neq": " que no es igual a "
    },
    "forgot_password": {
      "back_to_login": "Atrás para iniciar sesión",
      "button": "Envíame instrucciones para restablecer la contraseña",
      "feedback": "Se envió un mensaje con instrucciones para restablecer su contraseña al correo electrónico proporcionado.",
      "placeholder": "Tu correo electrónico",
      "subtitle": "Ingrese su dirección de correo electrónico para recibir instrucciones para restablecer su contraseña.",
      "title": "¿Olvidaste tu contraseña?"
    },
    "form": {
      "back": "Todas %{entity}",
      "cancel": "Cancelar",
      "create_entity": "Crear %{entity}",
      "create_error": "%{entity} no se pudo crear",
      "create_success": "%{entity} se creó con éxito",
      "duplicate_entity": "%{entity} duplicado",
      "duplicate_success": "¡El %{entity} y sus dependencias se duplicaron con éxito!",
      "general_information": "Información general",
      "hide": "Esconder",
      "inputs": {
        "attachment": {
          "add_error": "Error al agregar archivo",
          "add_evidence": "Agregar evidencia de la autorización del cliente",
          "allowed_extensions": "Las extensiones permitidas son",
          "drag_drop": "Agregar archivos usando arrastrar y soltar",
          "invalid_extension": "Tipo de archivo no permitido.",
          "max_size": "El archivo debe ser menor que %{max_size}",
          "maximum_num_uploads_error": "Solo puede cargar hasta %{max_num} archivos por columna flexible.",
          "one_file": "Sube un archivo a la vez",
          "or_drop_file_here": "o suelte el archivo aquí",
          "remove_error": "No se pudo quitar",
          "remove_success": "Eliminado con éxito",
          "select_files": "Selecciona archivos",
          "submit": "Envíe su adjunto",
          "upload_error": "Subida fallida",
          "upload_success": "Subido con éxito"
        },
        "contract_service": {
          "add": "Agregar un servicio por contrato",
          "subtitle": "Servicio por contrato"
        },
        "datasheet_filter": {
          "add_filter": "Añadir filtro",
          "title": "Filtro de hoja de datos"
        },
        "date_range": {
          "between": "%{start} hasta %{end}",
          "end": "Fecha final",
          "end_simplified": "Fin",
          "start": "Fecha de inicio",
          "start_simplified": "Comienzo"
        },
        "drop_formula": {
          "formula_select": "Por favor, seleccione una fórmula ..."
        },
        "errors": {
          "date_greater_than_max": "La fecha debe ser anterior al %{max}",
          "date_less_than_min": "La fecha debe ser el día %{min} o posterior",
          "drop_formula_result": "Resultado de la fórmula calculada %{field}",
          "mandatory": "%{field} es obligatorio.",
          "not_found": "'%{value}' no se encontró en la referencia. Seleccione uno nuevo."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "o arrastrar y soltar",
              "select_file": "Haga clic para cargar"
            },
            "note": {
              "extensions": "El archivo debe estar en %{extensions}",
              "max_size": "Tamaño máximo de archivo %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Imagen"
          },
          "upload": {
            "error": {
              "invalid_picture": "Tipo/tamaño no válido",
              "multiple_fails": "Todos los archivos adjuntos tienen un tipo y tamaño no válidos",
              "multiple_files": "Algunas imágenes adjuntas tienen un tipo y tamaño no válidos. Cargue las que sean válidas.",
              "title": "Subida fallida"
            },
            "success": {
              "multiple_files": "Se cargaron varios archivos con éxito",
              "title": "Subida exitosa"
            }
          }
        },
        "formula": {
          "description": "Esta es una columna de fórmula, el resultado se actualiza automáticamente",
          "icon": "click para ver la ecuacion",
          "result": "Resultado"
        },
        "gps": {
          "save_location": "Guardar dirección",
          "search": "Buscar ubicación ...",
          "without_location": "Sin ubicación"
        },
        "multiple_entries": {
          "add": "Agregar otro",
          "empty_entry": {
            "scaffolding_type_id": "Seleccione un tipo de andamiaje para agregar acciones"
          },
          "empty_inputs": "Las entradas de %{input} son necesarias en %{model}",
          "required": "El campo “%{field}” es obligatorio y debe ser completado"
        },
        "numeric": {
          "between": "El valor debe estar entre %{min} y %{max}",
          "greater": "El valor debe ser mayor que %{min}",
          "lower": "El valor debe ser inferior a %{max}"
        },
        "picture": {
          "comment_placeholder": "Escribe un comentario (opcional)",
          "edition_only": "Solo disponible al editar",
          "no_image_on_clipboard": "No se encontraron datos de imagen en su portapapeles. Por favor, copie una imagen primero.",
          "remove_error": "Error al eliminar",
          "remove_success": "Eliminado con éxito",
          "upload_error": "Subida fallida",
          "upload_success": "Subido con éxito"
        },
        "placeholder": "Ingrese el %{field} aquí",
        "value_selector": {
          "apply": "Aplicar",
          "done": "Hecho",
          "search": "Busca algo aquí ...",
          "waiting": "... Espere"
        }
      },
      "items": "Elementos",
      "loading": "Cargando...",
      "loading_calculations": "Cálculo de visibilidades, columnas obligatorias y valores predeterminados",
      "loading_contract": "Cargando información del contrato...",
      "schedule_entity": "Horario %{entity}",
      "schedule_success": "Su solicitud para programar %{entity} ha finalizado correctamente",
      "show": "Mostrar",
      "subtitle": "Proporcione información básica sobre %{entity}.",
      "subtitle_error": "No se pudo cargar el formulario, verifique su %{entity} o los datos de la plantilla.",
      "update_entity": "Actualizar %{entity}",
      "update_error": "%{entity} no se pudo actualizar",
      "update_success": "%{entity} se actualizó correctamente"
    },
    "formula_modal": {
      "back_button_text": "Volver al formulario",
      "calculate": "Calcular",
      "cancel": "Cancelar",
      "mandatory": "Debe completar los valores para los siguientes campos: %{fields}",
      "ok": "Guardar fórmula"
    },
    "grid": {
      "all": "Todos",
      "clear_filters": "Borrar filtros",
      "editable": {
        "error": {
          "mandatory": "Al menos un campo obligatorio se dejó vacío"
        },
        "missing_required": "Faltan %{number} celdas obligatorias en esta fila",
        "title": "Modo de edición",
        "update": {
          "error": "%{quantity} no se pudo guardar. Verifique el motivo en el lado izquierdo de cada fila resaltada",
          "success": "Los registros %{quantity} se guardaron correctamente"
        }
      },
      "empty_after_filtering": "No hay registros para los filtros aplicados",
      "failed_saving_preferences": "¡Las preferencias no se guardaron!",
      "flag_filter": "Filtro de bandera",
      "grid_filters": {
        "menu_title": "Filtros personalizados"
      },
      "menu": {
        "visibility": "Columnas"
      },
      "of": "de",
      "other": "Otros",
      "productivity_factor": "Factor de productividad",
      "quick_pin_popup": {
        "all_work_orders": "Todas las OTs",
        "clear_all": "Limpiar todo",
        "no_pinned_requests": "Aún no has fijado órdenes de trabajo",
        "pinned_work_orders": "OTs fijadas",
        "quick_pin": "Pin rápido",
        "search_and_pin": "Buscar y fijar OT"
      },
      "saved_preferences": "¡Salvado!",
      "saving_preferences": "Guardando preferencias...",
      "select_items": "Seleccionar articulos",
      "selected": "Seleccionado",
      "selecteds": "Seleccionados",
      "status_filter": "Filtro de estado",
      "totals": "Totales"
    },
    "history_form": {
      "create_title": "Agregar un nuevo progreso",
      "edit_title": "Edita tu progreso",
      "subtitle": "Inserta un progreso por cantidad o porcentaje para tu actividad."
    },
    "history_popup": {
      "back": "Atrás",
      "comments": "Comentarios",
      "crew_size": "Tamaño de la tripulación",
      "daily_hours": "Horas diarias",
      "date": "Fecha",
      "form_create_progress_service_title": "Crear historial de elementos de servicio de construcción",
      "form_create_progress_title": "Crear historial de elementos de progreso",
      "form_edit_progress_service_title": "Editar el historial de elementos del servicio de construcción",
      "form_edit_progress_title": "Editar el historial de elementos de progreso",
      "form_error_body": "Se deben completar todos los campos obligatorios del formulario.",
      "form_error_title": "No fue posible guardar el formulario de historial.",
      "form_subtitle": "Inserta un historial por cantidad o porcentaje para tu actividad.",
      "modal_create_title": "Crear historial",
      "modal_edit_title": "Editar historial",
      "number_of_crews": "No. de tripulaciones",
      "number_of_workers": "No. de trabajadores",
      "other_value": "Otro valor",
      "percentage": "Porcentaje",
      "progress_by": "Progreso por",
      "quantity": "Cantidad",
      "singular": "Historia",
      "working_hours": "Horas Laborales"
    },
    "home": {
      "available_on": "disponible en",
      "banner_description": "Gestione tareas, realice un seguimiento de los procesos y obtenga los mejores resultados utilizando una única plataforma",
      "create_request": {
        "text": "Lo primero es lo primero, debe crear una Orden de trabajo del Cliente en el DPMS con los detalles de su pedido.",
        "title": "Crear una orden de trabajo"
      },
      "dpms_mobile": "Aplicación móvil DPMS",
      "getting_started": "Empezando",
      "home_title": "Página de Inicio",
      "inspection": {
        "text": "Realice inspecciones de calidad en el campo y registre los obstáculos.",
        "title": "Inspección"
      },
      "inspection_calendar": {
        "calendar": "Calendario",
        "table": "Tabla",
        "title": "Calendario de inspección"
      },
      "performance": {
        "text": "Registre el Progreso y el Tiempo en base a las actividades planificadas de cada equipo y mida la productividad.",
        "title": "Construcción"
      },
      "scoping": {
        "text": "Preparar una Orden de Trabajo del Cliente. Asigne Servicios y Paquetes de Servicios desde su contrato.",
        "title": "Trabajo de preparación"
      },
      "welcome_user": "Bienvenido, %{user}"
    },
    "importation_drop": {
      "choose_error": "Elige un artículo"
    },
    "importation_popup": {
      "file_upload_message": "Haga clic o arrastre el archivo a esta área para cargar",
      "importation_error": "Puede revisar este mensaje en la página de Trabajos.",
      "importation_success": "Importación exitosa.",
      "importation_timeout": "La importación está tardando demasiado. Verifique su estado en la página Trabajos.",
      "instructions": "Los archivos deben ser .xls y no más grandes que xxmb. <br> Su archivo .xls debe contener la columna: Fecha, Cliente, Proyecto, Tarea, Notas ... <br> El orden de las columnas debe ser el mismo que el de la columna. orden de la plantilla. <br> La fecha debe estar en - AAAA / MM / DD.",
      "requirements": "Requisitos",
      "submit_spreadsheet": "Envía tu hoja de cálculo"
    },
    "inspections": {
      "acceptance": "Aceptación",
      "acceptance_error": {
        "error_description": "La inspección: %{item} no se pudo actualizar porque %{reason}",
        "title": "No podemos completar"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "Puedes revertir esto más tarde si es necesario.",
          "reject": "¿Está seguro de que desea rechazar esta/estas inspeccion(es)?"
        },
        "inspection_acceptance": "Aceptación de inspección",
        "items": "%{items} artículos",
        "reject": "Rechazar",
        "subtitle": "Proporcione información sobre la(s) inspección(es) para continuar con la aceptación."
      },
      "actions": {
        "approve": "Aprobar",
        "more": "Más",
        "reject": "Rechazar"
      },
      "choose_modal": {
        "schedule": "Programar elementos de inspección",
        "schedule_description": "Programe una o varias inspecciones según el intervalo de fechas",
        "single": "Artículo de inspección único",
        "single_description": "Creación de una única inspección",
        "title": "Cree una inspección como:"
      },
      "description": "Inspección",
      "description_title": "Descripción",
      "every": "Cada",
      "id": "ID",
      "inspect_date": "Fecha de inspección",
      "inspect_status_id": "Estado",
      "inspected": "Inspeccionado",
      "inspected_detail": "Detalle inspeccionado",
      "inspected_item": "Artículo inspeccionado",
      "inspected_modules": {
        "estimate_services": "Detalles de la estimación",
        "performance": "Construcción",
        "progress": "Construcción",
        "progress_services": "Servicios de construcción",
        "request": "Orden de trabajo",
        "scaffolding": "Andamio",
        "scope": "Trabajo de preparación"
      },
      "inspected_services": "Servicios Inspeccionados",
      "inspected_track": {
        "area": "Área",
        "comments": "Comentarios",
        "description": "Descripción",
        "item": "Artículo %{item}",
        "no_load": "No se pudo cargar el bloque de seguimiento inspeccionado: faltan datos",
        "number_of_tags": "Número de etiquetas",
        "reason": "Razón",
        "subarea": "Subárea"
      },
      "inspection_block": {
        "inspection_date": "Fecha de inspección",
        "missing_date": "Fecha faltante",
        "not_selected_yet": "Aún no seleccionado",
        "responsible": "Responsable"
      },
      "inspections_detail": {
        "side_panel_title": "Detalles de la inspección"
      },
      "module": "Módulo",
      "notifications": {
        "approve_successful": "La inspección fue aprobada con éxito",
        "reject_successful": "La inspección fue rechazada con éxito"
      },
      "pending_inspections": "Inspecciones pendientes",
      "recurrency_interval": "Intervalo de recurrencia",
      "related_inspections": {
        "missing_date": "Fecha faltante",
        "no_related": "No se encontraron inspecciones relacionadas",
        "no_responsible": "Sin Responsable",
        "title": "Inspecciones relacionadas"
      },
      "repeat": "Repetir",
      "repeat_every_value": {
        "day": "Día",
        "month": "Mes",
        "week": "Semana",
        "year": "Año"
      },
      "repeats_on": "Repetir en",
      "request_id": "DPMSID",
      "responsible_id": "Responsable",
      "schedule_detail": "Detalle del horario",
      "schedule_form": {
        "acceptance_step": "Definir aceptación (opcional)",
        "detailed_item_step": "Elija el elemento de preparación/construcción de inspección",
        "detailed_template_step": "Elija la plantilla de preparación/construcción de inspección",
        "further_step_information": "Los campos flexibles están listos para ser llenados individualmente después del paso de creación de repetición",
        "general_step": "Definir información general de inspección",
        "hints": "Para las repeticiones mensuales y anuales, el sistema programará la inspección que coincida con la fecha de inicio indicada en el campo de intervalo.",
        "monthly_hint": "Repetir mensualmente todos los días %{day}",
        "recurrence_step": "Reaparición"
      },
      "scheduled_exception": "Programado",
      "select_module_item": "Seleccione un elemento del módulo para ver sus detalles aquí",
      "statuses": {
        "approved": "Aprobado",
        "completed": "Completar",
        "overdue": "Atrasado",
        "pending": "Pendiente",
        "progress": "En Progreso",
        "rejected": "Disminuido",
        "scheduled": "Programado"
      },
      "template": "Plantilla",
      "title": "Inspecciones"
    },
    "inspects": {
      "statuses": {
        "approved": "Aprobado",
        "completed": "Completar",
        "pending": "Pendiente",
        "progress": "En Progreso",
        "rejected": "Disminuido"
      }
    },
    "kendo": {
      "upload": "Haga clic, arrastre o pegue los archivos aquí para cargar"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Hoy"
      },
      "dateinput": {
        "decrement": "Disminuir valor",
        "increment": "Incrementar valor"
      },
      "datepicker": {
        "toggleCalendar": "Alternar calendario"
      },
      "daterangepicker": {
        "end": "Fin",
        "separator": 0,
        "start": "comienzo",
        "swapStartEnd": "Intercambiar valores iniciales y finales"
      },
      "datetimepicker": {
        "cancel": "Cancelar",
        "date": "Fecha",
        "set": "Aplicar",
        "time": "Hora",
        "toggleDateTimeSelector": "Alternar selector de fecha y hora"
      },
      "dropdowns": {
        "clear": "Limpiar",
        "nodata": "Datos no Encontrados"
      },
      "editor": {
        "addColumnAfter": "Agregar columna a la derecha",
        "addColumnBefore": "Agregar columna a la izquierda",
        "addRowAfter": "Agregar fila a continuación",
        "addRowBefore": "Agregar fila arriba",
        "alignCenter": "Texto central",
        "alignJustify": "Justificar",
        "alignLeft": "Alinear texto a la izquierda",
        "alignRight": "Alinear texto a la derecha",
        "bold": "Negrita",
        "bulletList": "Insertar lista desordenada",
        "createTable": "Crea una tabla",
        "deleteColumn": "Eliminar columna",
        "deleteRow": "Borrar fila",
        "deleteTable": "Eliminar tabla",
        "fontName": "Nombre de la fuente",
        "fontSize": "Tamaño de fuente",
        "format": "Formato",
        "hyperlink": "Insertar hipervínculo",
        "hyperlink-dialog-cancel": "Cancelar",
        "hyperlink-dialog-content-address": "dirección web",
        "hyperlink-dialog-content-newwindow": "Abrir enlace en una nueva ventana",
        "hyperlink-dialog-content-title": "Título",
        "hyperlink-dialog-insert": "Insertar",
        "hyperlink-dialog-title": "Insertar hipervínculo",
        "image": "Insertar imagen",
        "image-address": "dirección web",
        "image-altText": "Texto alternativo",
        "image-cancel": "Cancelar",
        "image-dialog-title": "Insertar imagen",
        "image-height": "Altura (px)",
        "image-insert": "Insertar",
        "image-title": "Título",
        "image-width": "Ancho (px)",
        "indent": "Sangrar",
        "insertTableHint": "Cree una tabla de {0} x {1}",
        "italic": "Itálico",
        "mergeCells": "Combinar células",
        "orderedList": "Insertar lista ordenada",
        "outdent": "Sangrado",
        "redo": "Rehacer",
        "splitCell": "Celda dividida",
        "strikethrough": "Tachado",
        "subscript": "Subíndice",
        "superscript": "Sobrescrito",
        "underline": "Subrayar",
        "undo": "Deshacer",
        "unlink": "Eliminar hipervínculo",
        "viewHtml": "Ver HTML",
        "viewHtml-cancel": "Cancelar",
        "viewHtml-dialog-title": "Ver HTML",
        "viewHtml-update": "Actualizar"
      },
      "filter": {
        "addExpression": "Agregar expresión",
        "addGroup": "Añadir grupo",
        "afterOperator": "Es despues",
        "afterOrEqualOperator": "Es posterior o igual a",
        "andLogic": "Y",
        "beforeOperator": "Es antes",
        "beforeOrEqualOperator": "Es anterior o igual a",
        "close": "Cerrar",
        "containsOperator": "Contiene",
        "endsWithOperator": "Termina con",
        "eqOperator": "Es igual a",
        "gtOperator": "Es mayor que",
        "gteOperator": "Es mayor o igual a",
        "isEmptyOperator": "Esta vacio",
        "isFalse": "No es",
        "isNotEmptyOperator": "No está vacío",
        "isNotNullOperator": "No es nulo",
        "isNullOperator": "Es nulo",
        "isTrue": "Es sí",
        "ltOperator": "Es menos que",
        "lteOperator": "Es menor o igual que",
        "notContainsOperator": "No contiene",
        "notEqOperator": "No es igual a",
        "orLogic": "O",
        "startsWithOperator": "Comienza con"
      },
      "grid": {
        "filterAfterOperator": "Es despues",
        "filterAfterOrEqualOperator": "Es posterior o igual a",
        "filterBeforeOperator": "Es antes",
        "filterBeforeOrEqualOperator": "Es anterior o igual a",
        "filterBetweenOperator": "Está entre",
        "filterBooleanAll": "(Todas)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contiene",
        "filterEndsWithOperator": "Termina con",
        "filterEqOperator": "Es igual a",
        "filterGtOperator": "Es mayor que",
        "filterGteOperator": "Es mayor o igual a",
        "filterIsEmptyOperator": "Esta vacio",
        "filterIsFalse": "No es",
        "filterIsNotEmptyOperator": "No está vacío",
        "filterIsNotNullOperator": "No es nulo",
        "filterIsNullOperator": "Es nulo",
        "filterIsTrue": "Es sí",
        "filterLtOperator": "Es menos que",
        "filterLteOperator": "Es menor o igual que",
        "filterNotContainsOperator": "No contiene",
        "filterNotEqOperator": "No es igual a",
        "filterStartsWithOperator": "Comienza con",
        "filterSubmitButton": "Filtrar",
        "filterTitle": "Filtrar",
        "groupPanelEmpty": "Arrastre un encabezado de columna y suéltelo aquí para agrupar por esa columna",
        "noRecords": "No hay registros disponibles.",
        "pagerFirstPage": "Ir a la primera pagina",
        "pagerInfo": "{0} - {1} de {2} elementos",
        "pagerItemsPerPage": "Artículos por página",
        "pagerLastPage": "Ir a la última página",
        "pagerNextPage": "Ir a la página siguiente",
        "pagerOf": "de",
        "pagerPage": "Página",
        "pagerPreviousPage": "Ir a la pagina anterior"
      },
      "numerictextbox": {
        "decrement": "Disminuir valor",
        "increment": "Incrementar valor"
      },
      "pager": {
        "firstPage": "Ir a la primera pagina",
        "info": "{0} - {1} de {2} elementos",
        "itemsPerPage": "Artículos por página",
        "lastPage": "Ir a la última página",
        "nextPage": "Ir a la página siguiente",
        "of": "de",
        "page": "Página",
        "previousPage": "Ir a la pagina anterior"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "todo el dia",
        "allEvents": "Todos los eventos",
        "dateTitle": "Fecha",
        "dayViewTitle": "Día",
        "deleteConfirmation": "¿Estás seguro de que deseas eliminar este evento?",
        "deleteDialogTitle": "Eliminar evento",
        "deleteOccurrence": "Eliminar ocurrencia actual",
        "deleteRecurringConfirmation": "¿Desea eliminar solo esta ocurrencia de evento o toda la serie?",
        "deleteRecurringDialogTitle": "Eliminar artículo recurrente",
        "deleteSeries": "Eliminar la serie",
        "deleteTitle": "Eliminar",
        "editorCancel": "Cancelar",
        "editorDelete": "Eliminar",
        "editorEventAllDay": "Evento todo el día",
        "editorEventDescription": "Descripción",
        "editorEventEnd": "Fin",
        "editorEventEndTimeZone": "Zona horaria final",
        "editorEventSeparateTimeZones": "Termina en una zona horaria diferente",
        "editorEventStart": "comienzo",
        "editorEventStartTimeZone": "Zona horaria de inicio",
        "editorEventTimeZone": "Especificar zona horaria",
        "editorEventTitle": "Título",
        "editorOccurrence": "Editar ocurrencia actual",
        "editorRecurringConfirmation": "¿Quieres editar solo la ocurrencia de este evento o toda la serie?",
        "editorRecurringDialogTitle": "Editar artículo recurrente",
        "editorSave": "Salvar",
        "editorSeries": "Edita la serie",
        "editorTitle": "Evento",
        "editorValidationEnd": "La hora de finalización debe ser posterior a la hora de inicio.",
        "editorValidationRequired": "Se requiere campo.",
        "editorValidationStart": "La hora de inicio debe ser anterior a la hora de finalización.",
        "eventTitle": "Evento",
        "monthViewTitle": "Mes",
        "nextTitle": "próximo",
        "noEvents": "no hay eventos",
        "previousTitle": "Anterior",
        "recurrenceEditorDailyInterval": "dias)",
        "recurrenceEditorDailyRepeatEvery": "Repite cada",
        "recurrenceEditorEndAfter": "Después",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOccurrence": "ocurrencia (s)",
        "recurrenceEditorEndOn": "En",
        "recurrenceEditorFrequenciesDaily": "Diario",
        "recurrenceEditorFrequenciesMonthly": "Mensual",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanal",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorMonthlyDay": "Día",
        "recurrenceEditorMonthlyInterval": "meses)",
        "recurrenceEditorMonthlyRepeatEvery": "Repite cada",
        "recurrenceEditorMonthlyRepeatOn": "Repetir en",
        "recurrenceEditorOffsetPositionsFirst": "primero",
        "recurrenceEditorOffsetPositionsFourth": "Cuarto",
        "recurrenceEditorOffsetPositionsLast": "Último",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Tercero",
        "recurrenceEditorRepeat": "Repetir",
        "recurrenceEditorWeekdaysDay": "Día",
        "recurrenceEditorWeekdaysWeekday": "Día laborable",
        "recurrenceEditorWeekdaysWeekendday": "Día de fin de semana",
        "recurrenceEditorWeeklyInterval": "semanas)",
        "recurrenceEditorWeeklyRepeatEvery": "Repite cada",
        "recurrenceEditorWeeklyRepeatOn": "Repetir en",
        "recurrenceEditorYearlyInterval": "años)",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Repite cada",
        "recurrenceEditorYearlyRepeatOn": "Repetir en",
        "showFullDay": "Mostrar día completo",
        "showWorkDay": "Mostrar horario comercial",
        "timeTitle": "Hora",
        "timelineViewTitle": "Cronología",
        "today": "Hoy",
        "weekViewTitle": "Semana",
        "workWeekViewTitle": "Semana de trabajo"
      },
      "sortable": {
        "noData": "Sin datos"
      },
      "timepicker": {
        "cancel": "Cancelar",
        "now": "AHORA",
        "selectNow": "Seleccionar ahora",
        "set": "Aplicar",
        "toggleClock": "Alternar reloj",
        "toggleTimeSelector": "Alternar TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Es despues",
        "filterAfterOrEqualOperator": "Es posterior o igual a",
        "filterBeforeOperator": "Es antes",
        "filterBeforeOrEqualOperator": "Es anterior o igual a",
        "filterBooleanAll": "(Todas)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contiene",
        "filterEndsWithOperator": "Termina con",
        "filterEqOperator": "Es igual a",
        "filterGtOperator": "Es mayor que",
        "filterGteOperator": "Es mayor o igual a",
        "filterIsEmptyOperator": "Esta vacio",
        "filterIsFalse": "No es",
        "filterIsNotEmptyOperator": "No está vacío",
        "filterIsNotNullOperator": "No es nulo",
        "filterIsNullOperator": "Es nulo",
        "filterIsTrue": "Es sí",
        "filterLtOperator": "Es menos que",
        "filterLteOperator": "Es menor o igual que",
        "filterNotContainsOperator": "No contiene",
        "filterNotEqOperator": "No es igual a",
        "filterStartsWithOperator": "Comienza con",
        "noRecords": "No hay registros disponibles"
      },
      "upload": {
        "cancel": "Cancelar",
        "clearSelectedFiles": "Limpar",
        "dropFilesHere": "o suelte el archivo aquí",
        "files": "archivos",
        "headerStatusUploaded": "Hecho",
        "headerStatusUploading": "Subiendo ...",
        "invalidFileExtension": "Tipo de archivo no permitido.",
        "invalidFiles": "Archivo (s) inválidos. Compruebe los requisitos de carga de archivos.",
        "invalidMaxFileSize": "Tamaño de archivo demasiado grande.",
        "invalidMinFileSize": "Tamaño de archivo demasiado pequeño.",
        "remove": "Eliminar",
        "retry": "Rever",
        "select": "Seleccione Archivo",
        "total": "Total",
        "uploadSelectedFiles": "Subir"
      }
    },
    "loading_batch_entities": {
      "page_of": "Página %{page} de %{total}",
      "title": "Obteniendo páginas"
    },
    "login": {
      "again": "Para continuar, inicie sesión nuevamente",
      "continue_mykaefer": "Continuar con MyKaefer",
      "copyright": "®%{year} Todos los derechos reservados. Kaefer®",
      "forgot_password": "¿Se te olvidó tu contraseña?",
      "iframe_info": "Solicitando acceso a la página: %{link}",
      "log_in": "Iniciar sesión",
      "password": "Contraseña",
      "title_1": "Una herramienta poderosa",
      "title_2": "para gestión de proyectos",
      "username": "Nombre de usuario",
      "welcome_back": "Dar una buena acogida"
    },
    "main_dialog": {
      "failure_message": "No se pudo completar su solicitud.",
      "failure_title": "Fracaso",
      "loading_message": "¡Espere mientras preparamos las cosas para usted!",
      "loading_title": "Cargando...",
      "service_title": "Esperando a que termine el trabajo...",
      "success_message": "Su solicitud ha sido exitosa! Ahora puede continuar su trabajo.",
      "success_title": "Exitoso!"
    },
    "notification": {
      "access_denied": "Acceso denegado",
      "access_denied_message": "No tienes permiso para realizar esta acción. Póngase en contacto con su administrador para obtener más información.",
      "action_success": "%{model} %{id} ha sido %{action}",
      "bad_formula_setup": "Hay un problema de configuración con la fórmula '%{title}', ¡verifique su configuración!",
      "correct_subproject": "Seleccione el subproyecto correcto de este elemento",
      "dismiss_all": "Cerrar todo",
      "error": "Error",
      "error_cookies": "No pudimos establecer comunicación entre fuentes cruzadas. Revise la configuración de cookies en su navegador y desactive el \"Bloqueo de cookies de terceros\".",
      "error_job": "¡Ha fallado! Verifique el motivo en la página de trabajos.",
      "error_msg": "¡Lo siento! ¡Algo salió mal!",
      "info": "Info",
      "large_file_body": "Aplique algunos filtros para reducir el tamaño del archivo de salida.",
      "large_file_title": "La tabla tiene más de 1000 registros.",
      "page_denied": "No se pudo cargar la página.",
      "please": "Por favor",
      "select_subproject": "Seleccione un proyecto y subproyecto para continuar",
      "success": "Éxito",
      "success_msg": "¡Acción ejecutada con éxito!",
      "warning": "Advertencia"
    },
    "orders": {
      "add_evidence": "Agregar evidencia de la autorización del cliente",
      "attachment": "Adjunto archivo",
      "comments": "Comentarios",
      "date": "Fecha",
      "drag_and_drop": "Agregue archivos usando arrastrar y soltar",
      "recipient": "Recipiente",
      "responsible": "Responsable",
      "title": "Pedidos"
    },
    "performance": {
      "title": "Construcción"
    },
    "performances": {
      "title": "Construcciones"
    },
    "performances_wip": {
      "performance_item": "Artículo de construcción",
      "title": "Construcción"
    },
    "planning": {
      "item_no": "Artículo No.",
      "service_no": "Servicio No.",
      "title": "Planificación",
      "view": "Vista de planificación"
    },
    "plannings": {
      "title": "Planificación"
    },
    "print_grid": {
      "title": "Imprime la grilla"
    },
    "print_list": {
      "loading": "Generando su documento. Por favor espere un momento.",
      "title": "Lista de impresión"
    },
    "progress": {
      "statuses": {
        "canceled": "Cancelado",
        "done": "Hecho",
        "extra": "Extra",
        "in_progress": "En curso",
        "pending": "Pendiente",
        "revised": "Revisado"
      }
    },
    "progress_histories": {
      "add_new_progress": "Agregar un nuevo progreso",
      "empty_history": "No hay ningún elemento de Historial asociado y no se pueden crear nuevos elementos",
      "hours": "Horas",
      "production_factor": "PF",
      "progress": "Progreso",
      "progress_badge": "Elemento %{number}",
      "progress_history": "Historial de progreso",
      "progress_service_badge": "Servicio %{number}",
      "progress_service_history": "Historial de servicio",
      "progress_service_title": "Historias de servicios",
      "progress_title": "Historias de artículos",
      "quantity": "Cantidad",
      "quantity_empty_history": "No hay ítem de historial y no es posible crear historiales cuando la cantidad es cero",
      "subtitle": "Gestione el progreso diario en función de las actividades planificadas por su equipo."
    },
    "progress_services": {
      "actual_div_hours": "Horas objetivo reales / del equipo",
      "actual_div_quantity": "Cantidad real / objetivo",
      "actual_hours": "Horas reales",
      "actual_quantity": "Cantidad actual",
      "application_price": "Precio de la aplicación",
      "back": "Todas las construcciones",
      "budget_target_hours": "Horas objetivo de presupuesto",
      "canceled": "Cancelado",
      "choose_modal": {
        "package": "Paquete de detalle único",
        "package_description": "Creación de varios servicios basados en un mismo detalle",
        "single": "Detalle único",
        "single_description": "Creación de un solo detalle.",
        "title": "Crear nuevo"
      },
      "contract_service": "Servicio por contrato",
      "contribution": "Contribución",
      "contribution_info": "Valores calculados automáticamente",
      "crew": "equipo",
      "crew_size": "Tamaño del equipo",
      "crews": "equipos",
      "date_filter": {
        "all": "Todos",
        "to": "a",
        "today": "Hoy dia",
        "tomorrow": "Mañana",
        "week": "Semana",
        "yesterday": "El dia de ayer"
      },
      "date_filter_info": "Filtro de fecha",
      "delta_hours": "Horas Delta",
      "delta_qtt": "Cantidad delta",
      "description": "Servicio de construcción",
      "discipline": "Disciplina",
      "end_date": "Fecha final",
      "equipment_price": "Precio del equipo",
      "factor": "Factor",
      "flag": "Bandera",
      "id": "ID",
      "materials_price": "Precio de los materiales",
      "no_crews": "No. de equipos",
      "no_workers": "No. de trabajadores",
      "norm_hours": "Horas Norm",
      "not_planned": "No estaba planeado",
      "other_price": "Otro precio",
      "package_factor": "Factor",
      "people": "gente",
      "person": "persona",
      "pf": "PF",
      "pf_doing_bad": "No lo estás haciendo muy bien",
      "pf_doing_good": "¡Lo estás haciendo genial!",
      "pf_doing_neutral": "No se pudo calcular el pf",
      "planned": "Planificado",
      "planning_chart": {
        "actual": "Real",
        "actual_qty": "Cant. Real",
        "date": "Fecha",
        "forecasted": "Pronosticado",
        "forecasted_qty": "Cant. Pronosticada",
        "target": "Objetivo",
        "target_qty": "Cant. objetivo",
        "title": "Grafico de planificación"
      },
      "planning_timeline": {
        "day": "día",
        "day_advance": "día antes",
        "day_delayed": "día tarde",
        "days": "dias",
        "days_advance": "días antes",
        "days_delayed": "días tarde",
        "end_date": "Fecha final",
        "forecasted": "Pronosticado",
        "on_time": "A tiempo",
        "remaining_days": "Días restantes",
        "start_date": "Fecha de inicio",
        "title": "Cronograma de planificación"
      },
      "progress": "Progreso",
      "progress_services_detail": {
        "side_panel_title": "Detalles del servicio"
      },
      "quantity": "Cantidad",
      "responsible": "Responsable",
      "scoped_quantity": "Cantidad preparada",
      "service": "Servicio",
      "service_package": "Paquete de servicios",
      "service_planning": "Planificación de servicios",
      "service_progress": "Progreso del servicio",
      "service_weight": "Peso de servicio",
      "start_date": "Fecha de inicio",
      "statuses": {
        "canceled": "Cancelado",
        "done": "Terminado",
        "extra": "Extra",
        "in_planning": "No estaba planeado",
        "inspected_flag": "Inspección pendiente",
        "planned": "Planificado"
      },
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Servicios de construcción",
      "title_packageable": "Servicios de construcción por paquete de servicios",
      "total": "Total",
      "total_hours": "Horas totales",
      "unit": "Unidad",
      "worker": "trabajador",
      "workers": "trabajadores",
      "working_hours": "Horas Laborales"
    },
    "progresses": {
      "actual_hours": "Horas reales",
      "cancel": "Cancelar",
      "choose_modal": {
        "single": "Otro elemento de construcción.",
        "single_description": "Creación de un solo elemento de construcción."
      },
      "complete": "Completar",
      "complete_services": "Servicios completos",
      "current_hours": "Horas actuales",
      "current_qty": "Cantidad actual",
      "date_filter": {
        "all": "Todo",
        "current_week": "Semana actual",
        "previous_week": "Semana anterior",
        "today": "Hoy"
      },
      "date_filter_info": "Filtro de fecha",
      "delta_hours": "Δ Horas",
      "delta_qty": "Δ Cantidad",
      "description": "Descripción",
      "edit_work_description": "Editar descripción del trabajo",
      "extra": "Extra",
      "flag": "Bandera",
      "history": "Agregar nuevo progreso",
      "id": "Id",
      "item": "Artículo",
      "label": "Etiqueta",
      "more_details": "ver más",
      "pf": "PF",
      "planned_hours": "Horas planificadas",
      "planned_qty": "Cantidad planificada",
      "preview_unavailable": "No hay vista previa disponible",
      "progress": "Progreso",
      "progress_status_restriction": "El progreso no debe tener el estado Cancelado o Realizado",
      "progresses_detail": {
        "side_panel_title": "Detalles de construcción"
      },
      "remaining_hours": "Horas restantes",
      "request_status_restriction": "La orden de trabajo debe tener estado En planificación o En construcción",
      "revised": "Revisado",
      "success_work_description_edition": "La descripción del trabajo fue editada exitosamente",
      "title": "Construcciones",
      "work_description": "Descripción del trabajo"
    },
    "projects": {
      "workdays": {
        "fri": "Vie",
        "mon": "Lun",
        "sat": "Sáb",
        "sun": "Dom",
        "thu": "Jue",
        "tue": "Mar",
        "wed": "Mié"
      }
    },
    "requests": {
      "actual_hours": "Horas reales",
      "actual_progress": "Progreso actual",
      "all": "Todos",
      "are_you_sure_complete": "¿Está seguro de completar esta orden de trabajo?",
      "are_you_sure_complete_no_history": "¿Está seguro de completar una orden de trabajo sin ningún progreso determinado?",
      "are_you_sure_deletion": "¿Está seguro de que desea eliminar la orden de trabajo?",
      "are_you_sure_revertion": "¿Está seguro de que desea revertir y por eso perder los datos que se ingresaron en este Módulo?",
      "area": "Area",
      "authorization_pending": "Pendiente de autorización",
      "authorize_form": {
        "modal_title": "Autorización",
        "subtitle": "Proporcione información sobre la orden de trabajo para continuar con la aprobación.",
        "title": "Autorizar orden de trabajo"
      },
      "canceled": "Cancelado",
      "clustered": "Agrupados",
      "comments": "Comentarios",
      "confirmation_modal": {
        "cancel_description": "La orden de trabajo será cancelada.",
        "cancel_title": "¿Cancelar orden de trabajo?",
        "complete_description": "Esta orden de trabajo se completará",
        "complete_title": "¿Orden de trabajo completa?",
        "confirm_action": "Proceder a importar preparativos de trabajo",
        "confirm_cancel": "Ahora no",
        "confirm_confirmation": "Si, quiero confirmar",
        "confirm_description": "¿Quieres confirmar la orden de trabajo?",
        "confirm_title": "Confirmación de orden de trabajo",
        "delete_description": "La acción de eliminación no se puede deshacer. ¿Estás seguro?",
        "delete_title": "¿Eliminar orden de trabajo?",
        "reject_description": "Este artículo volverá al estado En preparación",
        "reject_title": "¿Rechazar orden de trabajo?",
        "remove_recycle_description": "Este elemento volverá al estado En alcance y perderá la bandera de Reciclaje.",
        "remove_recycle_title": "¿Reciclaje claro?",
        "revert_description": "La acción de reversión no se puede deshacer. ¿Estás seguro?",
        "revert_title": "¿Revertir orden de trabajo?"
      },
      "confirmed": "Confirmado",
      "create_inspection_modal": {
        "category_subtitle": "Seleccione una opción de la categoría %{category_name}",
        "loading": "Cargando plantillas de inspección...",
        "no_templates": "No existen plantillas de inspección.",
        "plural_title": "Crear inspecciones",
        "standalone_subtitle": "Seleccione una de las siguientes opciones para continuar",
        "title": "Elija la plantilla de inspección"
      },
      "creation_form": {
        "proceed": "Continúe con el paso 2"
      },
      "creation_page": {
        "add_template": "Agregar plantilla",
        "first_step": "Crear orden de trabajo",
        "second_step": "Preparativos para el trabajo de importación (opcional)",
        "third_step": "Aplicar paquetes de servicios (opcional)"
      },
      "description": "Orden de trabajo",
      "discipline": "Disciplina",
      "discipline_check": {
        "check_terms": "Términos de control de disciplina",
        "perform": "Realizar control de disciplina",
        "ready_for_discipline": "Listo para el control de disciplina",
        "ready_for_review": "Orden de trabajo lista para revisión disciplinaria",
        "set_as_ready": "Establecer la orden de trabajo como lista para control disciplinario",
        "title": "Control de disciplina"
      },
      "discipline_check_item": "WO01 – Listo para el control de disciplina",
      "discipline_check_no_comment": "No se agregó ningún comentario",
      "done": "Finalizado",
      "eav_template": "Modelo",
      "eav_template_id": "ID de plantilla",
      "edit_dialog": {
        "reason": "Nombre de la sub solicitud"
      },
      "elevation": "Elevación",
      "errors": {
        "not_found": "No se puede encontrar la orden de trabajo con ID: %{id}"
      },
      "estimates_authorization": "Autorización de estimación requerida",
      "flags": {
        "discipline_check": "WO01 - Verificación de disciplina",
        "discipline_check_done": "WO01 – Control de disciplina: Aprobado",
        "discipline_check_pending": "WO01 – Verificación de disciplina: Pendiente",
        "discipline_check_rejected": "WO01 – Control de disciplina: Rechazado",
        "recycle": "WO03 - Reciclar"
      },
      "forecast_end_date": "Fecha de finalización prevista",
      "id": "DPMSID",
      "in_performance": "En construcción",
      "in_planning": "En planificación",
      "in_scoping": "En la preparación de",
      "information": "Información Adicional",
      "inspection_date": "Fecha de inspección",
      "no_comment": "Sin comentarios",
      "notification": {
        "authorization_success": "La orden de trabajo está autorizada exitosamente.",
        "deleting_sub_request": "Se está eliminando la sub solicitud %{id}. Consulte la página de trabajos para conocer su progreso",
        "discipline_check_approved": "Verificación de disciplina aprobado con éxito",
        "discipline_check_rejected": "Verificación de disciplina rechazado con éxito",
        "discipline_check_success": "La orden de trabajo está lista con éxito para la revisión disciplinaria",
        "missing_order_error": "No hay ninguna orden pendiente asociada con esta orden de trabajo",
        "recycle_success": "La orden de trabajo se recicla exitosamente."
      },
      "on_hold": "En espera",
      "progress": "Progreso",
      "progress_end_date": "Fecha de finalización del progreso",
      "progress_responsible": "Responsable del progreso",
      "progress_responsible_id": "Responsable del progreso",
      "progress_start_date": "Fecha de inicio del progreso",
      "reason": "Razón",
      "recycle_form": {
        "label": "Razón - Disciplina",
        "modal_title": "Reciclar",
        "subtitle": "Proporcione información para marcar esta orden de trabajo para reciclaje",
        "title": "Solicitud de reciclaje"
      },
      "recycling_item": "WO03 - Reciclar al cliente",
      "reference": "Referencia",
      "registered": "Registrado",
      "remove_recycle_modal": {
        "cancel_description": "No, quédate con la bandera.",
        "confirm_description": "Sí, claro",
        "success_notification": "La bandera de Reciclaje fue eliminada en %{reason} - %{discipline}"
      },
      "request_date": "Fecha de la orden de trabajo",
      "request_detail": {
        "edit": "Editar",
        "estimates": "Estimaciones",
        "inspections": "Inspecciones",
        "performance": "Construcción",
        "planning": "Planificación",
        "scopes": "Preparativos para el trabajo",
        "show": "Mostrar",
        "side_panel_title": "Detalles de la solicitud"
      },
      "request_end_date": "Fecha de finalización de la orden de trabajo",
      "request_responsible": "Responsable de Orden de Trabajo",
      "request_responsible_id": "Responsable de Orden de Trabajo",
      "request_start_date": "Fecha de inicio de la orden de trabajo",
      "responsible": "Responsable",
      "schedule": "Calendario de órdenes de trabajo",
      "schedule_form": {
        "date": "Fecha",
        "days": "%{number} días",
        "performance": "Construcción",
        "progress": "Progreso",
        "request": "Orden de trabajo",
        "responsible": "Responsable",
        "schedule": "Programar",
        "scoping": "Trabajo de preparación",
        "subtitle": "Para crear un cronograma para esta orden de trabajo, primero necesitamos información adicional.",
        "timecode": "Código de tiempo",
        "title": "Calendario de órdenes de trabajo"
      },
      "scope_end_date": "Fecha de finalización de la preparación del trabajo",
      "scope_responsible": "Responsable de la preparación del trabajo",
      "scope_responsible_id": "Responsable de la preparación del trabajo",
      "scope_start_date": "Fecha de inicio de la preparación del trabajo",
      "scopes": "Preparativos para el trabajo",
      "service_desired_date": "Fecha deseada",
      "skip_scoping": "Saltar la fase de preparación del trabajo",
      "status": "Estado",
      "statuses": {
        "authorization_pending": "Pendiente",
        "canceled": "Cancelado",
        "clustered": "Agrupados",
        "confirmed": "En la preparación de",
        "deleting": "Borrando",
        "done": "Hecho",
        "in_performance": "En construcción",
        "in_planning": "En construcción",
        "in_scoping": "En la preparación de",
        "on_hold": "En espera",
        "registered": "Creado"
      },
      "sub_request": "Sub solicitud",
      "sub_requests": "Sub solicitudes",
      "subarea": "Subárea",
      "team_target_hours": "Horas objetivo del equipo",
      "title": "Órdenes de trabajo",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - Revisado %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "No, cancela",
      "description": "¿Está seguro de que desea revertir la inspección \"%{id}\"?",
      "ok": "Sí, volver a pendiente.",
      "success_description": "Revertido a pendiente con éxito.",
      "success_title": "La inspección fue revertida",
      "title": "¿Revertir elemento?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Texto alternativo",
        "by_url": "Por URL",
        "height": "Altura (píxeles)",
        "image": "Imagen",
        "title": "Título",
        "upload": "Subir",
        "web_address": "dirección web",
        "width": "Ancho (px)",
        "window_title": "Edición de imagen"
      }
    },
    "scaffolding_part_action": {
      "comment": "Comentario",
      "date": "Fecha",
      "height": "Altura",
      "length": "Longitud",
      "modification_type": {
        "dismantle": "Desmantelar",
        "erect": "Erguido",
        "erectDismantle": "Montar/Desmantelar"
      },
      "singular": "Acción de la pieza de andamio",
      "title": "Acción de la pieza de andamio",
      "total": "Total",
      "width": "Ancho",
      "work_dismantle": "Desmantelar en otra orden de trabajo",
      "work_order": {
        "choose_wo": "Elija orden de trabajo"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "Construido",
        "created_by": "Creado por",
        "dismantle": "Dimensión eliminada",
        "erect": "Dimensión agregada",
        "modify": "Modificado",
        "total": "Total",
        "total_dismantle": "Andamios desmontados"
      },
      "comment": "Comentario",
      "confirmation_modal": {
        "delete_cancel": "No, cancelar",
        "delete_confirmation": "Si, eliminar",
        "delete_description": "¿Está seguro de que desea eliminar la dimensión de %{total} %{dimension}?",
        "delete_title": "¿Eliminar acción?"
      },
      "date": "Fecha",
      "duplicate_title": "acción duplicada",
      "edit_title": "Editar acción",
      "feed": {
        "empty": "No se han creado acciones para esta pieza de andamio"
      },
      "height": "Altura",
      "length": "Longitud",
      "modification_type": {
        "dismantle": "Desmantelar",
        "erect": "Erguido",
        "erectDismantle": "Montar/Desmantelar"
      },
      "singular": "Acción de la pieza de andamio",
      "timestamp_badges": {
        "new": "Nuevo",
        "updated": "Actualizado"
      },
      "title": "Acción de la pieza de andamio",
      "total": "Total",
      "width": "Ancho",
      "work_dismantle": "Desmantelar en otra orden de trabajo",
      "work_order": {
        "choose_wo": "Elija orden de trabajo"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Acción del total %{total}%{dimension} eliminada de la lista",
        "title": "Agregar una dimensión"
      },
      "back": "Todas las piezas de andamios",
      "comment": "Comentario",
      "confirmation_modal": {
        "delete_cancel": "No, cancelar",
        "delete_confirmation": "Si, borrar",
        "delete_description": "¿Quieres eliminar el %{type} con %{total} %{dimension}?",
        "delete_title": "¿Eliminar parte %{type}?"
      },
      "description": "Descripción",
      "dismantle_modal": {
        "cancel": "No, cancelar",
        "confirm": "Si, desmantelar",
        "confirmation_message": "¿Quieres desmontar esta pieza?",
        "date": "Fecha",
        "errors": {
          "date_greater_max": "La fecha de desmontaje debe ser hoy o antes."
        },
        "success_message": "La Parte del Andamio fue desmontada con éxito",
        "title": "Pieza de desmontaje",
        "work_order": "Orden de trabajo"
      },
      "erect_method": "Método",
      "erect_methods": {
        "os": "sobre el mar",
        "standard": "Estándar",
        "tt": "Acceso por cuerda sobre el mar"
      },
      "id": "IDENTIFICACIÓN",
      "initial_date": "Fecha inicial",
      "menu_items": {
        "dimensions": "Agregar una dimensión",
        "dismantle": "Desmantelar",
        "modifications": "Modificar esta parte"
      },
      "operation_dismantle": "Operación desmantelar",
      "operation_erect": "Operación erecta",
      "scaffolding_part_detail": {
        "actions": "Comportamiento",
        "side_panel_title": "Detalles de la pieza de andamio"
      },
      "scaffolding_type_id": "Tipo de andamio",
      "scaffolding_types": {
        "dop": "DOP",
        "hanging": "Andamio suspendido",
        "lifting_rig": "Plataforma de elevación",
        "mobile": "Andamio móvil",
        "patch_boards": "Tablas de distribución",
        "roof_over_roof": "Techo sobre techo",
        "scaffolding_net": "Red de andamio",
        "shelf": "Estante",
        "staircase_tower": "Torre de escalera",
        "standard": "Andamio estándar",
        "stool": "Taburete",
        "support": "Soporte",
        "system_covering": "Cubierta modular",
        "tarpaulin": "Lona",
        "temporary_railing": "Barandilla temporal",
        "tent_frame": "Estructura de carpa"
      },
      "singular": "Pieza de andamio",
      "title": "Pieza de andamio",
      "total": "Total",
      "type_filter": "Tipo de filtro",
      "weight": "Peso"
    },
    "scaffoldings": {
      "area_id": "Área",
      "area_noise_id": "Ruido del área",
      "area_noises": {
        "db_100_105": "100-105 dB - Tiempo de residencia de 2 horas",
        "db_105_110": "105-110dB - Tiempo de residencia de 1/2 hora",
        "db_85_90": "85-90 dB - Tiempo de residencia de 12 horas",
        "db_90_100": "90-100 dB - Tiempo de residencia de 6 horas",
        "db_less_85": "< 85dB - Sin restricciones"
      },
      "back": "Todos los andamios",
      "buttons_form": {
        "create_part": "Crear una pieza"
      },
      "client": "Cliente",
      "comment": "Comentario",
      "confirmation_modal": {
        "delete_cancel": "No, cancelar",
        "delete_confirmation": "Sí, eliminar",
        "delete_description": "¿Quieres eliminar Scaffolding TAG %{tag}?",
        "delete_title": "Eliminar andamiaje",
        "dismantle_cancel": "Ahora no",
        "dismantle_confirmation": "Sí, quiero desmontar.",
        "dismantle_description": "¿Quieres desmontar el andamio TAG %{tag}?",
        "dismantle_title": "Desmontaje de andamios",
        "duplicate_with_parts_cancel": "Ahora no",
        "duplicate_with_parts_confirmation": "Sí, quiero duplicar con las partes.",
        "duplicate_with_parts_description": "¿Quieres duplicar el Andamiaje de TAG %{tag} con sus partes?",
        "duplicate_with_parts_title": "Andamio duplicado con piezas",
        "ready_to_dismantle_cancel": "Ahora no",
        "ready_to_dismantle_confirmation": "Sí, quiero cambiar",
        "ready_to_dismantle_description": "¿Quieres cambiar el estado de TAG %{tag} a listo para desmontar?",
        "ready_to_dismantle_title": "Andamio listo para desmontar",
        "revert_cancel": "Ahora no",
        "revert_confirmation": "Sí, quiero cambiar",
        "revert_description": "¿Quieres cambiar el estado de TAG %{tag} a erigido?",
        "revert_title": "Revertir el estado del andamio a erigido"
      },
      "contact_person": "Persona de contacto",
      "description": "Descripción",
      "description_of_deviation": "Descripción de la desviación",
      "deviation_improved": "Desviación Fecha de mejora",
      "dismantle_compensation_format": "Desmantelar el formato de compensación",
      "dismantle_date": "Fecha de desmantelamiento",
      "dismantle_modal": {
        "cancel": "No, cancelar",
        "confirm": "Si, desmontar",
        "confirmation_message": "¿Quieres desmontar este andamio?",
        "date": "Fecha",
        "errors": {
          "date_greater_max": "La fecha de desmontaje debe ser hoy o antes."
        },
        "success_message": "El Andamio fue desmantelado con éxito",
        "title": "Desmontaje de andamios",
        "work_order": "Orden de trabajo"
      },
      "erect_compensation_format": "Formato de compensación erecto",
      "erect_date": "Fecha erecta",
      "erect_with_deviation": "Erguido con desviación",
      "id": "IDENTIFICACIÓN",
      "inspection": {
        "delayed": "Demorado",
        "missing_initial": "Desaparecido"
      },
      "inspection_control": "Inspección",
      "installation": "Instalación",
      "load_class_id": "Clase de carga",
      "load_classes": {
        "kg_150": "2-150kg/m2",
        "kg_200": "3-200kg/m2",
        "kg_300": "4-300kg/m2",
        "kg_450": "5 - 450kg/m2",
        "kg_600": "6 - 600kg/m2",
        "kg_75": "1-75kg/m2",
        "not_applicable": "0 - No aplicable"
      },
      "main_work_order_dismantle_id": "Orden de Trabajo Desmantelar",
      "main_work_order_erect_id": "Orden de trabajo montada",
      "missing_initial_control": "Control inicial",
      "missing_request": "Orden de trabajo faltante",
      "number_of_tags": "Número de etiquetas",
      "operation_dismantle": "Operación Desmantelar",
      "operation_erect": "Operación erguida",
      "pictures": "Fotos)",
      "project_number": "Referencia del proyecto",
      "qr_code": "Código QR",
      "reason": "Motivo de la orden de trabajo",
      "scaffolding_detail": {
        "side_panel_title": "Detalles de andamio"
      },
      "scaffolding_status_id": "Estado",
      "sections": {
        "details": "Detalles",
        "deviations": "Desviaciones",
        "general": "Información general",
        "pictures": "Fotos)"
      },
      "singular": "Andamio",
      "statuses": {
        "created": "Creado",
        "dismantled": "Desmantelado",
        "erected": "Erigido",
        "ready_to_dismantle": "Listo para desmontar"
      },
      "subarea_id": "Subárea",
      "tag": "ETIQUETA",
      "tag_number": "Número de etiqueta",
      "tag_removed": "Etiqueta eliminada",
      "telephone_radio": "Teléfono - Radio",
      "title": "Registro de andamios",
      "total_m": "metros totales",
      "total_m2": "m2 totales",
      "total_m3": "m3 totales",
      "total_weight_message": "Peso total del andamio montado",
      "user_of_scaffolding": "Usuario de andamios",
      "weight": "Peso",
      "wind_exposed": "Viento expuesto"
    },
    "scopes": {
      "add": "Agregar elemento de preparación del trabajo",
      "choose_modal": {
        "single": "Otro elemento de preparación del trabajo.",
        "single_description": "Creación de un solo elemento de preparación del trabajo."
      },
      "clusters": {
        "title": "Grupos de preparación para el trabajo"
      },
      "count": "Contar",
      "crew_size": "Tamaño de la tripulación",
      "crews": "Número de tripulaciones",
      "date": "Fecha",
      "description": "Trabajo de preparación",
      "duplicate_services": {
        "other_request": "Otra orden de trabajo",
        "other_request_description": "Duplicar un elemento de preparación de trabajo en otra orden de trabajo",
        "same_request": "Misma orden de trabajo",
        "same_request_description": "Seleccione el elemento de preparación del trabajo",
        "success_notification": "Los servicios de Preparación del Trabajo se duplicaron con éxito",
        "title": "Servicios de Duplicación"
      },
      "edit_work_description": "Editar descripción del trabajo",
      "hours": "Horas (h)",
      "id": "ID",
      "item": "Articulo",
      "label": "Etiqueta",
      "label_assigner": {
        "success": "¡Sus etiquetas de preparación para el trabajo se han asignado correctamente! Puedes continuar con tu plan ahora."
      },
      "label_error": "No se pudieron recuperar las etiquetas",
      "labels_info": "Etiquetas de preparación del trabajo",
      "more_details": "ver más",
      "popup_label": {
        "create_label": "Crear etiqueta",
        "labels": "Etiquetas:",
        "name_label": "Nombre de etiqueta",
        "new_label": "Nueva etiqueta"
      },
      "preview_unavailable": "No hay vista previa disponible",
      "quantity": "Cantidad",
      "remaining_hours": "Horas Restantes",
      "remaining_quantity": "Cantidad Restante",
      "request_status_restriction": "La orden de trabajo debe tener el estado Confirmado o En preparación",
      "scopes_detail": {
        "side_panel_title": "Detalles de preparación del trabajo"
      },
      "success_work_description_edition": "La descripción del trabajo fue editada exitosamente.",
      "title": "Preparativos para el trabajo",
      "unit": "Unidad",
      "work_description": "Descripción del trabajo",
      "working_hours": "Horas Laborales"
    },
    "search": {
      "loading": "cargando...",
      "no_result_found": "No se han encontrado resultados",
      "placeholder": "Filtrar..."
    },
    "service_description": "Descripción del servicio",
    "settings": {
      "title": "Configuraciones"
    },
    "settings_shortcuts": "Atajos de configuración",
    "shortcuts": {
      "access_control": "Control de acceso",
      "areas": "Áreas y subáreas",
      "areas_subareas": "Áreas y subáreas",
      "assets": "Bienes",
      "background_jobs": "Trabajos en segundo plano",
      "branches": "Ramas",
      "cities": "Ciudades",
      "client_sites": "Sitios de clientes",
      "clients": "Clientela",
      "clients_info": "Información de clientes",
      "columns_renaming": "Cambio de nombre de columnas",
      "companies": "Empresas",
      "contract_services": "Servicios por contrato",
      "contracts": "Contratos",
      "countries": "Países",
      "customer_sites": "Sitios de clientes",
      "customers": "Clientela",
      "disciplines": "Disciplinas",
      "employees": "Personal",
      "equipaments": "Equipos",
      "equipments": "Equipos",
      "formulas": "Fórmulas",
      "location": "Ubicación",
      "materials": "Materiales",
      "matrices": "Matrices",
      "measures": "Medidas",
      "mobile_details": "Detalles móviles",
      "position_names": "Nombres de puestos",
      "preferences": "Preferencias",
      "project_assets": "Bienes",
      "project_contracts": "Contratos",
      "projects": "Proyectos y subproyectos",
      "projects_contracts": "Proyectos y Contratos",
      "projects_subprojects": "Proyectos y subproyectos",
      "provinces": "Estados / Provincias",
      "regions": "Regiones",
      "routes": "Rutas",
      "specialities": "Especialidades",
      "staff": "Personal",
      "states_provinces": "Estados / Provincias",
      "templates": "Plantillas",
      "translations": "Cambio de nombre de columna",
      "users": "Usuarios",
      "visible_fields": "Detalles móviles",
      "work_positions": "Nombres de puestos"
    },
    "side_menu": {
      "collapse": "Comprimir",
      "contracts": "Contratos",
      "datasheets": "Ficha de datos",
      "expand": "Expandir",
      "report": "Informes",
      "search": "Buscar",
      "settings": "Configuraciones",
      "template": {
        "asset": "Bienes",
        "contract_service": "Servicios por contrato",
        "datasheet": "Hojas de datos",
        "estimate_service": "Servicios de estimación",
        "inspection": "Inspecciones",
        "performance": "Construcción",
        "report": "Informes",
        "request": "Órdenes de trabajo",
        "scoping": "Trabajo de preparación",
        "valuation": "Valuación"
      },
      "templates": "Plantillas"
    },
    "side_panel": {
      "close": "Cerrar panel lateral",
      "collapse": "Contraer panel lateral",
      "expand": "Expandir panel lateral",
      "tabs": {
        "category_filter": "Filtro de categorías",
        "gallery": "Galería",
        "info": "Información",
        "inspections": "Inspecciones",
        "no_categories": "No hay categorías disponibles",
        "no_templates": "No hay plantillas disponibles",
        "preview_details": "Detalles de vista previa",
        "progress": "Progreso",
        "template_filter": "Filtro de plantilla"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "Todas las solicitudes secundarias",
        "back": "Todos los preparativos del trabajo",
        "title": "sub solicitudes"
      },
      "left_panel": {
        "add_scoping": "Agregar elemento de preparación del trabajo",
        "allocation": "Asignación",
        "cards_header_title": "Preparativos para el trabajo",
        "summary": "Resumen",
        "total_allocation": "Asignación total"
      },
      "scope_card": {
        "scope": "Trabajo de preparación"
      }
    },
    "tabs": {
      "error": "Imposible cargar pestañas"
    },
    "templates": {
      "asset": "Bienes",
      "contract_service": "Servicios por contrato",
      "datasheet": "Hojas de datos",
      "estimate_service": "Servicios de estimación",
      "inspection": "Inspecciones",
      "no_template": "Necesitas crear una plantilla",
      "progress": "Construcción",
      "progress_service": "Valuación",
      "report": "informes",
      "request": "Órdenes de trabajo",
      "scoping": "Trabajo de preparación",
      "title": "Plantillas para %{type}"
    },
    "tickets": {
      "icon_alt": "Un icono de boleto",
      "title": "Entradas"
    },
    "timeline": {
      "created": "Creado",
      "day_ago_singular": "Hace %{daysAgo} días",
      "day_until_singular": "Dentro de %{daysUntil} días",
      "days_ago": "Hace %{daysAgo} días",
      "days_until": "Dentro de %{daysUntil} días",
      "discipline_check_done": "Control de disciplina: hecho",
      "discipline_check_pending": "Control de disciplina: pendiente",
      "discipline_check_rejected": "Control de disciplina: rechazado",
      "estimate_authorized": "Estimación: Autorizada",
      "estimate_done": "Estimación: Completada",
      "estimate_on_hold": "Estimación: En espera",
      "estimate_pending": "Estimación: Pendiente",
      "estimate_pending_authorization": "Estimación: Autorización pendiente",
      "estimate_progress": "Estimación: En progreso",
      "performance": "Construcción",
      "progress": "Progreso",
      "recycle_done": "Reciclar: Listo",
      "recycle_recycling": "Reciclar: Reciclaje",
      "recycling": "Reciclaje",
      "rejected": "Rechazado",
      "request": "Orden de trabajo",
      "responsible": "Responsable",
      "schedule": "Cronograma",
      "scoping": "Trabajo de preparación",
      "title": "Cronograma de la orden de trabajo",
      "today": "Hoy"
    },
    "top_menu": {
      "authorization": "Autorizaciones",
      "datasheets": "Hojas de datos",
      "eav_templates": "Plantillas",
      "estimates": "Estimaciones",
      "inspections": "Inspecciones",
      "no_datasheets": "No se encontraron hojas de datos",
      "no_reports": "No se encontraron informes",
      "performances": "Construcción",
      "plannings": "Planificación",
      "reports": "Informes",
      "requests": "Órdenes de trabajo",
      "scaffoldings": "Registro de andamios",
      "scopes": "Trabajo de preparación",
      "workflow": "Flujo de trabajo"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Acerca de la versión",
      "clean_preferences": "Limpiar preferencias",
      "logout": "Cerrar Sesión",
      "notification": {
        "clean_preferences": "Preferencias limpiadas",
        "error": "Error al intentar limpiar preferencias"
      },
      "preferences": "Preferencias de cuadrícula",
      "profile": "Perfil"
    },
    "users": {
      "edit_title": "Actualización del perfil"
    },
    "work_order_attachments": {
      "subtitle": "Ver los documentos de la Orden de Trabajo relacionados",
      "title": "Adjuntos de órdenes de trabajo"
    }
  },
  "fr": {
    "actions": {
      "alternate_view": "Autre vue",
      "approve": "Approuver",
      "back_to": "Retour à %{page}",
      "bulk_editing": "Édition en bloc",
      "cancel": "Annuler",
      "choose_entity": "Choisissez %{entity}",
      "clear": "Clair",
      "clear_all_filters": "Effacer tous les filtres",
      "confirm": "Confirmer",
      "create": "Créer",
      "create_inspection": "Créer une inspection",
      "delete": "Supprimer",
      "discard": "Jeter",
      "dismantle": "Démanteler",
      "done": "Achevée",
      "duplicate": "Dupliquer",
      "duplicate_services": "Services de duplication",
      "duplicate_with_parts": "Dupliquer avec des pièces",
      "edit": "Éditer",
      "export": "Exporter vers .xls",
      "export_template": "Télécharger le modèle .xls",
      "filter_pinned_work_orders": "Filtrer les ordres de travail épinglés",
      "flag_filter": "Filtre de drapeau",
      "generate_work_package": "Générer un lot de travaux",
      "import": "Importer depuis .xls",
      "import_file": "Importer le fichier",
      "loading": "Chargement",
      "more": "Plus",
      "new": "Créer un nouveau",
      "next": "Prochain",
      "no": "Non",
      "past": {
        "cancel": "annulé",
        "complete": "terminé",
        "confirm": "confirmé",
        "delete": "supprimé",
        "destroy": "détruit",
        "dismantle": "démantelé",
        "duplicate_with_parts": "Dupliqué avec ses pièces",
        "ready_to_dismantle": "prêt à démonter",
        "reject": "rejeté",
        "revert": "retourné",
        "update": "actualisé"
      },
      "perform_discipline_check": "Effectuer un contrôle disciplinaire",
      "pin_request": "Filtrer par demandes sélectionnées",
      "prev": "Précédente",
      "ready": "Prêt",
      "ready_for_discipline": "Prêt pour le contrôle disciplinaire",
      "ready_to_dismantle": "Prêt à démonter",
      "recycle": "Recycler",
      "reject": "Rejeter",
      "remove_recycle": "Supprimer Recycler",
      "reset": "Réinitialiser",
      "retrieving": "Récupération...",
      "revert": "Revenir",
      "revert_to_erected": "Revenir à Érigé",
      "save": "Sauver",
      "save_button_keyboard": "Enregistrer (Ctrl + Entrée)",
      "save_exit": "Sauvegarder et quitter",
      "save_new": "Ajouter un autre",
      "saving": "Économie...",
      "schedule": "Programme",
      "show_all_work_orders": "Afficher tous les ordres de travail",
      "skip": "Sauter",
      "status_filter": "Filtre d’état",
      "unpin_request": "Supprimer le filtre par demandes",
      "unpin_selected_request": "Supprimer le filtre de %{numSelectedRequests} demandes sélectionnées",
      "unsaved_changes": "Lignes non enregistrées",
      "upload_file": "Téléverser un fichier",
      "upload_picture": "Charger une photo",
      "yes": "Oui"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Il n’est pas possible de dupliquer un bon de travail groupé",
      "cant_duplicate_progress": "Impossible de dupliquer les services en raison des erreurs suivantes ...",
      "cant_duplicate_request": "Impossible de dupliquer les Préparations, Devis et Services en raison des erreurs suivantes...",
      "cant_duplicate_scope": "Impossible de dupliquer les services en raison des erreurs suivantes ...",
      "only_estimate_service_copy": "Seul l’élément du service d’estimation tente d’être copié.",
      "only_progress_copy": "Seul le Progrès essaie d’être copié.",
      "only_request_copy": "Seul l’ordre de travail tente d’être copié.",
      "only_scope_copy": "Seul l’élément de préparation du travail tente d’être copié."
    },
    "cancel_modal": {
      "cancel": "Pas maintenant",
      "description": "Voulez-vous vraiment annuler le %{model} \"%{id}\"?",
      "ok": "Oui, annuler",
      "title": "Annuler l’article?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Non, annuler",
        "description": "Voulez-vous vraiment effacer tous les enregistrements %{model}?",
        "ok": "Oui, tout effacer",
        "title": "Effacer %{model}?"
      },
      "success_message": "%{model} effacé avec succès!",
      "title": "Effacer les enregistrements"
    },
    "clear_template_preferences": {
      "cancel": "Non, annuler",
      "description": "Voulez-vous vraiment effacer toutes les préférences de grille pour ce modèle ?",
      "ok": "Oui, effacer les préférences de grille",
      "title": "Effacer les préférences de grille"
    },
    "collections": {
      "clusters": "Clusters",
      "create_by_package": "Créer par package",
      "duplicate": "Dupliquer",
      "edit": "Éditer",
      "estimate": "Estimation",
      "new": "Nouveau",
      "schedule": "Programme",
      "sub_requests": "Sous-demandes",
      "view": "Vue"
    },
    "complete_modal": {
      "cancel": "Pas maintenant",
      "description": "Voulez-vous vraiment marquer %{model} comme terminé ?",
      "ok": "Oui, marquer comme terminé",
      "title": "Marquer comme terminé ?"
    },
    "complete_progress_modal": {
      "cancel": "Annuler",
      "complete": "Compléter",
      "description": "Êtes-vous sûr de vouloir terminer cet élément de construction ? Cette action ne peut pas être annulée.",
      "title": "Confirmer l’achèvement des éléments de construction"
    },
    "confirmation_modal": {
      "cancel": "Annuler",
      "description": "Cette action ne peut pas être annulée!",
      "ok": "D’accord",
      "title": "Êtes-vous sûr?"
    },
    "contract_services": {
      "title": "Services contractuels"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Détails de la fiche technique"
      },
      "description": "Feuilles de données",
      "id": "ID",
      "item": "Objet",
      "title": "Feuilles de données"
    },
    "delete_attachment_modal": {
      "cancel": "Non, garde la pièce jointe",
      "delete_successful": "Pièce jointe supprimée avec succès",
      "description": "Êtes-vous sûr de vouloir supprimer cette pièce jointe ?",
      "ok": "Oui, supprimez la pièce jointe",
      "title": "Supprimer la pièce jointe ?"
    },
    "delete_modal": {
      "cancel": "Non, annuler",
      "description": "Voulez-vous vraiment supprimer le %{model} \"%{id}\"?",
      "ok": "Oui, supprimer",
      "title": "Effacer l’article?"
    },
    "delete_picture_modal": {
      "cancel": "Non, garde la photo",
      "delete_successful": "Photo supprimée avec succès",
      "description": "Etes-vous sûr de vouloir supprimer cette photo ?",
      "ok": "Oui, supprimer l’image",
      "title": "Supprimer l’image"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Acceptation"
      },
      "column_comment": {
        "add_comment": "Cliquez pour ajouter un commentaire",
        "cancel": "Annuler",
        "comment": "Commentaire",
        "subtitle": "Ajouter/modifier un commentaire"
      },
      "columns_batch_assigner": {
        "actions": "Actions",
        "add_action": "Ajouter une action",
        "add_to_column": "Ajouter à la colonne",
        "add_value_to": "Ajouter %{value} à",
        "column": "Colonne",
        "done": "Enregistrer l’action",
        "duplicate_services": "Dupliquer les Services",
        "header": "Modification par lots",
        "multiple_success": "Les éléments %{numberOfItems} ont été mis à jour.",
        "new_value": "Nouvelle valeur",
        "single_success": "1 élément a été mis à jour.",
        "subtitle": "Attribuer des valeurs aux %{numberOfColumns} colonnes sélectionnées",
        "title": "Créer des actions",
        "update": "Mettre à jour"
      },
      "configure_print_grid_file": {
        "error_max_columns": "Vous ne pouvez sélectionner que jusqu’à %{maxColumns} colonnes.",
        "generate": "Générer un fichier",
        "header": "Grille d’impression",
        "paper_size_title": "Taille de papier",
        "pdf_file": {
          "information": "Information",
          "pictures": "Photos",
          "title": "Grille %{modelName} - %{numItems} éléments - Page %{pageNum} sur %{totalPages}"
        },
        "select_columns_title": "Colonnes à imprimer",
        "selected_columns": "Vous avez sélectionné %{numSelectedColumns} sur %{maxColumns}.",
        "subtitle": "Sélectionnez jusqu’à %{maxColumns} colonnes à imprimer",
        "title": "Configurer le fichier à imprimer"
      },
      "configure_print_list_file": {
        "big_picture_title": "Imprimer des images en utilisant la largeur de la page ?",
        "font_size_title": "Sélectionnez la taille de la police",
        "header": "Imprimer la liste",
        "hide_columns_title": "Inclure les colonnes cachées",
        "large": "Grand",
        "medium": "Moyen",
        "page_break_title": "Imprimer 1 élément par page ?",
        "small": "Petit"
      },
      "configure_work_package_file": {
        "attached_documents": "Documents attachés",
        "attachments": "Pièces jointes",
        "estimates": "Estimations",
        "estimates_price": "Estimations Prix",
        "generate": "Générer",
        "header": "Générer un lot de travaux",
        "hse_r": "Évaluation HSE-R",
        "inspection_page_breaker": "Imprimer une inspection par page",
        "inspection_pictures": "Inclure des photos et des signatures",
        "inspections": "Toutes les inspections connexes",
        "subtitle": "Sélectionnez les sections que vous souhaitez imprimer",
        "title": "Configurer le fichier du package de travail",
        "work_description": "Description du travail"
      },
      "duplicate_services": {
        "title": "Dupliquer les Services"
      }
    },
    "discard_modal": {
      "cancel": "Continue de modifier",
      "description": "Voulez-vous vraiment annuler vos modifications?",
      "ok": "Oui, jeter",
      "title": "Annuler les modifications"
    },
    "eav_templates": {
      "title": "Modèles"
    },
    "empty_state": {
      "columns": "Colonnes",
      "empty": "Pas de %{model}",
      "no_attachments": "Vous n’avez encore téléchargé aucune pièce jointe",
      "no_columns": "Il n’y a pas de colonne d’image disponible",
      "no_discipline_template": "Aucun modèle avec la discipline d’ordre de travail filtrée n’a été trouvé.",
      "no_estimate_service_template": "Aucun modèle de service d’estimation pour le modèle de préparation du travail sélectionné n’a été trouvé",
      "no_pictures": "Vous n’avez pas encore téléchargé de photos dans cette colonne",
      "no_unselect": "Il n’est pas possible de désélectionner la demande épinglée sur cette page. Vous ne pouvez le faire que sur la page Ordres de travail ou en vous déconnectant.",
      "not_created": "Vous n’avez pas encore créé de %{model}",
      "pictures": "des photos"
    },
    "environment": {
      "project": {
        "select_project": "Sélectionnez un projet ..."
      },
      "select_project_subproject": "Sélectionnez un projet et un sous-projet",
      "subproject": {
        "select_subproject": "Sélectionnez un sous-projet ..."
      }
    },
    "estimate": {
      "accept": "Accepter l’estimation",
      "all_estimates_approved": "Toutes les estimations ont été approuvées avec succès.",
      "application": "Application",
      "back": "Tous les ordres de travail",
      "budget_hours": "Budget",
      "confirmation": {
        "cancel": "Non, annuler",
        "confirm": "Oui, confirmez",
        "description": "Êtes-vous sûr de vouloir autoriser cette estimation pour ce bon de travail ?",
        "title": "Confirmer la commande"
      },
      "description": "Vous disposez des services %{number} pour le paiement. Veuillez vérifier les détails ci-dessous.",
      "equipment": "Équipement",
      "estimate_details": "Détails de l’estimation",
      "estimate_success": "Estimation approuvée avec succès",
      "info_message": "Ce service d’estimation a déjà été approuvé. Il est disponible en lecture seule.",
      "material": "Matériel",
      "no_estimate_message": "Aucune estimation n’est associée à cet ordre de travail.",
      "norm_hours": "Heures normales",
      "others": "Autres",
      "pdf_file": {
        "title": "Détails de l’estimation - Page %{pageNum} de %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Discipline",
        "functional_location": "Emplacement fonctionnel",
        "jobcard_status": "Statut de la fiche de travail",
        "ke_priority": "Priorité KE",
        "main_area_location": "Zone principale et emplacement",
        "main_work_center": "Centre de travail principal",
        "maintenance_plant": "Usine d’entretien",
        "not_defined": "Pas encore défini",
        "order_finish": "Fin de la commande",
        "order_start": "Début de la commande",
        "priority_sequence": "Séquence prioritaire",
        "project": "Projet",
        "project_type": "Type de projet",
        "report": "Rapport",
        "report_date": "Date du rapport",
        "responsible_engineer": "Ingénieur responsable",
        "status_completed": "Complété",
        "status_issued_for_approval": "Délivré pour approbation",
        "status_issued_for_construction": "Délivré pour la construction",
        "subproject": "Sous-projet",
        "system": "Système",
        "template": "Modèle",
        "title": "Estimation de l’ordre de travail",
        "work_order": "Demande de service"
      },
      "pdf_summary": {
        "title": "Résumé du budget des dépenses"
      },
      "save_draft": "Enregistrer le brouillon",
      "service_details": "Détails du service",
      "some_estimates_not_approved": "Certaines estimations n’ont pas pu être approuvées. Vérifiez les journaux d’erreurs.",
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Service de caisse",
      "tooltip": {
        "application": "Application",
        "equipment": "Équipement",
        "material": "Matériel",
        "others": "Autres"
      },
      "total_approved": "Total approuvé",
      "total_pending": "Encours total",
      "total_request": "Demande totale",
      "use_construction_prices": "Inclure les prix de construction ?"
    },
    "estimate_services": {
      "application_price": "Prix de l’application",
      "automatically_prices": "Prix calculés automatiquement",
      "back": "Tous les préparatifs de travail",
      "budget_target_hours": "Heures cibles budgétaires",
      "choose_modal": {
        "package": "Forfait détail unique",
        "package_description": "Création de plusieurs services basés sur le même détail",
        "single": "Détail unique",
        "single_description": "Création d’un seul détail",
        "title": "Créer un nouveau"
      },
      "contract_service": "Service contractuel",
      "crew_size": "Taille de l’équipage",
      "crews": "Nombre d’équipages",
      "description": "La description",
      "description_label": "La description",
      "discipline": "La discipline",
      "equipment_price": "Prix de l’équipement",
      "estimate": "Service de caisse",
      "estimate_services_detail": {
        "side_panel_title": "Détails du service"
      },
      "factor": "Facteur",
      "flag": "Drapeau",
      "id": "ID",
      "item_no": "Numéro d’article",
      "materials_price": "Prix des matériaux",
      "norm_hours": "Heures normales",
      "other_price": "Autre prix",
      "prices": "Des prix",
      "print": "Imprimer",
      "quantity": "Quantité",
      "service": "Un service",
      "service_no": "Numéro de service",
      "service_package": "Forfait de service",
      "statuses": {
        "inspected_flag": "Inspection en cours"
      },
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Services de cadrage",
      "title_packageable": "Estimer les services par package de services",
      "total": "Total",
      "unit": "Unité",
      "weight": "Poids",
      "working_hours": "Heures d’ouverture"
    },
    "fields": {
      "created_at": "Créé",
      "image_not_found": "Image non trouvée",
      "na": "N/A",
      "signature": "Signature.jpg",
      "updated_at": "Mis à jour"
    },
    "filter_steps_modal": {
      "filter_result": "Résultat du filtre",
      "result_subtitle": "Résumé de vos sélections",
      "step": "Étape",
      "subtitle": "Veuillez sélectionner %{title}"
    },
    "filtering": {
      "between": " c’est entre",
      "contains": " qui contient ",
      "eq": " qui est égal à ",
      "invalid_date": "Pour filtrer correctement, la date de début et la date de fin doivent être renseignées",
      "invalid_value": "valeur invalide",
      "neq": " qui n’est pas égal à "
    },
    "forgot_password": {
      "back_to_login": "Retour connexion",
      "button": "Envoyez-moi les instructions de réinitialisation du mot de passe",
      "feedback": "Un message contenant des instructions pour réinitialiser votre mot de passe a été envoyé à l’adresse e-mail fournie.",
      "placeholder": "Votre email",
      "subtitle": "Veuillez saisir votre adresse e-mail pour recevoir les instructions de réinitialisation du mot de passe.",
      "title": "Mot de passe oublié?"
    },
    "form": {
      "back": "Tout %{entity}",
      "cancel": "Annuler",
      "create_entity": "Créer %{entity}",
      "create_error": "%{entity} n’a pas pu être créé",
      "create_success": "%{entity} a été créé avec succès",
      "duplicate_entity": "Dupliquer %{entity}",
      "duplicate_success": "Le %{entity} et ses dépendances ont été dupliqués avec succès!",
      "general_information": "informations générales",
      "hide": "Cacher",
      "inputs": {
        "attachment": {
          "add_error": "Erreur lors de l’ajout du fichier",
          "add_evidence": "Ajouter la preuve de l’autorisation du client",
          "allowed_extensions": "Les extensions autorisées sont",
          "drag_drop": "Ajouter des fichiers par glisser-déposer",
          "invalid_extension": "Type de fichier non autorisé.",
          "max_size": "Le fichier doit être inférieur à %{max_size}",
          "maximum_num_uploads_error": "Vous ne pouvez charger que %{max_num} fichiers par colonne flexible.",
          "one_file": "Télécharger un fichier à la fois",
          "or_drop_file_here": "ou déposez le fichier ici",
          "remove_error": "Échec de la suppression",
          "remove_success": "Supprimé avec succès",
          "select_files": "Sélectionnez les fichiers",
          "submit": "Soumettez votre pièce jointe",
          "upload_error": "Échec du téléchargement",
          "upload_success": "Importé avec succès"
        },
        "contract_service": {
          "add": "Ajouter un service contractuel",
          "subtitle": "Service contractuel"
        },
        "datasheet_filter": {
          "add_filter": "Ajouter un filtre",
          "title": "Filtre de feuille de données"
        },
        "date_range": {
          "between": "%{start} jusqu’à %{end}",
          "end": "Date de fin",
          "end_simplified": "Fin",
          "start": "Date de début",
          "start_simplified": "Début"
        },
        "drop_formula": {
          "formula_select": "Veuillez sélectionner une formule ..."
        },
        "errors": {
          "date_greater_than_max": "La date doit être au plus tard le %{max}",
          "date_less_than_min": "La date doit être le ou après %{min}",
          "drop_formula_result": "%{field} résultat de la formule calculée",
          "mandatory": "%{field} est requis.",
          "not_found": "'%{value}' n’a pas été trouvé dans la référence. Sélectionnez-en un nouveau."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "ou glisser-déposer",
              "select_file": "Cliquez pour télécharger"
            },
            "note": {
              "extensions": "Le fichier doit être au format %{extensions}",
              "max_size": "Taille maximale du fichier %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Image"
          },
          "upload": {
            "error": {
              "invalid_picture": "Type/taille invalide",
              "multiple_fails": "Tous les fichiers joints ont un type/une taille non valide",
              "multiple_files": "Certaines images jointes ont un type/une taille invalide, téléchargez celles qui sont valides",
              "title": "Téléchargement échoué"
            },
            "success": {
              "multiple_files": "Plusieurs fichiers ont été téléchargés avec succès",
              "title": "Téléchargement réussi"
            }
          }
        },
        "formula": {
          "description": "Il s’agit d’une colonne de formules, le résultat est automatiquement actualisé",
          "icon": "cliquez pour voir l’équation",
          "result": "Résultat"
        },
        "gps": {
          "save_location": "Enregistrer la position",
          "search": "Rechercher un lieu ...",
          "without_location": "Sans emplacement"
        },
        "multiple_entries": {
          "add": "Ajouter un autre",
          "empty_entry": {
            "scaffolding_type_id": "Sélectionnez un type d’échafaudage pour ajouter des actions"
          },
          "empty_inputs": "Les entrées de %{input} sont requises dans %{model}",
          "required": "Le champ \"%{field}\" est obligatoire et doit être rempli"
        },
        "numeric": {
          "between": "La valeur doit être comprise entre %{min} et %{max}",
          "greater": "La valeur doit être supérieure à %{min}",
          "lower": "La valeur doit être inférieure à %{max}"
        },
        "picture": {
          "comment_placeholder": "Écrivez un commentaire (facultatif)",
          "edition_only": "Uniquement disponible lors de l’édition",
          "no_image_on_clipboard": "Aucune donnée d’image n’a été trouvée dans votre presse-papiers. Veuillez d’abord copier une image.",
          "remove_error": "Échec de la suppression",
          "remove_success": "Supprimé avec succès",
          "upload_error": "Échec du téléchargement",
          "upload_success": "Téléchargé avec succès"
        },
        "placeholder": "Entrez le %{field} ici",
        "value_selector": {
          "apply": "Appliquer",
          "done": "Terminé",
          "search": "Recherchez quelque chose ici ...",
          "waiting": "... Attendre"
        }
      },
      "items": "Articles",
      "loading": "Chargement...",
      "loading_calculations": "Calcul des visibilités, des colonnes requises et des valeurs par défaut",
      "loading_contract": "Chargement des informations sur le contrat...",
      "schedule_entity": "Horaire %{entity}",
      "schedule_success": "Votre demande de planification de %{entity} s’est terminée avec succès",
      "show": "Montrer",
      "subtitle": "Veuillez fournir quelques informations de base sur %{entity}.",
      "subtitle_error": "Le formulaire n’a pas pu être chargé, vérifiez vos données %{entity} ou modèle.",
      "update_entity": "Mettre à jour %{entity}",
      "update_error": "%{entity} n’a pas pu être mis à jour",
      "update_success": "%{entity} a été mis à jour avec succès"
    },
    "formula_modal": {
      "back_button_text": "Retour au formulaire",
      "calculate": "Calculer",
      "cancel": "Annuler",
      "mandatory": "Vous devez remplir les valeurs des champs suivants: %{fields}",
      "ok": "Enregistrer la formule"
    },
    "grid": {
      "all": "Tout",
      "clear_filters": "Effacer les filtres",
      "editable": {
        "error": {
          "mandatory": "Au moins un champ obligatoire a été laissé vide"
        },
        "missing_required": "%{number} cellules obligatoires manquantes sur cette ligne",
        "title": "Mode édition",
        "update": {
          "error": "%{quantity} n’a pas pu être enregistré. Vérifiez la raison sur le côté gauche de chaque ligne en surbrillance",
          "success": "%{quantity} enregistrements ont été enregistrés avec succès"
        }
      },
      "empty_after_filtering": "Il n’y a aucun enregistrement pour les filtres appliqués",
      "failed_saving_preferences": "Les préférences n’ont pas été enregistrées !",
      "flag_filter": "Filtre de drapeau",
      "grid_filters": {
        "menu_title": "Filtres personnalisés"
      },
      "menu": {
        "visibility": "Colonnes"
      },
      "of": "de",
      "other": "Autres",
      "productivity_factor": "Facteur de productivité",
      "quick_pin_popup": {
        "all_work_orders": "Tous les OTs",
        "clear_all": "Tout effacer",
        "no_pinned_requests": "Vous n'avez pas encore épinglé de ordres de travail",
        "pinned_work_orders": "OTs épinglés",
        "quick_pin": "Épingle rapide",
        "search_and_pin": "Rechercher et épingler OT"
      },
      "saved_preferences": "Enregistré!",
      "saving_preferences": "Enregistrement des préférences...",
      "select_items": "Sélectionnez les éléments",
      "selected": "Choisi",
      "selecteds": "Sélectionnés",
      "status_filter": "Filtre d’état",
      "totals": "Totaux"
    },
    "history_form": {
      "create_title": "Ajouter une nouvelle progression",
      "edit_title": "Modifiez votre progression",
      "subtitle": "Insérez une progression en quantité ou en pourcentage pour votre activité."
    },
    "history_popup": {
      "back": "Arrière",
      "comments": "commentaires",
      "crew_size": "Taille de l’équipage",
      "daily_hours": "Heures quotidiennes",
      "date": "Date",
      "form_create_progress_service_title": "Créer l’historique des éléments de service de construction",
      "form_create_progress_title": "Créer l’historique des éléments de progression",
      "form_edit_progress_service_title": "Modifier l’historique des éléments du service de construction",
      "form_edit_progress_title": "Modifier l’historique des éléments de progression",
      "form_error_body": "Tous les champs obligatoires du formulaire doivent être remplis.",
      "form_error_title": "Il n’a pas été possible d’enregistrer le formulaire d’historique",
      "form_subtitle": "Insérez un historique par quantité ou pourcentage pour votre activité.",
      "modal_create_title": "Créer un historique",
      "modal_edit_title": "Modifier l’historique",
      "number_of_crews": "Nombre d’équipages",
      "number_of_workers": "Nombre de travailleurs",
      "other_value": "Autre valeur",
      "percentage": "Pourcentage",
      "progress_by": "Progrès par",
      "quantity": "Quantité",
      "singular": "Histoire",
      "working_hours": "Heures d’ouverture"
    },
    "home": {
      "available_on": "Disponible sur",
      "banner_description": "Gérez les tâches, suivez les processus et obtenez les meilleurs résultats en utilisant une seule plateforme",
      "create_request": {
        "text": "Tout d’abord, vous devez créer un ordre de travail du client dans le DPMS avec les détails de votre commande.",
        "title": "Créer un ordre de travail"
      },
      "dpms_mobile": "Application mobile DPMS",
      "getting_started": "Commencer",
      "home_title": "Accueil",
      "inspection": {
        "text": "Effectuer des inspections de qualité sur le terrain et enregistrer les obstacles.",
        "title": "Inspection"
      },
      "inspection_calendar": {
        "calendar": "Calendrier",
        "table": "Tableau",
        "title": "Calendrier d’inspection"
      },
      "performance": {
        "text": "Enregistrez la progression et le temps en fonction des activités planifiées de chaque équipe et mesurez la productivité.",
        "title": "Construction"
      },
      "scoping": {
        "text": "Préparer une ordre de travail du client. Attribuez des services et des packages de services à partir de votre contrat.",
        "title": "Préparation du travail"
      },
      "welcome_user": "Bienvenue, %{user}"
    },
    "importation_drop": {
      "choose_error": "Choisir un élément"
    },
    "importation_popup": {
      "file_upload_message": "Cliquez ou faites glisser le fichier vers cette zone pour télécharger",
      "importation_error": "Vous pouvez consulter ce message sur la page des emplois.",
      "importation_success": "Succès de l’importation.",
      "importation_timeout": "L’importation prend trop de temps. Vérifiez son état dans la page Travaux.",
      "instructions": "Les fichiers doivent être au format .xls et ne pas dépasser xxmb. <br> Votre fichier .xls doit contenir la colonne - Date, Client, Projet, Tâche, Notes .... <br> L’ordre des colonnes doit être le même que le ordre du modèle. <br> La date doit être en - AAAA / MM / JJ.",
      "requirements": "Exigences",
      "submit_spreadsheet": "Soumettez votre feuille de calcul"
    },
    "inspections": {
      "acceptance": "Acceptation",
      "acceptance_error": {
        "error_description": "L’inspection – %{item} n’a pas pu être mise à jour car %{reason}",
        "title": "Nous ne pouvons pas terminer"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "Vous pouvez revenir en arrière plus tard si vous en avez besoin.",
          "reject": "Etes-vous sûr de vouloir rejeter cette/ces inspection(s) ?"
        },
        "inspection_acceptance": "Acceptation d’inspection",
        "items": "%{items} articles",
        "reject": "Rejeter",
        "subtitle": "Fournir des informations sur l’inspection(s) pour continuer avec l’acceptation."
      },
      "actions": {
        "approve": "Approuver",
        "more": "Plus",
        "reject": "Rejeter"
      },
      "choose_modal": {
        "schedule": "Planifier les articles d’inspection",
        "schedule_description": "Planifiez une ou plusieurs inspections en fonction de l’intervalle de dates",
        "single": "Article d’inspection unique",
        "single_description": "Création d’une seule inspection",
        "title": "Créez une inspection en tant que:"
      },
      "description": "Inspection",
      "description_title": "Description",
      "every": "Chaque",
      "id": "ID",
      "inspect_date": "Date d’inspection",
      "inspect_status_id": "Statut",
      "inspected": "Inspecté",
      "inspected_detail": "Détail inspecté",
      "inspected_item": "Article inspecté",
      "inspected_modules": {
        "estimate_services": "Détails de l’estimation",
        "performance": "Construction",
        "progress": "Construction",
        "progress_services": "Services de construction",
        "request": "Demande de service",
        "scaffolding": "Échafaudage",
        "scope": "Préparation du travail"
      },
      "inspected_services": "Services inspectés",
      "inspected_track": {
        "area": "Zone",
        "comments": "commentaires",
        "description": "Description",
        "item": "Article %{item}",
        "no_load": "Impossible de charger le bloc de piste inspecté : données manquantes",
        "number_of_tags": "Nombre de balises",
        "reason": "Raison",
        "subarea": "Sous-zone"
      },
      "inspection_block": {
        "inspection_date": "Date d’inspection",
        "missing_date": "Date manquante",
        "not_selected_yet": "Pas encore sélectionné",
        "responsible": "Responsable"
      },
      "inspections_detail": {
        "side_panel_title": "Détails de l’inspection"
      },
      "module": "Module",
      "notifications": {
        "approve_successful": "L’inspection a été approuvée avec succès",
        "reject_successful": "L’inspection a été rejetée avec succès"
      },
      "pending_inspections": "Inspections en attente",
      "recurrency_interval": "Intervalle de récurrence",
      "related_inspections": {
        "missing_date": "Date manquante",
        "no_related": "Aucune inspection connexe trouvée",
        "no_responsible": "Non responsable",
        "title": "Inspections connexes"
      },
      "repeat": "Répéter",
      "repeat_every_value": {
        "day": "journée",
        "month": "Mois",
        "week": "La semaine",
        "year": "An"
      },
      "repeats_on": "Répéter le",
      "request_id": "DPMSID",
      "responsible_id": "Responsable",
      "schedule_detail": "Détail du calendrier",
      "schedule_form": {
        "acceptance_step": "Définir l’acceptation (facultatif)",
        "detailed_item_step": "Choisissez l’élément de préparation/construction d’inspection",
        "detailed_template_step": "Choisissez le modèle de préparation/construction d’inspection",
        "further_step_information": "Les champs flexibles sont prêts à être remplis individuellement après l’étape de création de récurrence",
        "general_step": "Définir les informations générales d’inspection",
        "hints": "Pour les récurrences mensuelles et annuelles, le système planifiera l’inspection correspondant à la date de début indiquée dans le champ d’intervalle.",
        "monthly_hint": "Répétez chaque mois tous les jours %{day}",
        "recurrence_step": "Récurrence"
      },
      "scheduled_exception": "Programmé",
      "select_module_item": "Sélectionnez un élément de module pour afficher ses détails ici",
      "statuses": {
        "approved": "Approuvé",
        "completed": "Terminé",
        "overdue": "En retard",
        "pending": "En attente",
        "progress": "En cours",
        "rejected": "Refusé",
        "scheduled": "Programmé"
      },
      "template": "Modèle",
      "title": "Inspections"
    },
    "inspects": {
      "statuses": {
        "approved": "Approuvé",
        "completed": "Terminé",
        "pending": "En attente",
        "progress": "En cours",
        "rejected": "Refusé"
      }
    },
    "kendo": {
      "upload": "Cliquez, faites glisser ou collez les fichiers ici pour les télécharger"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Aujourd’hui"
      },
      "dateinput": {
        "decrement": "Diminuer la valeur",
        "increment": "Augmenter la valeur"
      },
      "datepicker": {
        "toggleCalendar": "Basculer le calendrier"
      },
      "daterangepicker": {
        "end": "Fin",
        "separator": 0,
        "start": "Début",
        "swapStartEnd": "Permuter les valeurs de début et de fin"
      },
      "datetimepicker": {
        "cancel": "Annuler",
        "date": "Date",
        "set": "Ensemble",
        "time": "Temps",
        "toggleDateTimeSelector": "Basculer le sélecteur date-heure"
      },
      "dropdowns": {
        "clear": "Clair",
        "nodata": "Aucune Donnée Disponible."
      },
      "editor": {
        "addColumnAfter": "Ajouter une colonne à droite",
        "addColumnBefore": "Ajouter une colonne à gauche",
        "addRowAfter": "Ajouter une ligne ci-dessous",
        "addRowBefore": "Ajouter une ligne ci-dessus",
        "alignCenter": "Texte au centre",
        "alignJustify": "Justifier",
        "alignLeft": "Aligner le texte à gauche",
        "alignRight": "Aligner le texte à droite",
        "bold": "Audacieux",
        "bulletList": "Insérer une liste non ordonnée",
        "createTable": "Créer une table",
        "deleteColumn": "Supprimer la colonne",
        "deleteRow": "Supprimer la ligne",
        "deleteTable": "Supprimer la table",
        "fontName": "Nom de la police",
        "fontSize": "Taille de police",
        "format": "Format",
        "hyperlink": "Insérer un lien hypertexte",
        "hyperlink-dialog-cancel": "Annuler",
        "hyperlink-dialog-content-address": "Adresse web",
        "hyperlink-dialog-content-newwindow": "Ouvre le lien dans une nouvelle fenêtre",
        "hyperlink-dialog-content-title": "Titre",
        "hyperlink-dialog-insert": "Insérer",
        "hyperlink-dialog-title": "Insérer un lien hypertexte",
        "image": "Insérer une image",
        "image-address": "Adresse web",
        "image-altText": "Texte alternatif",
        "image-cancel": "Annuler",
        "image-dialog-title": "Insérer une image",
        "image-height": "Hauteur (px)",
        "image-insert": "Insérer",
        "image-title": "Titre",
        "image-width": "Largeur (px)",
        "indent": "Retrait",
        "insertTableHint": "Créer une table {0} x {1}",
        "italic": "Italique",
        "mergeCells": "Fusionner des cellules",
        "orderedList": "Insérer une liste ordonnée",
        "outdent": "Outdent",
        "redo": "Refaire",
        "splitCell": "Cellule divisée",
        "strikethrough": "Barré",
        "subscript": "Indice",
        "superscript": "Exposant",
        "underline": "Souligner",
        "undo": "annuler",
        "unlink": "Supprimer le lien hypertexte",
        "viewHtml": "Afficher le HTML",
        "viewHtml-cancel": "Annuler",
        "viewHtml-dialog-title": "Afficher le HTML",
        "viewHtml-update": "Mise à jour"
      },
      "filter": {
        "addExpression": "Ajouter une expression",
        "addGroup": "Ajouter un groupe",
        "afterOperator": "Est après",
        "afterOrEqualOperator": "Est après ou égal à",
        "andLogic": "Et",
        "beforeOperator": "Est avant",
        "beforeOrEqualOperator": "Est avant ou égal à",
        "close": "proche",
        "containsOperator": "Contient",
        "endsWithOperator": "Se termine par",
        "eqOperator": "Est égal à",
        "gtOperator": "Est supérieur à",
        "gteOperator": "Est supérieur ou égal à",
        "isEmptyOperator": "Est vide",
        "isFalse": "Est-ce que non",
        "isNotEmptyOperator": "N’est pas vide",
        "isNotNullOperator": "Est non nulle",
        "isNullOperator": "Est nul",
        "isTrue": "C’est oui",
        "ltOperator": "Est inférieur à",
        "lteOperator": "Est inférieur ou égal à",
        "notContainsOperator": "Ne contient pas",
        "notEqOperator": "N’est pas égal à",
        "orLogic": "Ou",
        "startsWithOperator": "Commence avec"
      },
      "grid": {
        "filterAfterOperator": "Est après",
        "filterAfterOrEqualOperator": "Est après ou égal à",
        "filterBeforeOperator": "Est avant",
        "filterBeforeOrEqualOperator": "Est avant ou égal à",
        "filterBetweenOperator": "Est entre",
        "filterBooleanAll": "(Tout)",
        "filterClearButton": "Clair",
        "filterContainsOperator": "Contient",
        "filterEndsWithOperator": "Se termine par",
        "filterEqOperator": "Est égal à",
        "filterGtOperator": "Est supérieur à",
        "filterGteOperator": "Est supérieur ou égal à",
        "filterIsEmptyOperator": "Est vide",
        "filterIsFalse": "Est-ce que non",
        "filterIsNotEmptyOperator": "N’est pas vide",
        "filterIsNotNullOperator": "Est non nulle",
        "filterIsNullOperator": "Est nul",
        "filterIsTrue": "C’est oui",
        "filterLtOperator": "Est inférieur à",
        "filterLteOperator": "Est inférieur ou égal à",
        "filterNotContainsOperator": "Ne contient pas",
        "filterNotEqOperator": "N’est pas égal à",
        "filterStartsWithOperator": "Commence avec",
        "filterSubmitButton": "Filtre",
        "filterTitle": "Filtre",
        "groupPanelEmpty": "Faites glisser un en-tête de colonne et déposez-le ici pour le regrouper par cette colonne",
        "noRecords": "Aucun enregistrement disponible.",
        "pagerFirstPage": "Aller à la première page",
        "pagerInfo": "{0} - {1} sur {2} éléments",
        "pagerItemsPerPage": "objets par page",
        "pagerLastPage": "Aller à la dernière page",
        "pagerNextPage": "Aller à la page suivante",
        "pagerOf": "de",
        "pagerPage": "Page",
        "pagerPreviousPage": "Aller à la page précédente"
      },
      "numerictextbox": {
        "decrement": "Diminuer la valeur",
        "increment": "Augmenter la valeur"
      },
      "pager": {
        "firstPage": "Aller à la première page",
        "info": "{0} - {1} sur {2} éléments",
        "itemsPerPage": "objets par page",
        "lastPage": "Aller à la dernière page",
        "nextPage": "Aller à la page suivante",
        "of": "de",
        "page": "Page",
        "previousPage": "Aller à la page précédente"
      },
      "scheduler": {
        "agendaViewTitle": "Ordre du jour",
        "allDay": "toute la journée",
        "allEvents": "Tous les évènements",
        "dateTitle": "Date",
        "dayViewTitle": "journée",
        "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer cet événement?",
        "deleteDialogTitle": "Supprimer l’événement",
        "deleteOccurrence": "Supprimer l’occurrence actuelle",
        "deleteRecurringConfirmation": "Voulez-vous supprimer uniquement cette occurrence d’événement ou la série entière?",
        "deleteRecurringDialogTitle": "Supprimer l’élément récurrent",
        "deleteSeries": "Supprimer la série",
        "deleteTitle": "Supprimer",
        "editorCancel": "Annuler",
        "editorDelete": "Supprimer",
        "editorEventAllDay": "Événement d’une journée",
        "editorEventDescription": "La description",
        "editorEventEnd": "Fin",
        "editorEventEndTimeZone": "Fuseau horaire de fin",
        "editorEventSeparateTimeZones": "Terminer dans un fuseau horaire différent",
        "editorEventStart": "Début",
        "editorEventStartTimeZone": "Fuseau horaire de début",
        "editorEventTimeZone": "Spécifiez le fuseau horaire",
        "editorEventTitle": "Titre",
        "editorOccurrence": "Modifier l’occurrence actuelle",
        "editorRecurringConfirmation": "Voulez-vous modifier uniquement cette occurrence d’événement ou la série entière?",
        "editorRecurringDialogTitle": "Modifier l’élément récurrent",
        "editorSave": "sauvegarder",
        "editorSeries": "Modifier la série",
        "editorTitle": "un événement",
        "editorValidationEnd": "L’heure de fin doit être postérieure à l’heure de début.",
        "editorValidationRequired": "Champ requis.",
        "editorValidationStart": "L’heure de début doit être antérieure à l’heure de fin.",
        "eventTitle": "un événement",
        "monthViewTitle": "Mois",
        "nextTitle": "Suivant",
        "noEvents": "pas d’événements",
        "previousTitle": "précédent",
        "recurrenceEditorDailyInterval": "journées)",
        "recurrenceEditorDailyRepeatEvery": "Répétez chaque",
        "recurrenceEditorEndAfter": "Après",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Jamais",
        "recurrenceEditorEndOccurrence": "occurrence (s)",
        "recurrenceEditorEndOn": "Sur",
        "recurrenceEditorFrequenciesDaily": "du quotidien",
        "recurrenceEditorFrequenciesMonthly": "Mensuel",
        "recurrenceEditorFrequenciesNever": "Jamais",
        "recurrenceEditorFrequenciesWeekly": "Hebdomadaire",
        "recurrenceEditorFrequenciesYearly": "Annuel",
        "recurrenceEditorMonthlyDay": "journée",
        "recurrenceEditorMonthlyInterval": "mois)",
        "recurrenceEditorMonthlyRepeatEvery": "Répétez chaque",
        "recurrenceEditorMonthlyRepeatOn": "Répéter le",
        "recurrenceEditorOffsetPositionsFirst": "Première",
        "recurrenceEditorOffsetPositionsFourth": "Quatrième",
        "recurrenceEditorOffsetPositionsLast": "Dernier",
        "recurrenceEditorOffsetPositionsSecond": "Seconde",
        "recurrenceEditorOffsetPositionsThird": "Troisième",
        "recurrenceEditorRepeat": "Répéter",
        "recurrenceEditorWeekdaysDay": "journée",
        "recurrenceEditorWeekdaysWeekday": "Jour de la semaine",
        "recurrenceEditorWeekdaysWeekendday": "Jour de weekend",
        "recurrenceEditorWeeklyInterval": "semaines)",
        "recurrenceEditorWeeklyRepeatEvery": "Répétez chaque",
        "recurrenceEditorWeeklyRepeatOn": "Répéter le",
        "recurrenceEditorYearlyInterval": "années)",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Répétez chaque",
        "recurrenceEditorYearlyRepeatOn": "Répéter le",
        "showFullDay": "Afficher toute la journée",
        "showWorkDay": "Afficher les heures d’ouverture",
        "timeTitle": "Temps",
        "timelineViewTitle": "Chronologie",
        "today": "Aujourd’hui",
        "weekViewTitle": "La semaine",
        "workWeekViewTitle": "Semaine de travail"
      },
      "sortable": {
        "noData": "Pas de données"
      },
      "timepicker": {
        "cancel": "Annuler",
        "now": "MAINTENANT",
        "selectNow": "Sélectionnez maintenant",
        "set": "Ensemble",
        "toggleClock": "Basculer l’horloge",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Est après",
        "filterAfterOrEqualOperator": "Est après ou égal à",
        "filterBeforeOperator": "Est avant",
        "filterBeforeOrEqualOperator": "Est avant ou égal à",
        "filterBooleanAll": "(Tout)",
        "filterClearButton": "Clair",
        "filterContainsOperator": "Contient",
        "filterEndsWithOperator": "Se termine par",
        "filterEqOperator": "Est égal à",
        "filterGtOperator": "Est supérieur à",
        "filterGteOperator": "Est supérieur ou égal à",
        "filterIsEmptyOperator": "Est vide",
        "filterIsFalse": "Est-ce que non",
        "filterIsNotEmptyOperator": "N’est pas vide",
        "filterIsNotNullOperator": "Est non nulle",
        "filterIsNullOperator": "Est nul",
        "filterIsTrue": "C’est oui",
        "filterLtOperator": "Est inférieur à",
        "filterLteOperator": "Est inférieur ou égal à",
        "filterNotContainsOperator": "Ne contient pas",
        "filterNotEqOperator": "N’est pas égal à",
        "filterStartsWithOperator": "Commence avec",
        "noRecords": "Aucun enregistrement disponible"
      },
      "upload": {
        "cancel": "Annuler",
        "clearSelectedFiles": "Clair",
        "dropFilesHere": "ou déposez le fichier ici",
        "files": "des dossiers",
        "headerStatusUploaded": "Terminé",
        "headerStatusUploading": "Téléchargement...",
        "invalidFileExtension": "Type de fichier non autorisé.",
        "invalidFiles": "Fichier (s) non valide (s). Veuillez vérifier les conditions de téléchargement de fichiers.",
        "invalidMaxFileSize": "Taille de fichier trop grande.",
        "invalidMinFileSize": "Taille de fichier trop petite.",
        "remove": "Retirer",
        "retry": "Réessayez",
        "select": "Choisir le dossier",
        "total": "Total",
        "uploadSelectedFiles": "Télécharger"
      }
    },
    "loading_batch_entities": {
      "page_of": "Page %{page} sur %{total}",
      "title": "Récupérer des pages"
    },
    "login": {
      "again": "Pour continuer, veuillez vous reconnecter",
      "continue_mykaefer": "Continuez avec MyKaefer",
      "copyright": "®%{year} Tous droits réservés. Kaefer®",
      "forgot_password": "mot de passe oublié?",
      "iframe_info": "Demande d’accès à la page: %{link}",
      "log_in": "S’identifier",
      "password": "Mot de passe",
      "title_1": "Un outil puissant",
      "title_2": "pour la gestion de projet",
      "username": "Nom d’utilisateur",
      "welcome_back": "Nous saluons le retour"
    },
    "main_dialog": {
      "failure_message": "Votre demande n’a pas pu être complétée",
      "failure_title": "Échec",
      "loading_message": "Veuillez patienter pendant que nous organisons les choses pour vous!",
      "loading_title": "Chargement...",
      "service_title": "En attendant la fin des travaux...",
      "success_message": "Votre demande a été acceptée! Vous pouvez maintenant continuer votre travail.",
      "success_title": "Réussi!"
    },
    "notification": {
      "access_denied": "Accès refusé",
      "access_denied_message": "Vous n’êtes pas autorisé à effectuer cette action. Contactez votre administrateur pour plus d’informations.",
      "action_success": "%{model} %{id} était %{action}",
      "bad_formula_setup": "Il y a un problème de configuration avec la formule '%{title}', veuillez vérifier votre configuration!",
      "correct_subproject": "Sélectionnez le sous-projet correct de cet élément",
      "dismiss_all": "Rejeter la totalité",
      "error": "Erreur",
      "error_cookies": "Nous n’avons pas pu établir de communication entre les sources croisées. Vérifiez les paramètres des cookies dans votre navigateur et désactivez le «blocage des cookies tiers».",
      "error_job": "Échoué! Vérifiez la raison dans la page Emplois.",
      "error_msg": "Pardon! Un problème est survenu!",
      "info": "Info",
      "large_file_body": "Veuillez appliquer des filtres pour réduire la taille du fichier de sortie.",
      "large_file_title": "La grille a plus de 1000 enregistrements",
      "page_denied": "La page n’a pas pu être chargée.",
      "please": "S’il vous plaît",
      "select_subproject": "Sélectionnez un projet et un sous-projet pour continuer",
      "success": "Succès",
      "success_msg": "Action exécutée avec succès!",
      "warning": "Attention"
    },
    "orders": {
      "add_evidence": "Ajouter la preuve de l’autorisation du client",
      "attachment": "Attachement",
      "comments": "commentaires",
      "date": "Date",
      "drag_and_drop": "Ajouter des fichiers par glisser-déposer",
      "recipient": "Destinataire",
      "responsible": "Responsable",
      "title": "Ordres"
    },
    "performance": {
      "title": "Construction"
    },
    "performances": {
      "title": "Constructions"
    },
    "performances_wip": {
      "performance_item": "Objet de construction",
      "title": "Construction"
    },
    "planning": {
      "item_no": "N° d’article",
      "service_no": "N° de service",
      "title": "Planification",
      "view": "Vue Planification"
    },
    "plannings": {
      "title": "Planification"
    },
    "print_grid": {
      "title": "Imprimer la grille"
    },
    "print_list": {
      "loading": "Génération de votre document. Veuillez patienter un instant.",
      "title": "Imprimer la liste"
    },
    "progress": {
      "statuses": {
        "canceled": "Annulé",
        "done": "Fait",
        "extra": "Supplémentaire",
        "in_progress": "En cours",
        "pending": "En attente",
        "revised": "Révisé"
      }
    },
    "progress_histories": {
      "add_new_progress": "Ajouter une nouvelle progression",
      "empty_history": "Il n'y a aucun élément d'historique associé et de nouveaux éléments ne peuvent pas être créés",
      "hours": "Les heures",
      "production_factor": "PF",
      "progress": "Le progrès",
      "progress_badge": "Article %{number}",
      "progress_history": "Historique des progrès",
      "progress_service_badge": "Service %{number}",
      "progress_service_history": "Historique des services",
      "progress_service_title": "Historiques de service",
      "progress_title": "Historiques des articles",
      "quantity": "Quantité",
      "quantity_empty_history": "Il n’y a pas d’élément d’historique et il n’est pas possible de créer des historiques lorsque la quantité est nulle",
      "subtitle": "Gérez les progrès quotidiens en fonction des activités planifiées par votre équipe."
    },
    "progress_services": {
      "actual_div_hours": "Heures réelles / cibles de l’équipe",
      "actual_div_quantity": "Quantité réelle / cible",
      "actual_hours": "Heures réelles",
      "actual_quantity": "Quantité réelle",
      "application_price": "Prix de l’application",
      "back": "Toutes les constructions",
      "budget_target_hours": "Heures cibles du budget",
      "canceled": "Annulé",
      "choose_modal": {
        "package": "Forfait détail unique",
        "package_description": "Création de plusieurs services basés sur le même détail",
        "single": "Détail unique",
        "single_description": "Création d’un seul détail",
        "title": "Créer un nouveau"
      },
      "contract_service": "Service contractuel",
      "contribution": "Contribution",
      "contribution_info": "Valeurs calculées automatiquement",
      "crew": "équipage",
      "crew_size": "Taille de l’équipage",
      "crews": "équipages",
      "date_filter": {
        "all": "Tous",
        "to": "à",
        "today": "Aujourd’hui",
        "tomorrow": "Demain",
        "week": "La semaine",
        "yesterday": "Hier"
      },
      "date_filter_info": "Filtre de dates",
      "delta_hours": "Heures Delta",
      "delta_qtt": "Quantité Delta",
      "description": "Service de construction",
      "discipline": "La discipline",
      "end_date": "Date de fin",
      "equipment_price": "Prix de l’équipement",
      "factor": "Facteur",
      "flag": "Drapeau",
      "id": "ID",
      "materials_price": "Prix des matériaux",
      "no_crews": "Nombre d’équipages",
      "no_workers": "Nombre de travailleurs",
      "norm_hours": "Heures normales",
      "not_planned": "Pas prévu",
      "other_price": "Autre prix",
      "package_factor": "Facteur",
      "people": "personnes",
      "person": "personne",
      "pf": "PF",
      "pf_doing_bad": "Tu ne vas pas bien",
      "pf_doing_good": "Tu le fais bien!",
      "pf_doing_neutral": "Impossible de calculer le pf",
      "planned": "Prévu",
      "planning_chart": {
        "actual": "Réel",
        "actual_qty": "Qté réelle",
        "date": "Date",
        "forecasted": "Prévu",
        "forecasted_qty": "Qté prévue",
        "target": "Cible",
        "target_qty": "Quantité cible",
        "title": "Tableau de planification"
      },
      "planning_timeline": {
        "day": "journée",
        "day_advance": "jour à l’avance",
        "day_delayed": "jour de retard",
        "days": "jours",
        "days_advance": "jours à l’avance",
        "days_delayed": "jours de retard",
        "end_date": "Date de fin",
        "forecasted": "Prévu",
        "on_time": "À temps",
        "remaining_days": "Jours restants",
        "start_date": "Date de début",
        "title": "Calendrier de planification"
      },
      "progress": "Le progrès",
      "progress_services_detail": {
        "side_panel_title": "Détails du service"
      },
      "quantity": "Quantité",
      "responsible": "Responsable",
      "scoped_quantity": "Quantité préparée",
      "service": "Un service",
      "service_package": "Forfait de service",
      "service_planning": "Planification des services",
      "service_progress": "Progression du service",
      "service_weight": "Poids de service",
      "start_date": "Date de début",
      "statuses": {
        "canceled": "Annulé",
        "done": "Complété",
        "extra": "Supplémentaire",
        "in_planning": "Pas prévu",
        "inspected_flag": "Inspection en cours",
        "planned": "Prévu"
      },
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Services de construction",
      "title_packageable": "Services de construction par ensemble de services",
      "total": "Le total",
      "total_hours": "Heures totales",
      "unit": "Unité",
      "worker": "ouvrier",
      "workers": "ouvriers",
      "working_hours": "Heures d’ouverture"
    },
    "progresses": {
      "actual_hours": "Heures réelles",
      "cancel": "Annuler",
      "choose_modal": {
        "single": "Un autre élément de construction",
        "single_description": "Création d’un seul élément de construction"
      },
      "complete": "Compléter",
      "complete_services": "Services complets",
      "current_hours": "Heure actuelle",
      "current_qty": "Quantité actuelle",
      "date_filter": {
        "all": "Tous",
        "current_week": "Semaine en cours",
        "previous_week": "La semaine précédente",
        "today": "Aujourd'hui"
      },
      "date_filter_info": "Filtre de date",
      "delta_hours": "Δ Heures",
      "delta_qty": "Δ Qté",
      "description": "La description",
      "edit_work_description": "Modifier la description du travail",
      "extra": "Supplémentaire",
      "flag": "Drapeau",
      "history": "Ajouter une nouvelle progression",
      "id": "identifiant",
      "item": "Article",
      "label": "Étiqueter",
      "more_details": "voir plus",
      "pf": "PF",
      "planned_hours": "Horaires prévus",
      "planned_qty": "Quantité prévue",
      "preview_unavailable": "Aucun aperçu disponible",
      "progress": "Le progrès",
      "progress_status_restriction": "La progression ne doit pas avoir le statut Annulé ou Terminé",
      "progresses_detail": {
        "side_panel_title": "Détails de construction"
      },
      "remaining_hours": "Heures restantes",
      "request_status_restriction": "L’ordre de travail doit avoir le statut En planification ou En construction",
      "revised": "modifié",
      "success_work_description_edition": "La description du travail a été éditée avec succès",
      "title": "Constructions",
      "work_description": "Description du travail"
    },
    "projects": {
      "workdays": {
        "fri": "Ven",
        "mon": "Lun",
        "sat": "Sam",
        "sun": "Dim",
        "thu": "Jeu",
        "tue": "Mar",
        "wed": "Mer"
      }
    },
    "requests": {
      "actual_hours": "Heures réelles",
      "actual_progress": "Progrès réels",
      "all": "Tout",
      "are_you_sure_complete": "Êtes-vous sûr de terminer cette ordre de travail ?",
      "are_you_sure_complete_no_history": "Êtes-vous sûr de terminer un ordre de travail sans aucune progression donnée ?",
      "are_you_sure_deletion": "Êtes-vous sûr de vouloir supprimer l’ordre de travail ?",
      "are_you_sure_revertion": "Etes-vous sûr de vouloir revenir en arrière et de perdre les données saisies dans ce module?",
      "area": "Zone",
      "authorization_pending": "autorisation en attente",
      "authorize_form": {
        "modal_title": "Autorisation",
        "subtitle": "Fournir quelques informations sur l’ordre de travail pour procéder à l’approbation",
        "title": "Autoriser l’ordre de travail"
      },
      "canceled": "Annulé",
      "clustered": "Clustered",
      "comments": "commentaires",
      "confirmation_modal": {
        "cancel_description": "L’ordre de travail sera annulé",
        "cancel_title": "Annuler l’ordre de travail ?",
        "complete_description": "Cet ordre de travail sera complété",
        "complete_title": "Ordre de travail terminé ?",
        "confirm_action": "Procéder à l’importation des préparations de travail",
        "confirm_cancel": "Pas maintenant",
        "confirm_confirmation": "Oui, je veux confirmer",
        "confirm_description": "Voulez-vous confirmer l’ordre de travail?",
        "confirm_title": "Confirmation de l’ordre de travail",
        "delete_description": "L’action de suppression ne peut pas être annulée. Êtes-vous sûr?",
        "delete_title": "Supprimer l’ordre de travail ?",
        "reject_description": "Cet article reviendra au statut En préparation",
        "reject_title": "Rejeter l’ordre de travail ?",
        "remove_recycle_description": "Cet élément reviendra au statut En cours de cadrage et perdra l’indicateur Recycler.",
        "remove_recycle_title": "Effacer le recyclage ?",
        "revert_description": "L’action de retour ne peut pas être annulée. Êtes-vous sûr?",
        "revert_title": "Annuler l’ordre de travail ?"
      },
      "confirmed": "Confirmé",
      "create_inspection_modal": {
        "category_subtitle": "Sélectionnez une option dans la catégorie %{category_name}",
        "loading": "Chargement des modèles d’inspection...",
        "no_templates": "Il n’existe aucun modèle d’inspection.",
        "plural_title": "Créer des inspections",
        "standalone_subtitle": "Sélectionnez l’une des options ci-dessous pour continuer",
        "title": "Choisir un modèle d’inspection"
      },
      "creation_form": {
        "proceed": "Passez à l’étape 2"
      },
      "creation_page": {
        "add_template": "Ajouter un modèle",
        "first_step": "Créer un ordre de travail",
        "second_step": "Importer les préparations de travail (facultatif)",
        "third_step": "Appliquer des packages de services (facultatif)"
      },
      "description": "Demande de service",
      "discipline": "Activité",
      "discipline_check": {
        "check_terms": "Conditions du contrôle disciplinaire",
        "perform": "Effectuer un contrôle disciplinaire",
        "ready_for_discipline": "Prêt pour le contrôle disciplinaire",
        "ready_for_review": "Ordre de travail prêt pour examen disciplinaire",
        "set_as_ready": "Définir l’ordre de travail comme étant prêt pour le contrôle disciplinaire",
        "title": "Contrôle de discipline"
      },
      "discipline_check_item": "WO01 – Prêt pour le contrôle disciplinaire",
      "discipline_check_no_comment": "Aucun commentaire ajouté",
      "done": "Terminé",
      "eav_template": "Modèle",
      "eav_template_id": "ID du modèle",
      "edit_dialog": {
        "reason": "Nom de sous-demande"
      },
      "elevation": "Élévation",
      "errors": {
        "not_found": "Impossible de trouver l’ordre de travail avec l’identifiant : %{id}"
      },
      "estimates_authorization": "L’autorisation de l’Estimation est nécessaire",
      "flags": {
        "discipline_check": "WO01 - Contrôle disciplinaire",
        "discipline_check_done": "WO01 – Contrôle disciplinaire: Approuvé",
        "discipline_check_pending": "WO01 – Contrôle disciplinaire: En attente",
        "discipline_check_rejected": "WO01 – Contrôle disciplinaire: Rejeté",
        "recycle": "WO03 - Recycler"
      },
      "forecast_end_date": "Date de fin prévue",
      "id": "DPMSID",
      "in_performance": "En construction",
      "in_planning": "En planification",
      "in_scoping": "En préparation",
      "information": "Informations complémentaires",
      "inspection_date": "Date d’inspection",
      "no_comment": "Aucun commentaire",
      "notification": {
        "authorization_success": "L’ordre de travail est autorisé avec succès",
        "deleting_sub_request": "La sous-requête %{id} est en cours de suppression. Veuillez consulter la page des emplois pour en savoir plus sur sa progression",
        "discipline_check_approved": "Contrôle de discipline approuvé avec succès",
        "discipline_check_rejected": "Le contrôle disciplinaire a été rejeté avec succès",
        "discipline_check_success": "L’ordre de travail est prêt avec succès pour un examen disciplinaire",
        "missing_order_error": "Il n’y a aucune commande en attente associée à ce bon de travail",
        "recycle_success": "L’ordre de travail est recyclé avec succès"
      },
      "on_hold": "En attente",
      "progress": "Progrès",
      "progress_end_date": "Date de fin du progrès",
      "progress_responsible": "Responsable du progrès",
      "progress_responsible_id": "Responsable du progrès",
      "progress_start_date": "Date de début du progrès",
      "reason": "Référence",
      "recycle_form": {
        "label": "Raison - Discipline",
        "modal_title": "Recycler",
        "subtitle": "Fournir des informations pour signaler cet ordre de travail pour le recyclage",
        "title": "Demande de recyclage"
      },
      "recycling_item": "WO03 - Recycler vers le client",
      "reference": "Référence",
      "registered": "Inscrit",
      "remove_recycle_modal": {
        "cancel_description": "Non, garde le drapeau",
        "confirm_description": "Oui, c’est clair",
        "success_notification": "L’indicateur de recyclage a été supprimé le %{reason} - %{discipline}"
      },
      "request_date": "Date de l’ordre de travail",
      "request_detail": {
        "edit": "Éditer",
        "estimates": "Budget",
        "inspections": "Visites Chantier",
        "performance": "Construction",
        "planning": "Planification",
        "scopes": "Préparations de travail",
        "show": "Afficher",
        "side_panel_title": "Détails de la demande"
      },
      "request_end_date": "Date de fin de l’ordre de travail",
      "request_responsible": "Responsable de l’ordre de travail",
      "request_responsible_id": "Responsable de l’ordre de travail",
      "request_start_date": "Date de début de l’ordre de travail",
      "responsible": "Responsable",
      "schedule": "Calendrier des ordres de travail",
      "schedule_form": {
        "date": "Date",
        "days": "%{number} jours",
        "performance": "Construction",
        "progress": "Le progrès",
        "request": "Demande de service",
        "responsible": "Responsable",
        "schedule": "Programme",
        "scoping": "Préparation du travail",
        "subtitle": "Pour créer un calendrier pour cet ordre de travail, nous avons d’abord besoin de quelques informations supplémentaires.",
        "timecode": "Code temporel",
        "title": "Calendrier des ordres de travail"
      },
      "scope_end_date": "Date de fin de préparation du travail",
      "scope_responsible": "Responsable de la préparation des travaux",
      "scope_responsible_id": "Responsable de la préparation des travaux",
      "scope_start_date": "Date de début de la préparation du travail",
      "scopes": "Préparations de travail",
      "service_desired_date": "Date souhaitée",
      "skip_scoping": "Sauter la phase de préparation du travail",
      "status": "Statut",
      "statuses": {
        "authorization_pending": "En attente",
        "canceled": "Annulé",
        "clustered": "Groupé",
        "confirmed": "En préparation",
        "deleting": "Suppression",
        "done": "Terminé",
        "in_performance": "En construction",
        "in_planning": "En construction",
        "in_scoping": "En préparation",
        "on_hold": "En pause",
        "registered": "Établi"
      },
      "sub_request": "Sous-demande",
      "sub_requests": "Sous-demandes",
      "subarea": "Sous-zone",
      "team_target_hours": "Heures cibles de l’équipe",
      "title": "Ordres de travail",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - Révisé %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "Non, annuler",
      "description": "Voulez-vous vraiment annuler l’inspection \"%{id}\" ?",
      "ok": "Oui, revenir à en attente.",
      "success_description": "Remis en attente avec succès.",
      "success_title": "L’inspection a été annulée",
      "title": "Rétablir l’article ?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Texte alternatif",
        "by_url": "Par URL",
        "height": "Hauteur (px)",
        "image": "Image",
        "title": "Titre",
        "upload": "Télécharger",
        "web_address": "Adresse web",
        "width": "Largeur (px)",
        "window_title": "Édition d’images"
      }
    },
    "scaffolding_part_action": {
      "comment": "Commentaire",
      "date": "Date",
      "height": "Hauteur",
      "length": "Longueur",
      "modification_type": {
        "dismantle": "Démanteler",
        "erect": "Ériger",
        "erectDismantle": "Monter/Démonter"
      },
      "singular": "Action des pièces d’échafaudage",
      "title": "Action des pièces d’échafaudage",
      "total": "Total",
      "width": "Largeur",
      "work_dismantle": "Démonter sur un autre ordre de travail",
      "work_order": {
        "choose_wo": "Choisir un ordre de travail"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "Construit",
        "created_by": "Créé par",
        "dismantle": "Cote supprimée",
        "erect": "Dimension ajoutée",
        "modify": "Modifié",
        "total": "Total",
        "total_dismantle": "Échafaudage démonté"
      },
      "comment": "Commentaire",
      "confirmation_modal": {
        "delete_cancel": "Non, annule",
        "delete_confirmation": "Oui, supprimer",
        "delete_description": "Êtes-vous sûr de vouloir supprimer la dimension %{total} %{dimension} ?",
        "delete_title": "Supprimer l’action ?"
      },
      "date": "Date",
      "duplicate_title": "Action en double",
      "edit_title": "Modifier l’action",
      "feed": {
        "empty": "Aucune action créée pour cette pièce d’échafaudage"
      },
      "height": "Hauteur",
      "length": "Longueur",
      "modification_type": {
        "dismantle": "Démanteler",
        "erect": "Ériger",
        "erectDismantle": "Monter/Démonter"
      },
      "singular": "Action des pièces d’échafaudage",
      "timestamp_badges": {
        "new": "Nouveau",
        "updated": "Mis à jour"
      },
      "title": "Action des pièces d’échafaudage",
      "total": "Total",
      "width": "Largeur",
      "work_dismantle": "Démonter sur un autre ordre de travail",
      "work_order": {
        "choose_wo": "Choisir un ordre de travail"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Action du total %{total}%{dimension} supprimée de la liste",
        "title": "Ajouter une dimension"
      },
      "back": "Toutes les pièces d’échafaudage",
      "comment": "Commentaire",
      "confirmation_modal": {
        "delete_cancel": "Non, annule",
        "delete_confirmation": "Oui, supprimer",
        "delete_description": "Voulez-vous supprimer le %{type} avec %{total} %{dimension} ?",
        "delete_title": "Supprimer la pièce %{type} ?"
      },
      "description": "Description",
      "dismantle_modal": {
        "cancel": "Non, annule",
        "confirm": "Oui, démonter",
        "confirmation_message": "Voulez-vous démonter cette pièce ?",
        "date": "Date",
        "errors": {
          "date_greater_max": "La date de démontage doit être aujourd’hui ou avant"
        },
        "success_message": "La partie échafaudage a été démontée avec succès",
        "title": "Pièce de démontage",
        "work_order": "Demande de service"
      },
      "erect_method": "Méthode",
      "erect_methods": {
        "os": "Au-dessus de la mer",
        "standard": "Standard",
        "tt": "Accès par corde au-dessus de la mer"
      },
      "id": "IDENTIFIANT",
      "initial_date": "Date initiale",
      "menu_items": {
        "dimensions": "Ajouter une dimension",
        "dismantle": "Démanteler",
        "modifications": "Modifier cette pièce"
      },
      "operation_dismantle": "Opération démantèlement",
      "operation_erect": "Opération érigée",
      "scaffolding_part_detail": {
        "actions": "Actions",
        "side_panel_title": "Détails de la pièce d’échafaudage"
      },
      "scaffolding_type_id": "Type d’échafaudage",
      "scaffolding_types": {
        "dop": "DOP",
        "hanging": "Échafaudage suspendu",
        "lifting_rig": "Bâti de levage",
        "mobile": "Échafaudage roulant",
        "patch_boards": "Planches de répartition",
        "roof_over_roof": "Toit sur toit",
        "scaffolding_net": "Filet d'échafaudage",
        "shelf": "Étagère",
        "staircase_tower": "Tour d'escalier",
        "standard": "Échafaudage standard",
        "stool": "Tabouret",
        "support": "Support",
        "system_covering": "Couverture modulaire",
        "tarpaulin": "Bâche",
        "temporary_railing": "Garde-corps temporaire",
        "tent_frame": "Cadre de tente"
      },
      "singular": "Partie d’échafaudage",
      "title": "Partie d’échafaudage",
      "total": "Total",
      "type_filter": "Filtre de type",
      "weight": "Poids"
    },
    "scaffoldings": {
      "area_id": "Zone",
      "area_noise_id": "Bruit de la zone",
      "area_noises": {
        "db_100_105": "100-105dB - Temps de séjour de 2 heures",
        "db_105_110": "105-110dB - Temps de séjour 1/2 heure",
        "db_85_90": "85-90dB - Temps de séjour de 12 heures",
        "db_90_100": "90-100dB - Temps de séjour de 6 heures",
        "db_less_85": "< 85 dB - Aucune restriction"
      },
      "back": "Tous les échafaudages",
      "buttons_form": {
        "create_part": "Créer une pièce"
      },
      "client": "Client",
      "comment": "Commentaire",
      "confirmation_modal": {
        "delete_cancel": "Non, annuler",
        "delete_confirmation": "Oui, supprimer",
        "delete_description": "Voulez-vous supprimer Scaffolding TAG %{tag} ?",
        "delete_title": "Suppression de l’échafaudage",
        "dismantle_cancel": "Pas maintenant",
        "dismantle_confirmation": "Oui, je veux démonter",
        "dismantle_description": "Vous souhaitez démonter l’échafaudage TAG %{tag} ?",
        "dismantle_title": "Démontage d'échafaudages",
        "duplicate_with_parts_cancel": "Pas maintenant",
        "duplicate_with_parts_confirmation": "Oui, je veux dupliquer avec les pièces",
        "duplicate_with_parts_description": "Voulez-vous dupliquer l’échafaudage du TAG %{tag} avec ses pièces ?",
        "duplicate_with_parts_title": "Dupliquer un échafaudage avec des pièces",
        "ready_to_dismantle_cancel": "Pas maintenant",
        "ready_to_dismantle_confirmation": "Oui, je veux changer",
        "ready_to_dismantle_description": "Voulez-vous changer le statut du TAG %{tag} en prêt à démonter ?",
        "ready_to_dismantle_title": "Échafaudage prêt à démonter",
        "revert_cancel": "Pas maintenant",
        "revert_confirmation": "Oui, je veux changer",
        "revert_description": "Voulez-vous changer le statut de TAG %{tag} en érigé ?",
        "revert_title": "Rétablir le statut de l’échafaudage sur érigé"
      },
      "contact_person": "Personne de contact",
      "description": "Description",
      "description_of_deviation": "Description de l’écart",
      "deviation_improved": "Date d’amélioration de l’écart",
      "dismantle_compensation_format": "Démanteler le format de rémunération",
      "dismantle_date": "Date de démontage",
      "dismantle_modal": {
        "cancel": "Non, annule",
        "confirm": "Oui, démonter",
        "confirmation_message": "Vous souhaitez démonter cet échafaudage ?",
        "date": "Date",
        "errors": {
          "date_greater_max": "La date de démontage doit être aujourd’hui ou avant"
        },
        "success_message": "L’échafaudage a été démonté avec succès",
        "title": "Démontage d’échafaudage",
        "work_order": "Demande de service"
      },
      "erect_compensation_format": "Format de rémunération en érection",
      "erect_date": "Date de construction",
      "erect_with_deviation": "En érection avec déviation",
      "id": "IDENTIFIANT",
      "inspection": {
        "delayed": "Retardé",
        "missing_initial": "Manquant"
      },
      "inspection_control": "Inspection",
      "installation": "Installation",
      "load_class_id": "Classe de charge",
      "load_classes": {
        "kg_150": "2 - 150kg/m2",
        "kg_200": "3 - 200kg/m2",
        "kg_300": "4 - 300kg/m2",
        "kg_450": "5 - 450kg/m2",
        "kg_600": "6 - 600kg/m2",
        "kg_75": "1 - 75kg/m2",
        "not_applicable": "0 - Sans objet"
      },
      "main_work_order_dismantle_id": "Démantèlement de l’ordre de travail",
      "main_work_order_erect_id": "Ordre de travail établi",
      "missing_initial_control": "Contrôle initial",
      "missing_request": "Ordre de travail manquant",
      "number_of_tags": "Nombre de balises",
      "operation_dismantle": "Opération Démantèlement",
      "operation_erect": "Opération érigée",
      "pictures": "Des photos)",
      "project_number": "Référence du projet",
      "qr_code": "QR Code",
      "reason": "Raison de l’ordre de travail",
      "scaffolding_detail": {
        "side_panel_title": "Détails de l’échafaudage"
      },
      "scaffolding_status_id": "Statut",
      "sections": {
        "details": "Détails",
        "deviations": "Déviations",
        "general": "Informations générales",
        "pictures": "Des photos)"
      },
      "singular": "Échafaudage",
      "statuses": {
        "created": "Créé",
        "dismantled": "Démantelé",
        "erected": "Érigé",
        "ready_to_dismantle": "Prêt à démonter"
      },
      "subarea_id": "Sous-zone",
      "tag": "ÉTIQUETER",
      "tag_number": "Numéro d’étiquette",
      "tag_removed": "Balise supprimée",
      "telephone_radio": "Téléphone - Radio",
      "title": "Journal d’échafaudage",
      "total_m": "Total m",
      "total_m2": "m2 totaux",
      "total_m3": "Total m3",
      "total_weight_message": "Poids total de l'échafaudage érigé",
      "user_of_scaffolding": "Utilisateur d’échafaudage",
      "weight": "Poids",
      "wind_exposed": "exposé au vent"
    },
    "scopes": {
      "add": "Ajouter un élément de préparation du travail",
      "choose_modal": {
        "single": "Un autre élément de préparation au travail",
        "single_description": "Création d’un seul item de préparation du travail"
      },
      "clusters": {
        "title": "Clusters de préparation au travail"
      },
      "count": "Compter",
      "crew_size": "Taille de l’équipage",
      "crews": "Nombre d’équipages",
      "date": "Fecha",
      "description": "Préparation du travail",
      "duplicate_services": {
        "other_request": "Un autre ordre de travail",
        "other_request_description": "Dupliquer un élément de préparation de travail dans un autre ordre de travail",
        "same_request": "Même ordre de travail",
        "same_request_description": "Sélectionnez l’élément de préparation du travail",
        "success_notification": "Les services de préparation des travaux ont été dupliqués avec succès",
        "title": "Services de duplication"
      },
      "edit_work_description": "Modifier la description de travail",
      "hours": "Heures (h)",
      "id": "ID",
      "item": "Article",
      "label": "Étiquette",
      "label_assigner": {
        "success": "Vos étiquettes de préparations de travail ont été attribuées avec succès ! Vous pouvez poursuivre votre plan maintenant."
      },
      "label_error": "Impossible de récupérer les libellés",
      "labels_info": "Étiquettes de préparation au travail",
      "more_details": "voir plus",
      "popup_label": {
        "create_label": "Créer une étiquette",
        "labels": "Étiquettes:",
        "name_label": "Nom de l’étiquette",
        "new_label": "Nouvelle étiquette"
      },
      "preview_unavailable": "Aucun aperçu disponible",
      "quantity": "Qté",
      "remaining_hours": "Heures Restantes",
      "remaining_quantity": "Qté Restante",
      "request_status_restriction": "L’ordre de travail doit avoir le statut Confirmé ou En préparation",
      "scopes_detail": {
        "side_panel_title": "Détails de la préparation du travail"
      },
      "success_work_description_edition": "La description de travail a été modifiée avec succès",
      "title": "Préparations de travail",
      "unit": "Unité",
      "work_description": "Description du travail",
      "working_hours": "Heures d’ouverture"
    },
    "search": {
      "loading": "chargement...",
      "no_result_found": "Aucun résultat trouvé",
      "placeholder": "Filtre..."
    },
    "service_description": "Description du service",
    "settings": {
      "title": "Réglages"
    },
    "settings_shortcuts": "Raccourcis des paramètres",
    "shortcuts": {
      "access_control": "Contrôle d’accès",
      "areas": "Zones et sous-zones",
      "areas_subareas": "Zones et sous-zones",
      "assets": "Les atouts",
      "background_jobs": "Emplois d’arrière-plan",
      "branches": "Branches",
      "cities": "Villes",
      "client_sites": "Sites clients",
      "clients": "Clients",
      "clients_info": "Info clients",
      "columns_renaming": "Renommer des colonnes",
      "companies": "Entreprises",
      "contract_services": "Services contractuels",
      "contracts": "Contrats",
      "countries": "Des pays",
      "customer_sites": "Sites clients",
      "customers": "Clients",
      "disciplines": "Disciplines",
      "employees": "Personnel",
      "equipaments": "Équipements",
      "equipments": "Équipements",
      "formulas": "Formules",
      "location": "Emplacement",
      "materials": "Matériaux",
      "matrices": "Matrices",
      "measures": "Les mesures",
      "mobile_details": "Détails du mobile",
      "position_names": "Noms de poste",
      "preferences": "Préférences",
      "project_assets": "Les atouts",
      "project_contracts": "Contrats",
      "projects": "Projets et sous-projets",
      "projects_contracts": "Projets et contrats",
      "projects_subprojects": "Projets et sous-projets",
      "provinces": "États / Provinces",
      "regions": "Régions",
      "routes": "Itinéraires",
      "specialities": "Spécialités",
      "staff": "Personnel",
      "states_provinces": "États / Provinces",
      "templates": "Modèles",
      "translations": "Renommage de colonne",
      "users": "Utilisateurs",
      "visible_fields": "Détails du mobile",
      "work_positions": "Noms de poste"
    },
    "side_menu": {
      "collapse": "S’effondrer",
      "contracts": "Contrats",
      "datasheets": "Fiche technique",
      "expand": "Développer",
      "report": "Rapports",
      "search": "Chercher",
      "settings": "Réglages",
      "template": {
        "asset": "Les atouts",
        "contract_service": "Services contractuels",
        "datasheet": "Feuilles de données",
        "estimate_service": "Estimer les services",
        "inspection": "Inspections",
        "performance": "Construction",
        "report": "Rapports",
        "request": "Ordres de travail",
        "scoping": "Préparation du travail",
        "valuation": "Évaluation"
      },
      "templates": "Modèles"
    },
    "side_panel": {
      "close": "Fermer le panneau latéral",
      "collapse": "Réduire le panneau latéral",
      "expand": "Développer le panneau latéral",
      "tabs": {
        "category_filter": "Filtre de catégorie",
        "gallery": "Galerie",
        "info": "Info",
        "inspections": "Inspections",
        "no_categories": "Aucune catégorie n'est disponible",
        "no_templates": "Il n'y a pas de modèles disponibles",
        "preview_details": "Détails de l’aperçu",
        "progress": "Progrès",
        "template_filter": "Filtre de modèle"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "Toutes les sous-demandes",
        "back": "Tous les préparatifs de travail",
        "title": "sous demandes"
      },
      "left_panel": {
        "add_scoping": "Ajouter un élément de préparation du travail",
        "allocation": "Allocation",
        "cards_header_title": "Préparations de travail",
        "summary": "Sommaire",
        "total_allocation": "Allocation totale"
      },
      "scope_card": {
        "scope": "Préparation du travail"
      }
    },
    "tabs": {
      "error": "Impossible de charger les onglets"
    },
    "templates": {
      "asset": "Les atouts",
      "contract_service": "Services contractuels",
      "datasheet": "Feuilles de données",
      "estimate_service": "Estimer les services",
      "inspection": "Inspections",
      "no_template": "Vous devez créer un modèle",
      "progress": "Construction",
      "progress_service": "Évaluation",
      "report": "rapports",
      "request": "Ordres de travail",
      "scoping": "Préparation du travail",
      "title": "Modèles pour %{type}"
    },
    "tickets": {
      "icon_alt": "Une icône de billet",
      "title": "Des billets"
    },
    "timeline": {
      "created": "Créé",
      "day_ago_singular": "X__il y a 0 jour",
      "day_until_singular": "Dans un délai de %{daysUntil} jours",
      "days_ago": "Il y a %{daysAgo} jours",
      "days_until": "Dans un délai de %{daysUntil} jours",
      "discipline_check_done": "Contrôle disciplinaire : terminé",
      "discipline_check_pending": "Contrôle disciplinaire : en attente",
      "discipline_check_rejected": "Contrôle disciplinaire : rejeté",
      "estimate_authorized": "Estimation : Autorisé",
      "estimate_done": "Estimation : Terminé",
      "estimate_on_hold": "Estimation : En attente",
      "estimate_pending": "Estimation : En attente",
      "estimate_pending_authorization": "Estimation : Autorisation en attente",
      "estimate_progress": "Estimation : En cours",
      "performance": "Construction",
      "progress": "Progrès",
      "recycle_done": "Recycler : terminé",
      "recycle_recycling": "Recycler : Recyclage",
      "recycling": "Recyclage",
      "rejected": "Rejeté",
      "request": "Demande de service",
      "responsible": "Responsable",
      "schedule": "Calendrier",
      "scoping": "Préparation du travail",
      "title": "Chronologie des ordres de travail",
      "today": "Aujourd’hui"
    },
    "top_menu": {
      "authorization": "Autorisation",
      "datasheets": "Feuilles de données",
      "eav_templates": "Modèles",
      "estimates": "Budget",
      "inspections": "Visites Chantier",
      "no_datasheets": "Aucune fiche technique trouvée",
      "no_reports": "Aucun rapport trouvé",
      "performances": "Construction",
      "plannings": "Planification",
      "reports": "Rapports",
      "requests": "Ordres de travail",
      "scaffoldings": "Journal d’échafaudage",
      "scopes": "Préparation du travail",
      "workflow": "Flux de travail"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "À propos de la version",
      "clean_preferences": "Nettoyer les préférences",
      "logout": "Se déconnecter",
      "notification": {
        "clean_preferences": "Préférences nettoyées",
        "error": "Erreur lors de la tentative de nettoyage des préférences"
      },
      "preferences": "Préférences de grille",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Mettre à jour le profil"
    },
    "work_order_attachments": {
      "subtitle": "Voir les documents de l’ordre de travail correspondant pour",
      "title": "Pièces jointes aux ordres de travail"
    }
  },
  "nb": {
    "actions": {
      "alternate_view": "Alternativ visning",
      "approve": "Vedta",
      "back_to": "Tilbake til %{page}",
      "bulk_editing": "Bulkredigering",
      "cancel": "Avbryt",
      "choose_entity": "Velg %{entity}",
      "clear": "Klar",
      "clear_all_filters": "Fjern alle filtre",
      "confirm": "Bekrefte",
      "create": "Skape",
      "create_inspection": "Opprett inspeksjon",
      "delete": "Slett",
      "discard": "Kast",
      "dismantle": "Demontere",
      "done": "Ferdig",
      "duplicate": "Duplisere",
      "duplicate_services": "Dupliserte tjenester",
      "duplicate_with_parts": "Dupliser med deler",
      "edit": "Redigere",
      "export": "Eksporter til .xls",
      "export_template": "Last ned mal .xls",
      "filter_pinned_work_orders": "Filtrer festede arbeidsordrer",
      "flag_filter": "Flaggfilter",
      "generate_work_package": "Generer arbeidspakke",
      "import": "Importer fra .xls",
      "import_file": "Importer fil",
      "loading": "Laster",
      "more": "Mer",
      "new": "Lag ny",
      "next": "neste",
      "no": "Nei",
      "past": {
        "cancel": "kansellert",
        "complete": "fullført",
        "confirm": "bekreftet",
        "delete": "slettet",
        "destroy": "ødelagt",
        "dismantle": "demontert",
        "duplicate_with_parts": "Duplisert med delene",
        "ready_to_dismantle": "klar til å demontere",
        "reject": "avvist",
        "revert": "tilbakeført",
        "update": "oppdatert"
      },
      "perform_discipline_check": "Utfør disiplinsjekk",
      "pin_request": "Filtrer etter valgte forespørsler",
      "prev": "Forrige",
      "ready": "Klar",
      "ready_for_discipline": "Klar for disiplinsjekk",
      "ready_to_dismantle": "Klar til å demontere",
      "recycle": "Resirkulere",
      "reject": "Avvis",
      "remove_recycle": "Fjern resirkulering",
      "reset": "Nullstille",
      "retrieving": "Henter ...",
      "revert": "Tilbakestill",
      "revert_to_erected": "Gå tilbake til Erected",
      "save": "Lagre",
      "save_button_keyboard": "Lagre (Ctrl + Enter)",
      "save_exit": "Lagre og lukk",
      "save_new": "Legg til en til",
      "saving": "Lagrer ...",
      "schedule": "Rute",
      "show_all_work_orders": "Vis alle arbeidsordrer",
      "skip": "Hopp over",
      "status_filter": "Statusfilter",
      "unpin_request": "Fjern filter etter forespørsler",
      "unpin_selected_request": "Fjern filter for %{numSelectedRequests} valgte forespørsler",
      "unsaved_changes": "Ulagrede rader",
      "upload_file": "Last opp fil",
      "upload_picture": "Last opp bilde",
      "yes": "Ja"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Er ikke mulig å duplisere en gruppert arbeidsordre",
      "cant_duplicate_progress": "Kan ikke duplisere tjenestene på grunn av følgende feil ...",
      "cant_duplicate_request": "Kan ikke duplisere forberedelser, anslag og tjenester på grunn av følgende feil...",
      "cant_duplicate_scope": "Kan ikke duplisere tjenestene på grunn av følgende feil ...",
      "only_estimate_service_copy": "Bare elementet Estimate Detail prøver å bli kopiert.",
      "only_progress_copy": "Bare Fremgangen prøver å bli kopiert.",
      "only_request_copy": "Bare arbeidsordren prøver å bli kopiert.",
      "only_scope_copy": "Bare elementet Arbeidsforberedelse prøver å bli kopiert."
    },
    "cancel_modal": {
      "cancel": "Ikke nå",
      "description": "Er du sikker på at du vil avbryte %{model} \"%{id}\"?",
      "ok": "Ja, avbryt",
      "title": "Avbryte varen?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Nei, avbryt",
        "description": "Er du sikker på at du vil tømme alle %{model}-poster?",
        "ok": "Ja, fjern alt",
        "title": "Fjern %{model}?"
      },
      "success_message": "%{model} fjernet vellykket!",
      "title": "Tøm poster"
    },
    "clear_template_preferences": {
      "cancel": "Nei, avbryt",
      "description": "Er du sikker på at du vil slette alle rutenettpreferanser for denne malen?",
      "ok": "Ja, fjern rutenettpreferanser",
      "title": "Fjern rutenettpreferanser"
    },
    "collections": {
      "clusters": "Klynger",
      "create_by_package": "Lag etter pakke",
      "duplicate": "Duplisere",
      "edit": "Redigere",
      "estimate": "anslag",
      "new": "Ny",
      "schedule": "Rute",
      "sub_requests": "Underforespørsler",
      "view": "Utsikt"
    },
    "complete_modal": {
      "cancel": "Ikke nå",
      "description": "Er du sikker på at du vil merke %{model} som fullført?",
      "ok": "Ja, merk som fullført",
      "title": "Merk som fullført?"
    },
    "complete_progress_modal": {
      "cancel": "Avbryt",
      "complete": "Å fullføre",
      "description": "Er du sikker på at du vil fullføre dette byggeelementet? Denne handlingen kan ikke angres.",
      "title": "Bekreft fullføring av byggeelementer"
    },
    "confirmation_modal": {
      "cancel": "Avbryt",
      "description": "Denne handlingen kan ikke angres!",
      "ok": "Ok",
      "title": "Er du sikker?"
    },
    "contract_services": {
      "title": "Kontrakttjenester"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Dataarkdetaljer"
      },
      "description": "Dataark",
      "id": "ID",
      "item": "punkt",
      "title": "Dataark"
    },
    "delete_attachment_modal": {
      "cancel": "Nei, behold vedlegget",
      "delete_successful": "Vedlegget ble slettet",
      "description": "Er du sikker på at du vil slette dette vedlegget?",
      "ok": "Ja, slett vedlegget",
      "title": "Vil du slette vedlegg?"
    },
    "delete_modal": {
      "cancel": "Nei, avbryt",
      "description": "Er du sikker på at du vil slette %{model} \"%{id}\"?",
      "ok": "Ja, slett",
      "title": "Vil du slette elementet?"
    },
    "delete_picture_modal": {
      "cancel": "Nei, behold bildet",
      "delete_successful": "Bildet ble slettet",
      "description": "Er du sikker på at du vil slette dette bildet?",
      "ok": "Ja, slett bildet",
      "title": "Slett bilde"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Godkjennelse"
      },
      "column_comment": {
        "add_comment": "Klikk for å legge til en kommentar",
        "cancel": "Kansellere",
        "comment": "Kommentar",
        "subtitle": "Legg til/rediger kommentar"
      },
      "columns_batch_assigner": {
        "actions": "handlinger",
        "add_action": "Legg til handling",
        "add_to_column": "Legg til i kolonne",
        "add_value_to": "Legg %{value} til",
        "column": "Kolonne",
        "done": "Lagre handling",
        "duplicate_services": "Dupliser Tjenester",
        "header": "Batchredigering",
        "multiple_success": "%{numberOfItems} varene ble oppdatert.",
        "new_value": "Ny verdi",
        "single_success": "1 vare ble oppdatert.",
        "subtitle": "Tilordne verdier til %{numberOfColumns} valgte kolonne(r)",
        "title": "Opprett handlinger",
        "update": "Oppdater"
      },
      "configure_print_grid_file": {
        "error_max_columns": "Du kan bare velge opptil %{maxColumns} kolonner.",
        "generate": "Generer fil",
        "header": "Skriv ut rutenett",
        "paper_size_title": "Papir størrelse",
        "pdf_file": {
          "information": "Informasjon",
          "pictures": "Bilder",
          "title": "Rutenett %{modelName} - %{numItems} elementer - Side %{pageNum} av %{totalPages}"
        },
        "select_columns_title": "Kolonner som skal skrives ut",
        "selected_columns": "Du har valgt %{numSelectedColumns} av %{maxColumns}.",
        "subtitle": "Velg opptil %{maxColumns} kolonner for utskrift",
        "title": "Konfigurer filen for utskrift"
      },
      "configure_print_list_file": {
        "big_picture_title": "Skrive ut bilder med bredden på siden?",
        "font_size_title": "Velg skriftstørrelse",
        "header": "Skriv ut liste",
        "hide_columns_title": "Inkluder skjulte kolonner",
        "large": "Stor",
        "medium": "Medium",
        "page_break_title": "Skrive ut 1 vare per side?",
        "small": "Liten"
      },
      "configure_work_package_file": {
        "attached_documents": "Vedlagte dokumenter",
        "attachments": "Vedlegg",
        "estimates": "Estimater",
        "estimates_price": "Estimert pris",
        "generate": "generere",
        "header": "Generer arbeidspakke",
        "hse_r": "HMS-R vurdering",
        "inspection_page_breaker": "Skriv ut en inspeksjon per side",
        "inspection_pictures": "Inkluder bilder og signaturer",
        "inspections": "Alle relaterte inspeksjoner",
        "subtitle": "Velg delene du vil skrive ut",
        "title": "Konfigurer arbeidspakkefil",
        "work_description": "arbeidsbeskrivelse"
      },
      "duplicate_services": {
        "title": "Dupliser Tjenester"
      }
    },
    "discard_modal": {
      "cancel": "Fortsetter redigering",
      "description": "Er du sikker på at du vil forkaste endringene?",
      "ok": "Ja, kast",
      "title": "Forkaste endringer"
    },
    "eav_templates": {
      "title": "Maler"
    },
    "empty_state": {
      "columns": "kolonner",
      "empty": "Ingen %{model}",
      "no_attachments": "Du har ikke lastet opp noen vedlegg ennå",
      "no_columns": "Det er ingen bildekolonne tilgjengelig",
      "no_discipline_template": "Ingen maler med filtrert arbeidsordredisiplin ble funnet.",
      "no_estimate_service_template": "Ingen anslagstjenestemal for den valgte arbeidsforberedelsesmalen ble funnet",
      "no_pictures": "Du har ikke lastet opp noen bilder i denne kolonnen ennå",
      "no_unselect": "Det er ikke mulig å fjerne valget av den festede forespørselen på denne siden. Du kan bare gjøre det på arbeidsordresiden eller ved å logge ut.",
      "not_created": "Du har ikke opprettet noen %{model} ennå",
      "pictures": "bilder"
    },
    "environment": {
      "project": {
        "select_project": "Velg prosjekt ..."
      },
      "select_project_subproject": "Velg prosjekt og delprosjekt",
      "subproject": {
        "select_subproject": "Velg delprosjekt ..."
      }
    },
    "estimate": {
      "accept": "Godta estimat",
      "all_estimates_approved": "Alle estimater ble godkjent.",
      "application": "applikasjon",
      "back": "Alle arbeidsordrer",
      "budget_hours": "Budsjett",
      "confirmation": {
        "cancel": "Nei, Avbryt",
        "confirm": "Ja, bekreft",
        "description": "Er du sikker på at du vil godkjenne dette anslaget for denne arbeidsordren?",
        "title": "Bekreft ordre"
      },
      "description": "Du har %{number}-tjenester for kassen. Vennligst sjekk detaljene nedenfor.",
      "equipment": "Utstyr",
      "estimate_details": "Estimeringsdetaljer",
      "estimate_success": "Anslag er godkjent",
      "info_message": "Denne estimeringstjenesten er allerede godkjent. Den er tilgjengelig for skrivebeskyttet.",
      "material": "Materiale",
      "no_estimate_message": "Det er ingen anslag knyttet til denne arbeidsordren.",
      "norm_hours": "Norm Hours",
      "others": "Andre",
      "pdf_file": {
        "title": "Estimatdetaljer - Side %{pageNum} av %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Disiplin",
        "functional_location": "Funksjonell plassering",
        "jobcard_status": "Jobbkortstatus",
        "ke_priority": "KE Prioritet",
        "main_area_location": "Hovedområde og plassering",
        "main_work_center": "Hovedarbeidssenter",
        "maintenance_plant": "Vedlikeholdsanlegg",
        "not_defined": "Ikke definert ennå",
        "order_finish": "Bestilling Fullfør",
        "order_start": "Bestilling Start",
        "priority_sequence": "Prioritert sekvens",
        "project": "Prosjekt",
        "project_type": "Prosjekttype",
        "report": "Rapportere",
        "report_date": "Rapportdato",
        "responsible_engineer": "Ansvarlig ingeniør",
        "status_completed": "Fullført",
        "status_issued_for_approval": "Utstedt for godkjenning",
        "status_issued_for_construction": "Utstedt for bygging",
        "subproject": "Delprosjekt",
        "system": "System",
        "template": "Mal",
        "title": "Arbeidsordreanslag",
        "work_order": "Arbeidsordre"
      },
      "pdf_summary": {
        "title": "Overslagssammendrag"
      },
      "save_draft": "Lagre utkast",
      "service_details": "Tjenestedetaljer",
      "some_estimates_not_approved": "Noen estimater kunne ikke godkjennes. Sjekk feilloggene.",
      "team_target_hours": "Lagets måltimer",
      "title": "Kassetjeneste",
      "tooltip": {
        "application": "applikasjon",
        "equipment": "Utstyr",
        "material": "Materiale",
        "others": "Andre"
      },
      "total_approved": "Totalt godkjent",
      "total_pending": "Totalt enestående",
      "total_request": "Total forespørsel",
      "use_construction_prices": "Inkludere byggepriser?"
    },
    "estimate_services": {
      "application_price": "Søknadspris",
      "automatically_prices": "Automatisk beregnede priser",
      "back": "Alle arbeidsforberedelser",
      "budget_target_hours": "Budsjettmåltimer",
      "choose_modal": {
        "package": "Enkelt detaljpakke",
        "package_description": "Oppretting av flere tjenester basert på samme detalj",
        "single": "Enkel detalj",
        "single_description": "Oppretting av bare én detalj",
        "title": "Lag ny"
      },
      "contract_service": "Kontraktservice",
      "crew_size": "Mannskap størrelse",
      "crews": "Antall mannskaper",
      "description": "Beskrivelse",
      "description_label": "Beskrivelse",
      "discipline": "Disiplin",
      "equipment_price": "Utstyrspris",
      "estimate": "Kassetjeneste",
      "estimate_services_detail": {
        "side_panel_title": "Tjenestens detaljer"
      },
      "factor": "Faktor",
      "flag": "Flagg",
      "id": "ID",
      "item_no": "Varenr.",
      "materials_price": "Materialpris",
      "norm_hours": "Norm Hours",
      "other_price": "Annen pris",
      "prices": "Priser",
      "print": "Skrive ut",
      "quantity": "Mengde",
      "service": "Service",
      "service_no": "Tjeneste nr.",
      "service_package": "Servicepakke",
      "statuses": {
        "inspected_flag": "Inspeksjon venter"
      },
      "team_target_hours": "Lagets måltimer",
      "title": "Omfangstjenester",
      "title_packageable": "Anslå tjenester etter tjenestepakke",
      "total": "Total",
      "unit": "Enhet",
      "weight": "Vekt",
      "working_hours": "Arbeidstid"
    },
    "fields": {
      "created_at": "Opprettet",
      "image_not_found": "Bildet ble ikke funnet",
      "na": "Ikke relevant",
      "signature": "Signatur.jpg",
      "updated_at": "Oppdatert"
    },
    "filter_steps_modal": {
      "filter_result": "Filtrer resultat",
      "result_subtitle": "Sammendrag av valgene dine",
      "step": "Steg",
      "subtitle": "Velg %{title}"
    },
    "filtering": {
      "between": " det er mellom",
      "contains": " som inneholder ",
      "eq": " det er lik ",
      "invalid_date": "For å filtrere riktig, må startdato og sluttdato fylles ut",
      "invalid_value": "ugyldig verdi",
      "neq": " det er ikke lik "
    },
    "forgot_password": {
      "back_to_login": "Tilbake til innlogging",
      "button": "Send meg instruksjoner for tilbakestilling av passord",
      "feedback": "En melding med instruksjoner for å tilbakestille passordet ditt er sendt til den oppgitte e-postadressen.",
      "placeholder": "Din epost",
      "subtitle": "Vennligst skriv inn e-postadressen din for å motta instruksjoner for tilbakestilling av passord.",
      "title": "Glemt passordet?"
    },
    "form": {
      "back": "Alle %{entity}",
      "cancel": "Avbryt",
      "create_entity": "Lag %{entity}",
      "create_error": "%{entity} kunne ikke opprettes",
      "create_success": "%{entity} ble opprettet",
      "duplicate_entity": "Dupliser %{entity}",
      "duplicate_success": "%{entity} og dens avhengigheter ble vellykket duplisert!",
      "general_information": "Generell informasjon",
      "hide": "Gjemme seg",
      "inputs": {
        "attachment": {
          "add_error": "Feil ved legging av fil",
          "add_evidence": "Legg til bevis på klientautorisasjonen",
          "allowed_extensions": "De tillatte utvidelsene er",
          "drag_drop": "Legg til filer ved å dra og slippe",
          "invalid_extension": "Filtype er ikke tillatt.",
          "max_size": "Filen må være mindre enn %{max_size}",
          "maximum_num_uploads_error": "Du kan bare laste opp til %{max_num}-filer per fleksibel kolonne.",
          "one_file": "Last opp én fil om gangen",
          "or_drop_file_here": "eller slipp filen her",
          "remove_error": "Kunne ikke fjerne",
          "remove_success": "Fjernet vellykket",
          "select_files": "Velg filer",
          "submit": "Send inn vedlegget ditt",
          "upload_error": "Opplasting mislyktes",
          "upload_success": "Lastet opp vellykket"
        },
        "contract_service": {
          "add": "Legg til en kontraktstjeneste",
          "subtitle": "Kontraktservice"
        },
        "datasheet_filter": {
          "add_filter": "Legg til filter",
          "title": "Dataarkfilter"
        },
        "date_range": {
          "between": "%{start} til %{end}",
          "end": "Sluttdato",
          "end_simplified": "Slutt",
          "start": "Startdato",
          "start_simplified": "Begynnelse"
        },
        "drop_formula": {
          "formula_select": "Velg en formel ..."
        },
        "errors": {
          "date_greater_than_max": "Datoen skal være på eller før %{max}",
          "date_less_than_min": "Datoen skal være på eller etter %{min}",
          "drop_formula_result": "%{field} beregnet formelresultat",
          "mandatory": "%{field} er påkrevd.",
          "not_found": "'%{value}' ble ikke funnet i referansen. Velg en ny."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "eller dra og slipp",
              "select_file": "Klikk for å laste opp"
            },
            "note": {
              "extensions": "Filen må være i %{extensions}",
              "max_size": "Maksimal filstørrelse %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Bilde"
          },
          "upload": {
            "error": {
              "invalid_picture": "Ugyldig type/størrelse",
              "multiple_fails": "Alle vedlagte filer har ugyldig type/størrelse",
              "multiple_files": "Noen vedlagte bilder har ugyldig type/størrelse, laster opp de gyldige",
              "title": "Opplasting mislyktes"
            },
            "success": {
              "multiple_files": "Flere filer ble lastet opp",
              "title": "Opplasting suksess"
            }
          }
        },
        "formula": {
          "description": "Dette er en formelkolonne, resultatet blir automatisk oppdatert",
          "icon": "klikk for å se ligningen",
          "result": "Resultat"
        },
        "gps": {
          "save_location": "Lagre beliggenhet",
          "search": "Søk etter sted ...",
          "without_location": "Uten beliggenhet"
        },
        "multiple_entries": {
          "add": "Legg til en annen",
          "empty_entry": {
            "scaffolding_type_id": "Velg en stillastype for å legge til handlinger"
          },
          "empty_inputs": "Inngangene til %{input} kreves i %{model}",
          "required": "Feltet \"%{field}\" er obligatorisk og må fylles ut"
        },
        "numeric": {
          "between": "Verdien skal være mellom %{min} og %{max}",
          "greater": "Verdien skal være større enn %{min}",
          "lower": "Verdien skal være lavere enn %{max}"
        },
        "picture": {
          "comment_placeholder": "Skriv en kommentar (valgfritt)",
          "edition_only": "Kun tilgjengelig ved redigering",
          "no_image_on_clipboard": "Ingen bildedata ble funnet på utklippstavlen. Kopier et bilde først.",
          "remove_error": "Kunne ikke fjerne",
          "remove_success": "Fjernet suksess",
          "upload_error": "Opplasting mislyktes",
          "upload_success": "Lastet opp Successfuly"
        },
        "placeholder": "Skriv inn %{field} her",
        "value_selector": {
          "apply": "Søke om",
          "done": "Ferdig",
          "search": "Søk på noe her ...",
          "waiting": "... Vente"
        }
      },
      "items": "Varer",
      "loading": "Laster inn ...",
      "loading_calculations": "Beregning av synlighet, nødvendige kolonner og standardverdier",
      "loading_contract": "Laster kontraktinformasjon ...",
      "schedule_entity": "Planlegg %{entity}",
      "schedule_success": "Forespørselen din om å planlegge %{entity} er fullført",
      "show": "Vise fram",
      "subtitle": "Oppgi grunnleggende informasjon om %{entity}.",
      "subtitle_error": "Skjemaet kunne ikke lastes inn, sjekk %{entity}- eller maldataene dine.",
      "update_entity": "Oppdater %{entity}",
      "update_error": "%{entity} kunne ikke oppdateres",
      "update_success": "%{entity} ble oppdatert"
    },
    "formula_modal": {
      "back_button_text": "Tilbake til skjemaet",
      "calculate": "Regne ut",
      "cancel": "Avbryt",
      "mandatory": "Du må fylle ut verdiene for følgende felt: %{fields}",
      "ok": "Lagre formel"
    },
    "grid": {
      "all": "Alle",
      "clear_filters": "Fjern filtre",
      "editable": {
        "error": {
          "mandatory": "Minst ett obligatorisk felt var tomt"
        },
        "missing_required": "%{number} mangler obligatoriske celler på denne raden",
        "title": "Redigeringsmodus",
        "update": {
          "error": "%{quantity} kunne ikke lagres. Sjekk årsaken på venstre side av hver uthevet rad",
          "success": "%{quantity} poster ble lagret"
        }
      },
      "empty_after_filtering": "Det er ingen poster for de anvendte filtrene",
      "failed_saving_preferences": "Preferansene ble ikke lagret!",
      "flag_filter": "Flaggfilter",
      "grid_filters": {
        "menu_title": "Egendefinerte filtre"
      },
      "menu": {
        "visibility": "Kolonner"
      },
      "of": "av",
      "other": "Andre",
      "productivity_factor": "Produktivitetsfaktor",
      "quick_pin_popup": {
        "all_work_orders": "Alle OTer",
        "clear_all": "Fjern alle",
        "no_pinned_requests": "Du har ikke festet arbeidsordrer ennå",
        "pinned_work_orders": "Festede OTer",
        "quick_pin": "Hurtignål",
        "search_and_pin": "Søk og fest OT"
      },
      "saved_preferences": "Lagret!",
      "saving_preferences": "Lagrer innstillinger...",
      "select_items": "Velg elementer",
      "selected": "valgt",
      "selecteds": "utvalgte",
      "status_filter": "Statusfilter",
      "totals": "Total"
    },
    "history_form": {
      "create_title": "Legg til en ny fremgang",
      "edit_title": "Rediger fremgangen din",
      "subtitle": "Sett inn en fremgang etter mengde eller prosent for aktiviteten din."
    },
    "history_popup": {
      "back": "Tilbake",
      "comments": "Kommentarer",
      "crew_size": "Mannskap størrelse",
      "daily_hours": "Daglige timer",
      "date": "Dato",
      "form_create_progress_service_title": "Opprett byggetjenestevarehistorikk",
      "form_create_progress_title": "Lag fremdriftshistorikk",
      "form_edit_progress_service_title": "Rediger byggevarehistorikk",
      "form_edit_progress_title": "Rediger historikk for fremdriftselementer",
      "form_error_body": "Alle obligatoriske felt på skjemaet må fylles ut.",
      "form_error_title": "Det var ikke mulig å lagre historikkskjemaet",
      "form_subtitle": "Sett inn en historie etter antall eller prosent for aktiviteten din.",
      "modal_create_title": "Lag historie",
      "modal_edit_title": "Rediger historie",
      "number_of_crews": "Antall mannskaper",
      "number_of_workers": "Antall arbeidere",
      "other_value": "Annen verdi",
      "percentage": "Prosentdel",
      "progress_by": "Fremgang av",
      "quantity": "Mengde",
      "singular": "Historie",
      "working_hours": "Arbeidstid"
    },
    "home": {
      "available_on": "tilgjengelig på",
      "banner_description": "Administrer oppgaver, følg prosesser og få de beste resultatene ved hjelp av en enkelt plataform",
      "create_request": {
        "text": "Først må du opprette en arbeidsordre fra klienten i DPMS med detaljene om bestillingen din.",
        "title": "Opprett en arbeidsordre"
      },
      "dpms_mobile": "DPMS mobilapp",
      "getting_started": "Starter",
      "home_title": "Hjem",
      "inspection": {
        "text": "Gjennomføre kvalitetsinspeksjoner i felt og registrere hindringer.",
        "title": "Undersøkelse"
      },
      "inspection_calendar": {
        "calendar": "Kalender",
        "table": "Bord",
        "title": "Inspeksjonsplan"
      },
      "performance": {
        "text": "Registrer fremgang og tid basert på de planlagte aktivitetene til hvert lag, og mål produktiviteten.",
        "title": "Konstruksjon"
      },
      "scoping": {
        "text": "Utarbeide en arbeidsordre fra kunden. Tildel tjenester og tjenestepakker fra kontrakten din.",
        "title": "Arbeidsforberedelse"
      },
      "welcome_user": "Velkommen, %{user}"
    },
    "importation_drop": {
      "choose_error": "Velg et element"
    },
    "importation_popup": {
      "file_upload_message": "Klikk eller dra filen til dette området for å laste opp",
      "importation_error": "Du kan se gjennom denne meldingen på jobbsiden.",
      "importation_success": "Importsuksess.",
      "importation_timeout": "Importen tar for lang tid. Sjekk statusen på Jobbsiden.",
      "instructions": "Filene må være .xls og ikke være større enn xxmb. <br> .xls-filen din skal inneholde kolonnen - Dato, klient, prosjekt, oppgave, notater .... rekkefølgen på malen. <br> Dato skal være i - ÅÅÅÅ / MM / DD.",
      "requirements": "Krav",
      "submit_spreadsheet": "Send inn regnearket"
    },
    "inspections": {
      "acceptance": "Godkjennelse",
      "acceptance_error": {
        "error_description": "Inspeksjonen - %{item} kunne ikke oppdateres fordi %{reason}",
        "title": "Vi kan ikke fullføre"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "Du kan tilbakestille dette senere hvis du trenger det.",
          "reject": "Er du sikker på at du vil avvise denne/disse inspeksjonen(e)?"
        },
        "inspection_acceptance": "Godkjenning av inspeksjon",
        "items": "%{items} elementer",
        "reject": "Avvis",
        "subtitle": "Gi informasjon om inspeksjonen for å fortsette med aksept."
      },
      "actions": {
        "approve": "Vedta",
        "more": "Mer",
        "reject": "Avvis"
      },
      "choose_modal": {
        "schedule": "Planlegg inspeksjonselementer",
        "schedule_description": "Planlegg en eller flere inspeksjoner basert på datointervall",
        "single": "Enkelt inspeksjonsgjenstand",
        "single_description": "Opprettelse av bare én inspeksjon",
        "title": "Opprett en inspeksjon som:"
      },
      "description": "Undersøkelse",
      "description_title": "Beskrivelse",
      "every": "Hver",
      "id": "ID",
      "inspect_date": "Inspeksjonsdato",
      "inspect_status_id": "Status",
      "inspected": "Inspisert",
      "inspected_detail": "Inspisert detalj",
      "inspected_item": "Inspisert gjenstand",
      "inspected_modules": {
        "estimate_services": "Estimatdetaljer",
        "performance": "Konstruksjon",
        "progress": "Konstruksjon",
        "progress_services": "Byggetjenester",
        "request": "Arbeidsordre",
        "scaffolding": "Stillas",
        "scope": "Arbeidsforberedelse"
      },
      "inspected_services": "Inspiserte tjenester",
      "inspected_track": {
        "area": "Område",
        "comments": "Kommentarer",
        "description": "Beskrivelse",
        "item": "Vare %{item}",
        "no_load": "Kunne ikke laste inspisert sporblokk: manglende data",
        "number_of_tags": "Antall tagger",
        "reason": "Grunnen til",
        "subarea": "Delområde"
      },
      "inspection_block": {
        "inspection_date": "Inspeksjonsdato",
        "missing_date": "Manglende dato",
        "not_selected_yet": "Ikke valgt ennå",
        "responsible": "Ansvarlig"
      },
      "inspections_detail": {
        "side_panel_title": "Inspeksjonens detaljer"
      },
      "module": "Modul",
      "notifications": {
        "approve_successful": "Inspeksjonen ble vellykket godkjent",
        "reject_successful": "Inspeksjonen ble avvist"
      },
      "pending_inspections": "Venter på inspeksjoner",
      "recurrency_interval": "Gjentaksintervall",
      "related_inspections": {
        "missing_date": "Manglende dato",
        "no_related": "Ingen relaterte inspeksjoner funnet",
        "no_responsible": "Ingen ansvarlig",
        "title": "Relaterte inspeksjoner"
      },
      "repeat": "Gjenta",
      "repeat_every_value": {
        "day": "Dag",
        "month": "Måned",
        "week": "Uke",
        "year": "År"
      },
      "repeats_on": "Gjenta på",
      "request_id": "DPMSID",
      "responsible_id": "Ansvarlig",
      "schedule_detail": "Tidsplandetaljer",
      "schedule_form": {
        "acceptance_step": "Definer aksept (valgfritt)",
        "detailed_item_step": "Velg Inspeksjonsforberedelse/Byggeelement",
        "detailed_template_step": "Velg Inspeksjonsforberedelse/Konstruksjonsmal",
        "further_step_information": "De fleksible feltene er klare til å fylles ut hver for seg etter trinnet for gjentakelse",
        "general_step": "Definer inspeksjon Generell informasjon",
        "hints": "For månedlige og årlige gjentakelser vil systemet planlegge inspeksjonen som samsvarer med startdatoen gitt i intervallfeltet",
        "monthly_hint": "Gjenta månedlig hver dag %{day}",
        "recurrence_step": "Tilbakefall"
      },
      "scheduled_exception": "planlagt",
      "select_module_item": "Velg et modulelement for å se detaljene her",
      "statuses": {
        "approved": "Godkjent",
        "completed": "Venter på godkjenning",
        "overdue": "Forfalt",
        "pending": "I påvente av",
        "progress": "I prosess",
        "rejected": "Avvist",
        "scheduled": "Planlagt"
      },
      "template": "Mal",
      "title": "inspeksjoner"
    },
    "inspects": {
      "statuses": {
        "approved": "Godkjent",
        "completed": "Venter på godkjenning",
        "pending": "I påvente av",
        "progress": "I prosess",
        "rejected": "Avvist"
      }
    },
    "kendo": {
      "upload": "Klikk, dra eller lim inn filer her for å laste opp"
    },
    "kendo_translations": {
      "calendar": {
        "today": "I dag"
      },
      "dateinput": {
        "decrement": "Reduser verdien",
        "increment": "Øk verdien"
      },
      "datepicker": {
        "toggleCalendar": "Bytt kalender"
      },
      "daterangepicker": {
        "end": "Slutt",
        "separator": 0,
        "start": "Start",
        "swapStartEnd": "Bytt start- og sluttverdier"
      },
      "datetimepicker": {
        "cancel": "Avbryt",
        "date": "Dato",
        "set": "Sett",
        "time": "Tid",
        "toggleDateTimeSelector": "Bytt dato- og tidvelger"
      },
      "dropdowns": {
        "clear": "Klar",
        "nodata": "Ingen Data Funnet"
      },
      "editor": {
        "addColumnAfter": "Legg til kolonne til høyre",
        "addColumnBefore": "Legg til kolonne til venstre",
        "addRowAfter": "Legg til rad nedenfor",
        "addRowBefore": "Legg til rad over",
        "alignCenter": "Sentertekst",
        "alignJustify": "Rettferdiggjøre",
        "alignLeft": "Juster teksten igjen",
        "alignRight": "Juster teksten til høyre",
        "bold": "Modig",
        "bulletList": "Sett inn uordnet liste",
        "createTable": "Lag et bord",
        "deleteColumn": "Slett kolonne",
        "deleteRow": "Slett rad",
        "deleteTable": "Slett tabellen",
        "fontName": "Skriftnavn",
        "fontSize": "Skriftstørrelse",
        "format": "Format",
        "hyperlink": "Sett inn hyperkobling",
        "hyperlink-dialog-cancel": "Avbryt",
        "hyperlink-dialog-content-address": "nettadresse",
        "hyperlink-dialog-content-newwindow": "Åpne lenke i nytt vindu",
        "hyperlink-dialog-content-title": "Tittel",
        "hyperlink-dialog-insert": "Sett inn",
        "hyperlink-dialog-title": "Sett inn hyperkobling",
        "image": "Sett inn bilde",
        "image-address": "nettadresse",
        "image-altText": "Alternativ tekst",
        "image-cancel": "Avbryt",
        "image-dialog-title": "Sett inn bilde",
        "image-height": "Høyde (px)",
        "image-insert": "Sett inn",
        "image-title": "Tittel",
        "image-width": "Bredde (px)",
        "indent": "Innrykk",
        "insertTableHint": "Lag en {0} x {1} tabell",
        "italic": "Kursiv",
        "mergeCells": "Slå sammen celler",
        "orderedList": "Sett inn bestilt liste",
        "outdent": "Utelukket",
        "redo": "Gjøre om",
        "splitCell": "Delt celle",
        "strikethrough": "Gjennomtrenging",
        "subscript": "Abonnement",
        "superscript": "Overskrift",
        "underline": "Understreke",
        "undo": "Angre",
        "unlink": "Fjern hyperkobling",
        "viewHtml": "Vis HTML",
        "viewHtml-cancel": "Avbryt",
        "viewHtml-dialog-title": "Vis HTML",
        "viewHtml-update": "Oppdater"
      },
      "filter": {
        "addExpression": "Legg til uttrykk",
        "addGroup": "Legg til gruppe",
        "afterOperator": "Er ute etter",
        "afterOrEqualOperator": "Er etter eller lik",
        "andLogic": "Og",
        "beforeOperator": "Er før",
        "beforeOrEqualOperator": "Er før eller lik",
        "close": "Lukk",
        "containsOperator": "Inneholder",
        "endsWithOperator": "Slutter med",
        "eqOperator": "Er lik",
        "gtOperator": "Er større enn",
        "gteOperator": "Er større enn eller lik",
        "isEmptyOperator": "Er tom",
        "isFalse": "Er nei",
        "isNotEmptyOperator": "Er ikke tom",
        "isNotNullOperator": "Er ikke null",
        "isNullOperator": "Er null",
        "isTrue": "Er ja",
        "ltOperator": "Er mindre enn",
        "lteOperator": "Er mindre enn eller lik",
        "notContainsOperator": "Inneholder ikke",
        "notEqOperator": "Er ikke lik",
        "orLogic": "Eller",
        "startsWithOperator": "Begynner med"
      },
      "grid": {
        "filterAfterOperator": "Er ute etter",
        "filterAfterOrEqualOperator": "Er etter eller lik",
        "filterBeforeOperator": "Er før",
        "filterBeforeOrEqualOperator": "Er før eller lik",
        "filterBetweenOperator": "Er mellom",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Klar",
        "filterContainsOperator": "Inneholder",
        "filterEndsWithOperator": "Slutter med",
        "filterEqOperator": "Er lik",
        "filterGtOperator": "Er større enn",
        "filterGteOperator": "Er større enn eller lik",
        "filterIsEmptyOperator": "Er tom",
        "filterIsFalse": "Er nei",
        "filterIsNotEmptyOperator": "Er ikke tom",
        "filterIsNotNullOperator": "Er ikke null",
        "filterIsNullOperator": "Er null",
        "filterIsTrue": "Er ja",
        "filterLtOperator": "Er mindre enn",
        "filterLteOperator": "Er mindre enn eller lik",
        "filterNotContainsOperator": "Inneholder ikke",
        "filterNotEqOperator": "Er ikke lik",
        "filterStartsWithOperator": "Begynner med",
        "filterSubmitButton": "Filter",
        "filterTitle": "Filter",
        "groupPanelEmpty": "Dra en kolonneoverskrift og slipp den her for å gruppere etter den kolonnen",
        "noRecords": "Ingen poster tilgjengelig.",
        "pagerFirstPage": "Gå til første side",
        "pagerInfo": "{0} - {1} av {2} elementer",
        "pagerItemsPerPage": "elementer per side",
        "pagerLastPage": "Gå til siste side",
        "pagerNextPage": "Gå til neste side",
        "pagerOf": "av",
        "pagerPage": "Side",
        "pagerPreviousPage": "Gå til forrige side"
      },
      "numerictextbox": {
        "decrement": "Reduser verdien",
        "increment": "Øk verdien"
      },
      "pager": {
        "firstPage": "Gå til første side",
        "info": "{0} - {1} av {2} elementer",
        "itemsPerPage": "elementer per side",
        "lastPage": "Gå til siste side",
        "nextPage": "Gå til neste side",
        "of": "av",
        "page": "Side",
        "previousPage": "Gå til forrige side"
      },
      "scheduler": {
        "agendaViewTitle": "Dagsorden",
        "allDay": "hele dagen",
        "allEvents": "Alle arrangementer",
        "dateTitle": "Dato",
        "dayViewTitle": "Dag",
        "deleteConfirmation": "Er du sikker på at du vil slette denne hendelsen?",
        "deleteDialogTitle": "Slett hendelse",
        "deleteOccurrence": "Slett nåværende forekomst",
        "deleteRecurringConfirmation": "Vil du bare slette denne hendelsen eller hele serien?",
        "deleteRecurringDialogTitle": "Slett tilbakevendende element",
        "deleteSeries": "Slett serien",
        "deleteTitle": "Slett",
        "editorCancel": "Avbryt",
        "editorDelete": "Slett",
        "editorEventAllDay": "Heldags hendelse",
        "editorEventDescription": "Beskrivelse",
        "editorEventEnd": "Slutt",
        "editorEventEndTimeZone": "Slutt tidssone",
        "editorEventSeparateTimeZones": "Avslutt i en annen tidssone",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start tidssone",
        "editorEventTimeZone": "Spesifiser tidssone",
        "editorEventTitle": "Tittel",
        "editorOccurrence": "Rediger gjeldende forekomst",
        "editorRecurringConfirmation": "Vil du redigere bare denne hendelsesforekomsten eller hele serien?",
        "editorRecurringDialogTitle": "Rediger tilbakevendende element",
        "editorSave": "Lagre",
        "editorSeries": "Rediger serien",
        "editorTitle": "Begivenhet",
        "editorValidationEnd": "Sluttid må være etter starttid.",
        "editorValidationRequired": "Feltet er påkrevet.",
        "editorValidationStart": "Starttid må være før sluttid.",
        "eventTitle": "Begivenhet",
        "monthViewTitle": "Måned",
        "nextTitle": "Neste",
        "noEvents": "ingen hendelser",
        "previousTitle": "Tidligere",
        "recurrenceEditorDailyInterval": "dager)",
        "recurrenceEditorDailyRepeatEvery": "Gjenta hver",
        "recurrenceEditorEndAfter": "Etter",
        "recurrenceEditorEndLabel": "Slutt",
        "recurrenceEditorEndNever": "Aldri",
        "recurrenceEditorEndOccurrence": "forekomst (er)",
        "recurrenceEditorEndOn": "På",
        "recurrenceEditorFrequenciesDaily": "Daglig",
        "recurrenceEditorFrequenciesMonthly": "Månedlig",
        "recurrenceEditorFrequenciesNever": "Aldri",
        "recurrenceEditorFrequenciesWeekly": "Ukentlig",
        "recurrenceEditorFrequenciesYearly": "Årlig",
        "recurrenceEditorMonthlyDay": "Dag",
        "recurrenceEditorMonthlyInterval": "måneder)",
        "recurrenceEditorMonthlyRepeatEvery": "Gjenta hver",
        "recurrenceEditorMonthlyRepeatOn": "Gjenta på",
        "recurrenceEditorOffsetPositionsFirst": "Først",
        "recurrenceEditorOffsetPositionsFourth": "Fjerde",
        "recurrenceEditorOffsetPositionsLast": "Siste",
        "recurrenceEditorOffsetPositionsSecond": "Sekund",
        "recurrenceEditorOffsetPositionsThird": "Tredje",
        "recurrenceEditorRepeat": "Gjenta",
        "recurrenceEditorWeekdaysDay": "Dag",
        "recurrenceEditorWeekdaysWeekday": "Ukedag",
        "recurrenceEditorWeekdaysWeekendday": "Helgedag",
        "recurrenceEditorWeeklyInterval": "uke (r)",
        "recurrenceEditorWeeklyRepeatEvery": "Gjenta hver",
        "recurrenceEditorWeeklyRepeatOn": "Gjenta på",
        "recurrenceEditorYearlyInterval": "år",
        "recurrenceEditorYearlyOf": "av",
        "recurrenceEditorYearlyRepeatEvery": "Gjenta hver",
        "recurrenceEditorYearlyRepeatOn": "Gjenta på",
        "showFullDay": "Vis hele dagen",
        "showWorkDay": "Vis åpningstider",
        "timeTitle": "Tid",
        "timelineViewTitle": "Tidslinje",
        "today": "I dag",
        "weekViewTitle": "Uke",
        "workWeekViewTitle": "Arbeidsuke"
      },
      "sortable": {
        "noData": "Ingen data"
      },
      "timepicker": {
        "cancel": "Avbryt",
        "now": "NÅ",
        "selectNow": "Velg nå",
        "set": "Sett",
        "toggleClock": "Veksle klokke",
        "toggleTimeSelector": "Bytt TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "Er ute etter",
        "filterAfterOrEqualOperator": "Er etter eller lik",
        "filterBeforeOperator": "Er før",
        "filterBeforeOrEqualOperator": "Er før eller lik",
        "filterBooleanAll": "(Alle)",
        "filterClearButton": "Klar",
        "filterContainsOperator": "Inneholder",
        "filterEndsWithOperator": "Slutter med",
        "filterEqOperator": "Er lik",
        "filterGtOperator": "Er større enn",
        "filterGteOperator": "Er større enn eller lik",
        "filterIsEmptyOperator": "Er tom",
        "filterIsFalse": "Er nei",
        "filterIsNotEmptyOperator": "Er ikke tom",
        "filterIsNotNullOperator": "Er ikke null",
        "filterIsNullOperator": "Er null",
        "filterIsTrue": "Er ja",
        "filterLtOperator": "Er mindre enn",
        "filterLteOperator": "Er mindre enn eller lik",
        "filterNotContainsOperator": "Inneholder ikke",
        "filterNotEqOperator": "Er ikke lik",
        "filterStartsWithOperator": "Begynner med",
        "noRecords": "Ingen poster tilgjengelig"
      },
      "upload": {
        "cancel": "Avbryt",
        "clearSelectedFiles": "Klar",
        "dropFilesHere": "eller slipp filen her",
        "files": "filer",
        "headerStatusUploaded": "Ferdig",
        "headerStatusUploading": "Laster opp ...",
        "invalidFileExtension": "Filtype er ikke tillatt.",
        "invalidFiles": "Ugyldig fil (er). Kontroller kravene til filopplasting.",
        "invalidMaxFileSize": "Filstørrelsen er for stor.",
        "invalidMinFileSize": "Filstørrelsen er for liten.",
        "remove": "Ta vekk",
        "retry": "Prøv på nytt",
        "select": "Velg Fil",
        "total": "Total",
        "uploadSelectedFiles": "Laste opp"
      }
    },
    "loading_batch_entities": {
      "page_of": "Side %{page} av %{total}",
      "title": "Henter sider"
    },
    "login": {
      "again": "For å fortsette, vennligst logg inn igjen",
      "continue_mykaefer": "Fortsett med MyKaefer",
      "copyright": "®%{year} Med enerett. Kaefer®",
      "forgot_password": "Glemt passord?",
      "iframe_info": "Ber om tilgang til siden: %{link}",
      "log_in": "Logg Inn",
      "password": "Passord",
      "title_1": "Et kraftig verktøy",
      "title_2": "for prosjektledelse",
      "username": "Brukernavn",
      "welcome_back": "Velkommen tilbake"
    },
    "main_dialog": {
      "failure_message": "Forespørselen din kunne ikke fullføres",
      "failure_title": "Failure",
      "loading_message": "Vent litt mens vi setter opp ting for deg!",
      "loading_title": "Laster ...",
      "service_title": "Venter på at jobben skal fullføres...",
      "success_message": "Forespørselen din har lyktes! Du kan nå fortsette jobben.",
      "success_title": "Vellykkede!"
    },
    "notification": {
      "access_denied": "Ingen tilgang",
      "access_denied_message": "Du har ikke tillatelse til å utføre denne handlingen. Kontakt administratoren for mer informasjon.",
      "action_success": "%{model} %{id} var %{action}",
      "bad_formula_setup": "Det er et oppsettproblem med formelen '%{title}', sjekk oppsettet ditt!",
      "correct_subproject": "Velg riktig delprosjekt for dette elementet",
      "dismiss_all": "Avvis alle",
      "error": "Feil",
      "error_cookies": "Vi klarte ikke å etablere kommunikasjon mellom kryss kilder. Gjennomgå innstillingene for informasjonskapsler i nettleseren din og deaktiver 'Tredjeparts cookies-blokkering'.",
      "error_job": "Mislyktes! Sjekk årsaken på Jobsiden.",
      "error_msg": "Unnskyld! Noe gikk galt!",
      "info": "Info",
      "large_file_body": "Bruk noen filtre for å gjøre utdatafilen mindre.",
      "large_file_title": "Rutenettet har mer enn 1000 poster",
      "page_denied": "Siden kunne ikke lastes inn.",
      "please": "Vær så snill",
      "select_subproject": "Velg et prosjekt og delprosjekt for å fortsette",
      "success": "Suksess",
      "success_msg": "Handling utført med suksess!",
      "warning": "Advarsel"
    },
    "orders": {
      "add_evidence": "Legg til bevis på klientautorisasjonen",
      "attachment": "Vedlegg",
      "comments": "Kommentarer",
      "date": "Dato",
      "drag_and_drop": "Legg til filer ved å dra og slippe",
      "recipient": "Mottaker",
      "responsible": "Ansvarlig",
      "title": "Bestillinger"
    },
    "performance": {
      "title": "Konstruksjon"
    },
    "performances": {
      "title": "Konstruksjoner"
    },
    "performances_wip": {
      "performance_item": "Byggevare",
      "title": "Konstruksjon"
    },
    "planning": {
      "item_no": "Varenr.",
      "service_no": "Tjeneste nr.",
      "title": "Planlegger",
      "view": "Planleggingsvisning"
    },
    "plannings": {
      "title": "Planlegger"
    },
    "print_grid": {
      "title": "Skriv ut rutenettet"
    },
    "print_list": {
      "loading": "Genererer dokumentet ditt. Vennligst vent et øyeblikk.",
      "title": "Skriv ut liste"
    },
    "progress": {
      "statuses": {
        "canceled": "Kansellert",
        "done": "Ferdig",
        "extra": "Ekstra",
        "in_progress": "Pågår",
        "pending": "I påvente av",
        "revised": "Revidert"
      }
    },
    "progress_histories": {
      "add_new_progress": "Legg til en ny fremgang",
      "empty_history": "Det er ingen tilknyttet historieelement, og nye elementer kan ikke opprettes",
      "hours": "Timer",
      "production_factor": "PF",
      "progress": "Framgang",
      "progress_badge": "Vare %{number}",
      "progress_history": "Fremgangshistorie",
      "progress_service_badge": "Tjeneste %{number}",
      "progress_service_history": "Tjenestehistorikk",
      "progress_service_title": "Tjenestehistorier",
      "progress_title": "Varehistorier",
      "quantity": "Mengde",
      "quantity_empty_history": "Det er ingen historieelement og det er ikke mulig å lage historier når antallet er null",
      "subtitle": "Administrer daglig fremgang basert på aktiviteter som er planlagt av teamet ditt."
    },
    "progress_services": {
      "actual_div_hours": "Faktiske / lagmåltid",
      "actual_div_quantity": "Faktisk / målmengde",
      "actual_hours": "Faktiske timer",
      "actual_quantity": "Faktisk mengde",
      "application_price": "Søknadspris",
      "back": "Alle konstruksjoner",
      "budget_target_hours": "Budsjettmåltimer",
      "canceled": "Avlyst",
      "choose_modal": {
        "package": "Enkelt detaljpakke",
        "package_description": "Oppretting av flere tjenester basert på samme detalj",
        "single": "Enkel detalj",
        "single_description": "Oppretting av bare én detalj",
        "title": "Lag ny"
      },
      "contract_service": "Kontraktservice",
      "contribution": "Bidrag",
      "contribution_info": "Verdier beregnes automatisk",
      "crew": "mannskap",
      "crew_size": "Mannskapsstørrelse",
      "crews": "mannskaper",
      "date_filter": {
        "all": "Alle",
        "to": "til",
        "today": "I dag",
        "tomorrow": "I morgen",
        "week": "Uke",
        "yesterday": "I går"
      },
      "date_filter_info": "Datofilter",
      "delta_hours": "Delta Hours",
      "delta_qtt": "Delta Mengde",
      "description": "Byggetjeneste",
      "discipline": "Disiplin",
      "end_date": "Sluttdato",
      "equipment_price": "Utstyrspris",
      "factor": "Faktor",
      "flag": "Flagg",
      "id": "ID",
      "materials_price": "Materialpris",
      "no_crews": "Antall mannskaper",
      "no_workers": "Antall arbeidere",
      "norm_hours": "Normtimer",
      "not_planned": "Ikke planlagt",
      "other_price": "Annen pris",
      "package_factor": "Faktor",
      "people": "mennesker",
      "person": "person",
      "pf": "PF",
      "pf_doing_bad": "Du har det ikke bra",
      "pf_doing_good": "Du har det kjempebra!",
      "pf_doing_neutral": "Kunne ikke beregne pf",
      "planned": "Planlagt",
      "planning_chart": {
        "actual": "Faktiske",
        "actual_qty": "Faktisk Antall",
        "date": "Dato",
        "forecasted": "Værvarsel",
        "forecasted_qty": "Forventet antall",
        "target": "Mål",
        "target_qty": "Antall mål",
        "title": "Planleggingsdiagram"
      },
      "planning_timeline": {
        "day": "dag",
        "day_advance": "dag i forveien",
        "day_delayed": "dag forsinket",
        "days": "dager",
        "days_advance": "dager i forveien",
        "days_delayed": "dager forsinket",
        "end_date": "Sluttdato",
        "forecasted": "Værvarsel",
        "on_time": "På tide",
        "remaining_days": "Gjenværende dager",
        "start_date": "Startdato",
        "title": "Planlegging av tidslinje"
      },
      "progress": "Framgang",
      "progress_services_detail": {
        "side_panel_title": "Tjenestens detaljer"
      },
      "quantity": "Mengde",
      "responsible": "Ansvarlig",
      "scoped_quantity": "Tilberedt mengde",
      "service": "Service",
      "service_package": "Servicepakke",
      "service_planning": "Tjenesteplanlegging",
      "service_progress": "Tjenesteframskritt",
      "service_weight": "Tjenestevekt",
      "start_date": "Startdato",
      "statuses": {
        "canceled": "Avbrutt",
        "done": "Fullført",
        "extra": "Ekstra",
        "in_planning": "Ikke planlagt",
        "inspected_flag": "Inspeksjon venter",
        "planned": "Planlagt"
      },
      "team_target_hours": "Lagets måltimer",
      "title": "Byggetjenester",
      "title_packageable": "Byggetjenester etter tjenestepakke",
      "total": "Total",
      "total_hours": "Totalt antall timer",
      "unit": "Enhet",
      "worker": "arbeider",
      "workers": "arbeidere",
      "working_hours": "Arbeidstid"
    },
    "progresses": {
      "actual_hours": "Faktiske timer",
      "cancel": "Avbryt",
      "choose_modal": {
        "single": "Et annet byggeelement",
        "single_description": "Oppretting av kun ett byggeelement"
      },
      "complete": "Fullstendig",
      "complete_services": "Komplette tjenester",
      "current_hours": "Gjeldende timer",
      "current_qty": "Nåværende mengde",
      "date_filter": {
        "all": "Alle",
        "current_week": "Gjeldende uke",
        "previous_week": "Forrige uke",
        "today": "I dag"
      },
      "date_filter_info": "Datofilter",
      "delta_hours": "Δ timer",
      "delta_qty": "Δ Antall",
      "description": "Beskrivelse",
      "edit_work_description": "Rediger arbeidsbeskrivelse",
      "extra": "Ekstra",
      "flag": "Flagg",
      "history": "Legg til ny fremgang",
      "id": "ID",
      "item": "Punkt",
      "label": "Merkelapp",
      "more_details": "se mer",
      "pf": "PF",
      "planned_hours": "Planlagte timer",
      "planned_qty": "Planlagt mengde",
      "preview_unavailable": "Ingen forhåndsvisning tilgjengelig",
      "progress": "Framgang",
      "progress_status_restriction": "Fremdriften skal ikke ha statusen Avbrutt eller Ferdig",
      "progresses_detail": {
        "side_panel_title": "Konstruksjonsdetaljer"
      },
      "remaining_hours": "Gjenstående timer",
      "request_status_restriction": "Arbeidsordre skal ha status i planlegging eller i konstruksjon",
      "revised": "Revidert",
      "success_work_description_edition": "Arbeidsbeskrivelsen ble redigert",
      "title": "Konstruksjoner",
      "work_description": "Arbeidsbeskrivelse"
    },
    "projects": {
      "workdays": {
        "fri": "Fre",
        "mon": "Man",
        "sat": "Lør",
        "sun": "Søn",
        "thu": "Tor",
        "tue": "Tir",
        "wed": "Ons"
      }
    },
    "requests": {
      "actual_hours": "Faktiske timer",
      "actual_progress": "Faktisk fremgang",
      "all": "Alle",
      "are_you_sure_complete": "Er du sikker på å fullføre denne arbeidsordren?",
      "are_you_sure_complete_no_history": "Er du sikker på å fullføre en arbeidsordre uten noen gitt fremdrift?",
      "are_you_sure_deletion": "Er du sikker på at du vil slette arbeidsordren?",
      "are_you_sure_revertion": "Er du sikker på at du vil snu og dermed miste dataene som ble lagt inn i denne modulen",
      "area": "Område",
      "authorization_pending": "Tillatelse venter",
      "authorize_form": {
        "modal_title": "Autorisasjon",
        "subtitle": "Gi litt informasjon om arbeidsordren for å fortsette med godkjenning",
        "title": "Autoriser arbeidsordre"
      },
      "canceled": "avbrutt",
      "clustered": "gruppert",
      "comments": "kommentarer",
      "confirmation_modal": {
        "cancel_description": "Arbeidsordren vil bli kansellert",
        "cancel_title": "Kansellere arbeidsordre?",
        "complete_description": "Denne arbeidsordren vil bli fullført",
        "complete_title": "Fullfør arbeidsordre?",
        "confirm_action": "Fortsett til importarbeidsforberedelser",
        "confirm_cancel": "Ikke nå",
        "confirm_confirmation": "Ja, jeg vil bekrefte",
        "confirm_description": "Vil du bekrefte arbeidsordren?",
        "confirm_title": "Arbeidsordrebekreftelse",
        "delete_description": "Fjerningshandlingen kan ikke angres. Er du sikker?",
        "delete_title": "Vil du slette arbeidsordre?",
        "reject_description": "Dette elementet vil gå tilbake til statusen Under forberedelse",
        "reject_title": "Avvise arbeidsordre?",
        "remove_recycle_description": "Dette elementet vil gå tilbake til statusen In Scoping og miste resirkuleringsflagget.",
        "remove_recycle_title": "Fjerne resirkulering?",
        "revert_description": "Tilbakestillingen kan ikke angres. Er du sikker?",
        "revert_title": "Tilbakestille arbeidsordre?"
      },
      "confirmed": "bekreftet",
      "create_inspection_modal": {
        "category_subtitle": "Velg ett alternativ fra kategorien %{category_name}",
        "loading": "Laster inspeksjonsmaler ...",
        "no_templates": "Det finnes ingen inspeksjonsmaler.",
        "plural_title": "Lag inspeksjoner",
        "standalone_subtitle": "Velg ett av alternativene nedenfor for å fortsette",
        "title": "Velg Inspeksjonsmal"
      },
      "creation_form": {
        "proceed": "Fortsett til trinn 2"
      },
      "creation_page": {
        "add_template": "Legg til mal",
        "first_step": "Opprett arbeidsordre",
        "second_step": "Importer arbeidsforberedelser (valgfritt)",
        "third_step": "Bruk servicepakker (valgfritt)"
      },
      "description": "Arbeidsordre",
      "discipline": "Disiplin",
      "discipline_check": {
        "check_terms": "Vilkår for disiplinsjekk",
        "perform": "Utfør disiplinsjekk",
        "ready_for_discipline": "Klar for disiplinsjekk",
        "ready_for_review": "Arbeidsordre klar for disiplinær gjennomgang",
        "set_as_ready": "Sett arbeidsordren som klar for disiplinkontroll",
        "title": "Disiplinsjekk"
      },
      "discipline_check_item": "WO01 – Klar for disiplinsjekk",
      "discipline_check_no_comment": "Ingen kommentar lagt til",
      "done": "Ferdig",
      "eav_template": "Mal",
      "eav_template_id": "Mal-ID",
      "edit_dialog": {
        "reason": "Underforespørsel navn"
      },
      "elevation": "Høyde",
      "errors": {
        "not_found": "Kan ikke finne arbeidsordre med ID: %{id}"
      },
      "estimates_authorization": "Estimatets autorisasjon kreves",
      "flags": {
        "discipline_check": "WO01 - Disiplinsjekk",
        "discipline_check_done": "WO01 – Disiplinsjekk: Godkjent",
        "discipline_check_pending": "WO01 – Disiplinsjekk: Venter",
        "discipline_check_rejected": "WO01 – Disiplinsjekk: Avvist",
        "recycle": "WO03 - Resirkuler"
      },
      "forecast_end_date": "Prognosert sluttdato",
      "id": "DPMSID",
      "in_performance": "Under konstruksjon",
      "in_planning": "I planlegging",
      "in_scoping": "Under forberedelse",
      "information": "Tilleggsinformasjon",
      "inspection_date": "Inspeksjonsdato",
      "no_comment": "Ingen kommentar",
      "notification": {
        "authorization_success": "Arbeidsordren er autorisert",
        "deleting_sub_request": "Underforespørselen %{id} blir slettet. Vennligst sjekk jobbsiden for å lære om fremdriften",
        "discipline_check_approved": "Disiplinsjekk godkjent",
        "discipline_check_rejected": "Disiplinsjekk ble avvist",
        "discipline_check_success": "Arbeidsordren er klar for disiplinær gjennomgang",
        "missing_order_error": "Det er ingen ventende ordre knyttet til denne arbeidsordren",
        "recycle_success": "Arbeidsordren er resirkulert"
      },
      "on_hold": "På vent",
      "progress": "Framgang",
      "progress_end_date": "Fremskritt sluttdato",
      "progress_responsible": "Ansvarlig for fremdrift",
      "progress_responsible_id": "Ansvarlig for fremdrift",
      "progress_start_date": "Fremskritt Startdato",
      "reason": "Henvisning",
      "recycle_form": {
        "label": "Årsak - Disiplin",
        "modal_title": "Resirkulere",
        "subtitle": "Oppgi informasjon for å flagge denne arbeidsordren for resirkulering",
        "title": "Resirkuleringsforespørsel"
      },
      "recycling_item": "WO03 - Resirkuler til klient",
      "reference": "Referanse",
      "registered": "registrert",
      "remove_recycle_modal": {
        "cancel_description": "Nei, behold flagget",
        "confirm_description": "Ja, klart",
        "success_notification": "Flagget for Resirkulering ble fjernet på %{reason} - %{discipline}"
      },
      "request_date": "Dato for arbeidsordre",
      "request_detail": {
        "edit": "Å redigere",
        "estimates": "estimater",
        "inspections": "inspeksjoner",
        "performance": "Konstruksjon",
        "planning": "Planlegger",
        "scopes": "Arbeidsforberedelser",
        "show": "Showet",
        "side_panel_title": "Forespørsel detaljer"
      },
      "request_end_date": "Sluttdato for arbeidsordre",
      "request_responsible": "Ansvarlig for Arbeidsordre",
      "request_responsible_id": "Ansvarlig for Arbeidsordre",
      "request_start_date": "Startdato for arbeidsordre",
      "responsible": "Ansvarlig",
      "schedule": "Arbeidsordreplan",
      "schedule_form": {
        "date": "Dato",
        "days": "%{number} dager",
        "performance": "Konstruksjon",
        "progress": "Framgang",
        "request": "Arbeidsordre",
        "responsible": "Ansvarlig",
        "schedule": "Rute",
        "scoping": "Arbeidsforberedelse",
        "subtitle": "For å lage en tidsplan for denne arbeidsordren trenger vi først litt tilleggsinformasjon.",
        "timecode": "Tidskode",
        "title": "Arbeidsordreplan"
      },
      "scope_end_date": "Sluttdato for arbeidsforberedelse",
      "scope_responsible": "Ansvarlig for Arbeidsforberedelse",
      "scope_responsible_id": "Ansvarlig for Arbeidsforberedelse",
      "scope_start_date": "Arbeidsforberedende startdato",
      "scopes": "Arbeidsforberedelser",
      "service_desired_date": "Ønsket dato",
      "skip_scoping": "Hopp over Arbeidsforberedelsesfasen",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Avventer",
        "canceled": "Avbrutt",
        "clustered": "Gruppert",
        "confirmed": "Under forberedelse",
        "deleting": "Sletter",
        "done": "Ferdig",
        "in_performance": "Under konstruksjon",
        "in_planning": "Under konstruksjon",
        "in_scoping": "Under forberedelse",
        "on_hold": "På vent",
        "registered": "Laget"
      },
      "sub_request": "Underforespørsel",
      "sub_requests": "Underforespørselener",
      "subarea": "Delområde",
      "team_target_hours": "Team Måltider",
      "title": "Arbeidsordrer",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - Revidert %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "Nei, avbryt",
      "description": "Er du sikker på at du vil tilbakestille inspeksjonen \"%{id}\"?",
      "ok": "Ja, gå tilbake til ventende.",
      "success_description": "Tilbakestilt til ventende.",
      "success_title": "Inspeksjonen ble omgjort",
      "title": "Vil du tilbakestille elementet?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Alternativ tekst",
        "by_url": "Etter URL",
        "height": "Høyde (px)",
        "image": "Bilde",
        "title": "Tittel",
        "upload": "Laste opp",
        "web_address": "nettadresse",
        "width": "Bredde (px)",
        "window_title": "Bildeutgave"
      }
    },
    "scaffolding_part_action": {
      "comment": "Kommentar",
      "date": "Dato",
      "height": "Høyde",
      "length": "Lengde",
      "modification_type": {
        "dismantle": "Demontere",
        "erect": "Oppreist",
        "erectDismantle": "Sett opp/demonter"
      },
      "singular": "Stillas del Handling",
      "title": "Stillas del Handling",
      "total": "Total",
      "width": "Bredde",
      "work_dismantle": "Demonter på en annen arbeidsordre",
      "work_order": {
        "choose_wo": "Velg Arbeidsordre"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "bygget",
        "created_by": "Laget av",
        "dismantle": "Dimensjon fjernet",
        "erect": "Dimensjon lagt til",
        "modify": "Modifisert",
        "total": "Total",
        "total_dismantle": "Stillas demontert"
      },
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nei, avbryt",
        "delete_confirmation": "Ja, slett",
        "delete_description": "Er du sikker på at du vil slette dimensjonen til %{total} %{dimension}?",
        "delete_title": "Vil du slette handlingen?"
      },
      "date": "Dato",
      "duplicate_title": "Duplikathandling",
      "edit_title": "Rediger handling",
      "feed": {
        "empty": "Ingen handlinger opprettet for denne stillasdelen"
      },
      "height": "Høyde",
      "length": "Lengde",
      "modification_type": {
        "dismantle": "Demontere",
        "erect": "Oppreist",
        "erectDismantle": "Sett opp/demonter"
      },
      "singular": "Stillas del Handling",
      "timestamp_badges": {
        "new": "Ny",
        "updated": "Oppdatert"
      },
      "title": "Stillas del Handling",
      "total": "Total",
      "width": "Bredde",
      "work_dismantle": "Demonter på en annen arbeidsordre",
      "work_order": {
        "choose_wo": "Velg Arbeidsordre"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Handling av totalt %{total}%{dimension} fjernet fra listen",
        "title": "Legg til en dimensjon"
      },
      "back": "Alle stillasdeler",
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nei, avbryt",
        "delete_confirmation": "Ja, slett",
        "delete_description": "Vil du slette %{type} med %{total} %{dimension}?",
        "delete_title": "Vil du slette %{type}-delen?"
      },
      "description": "Beskrivelse",
      "dismantle_modal": {
        "cancel": "Nei, avbryt",
        "confirm": "Ja, demonter",
        "confirmation_message": "Ønsker du å demontere denne delen?",
        "date": "Dato",
        "errors": {
          "date_greater_max": "Demonteringsdato må være i dag eller tidligere"
        },
        "success_message": "Stillasdelen ble demontert med suksess",
        "title": "Demonteringsdel",
        "work_order": "Arbeidsordre"
      },
      "erect_method": "Metode",
      "erect_methods": {
        "os": "Over havet",
        "standard": "Standard",
        "tt": "Tautilgang over havet"
      },
      "id": "ID",
      "initial_date": "Startdato",
      "menu_items": {
        "dimensions": "Legg til en dimensjon",
        "dismantle": "Demontere",
        "modifications": "Endre denne delen"
      },
      "operation_dismantle": "Drift demontering",
      "operation_erect": "Operasjon oppreist",
      "scaffolding_part_detail": {
        "actions": "Handlinger",
        "side_panel_title": "Stillas Del detaljer"
      },
      "scaffolding_type_id": "Type stillas",
      "scaffolding_types": {
        "covering": "Presenning",
        "dop": "DOP",
        "hanging_scaffolding": "Hengende",
        "patch_boards": "Lappeplater",
        "railing": "Midlertidig rekkverk",
        "rolling_scaffolding": "Flyttbart",
        "roof_over_roof": "Tak over tak",
        "scaffolding_for_lift": "Løftebukk",
        "scaffolding_net": "Stillasnot",
        "shelf": "Reol",
        "staircase_tower": "Trappetårn",
        "standard_scaffolding": "Standard",
        "stool": "Krakk",
        "support": "Understøtte",
        "system_covering": "Skinnesystem",
        "tent": "Telt",
        "tent_frame": "Teltramme"
      },
      "singular": "Stillas del",
      "title": "Stillas del",
      "total": "Total",
      "type_filter": "Skriv filter",
      "weight": "Vekt"
    },
    "scaffoldings": {
      "area_id": "Område",
      "area_noise_id": "Områdestøy",
      "area_noises": {
        "db_100_105": "100-105dB - 2 timers oppholdstid",
        "db_105_110": "105-110dB - 1/2 times oppholdstid",
        "db_85_90": "85-90dB - 12 timers oppholdstid",
        "db_90_100": "90-100dB - 6 timers oppholdstid",
        "db_less_85": "< 85dB - Ingen begrensninger"
      },
      "back": "Alle stillaser",
      "buttons_form": {
        "create_part": "Lag en del"
      },
      "client": "Kunde",
      "comment": "Kommentar",
      "confirmation_modal": {
        "delete_cancel": "Nei, avbryt",
        "delete_confirmation": "Ja, slett",
        "delete_description": "Vil du slette Scaffolding TAG %{tag}?",
        "delete_title": "Slett stillas",
        "dismantle_cancel": "Ikke nå",
        "dismantle_confirmation": "Ja, jeg vil demontere",
        "dismantle_description": "Ønsker du å demontere Stillas TAG %{tag}?",
        "dismantle_title": "Demontering av stillas",
        "duplicate_with_parts_cancel": "Ikke nå",
        "duplicate_with_parts_confirmation": "Ja, jeg vil duplisere med delene",
        "duplicate_with_parts_description": "Vil du duplisere stillaset til TAG %{tag} med delene?",
        "duplicate_with_parts_title": "Duplikatstillas med deler",
        "ready_to_dismantle_cancel": "Ikke nå",
        "ready_to_dismantle_confirmation": "Ja, jeg vil endre meg",
        "ready_to_dismantle_description": "Vil du endre statusen til TAG %{tag} til klar for demontering?",
        "ready_to_dismantle_title": "Stillas klar til demontering",
        "revert_cancel": "Ikke nå",
        "revert_confirmation": "Ja, jeg vil endre meg",
        "revert_description": "Vil du endre status for TAG %{tag} til oppsatt?",
        "revert_title": "Tilbakestill stillasstatus til oppsatt"
      },
      "contact_person": "Kontaktperson",
      "description": "Beskrivelse",
      "description_of_deviation": "Beskrivelse av avvik",
      "deviation_improved": "Avvik Forbedret Dato",
      "dismantle_compensation_format": "Ratetype riv",
      "dismantle_date": "Demonteringsdato",
      "dismantle_modal": {
        "cancel": "Nei, avbryt",
        "confirm": "Ja, demonter",
        "confirmation_message": "Ønsker du å demontere dette stillaset?",
        "date": "Dato",
        "errors": {
          "date_greater_max": "Demonteringsdato må være i dag eller tidligere"
        },
        "success_message": "Stillaset ble demontert med suksess",
        "title": "Demontering av stillas",
        "work_order": "Arbeidsordre"
      },
      "erect_compensation_format": "Ratetype bygg",
      "erect_date": "Dato bygg",
      "erect_with_deviation": "Oppreist med avvik",
      "id": "ID",
      "inspection": {
        "delayed": "Forsinket",
        "missing_initial": "Savnet"
      },
      "inspection_control": "Undersøkelse",
      "installation": "Installasjon",
      "load_class_id": "Belastningsklasse",
      "load_classes": {
        "kg_150": "2 - 150 kg/m2",
        "kg_200": "3 - 200 kg/m2",
        "kg_300": "4 - 300 kg/m2",
        "kg_450": "5 - 450 kg/m2",
        "kg_600": "6 - 600 kg/m2",
        "kg_75": "1 - 75 kg/m2",
        "not_applicable": "0 - Ikke aktuelt"
      },
      "main_work_order_dismantle_id": "Demontering av arbeidsordre",
      "main_work_order_erect_id": "Arbeidsordre oppreist",
      "missing_initial_control": "Innledende kontroll",
      "missing_request": "Manglende arbeidsordre",
      "number_of_tags": "Antall tag",
      "operation_dismantle": "Operasjon Demontering",
      "operation_erect": "Operasjon Erect",
      "pictures": "Bilde(r)",
      "project_number": "Prosjektreferanse",
      "qr_code": "QR kode",
      "reason": "Årsak til arbeidsordre",
      "scaffolding_detail": {
        "side_panel_title": "Stillasdetaljer"
      },
      "scaffolding_status_id": "Status",
      "sections": {
        "details": "Detaljer",
        "deviations": "Avvik",
        "general": "Generell Informasjon",
        "pictures": "Bilde(r)"
      },
      "singular": "Stillas",
      "statuses": {
        "created": "Opprettet",
        "dismantled": "Demontert",
        "erected": "Montert",
        "ready_to_dismantle": "Klar til demontering"
      },
      "subarea_id": "Delområde",
      "tag": "STIKKORD",
      "tag_number": "Tagnummer",
      "tag_removed": "Tag fjernet",
      "telephone_radio": "Telefon - Radio",
      "title": "Stillaslogg",
      "total_m": "Total m",
      "total_m2": "Total m2",
      "total_m3": "Total m3",
      "total_weight_message": "Totalvekt av oppsatt stillas",
      "user_of_scaffolding": "Bruker av stillas",
      "weight": "Vekt",
      "wind_exposed": "Vindutsatt"
    },
    "scopes": {
      "add": "Legg til arbeidsforberedende element",
      "choose_modal": {
        "single": "Nok et arbeidsforberedende element",
        "single_description": "Oppretting av kun ett arbeidsforberedende element"
      },
      "clusters": {
        "title": "Arbeidsforberedende klynger"
      },
      "count": "Telle",
      "crew_size": "Mannskap størrelse",
      "crews": "Antall mannskaper",
      "date": "Dato",
      "description": "Arbeidsforberedelse",
      "duplicate_services": {
        "other_request": "Enda en arbeidsordre",
        "other_request_description": "Duplikat til et arbeidsforberedelseselement i en annen arbeidsordre",
        "same_request": "Samme arbeidsordre",
        "same_request_description": "Velg elementet Arbeidsforberedelse",
        "success_notification": "Arbeidsforberedelsens tjenester ble vellykket duplisert",
        "title": "Dupliserte tjenester"
      },
      "edit_work_description": "Rediger arbeidsbeskrivelse",
      "hours": "Timer (h)",
      "id": "ID",
      "item": "Punkt",
      "label": "Merkelapp",
      "label_assigner": {
        "success": "Dine arbeidsforberedelsesetiketter har blitt tildelt! Du kan fortsette planen din nå."
      },
      "label_error": "Kunne ikke hente etiketter",
      "labels_info": "Arbeidsforberedelsesetiketter",
      "more_details": "se mer",
      "popup_label": {
        "create_label": "Lag etikett",
        "labels": "etiketter:",
        "name_label": "Navn på etikett",
        "new_label": "Ny etikett"
      },
      "preview_unavailable": "Ingen forhåndsvisning tilgjengelig",
      "quantity": "Kvantitet",
      "remaining_hours": "Gjenværende Timer",
      "remaining_quantity": "Gjenværende Kvantitet",
      "request_status_restriction": "Arbeidsordre skal ha status bekreftet eller under forberedelse",
      "scopes_detail": {
        "side_panel_title": "Arbeidsforberedende detaljer"
      },
      "success_work_description_edition": "Arbeidsbeskrivelsen ble redigert",
      "title": "Arbeidsforberedelser",
      "unit": "Enhet",
      "work_description": "arbeidsbeskrivelse",
      "working_hours": "Arbeidstid"
    },
    "search": {
      "loading": "lasting...",
      "no_result_found": "Ingen resultater funnet",
      "placeholder": "Filter..."
    },
    "service_description": "Tjenestebeskrivelse",
    "settings": {
      "title": "Innstillinger"
    },
    "settings_shortcuts": "Snarveier for innstillinger",
    "shortcuts": {
      "access_control": "Adgangskontroll",
      "areas": "Områder og delområder",
      "areas_subareas": "Områder og delområder",
      "assets": "Eiendeler",
      "background_jobs": "Bakgrunnsjobber",
      "branches": "Grener",
      "cities": "Byer",
      "client_sites": "Klientnettsteder",
      "clients": "Kunder",
      "clients_info": "Klienter Info",
      "columns_renaming": "Gi nytt navn til kolonner",
      "companies": "Bedrifter",
      "contract_services": "Kontrakttjenester",
      "contracts": "Kontrakter",
      "countries": "Land",
      "customer_sites": "Klientnettsteder",
      "customers": "Kunder",
      "disciplines": "Disipliner",
      "employees": "Personale",
      "equipaments": "Utstyr",
      "equipments": "Utstyr",
      "formulas": "Formler",
      "location": "plassering",
      "materials": "Materialer",
      "matrices": "Matriser",
      "measures": "målinger",
      "mobile_details": "Mobildetaljer",
      "position_names": "Posisjonsnavn",
      "preferences": "Preferanser",
      "project_assets": "Eiendeler",
      "project_contracts": "Kontrakter",
      "projects": "Prosjekter og delprosjekter",
      "projects_contracts": "Prosjekter og kontrakter",
      "projects_subprojects": "Prosjekter og delprosjekter",
      "provinces": "Stater / provinser",
      "regions": "Regioner",
      "routes": "Ruter",
      "specialities": "Spesialiteter",
      "staff": "Personale",
      "states_provinces": "Stater / provinser",
      "templates": "Maler",
      "translations": "Endre navn på kolonne",
      "users": "Brukere",
      "visible_fields": "Mobildetaljer",
      "work_positions": "Posisjonsnavn"
    },
    "side_menu": {
      "collapse": "Kollapse",
      "contracts": "Kontraktsavtale",
      "datasheets": "Datablad",
      "expand": "Utvide",
      "report": "Rapportere",
      "search": "Søk",
      "settings": "innstillinger",
      "template": {
        "asset": "Eiendeler",
        "contract_service": "Kontrakttjenester",
        "datasheet": "Dataark",
        "estimate_service": "Anslå tjenester",
        "inspection": "Inspeksjoner",
        "performance": "Konstruksjon",
        "report": "Rapporter",
        "request": "Arbeidsordrer",
        "scoping": "Arbeidsforberedelse",
        "valuation": "Verdsettelse"
      },
      "templates": "maler"
    },
    "side_panel": {
      "close": "Lukk sidepanelet",
      "collapse": "Skjul sidepanelet",
      "expand": "Utvid sidepanelet",
      "tabs": {
        "category_filter": "Kategorifilter",
        "gallery": "Galleri",
        "info": "Info",
        "inspections": "Inspeksjoner",
        "no_categories": "Det er ingen tilgjengelige kategorier",
        "no_templates": "Det er ingen maler tilgjengelig",
        "preview_details": "Forhåndsvis detaljer",
        "progress": "Framgang",
        "template_filter": "Malfilter"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "Alle underforespørsler",
        "back": "Alle arbeidsforberedelser",
        "title": "underforespørsler"
      },
      "left_panel": {
        "add_scoping": "Legg til arbeidsforberedende element",
        "allocation": "Tildeling",
        "cards_header_title": "Arbeidsforberedelser",
        "summary": "Sammendrag",
        "total_allocation": "Total tildeling"
      },
      "scope_card": {
        "scope": "Arbeidsforberedelse"
      }
    },
    "tabs": {
      "error": "Det er umulig å laste inn faner"
    },
    "templates": {
      "asset": "Eiendeler",
      "contract_service": "Kontrakttjenester",
      "datasheet": "Dataark",
      "estimate_service": "Anslå tjenester",
      "inspection": "Inspeksjoner",
      "no_template": "Du må lage mal",
      "progress": "Konstruksjon",
      "progress_service": "Verdsettelse",
      "report": "rapporter",
      "request": "Arbeidsordrer",
      "scoping": "Arbeidsforberedelse",
      "title": "Maler for %{type}"
    },
    "tickets": {
      "icon_alt": "Et billettikon",
      "title": "Billetter"
    },
    "timeline": {
      "created": "Opprettet",
      "day_ago_singular": "%{daysAgo} dager siden",
      "day_until_singular": "Innen %{daysUntil} dag",
      "days_ago": "%{daysAgo} dager siden",
      "days_until": "Innen %{daysUntil} dager",
      "discipline_check_done": "Disiplinsjekk: Ferdig",
      "discipline_check_pending": "Disiplinsjekk: Venter",
      "discipline_check_rejected": "Disiplinsjekk: Avvist",
      "estimate_authorized": "Estimat: Autorisert",
      "estimate_done": "Estimat: Fullført",
      "estimate_on_hold": "Estimat: På vent",
      "estimate_pending": "Estimat: Venter",
      "estimate_pending_authorization": "Estimat: Godkjenning venter",
      "estimate_progress": "Estimat: Pågår",
      "performance": "Konstruksjon",
      "progress": "Framgang",
      "recycle_done": "Resirkuler: Ferdig",
      "recycle_recycling": "Resirkulering: Resirkulering",
      "recycling": "Resirkulering",
      "rejected": "Avvist",
      "request": "Arbeidsordre",
      "responsible": "Ansvarlig",
      "schedule": "Rute",
      "scoping": "Arbeidsforberedelse",
      "title": "Arbeidsordre tidslinje",
      "today": "I dag"
    },
    "top_menu": {
      "authorization": "Autorisasjon",
      "datasheets": "Dataark",
      "eav_templates": "Maler",
      "estimates": "estimater",
      "inspections": "inspeksjoner",
      "no_datasheets": "Ingen datablad funnet",
      "no_reports": "Ingen rapporter funnet",
      "performances": "Konstruksjon",
      "plannings": "Planlegger",
      "reports": "Rapporter",
      "requests": "Arbeidsordrer",
      "scaffoldings": "Stillaslogg",
      "scopes": "Arbeidsforberedelse",
      "workflow": "Arbeidsflyt"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Om versjon",
      "clean_preferences": "Rengjør preferanser",
      "logout": "Logg ut",
      "notification": {
        "clean_preferences": "Preferanser ryddet opp",
        "error": "Feil ved forsøk på å rense preferansene"
      },
      "preferences": "Rutenettpreferanser",
      "profile": "Profil"
    },
    "users": {
      "edit_title": "Oppdater profil"
    },
    "work_order_attachments": {
      "subtitle": "Se dokumentene til den relaterte arbeidsordren til",
      "title": "Arbeidsordrevedlegg"
    }
  },
  "pt-BR": {
    "actions": {
      "alternate_view": "Visualização Alternativa",
      "approve": "Aprovar",
      "back_to": "Voltar para %{page}",
      "bulk_editing": "Edição em massa",
      "cancel": "Cancelar",
      "choose_entity": "Escolha %{entity}",
      "clear": "Limpar",
      "clear_all_filters": "Limpar todos os filtros",
      "confirm": "Confirmar",
      "create": "Criar",
      "create_inspection": "Criar inspeção",
      "delete": "Excluir",
      "discard": "Descartar",
      "dismantle": "Desmantelar",
      "done": "Completar",
      "duplicate": "Duplicar",
      "duplicate_services": "Serviços Duplicados",
      "duplicate_with_parts": "Duplicar com peças",
      "edit": "Editar",
      "export": "Exportar para .xls",
      "export_template": "Faça o download do modelo .xls",
      "filter_pinned_work_orders": "Filtrar Ordens de Serviço Fixadas",
      "flag_filter": "Filtro de sinalização",
      "generate_work_package": "Gerar pacote de trabalho",
      "import": "Importar de .xls",
      "import_file": "Importar arquivo",
      "loading": "Carregando",
      "more": "Mais",
      "new": "Crie um novo",
      "next": "Próximo",
      "no": "Não",
      "past": {
        "cancel": "cancelado",
        "complete": "completado",
        "confirm": "confirmado",
        "delete": "apagado",
        "destroy": "removido",
        "dismantle": "desmontado",
        "duplicate_with_parts": "Duplicado com suas partes",
        "ready_to_dismantle": "pronto para desmontar",
        "reject": "rejeitado",
        "revert": "revertido",
        "update": "atualizado"
      },
      "perform_discipline_check": "Realizar Verificação Disciplinar",
      "pin_request": "Filtrar por solicitações selecionadas",
      "prev": "Anterior",
      "ready": "Preparar",
      "ready_for_discipline": "Pronto para verificação disciplinar",
      "ready_to_dismantle": "Pronto para desmontar",
      "recycle": "Reciclar",
      "reject": "Rejeitar",
      "remove_recycle": "Remover reciclagem",
      "reset": "Redefinir",
      "retrieving": "Recuperando...",
      "revert": "Reverter",
      "revert_to_erected": "Reverter para Erguido",
      "save": "Salvar",
      "save_button_keyboard": "Salvar (Ctrl + Enter)",
      "save_exit": "Salvar e sair",
      "save_new": "Adicionar outro",
      "saving": "Salvando ...",
      "schedule": "Cronograma",
      "show_all_work_orders": "Mostrar todas as ordens de serviço",
      "skip": "Pular",
      "status_filter": "Filtro de status",
      "unpin_request": "Remover filtro por solicitações",
      "unpin_selected_request": "Remover filtro de solicitações selecionadas %{numSelectedRequests}",
      "unsaved_changes": "Linhas não salvas",
      "upload_file": "Fazer upload do arquivo",
      "upload_picture": "Carregar foto",
      "yes": "Sim"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "Não é possível duplicar uma Ordem de Serviço Clusterizada",
      "cant_duplicate_progress": "Não é possível duplicar os serviços devido aos seguintes erros ...",
      "cant_duplicate_request": "Não é possível duplicar Preparações, Orçamentos e Serviços devido aos seguintes erros...",
      "cant_duplicate_scope": "Não é possível duplicar os serviços devido aos seguintes erros ...",
      "only_estimate_service_copy": "Apenas o item Serviço de estimativa está tentando ser copiado.",
      "only_progress_copy": "Apenas o Progresso está tentando ser copiado.",
      "only_request_copy": "Apenas a Ordem de Serviço está tentando ser copiada.",
      "only_scope_copy": "copiado."
    },
    "cancel_modal": {
      "cancel": "Agora não",
      "description": "Tem certeza de que deseja cancelar o %{model} \"%{id}\"?",
      "ok": "Sim cancele",
      "title": "Cancelar item?"
    },
    "clear_records": {
      "modal": {
        "cancel": "Não, cancele",
        "description": "Tem certeza de que deseja limpar todos os registros %{model}?",
        "ok": "Sim, limpar tudo",
        "title": "Limpar %{model}?"
      },
      "success_message": "%{model} apagado com sucesso!",
      "title": "Limpar registros"
    },
    "clear_template_preferences": {
      "cancel": "Não, cancelar",
      "description": "Tem certeza de que deseja limpar todas as preferências de grade para este modelo?",
      "ok": "Sim, limpar preferências de grade",
      "title": "Limpar preferências de grade"
    },
    "collections": {
      "clusters": "Agrupamentos",
      "create_by_package": "Criar por pacote",
      "duplicate": "Duplicar",
      "edit": "Editar",
      "estimate": "Estimativa",
      "new": "Novo",
      "schedule": "Cronograma",
      "sub_requests": "Sub-requisições",
      "view": "Visualização"
    },
    "complete_modal": {
      "cancel": "Agora não",
      "description": "Tem certeza de que deseja marcar %{model} como concluído?",
      "ok": "Sim, marque como concluído",
      "title": "Marcar como concluído?"
    },
    "complete_progress_modal": {
      "cancel": "Cancelar",
      "complete": "Completar",
      "description": "Tem certeza de que deseja concluir este item de Construção? Essa ação não pode ser desfeita.",
      "title": "Confirmar a conclusão do item de construção"
    },
    "confirmation_modal": {
      "cancel": "Cancelar",
      "description": "Essa ação não pode ser desfeita!",
      "ok": "Está bem",
      "title": "Você tem certeza?"
    },
    "contract_services": {
      "title": "Serviços do contrato"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "Detalhes da Planilha"
      },
      "description": "Planilha",
      "id": "ID",
      "item": "item",
      "title": "Planilhas"
    },
    "delete_attachment_modal": {
      "cancel": "Não, guarde o anexo",
      "delete_successful": "Anexo excluído com sucesso",
      "description": "Tem certeza de que deseja excluir este anexo?",
      "ok": "Sim, exclua o anexo",
      "title": "Excluir anexo?"
    },
    "delete_modal": {
      "cancel": "Não, cancelar",
      "description": "Tem certeza que deseja excluir o %{model} \"%{id}\"?",
      "ok": "Sim, excluir",
      "title": "Excluir item?"
    },
    "delete_picture_modal": {
      "cancel": "Não, mantenha a foto",
      "delete_successful": "Imagem excluída com sucesso",
      "description": "Tem certeza de que deseja excluir esta foto?",
      "ok": "Sim, excluir foto",
      "title": "Excluir imagem"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "Aceitação"
      },
      "column_comment": {
        "add_comment": "Clique para adicionar um comentário",
        "cancel": "Cancelar",
        "comment": "Comentário",
        "subtitle": "Adicionar/editar comentário"
      },
      "columns_batch_assigner": {
        "actions": "Ações",
        "add_action": "Adicionar ação",
        "add_to_column": "Adicionar à coluna",
        "add_value_to": "Adicionar %{value} a",
        "column": "Coluna",
        "done": "Salvar ação",
        "duplicate_services": "Duplicar Serviços",
        "header": "Edição em grupo",
        "multiple_success": "%{numberOfItems} itens foram atualizados.",
        "new_value": "Novo valor",
        "single_success": "1 item foi atualizado.",
        "subtitle": "Atribuir valores às %{numberOfColumns} colunas selecionadas",
        "title": "Criar Ações",
        "update": "Atualizar"
      },
      "configure_print_grid_file": {
        "error_max_columns": "Você só pode selecionar até %{maxColumns} colunas.",
        "generate": "Gerar arquivo",
        "header": "Grade de impressão",
        "paper_size_title": "Tamanho do papel",
        "pdf_file": {
          "information": "Informação",
          "pictures": "Fotos",
          "title": "Grade %{modelName} - %{numItems} itens - Página %{pageNum} de %{totalPages}"
        },
        "select_columns_title": "Colunas para imprimir",
        "selected_columns": "Você selecionou %{numSelectedColumns} de %{maxColumns}.",
        "subtitle": "Selecione até %{maxColumns} colunas para imprimir",
        "title": "Configurar arquivo para imprimir"
      },
      "configure_print_list_file": {
        "big_picture_title": "Imprimir imagens usando a largura da página?",
        "font_size_title": "Selecione o tamanho da fonte",
        "header": "Imprimir lista",
        "hide_columns_title": "Incluir colunas ocultas",
        "large": "Grande",
        "medium": "Médio",
        "page_break_title": "Imprimir 1 item por página?",
        "small": "Pequeno"
      },
      "configure_work_package_file": {
        "attached_documents": "Documentos anexados",
        "attachments": "Anexos",
        "estimates": "Estimativas",
        "estimates_price": "Preço estimado",
        "generate": "Gerar",
        "header": "Gerar pacote de trabalho",
        "hse_r": "Avaliação HSE-R",
        "inspection_page_breaker": "Imprimir uma inspeção por página",
        "inspection_pictures": "Incluir fotos e assinaturas",
        "inspections": "Todas as inspeções relacionadas",
        "subtitle": "Selecione as seções que deseja imprimir",
        "title": "Configurar arquivo de pacote de trabalho",
        "work_description": "Descrição do Trabalho"
      },
      "duplicate_services": {
        "title": "Duplicar Serviços"
      }
    },
    "discard_modal": {
      "cancel": "Continuar editando",
      "description": "Tem certeza de que deseja descartar suas alterações?",
      "ok": "Sim, descartar",
      "title": "Descartar mudanças"
    },
    "eav_templates": {
      "title": "Modelos"
    },
    "empty_state": {
      "columns": "colunas",
      "empty": "Não há %{model}",
      "no_attachments": "Você ainda não enviou nenhum anexo",
      "no_columns": "Não há coluna de imagem disponível",
      "no_discipline_template": "Não foram encontrados modelos com a disciplina de ordem de serviço filtrada.",
      "no_estimate_service_template": "Nenhum modelo de serviço de estimativa para o modelo de preparação de trabalho selecionado foi encontrado",
      "no_pictures": "Você ainda não enviou nenhuma foto nesta coluna",
      "no_unselect": "Não é possível desmarcar a solicitação fixada nesta página. Você só pode fazer isso na página Ordens de Serviço ou fazendo logout.",
      "not_created": "Você ainda não criou nenhum tipo de %{model}",
      "pictures": "fotos"
    },
    "environment": {
      "project": {
        "select_project": "Selecione Projeto ..."
      },
      "select_project_subproject": "Selecionar projeto e subprojeto",
      "subproject": {
        "select_subproject": "Selecione Subprojeto ..."
      }
    },
    "estimate": {
      "accept": "Aceitar estimativa",
      "all_estimates_approved": "Todas as estimativas foram aprovadas com sucesso.",
      "application": "Aplicação",
      "back": "Todas as ordens de serviço",
      "budget_hours": "Horas do oçamento",
      "confirmation": {
        "cancel": "Não, Cancelar",
        "confirm": "Sim, Confirmar",
        "description": "Tem certeza de que deseja autorizar esta estimativa para esta ordem de serviço?",
        "title": "Confirmar pedido"
      },
      "description": "Você tem serviços %{number} para checkout. Verifique os detalhes abaixo.",
      "equipment": "Equipamento",
      "estimate_details": "Detalhes de estimativa",
      "estimate_success": "Estimativa aprovada com sucesso",
      "info_message": "Este serviço de estimativa já foi aprovado. Ele está disponível somente para leitura.",
      "material": "Material",
      "no_estimate_message": "Não há estimativa associada a esta ordem de serviço.",
      "norm_hours": "Horas da norma",
      "others": "Outros preços",
      "pdf_file": {
        "title": "Detalhes da estimativa - Página %{pageNum} de %{totalPages}"
      },
      "pdf_header": {
        "discipline": "Disciplina",
        "functional_location": "Localização Funcional",
        "jobcard_status": "Status do cartão de trabalho",
        "ke_priority": "Prioridade KE",
        "main_area_location": "Área Principal e Localização",
        "main_work_center": "Centro de Trabalho Principal",
        "maintenance_plant": "Planta de Manutenção",
        "not_defined": "Ainda não definido",
        "order_finish": "Fim do pedido",
        "order_start": "Início do pedido",
        "priority_sequence": "Sequência Prioritária",
        "project": "Projeto",
        "project_type": "Tipo de projeto",
        "report": "Relatório",
        "report_date": "Data do Relatório",
        "responsible_engineer": "Engenheiro Responsável",
        "status_completed": "Concluído",
        "status_issued_for_approval": "Emitido para aprovação",
        "status_issued_for_construction": "Emitido para construção",
        "subproject": "Subprojeto",
        "system": "Sistema",
        "template": "Modelo",
        "title": "Estimativa de ordem de serviço",
        "work_order": "Ordem de Serviço"
      },
      "pdf_summary": {
        "title": "Resumo das estimativas"
      },
      "save_draft": "Salvar rascunho",
      "service_details": "Detalhes do serviço",
      "some_estimates_not_approved": "Algumas estimativas não puderam ser aprovadas. Verifique os logs de erros.",
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviço de check-out",
      "tooltip": {
        "application": "Aplicação",
        "equipment": "Equipamento",
        "material": "Material",
        "others": "Outros"
      },
      "total_approved": "Total aprovado",
      "total_pending": "Total pendente",
      "total_request": "Pedido Total",
      "use_construction_prices": "Incluir preços de construção?"
    },
    "estimate_services": {
      "application_price": "Preço da Aplicação",
      "automatically_prices": "Preços calculados automaticamente",
      "back": "Todos os preparativos para o trabalho",
      "budget_target_hours": "Horas de orçamento",
      "choose_modal": {
        "package": "Pacote de detalhe único",
        "package_description": "Criação de vários serviços baseados no mesmo detalhe",
        "single": "Detalhe único",
        "single_description": "Criação de apenas um detalhe",
        "title": "Crie um novo"
      },
      "contract_service": "Serviços do contrato",
      "crew_size": "Tamanho da equipe",
      "crews": "Número de equipes",
      "description": "Serviço",
      "description_label": "Descrição",
      "discipline": "Disciplina",
      "equipment_price": "Preço do Equipamento",
      "estimate": "Estimativa",
      "estimate_services_detail": {
        "side_panel_title": "Detalhes do serviço"
      },
      "factor": "Fator",
      "flag": "Bandeira",
      "id": "ID",
      "item_no": "N° Item.",
      "materials_price": "Preço dos materiais",
      "norm_hours": "Horas normais",
      "other_price": "Outros preços",
      "prices": "Preços",
      "print": "Imprimir",
      "quantity": "Quantidade",
      "service": "Serviço",
      "service_no": "N° Serviço.",
      "service_package": "Pacote de serviço",
      "statuses": {
        "inspected_flag": "Inspeção Pendente"
      },
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviços",
      "title_packageable": "Criar pacote de serviços",
      "total": "Total",
      "unit": "Unidade",
      "weight": "Peso",
      "working_hours": "Horas de trabalho"
    },
    "fields": {
      "created_at": "Criada",
      "image_not_found": "Imagem não encontrada",
      "na": "N/D",
      "signature": "Assinatura.jpg",
      "updated_at": "Atualizada"
    },
    "filter_steps_modal": {
      "filter_result": "Resultado do Filtro",
      "result_subtitle": "Resumo das suas seleções",
      "step": "Passo",
      "subtitle": "Selecione %{title}"
    },
    "filtering": {
      "between": " que está entre",
      "contains": " que contém ",
      "eq": " que é igual a ",
      "invalid_date": "Para filtrar corretamente, a data de início e a data de término devem ser preenchidas",
      "invalid_value": "Valor inválido",
      "neq": " que nao é igual a "
    },
    "forgot_password": {
      "back_to_login": "Volte ao login",
      "button": "Envie-me instruções de redefinição de senha",
      "feedback": "Uma mensagem com instruções para redefinir sua senha foi enviada para o e-mail fornecido.",
      "placeholder": "Seu email",
      "subtitle": "Por favor, digite seu endereço de e-mail para receber instruções de redefinição de senha.",
      "title": "Esqueceu sua senha?"
    },
    "form": {
      "back": "Todas %{entity}",
      "cancel": "Cancelar",
      "create_entity": "Criar %{entity}",
      "create_error": "%{entity} não pôde ser criado",
      "create_success": "%{entity} foi criado com sucesso",
      "duplicate_entity": "Duplicar %{entity}",
      "duplicate_success": "O %{entity} e suas dependências foram duplicados com sucesso!",
      "general_information": "Informação geral",
      "hide": "Ocultar",
      "inputs": {
        "attachment": {
          "add_error": "Erro ao adicionar arquivo",
          "add_evidence": "Adicionar evidência da autorização do cliente",
          "allowed_extensions": "As extensões permitidas são",
          "drag_drop": "Adicionar arquivos usando arrastar e soltar",
          "invalid_extension": "Tipo de arquivo não permitido.",
          "max_size": "O arquivo deve ser menor que %{max_size}",
          "maximum_num_uploads_error": "Você só pode carregar até %{max_num} arquivos por coluna flexível.",
          "one_file": "Carregar um arquivo por vez",
          "or_drop_file_here": "ou solte o arquivo aqui",
          "remove_error": "Falha ao Remover",
          "remove_success": "Removido com Sucesso",
          "select_files": "Selecionar arquivos",
          "submit": "Envie seu anexo",
          "upload_error": "Falha no upload",
          "upload_success": "Carregado com sucesso"
        },
        "contract_service": {
          "add": "Adicionar um contrato de serviço",
          "subtitle": "Contrato de Serviço"
        },
        "datasheet_filter": {
          "add_filter": "Adicionar Filtro",
          "title": "Filtro de Planilha"
        },
        "date_range": {
          "between": "%{start} até %{end}",
          "end": "Data final",
          "end_simplified": "Fim",
          "start": "Data de início",
          "start_simplified": "Começo"
        },
        "drop_formula": {
          "formula_select": "Por favor, selecione uma fórmula ..."
        },
        "errors": {
          "date_greater_than_max": "A data deve ser em ou antes de %{max}",
          "date_less_than_min": "A data deve ser em ou depois de %{min}",
          "drop_formula_result": "%{field} resultado da fórmula calculada",
          "mandatory": "%{field} é obrigatório.",
          "not_found": "'%{value}' não foi encontrado na referência. Selecione um novo."
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "ou arraste e solte",
              "select_file": "Clique para carregar"
            },
            "note": {
              "extensions": "O arquivo deve estar em %{extensions}",
              "max_size": "Tamanho máximo do arquivo %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "Foto"
          },
          "upload": {
            "error": {
              "invalid_picture": "Tipo/tamanho inválido",
              "multiple_fails": "Todos os arquivos anexados têm tipo/tamanho inválidos",
              "multiple_files": "Algumas imagens anexadas têm tipo/tamanho inválidos, carregue as válidas",
              "title": "Falha no upload"
            },
            "success": {
              "multiple_files": "Vários arquivos foram enviados com sucesso",
              "title": "Carregamento realizado com sucesso"
            }
          }
        },
        "formula": {
          "description": "Esta é uma coluna do tipo formula, o valor do campo é atualizado automaticamente",
          "icon": "clique para ver a equação",
          "result": "Resultado"
        },
        "gps": {
          "save_location": "Salvar localização",
          "search": "Pesquisar local ...",
          "without_location": "Sem localização"
        },
        "multiple_entries": {
          "add": "Adicionar outro",
          "empty_entry": {
            "scaffolding_type_id": "Selecione um tipo de andaime para adicionar ações"
          },
          "empty_inputs": "As entradas de %{input} são necessárias em %{model}",
          "required": "O campo \"%{field}\" é obrigatório e deve ser preenchido"
        },
        "numeric": {
          "between": "O valor deve estar entre %{min} e %{max}",
          "greater": "O valor deve ser maior que %{min}",
          "lower": "O valor deve ser menor que %{max}"
        },
        "picture": {
          "comment_placeholder": "Escreva um comentário (opcional)",
          "edition_only": "Disponível apenas durante a edição",
          "no_image_on_clipboard": "Nenhum dado de imagem foi encontrado na sua área de transferência. Por favor, copie uma imagem primeiro.",
          "remove_error": "Falha ao remover",
          "remove_success": "Removido com sucesso",
          "upload_error": "Falha no upload",
          "upload_success": "Carregado com sucesso"
        },
        "placeholder": "Informe %{field} aqui",
        "value_selector": {
          "apply": "Aplicar",
          "done": "Concluído",
          "search": "Pesquise algo aqui ...",
          "waiting": "... Aguarde"
        }
      },
      "items": "Items",
      "loading": "Carregando...",
      "loading_calculations": "Cálculo de visibilidades, colunas necessárias e valores padrão",
      "loading_contract": "Carregando informações do contrato...",
      "schedule_entity": "Agendar %{entity}",
      "schedule_success": "Sua requisição para agendar %{entity} foi concluída com sucesso",
      "show": "Mostrar",
      "subtitle": "Por favor, Forneça informações básicas sobre %{entity}.",
      "subtitle_error": "Não foi possível carregar o formulário, verifique os dados da %{entity} ou do Modelo.",
      "update_entity": "Editar %{entity}",
      "update_error": "%{entity} não pôde ser atualizado",
      "update_success": "%{entity} foi atualizado com sucesso"
    },
    "formula_modal": {
      "back_button_text": "Voltar para o formulário",
      "calculate": "Calcular",
      "cancel": "Cancelar",
      "mandatory": "Você deve preencher os valores para os seguintes campos: %{fields}",
      "ok": "Salvar fórmula"
    },
    "grid": {
      "all": "Todos",
      "clear_filters": "Limpar Filtros",
      "editable": {
        "error": {
          "mandatory": "Pelo menos um campo obrigatório foi deixado em branco"
        },
        "missing_required": "%{number} células obrigatórias ausentes nesta linha",
        "title": "Modo de edição",
        "update": {
          "error": "%{quantity} não pôde ser salvo. Verifique o motivo no lado esquerdo de cada linha destacada",
          "success": "%{quantity} registros foram salvos com sucesso"
        }
      },
      "empty_after_filtering": "Não há registros para os filtros aplicados",
      "failed_saving_preferences": "As preferências não foram salvas!",
      "flag_filter": "Filtro de sinalização",
      "grid_filters": {
        "menu_title": "Filtros Personalizados"
      },
      "menu": {
        "visibility": "Colunas"
      },
      "of": "de",
      "other": "Outros",
      "productivity_factor": "Fator de Produtividade",
      "quick_pin_popup": {
        "all_work_orders": "Todas as OTs",
        "clear_all": "Limpar tudo",
        "no_pinned_requests": "Você ainda não fixou ordens de serviço",
        "pinned_work_orders": "OTs fixadas",
        "quick_pin": "Pin rápido",
        "search_and_pin": "Pesquise e fixe OT"
      },
      "saved_preferences": "Salvo!",
      "saving_preferences": "Salvando preferências...",
      "select_items": "Selecionar itens",
      "selected": "Selecionado",
      "selecteds": "Selecionados",
      "status_filter": "Filtro de status",
      "totals": "Totais"
    },
    "history_form": {
      "create_title": "Adicione um novo progresso",
      "edit_title": "Edite seu progresso",
      "subtitle": "Insira um progresso por quantidade ou porcentagem para sua atividade."
    },
    "history_popup": {
      "back": "Voltar",
      "comments": "Comentários",
      "crew_size": "Tamanho da Equipe",
      "daily_hours": "Horas Diárias",
      "date": "Data",
      "form_create_progress_service_title": "Criar histórico de item de serviço de construção",
      "form_create_progress_title": "Criar histórico do item de execução",
      "form_edit_progress_service_title": "Editar histórico de itens de serviço de construção",
      "form_edit_progress_title": "Editar histórico do item de execução",
      "form_error_body": "Todos os campos obrigatórios do formulário devem ser preenchidos.",
      "form_error_title": "Não foi possível salvar o formulário de histórico",
      "form_subtitle": "Insira um histórico por quantidade ou porcentagem para sua atividade.",
      "modal_create_title": "Criar Histórico",
      "modal_edit_title": "Editar Histórico",
      "number_of_crews": "Nº de Equipes",
      "number_of_workers": "No. de trabalhadores",
      "other_value": "Outro Valor",
      "percentage": "Percentagem",
      "progress_by": "Progresso por",
      "quantity": "Quantidade",
      "singular": "Histórico",
      "working_hours": "Jornada de trabalho"
    },
    "home": {
      "available_on": "disponível no",
      "banner_description": "Gerenciar tarefas, rastrear processos e obter os melhores resultados usando uma única plataforma",
      "create_request": {
        "text": "Primeiramente, você precisa criar uma Ordem de Serviço do Cliente no DPMS com os detalhes do seu pedido.",
        "title": "Criar uma ordem de serviço"
      },
      "dpms_mobile": "DPMS Mobile App",
      "getting_started": "Começando",
      "home_title": "Home",
      "inspection": {
        "text": "Realize inspeções de qualidade no campo e registre os obstáculos.",
        "title": "Inspeção"
      },
      "inspection_calendar": {
        "calendar": "Calendário",
        "table": "Tabela",
        "title": "Cronograma de Inspeção"
      },
      "performance": {
        "text": "Registre o progresso e o tempo com base nas atividades planejadas de cada equipe e meça a produtividade.",
        "title": "Construção"
      },
      "scoping": {
        "text": "Prepare uma ordem de serviço do cliente. Atribua serviços e pacotes de serviços do seu contrato.",
        "title": "Preparação para o Trabalho"
      },
      "welcome_user": "Bem-vindo, %{user}"
    },
    "importation_drop": {
      "choose_error": "Escolha um item"
    },
    "importation_popup": {
      "file_upload_message": "Clique ou arraste o arquivo para esta área para fazer o upload",
      "importation_error": "Você pode rever esta mensagem na página Trabalhos.",
      "importation_success": "Sucesso na importação.",
      "importation_timeout": "A importação está demorando muito. Verifique seu status na página Trabalhos.",
      "instructions": "Os arquivos devem ser .xls e não maiores que xxmb. <br> Seu arquivo .xls deve conter a coluna - Data, Cliente, Projeto, Tarefa, Notas .... <br> A ordem das colunas deve ser a mesma que a ordem do modelo. <br> A data deve estar em - AAAA / MM / DD.",
      "requirements": "Exigências",
      "submit_spreadsheet": "Envie sua planilha"
    },
    "inspections": {
      "acceptance": "Aceitação",
      "acceptance_error": {
        "error_description": "A Inspeção - %{item} não pôde ser atualizada porque %{reason}",
        "title": "Não podemos completar"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "Você pode reverter isso mais tarde, se necessário.",
          "reject": "Tem certeza de que deseja rejeitar esta(s) inspeção(ões)?"
        },
        "inspection_acceptance": "Aceitação de Inspeção",
        "items": "%{items} itens",
        "reject": "Rejeitar",
        "subtitle": "Forneça informações sobre a(s) inspeção(ões) para prosseguir com a aceitação."
      },
      "actions": {
        "approve": "Aprovar",
        "more": "Mais",
        "reject": "Rejeitar"
      },
      "choose_modal": {
        "schedule": "Agendar Itens de Inspeção",
        "schedule_description": "Agende uma ou várias inspeções com base num intervalo de datas",
        "single": "Único Item de Inspeção",
        "single_description": "Criação de apenas uma Inspeção",
        "title": "Crie uma inspeção como:"
      },
      "description": "Inspeção",
      "description_title": "Descrição",
      "every": "Cada",
      "id": "ID",
      "inspect_date": "Data de Inspeção",
      "inspect_status_id": "Status",
      "inspected": "Inspecionado",
      "inspected_detail": "Detalhe inspecionado",
      "inspected_item": "Item inspecionado",
      "inspected_modules": {
        "estimate_services": "Detalhes da estimativa",
        "performance": "Construção",
        "progress": "Construção",
        "progress_services": "Serviços de Construção",
        "request": "Ordem de Serviço",
        "scaffolding": "Andaimes",
        "scope": "Preparação para o Trabalho"
      },
      "inspected_services": "Serviços Inspecionados",
      "inspected_track": {
        "area": "Área",
        "comments": "Comentários",
        "description": "Descrição",
        "item": "Item %{item}",
        "no_load": "Não foi possível carregar o bloco de rastreamento inspecionado: dados ausentes",
        "number_of_tags": "Número de tags",
        "reason": "Razão",
        "subarea": "Subárea"
      },
      "inspection_block": {
        "inspection_date": "Data de inspeção",
        "missing_date": "Data ausente",
        "not_selected_yet": "Ainda não selecionado",
        "responsible": "Responsável"
      },
      "inspections_detail": {
        "side_panel_title": "Detalhes da inspeção"
      },
      "module": "Módulo",
      "notifications": {
        "approve_successful": "A inspeção foi aprovada com sucesso",
        "reject_successful": "A inspeção foi rejeitada com sucesso"
      },
      "pending_inspections": "Inspeções pendentes",
      "recurrency_interval": "Intervalo de recorrência",
      "related_inspections": {
        "missing_date": "Data ausente",
        "no_related": "Nenhuma inspeção relacionada encontrada",
        "no_responsible": "Nenhum responsável",
        "title": "Inspeções Relacionadas"
      },
      "repeat": "Repetir",
      "repeat_every_value": {
        "day": "Dia",
        "month": "Mês",
        "week": "Semana",
        "year": "Ano"
      },
      "repeats_on": "Repita",
      "request_id": "DPMSID",
      "responsible_id": "Responsável",
      "schedule_detail": "Detalhe da programação",
      "schedule_form": {
        "acceptance_step": "Definir Aceitação (opcional)",
        "detailed_item_step": "Escolha o item de preparação/construção para inspeção",
        "detailed_template_step": "Escolha o modelo de preparação/construção para inspeção",
        "further_step_information": "Os campos flexíveis estão prontos para serem preenchidos individualmente após a etapa de criação de recorrência",
        "general_step": "Definir informações gerais de inspeção",
        "hints": "Para recorrências mensais e anuais, o sistema agendará a inspeção no dia igual a data inicial do campo Intervalo",
        "monthly_hint": "Repete mensalmente todo o dia %{day}",
        "recurrence_step": "Recorrência"
      },
      "scheduled_exception": "Programado",
      "select_module_item": "Selecione um item de módulo para ver seus detalhes aqui",
      "statuses": {
        "approved": "Aprovado",
        "completed": "Completo",
        "overdue": "Atrasado",
        "pending": "Pendente",
        "progress": "Em Progresso",
        "rejected": "Rejeitado",
        "scheduled": "Agendado"
      },
      "template": "Modelo",
      "title": "Inspeções"
    },
    "inspects": {
      "statuses": {
        "approved": "Aprovado",
        "completed": "Completo",
        "pending": "Pendente",
        "progress": "Em Progresso",
        "rejected": "Rejeitado"
      }
    },
    "kendo": {
      "upload": "Clique, arraste ou cole arquivos aqui para fazer upload"
    },
    "kendo_translations": {
      "calendar": {
        "today": "Hoje"
      },
      "dateinput": {
        "decrement": "Diminuir valor",
        "increment": "Aumente o valor"
      },
      "datepicker": {
        "toggleCalendar": "Alternar calendário"
      },
      "daterangepicker": {
        "end": "Fim",
        "separator": 0,
        "start": "Começar",
        "swapStartEnd": "Troque os valores inicial e final"
      },
      "datetimepicker": {
        "cancel": "Cancelar",
        "date": "Data",
        "set": "Aplicar",
        "time": "Tempo",
        "toggleDateTimeSelector": "Alternar seletor de data e hora"
      },
      "dropdowns": {
        "clear": "Limpar",
        "nodata": "Nenhum Dado Encontrado"
      },
      "editor": {
        "addColumnAfter": "Adicionar coluna à direita",
        "addColumnBefore": "Adicionar coluna à esquerda",
        "addRowAfter": "Adicionar linha abaixo",
        "addRowBefore": "Adicionar linha acima",
        "alignCenter": "Texto central",
        "alignJustify": "Justificar",
        "alignLeft": "Alinhar o texto à esquerda",
        "alignRight": "Alinhar o texto à direita",
        "bold": "Negrito",
        "bulletList": "Inserir lista não ordenada",
        "createTable": "Crie uma mesa",
        "deleteColumn": "Apagar coluna",
        "deleteRow": "Apagar linha",
        "deleteTable": "Apagar mesa",
        "fontName": "Nome da fonte",
        "fontSize": "Tamanho da fonte",
        "format": "Formato",
        "hyperlink": "Insira o hyperlink",
        "hyperlink-dialog-cancel": "Cancelar",
        "hyperlink-dialog-content-address": "Endereço da web",
        "hyperlink-dialog-content-newwindow": "Abrir link em nova janela",
        "hyperlink-dialog-content-title": "Título",
        "hyperlink-dialog-insert": "Inserir",
        "hyperlink-dialog-title": "Insira o hyperlink",
        "image": "Inserir Imagem",
        "image-address": "Endereço da web",
        "image-altText": "Texto alternativo",
        "image-cancel": "Cancelar",
        "image-dialog-title": "Inserir Imagem",
        "image-height": "Altura (px)",
        "image-insert": "Inserir",
        "image-title": "Título",
        "image-width": "Largura (px)",
        "indent": "Recuar",
        "insertTableHint": "Crie uma tabela {0} x {1}",
        "italic": "itálico",
        "mergeCells": "Mesclar células",
        "orderedList": "Inserir lista ordenada",
        "outdent": "Outdent",
        "redo": "Refazer",
        "splitCell": "Dividir célula",
        "strikethrough": "Tachado",
        "subscript": "Subscrito",
        "superscript": "Sobrescrito",
        "underline": "Sublinhado",
        "undo": "Desfazer",
        "unlink": "Remover hiperlink",
        "viewHtml": "Ver HTML",
        "viewHtml-cancel": "Cancelar",
        "viewHtml-dialog-title": "Ver HTML",
        "viewHtml-update": "Atualizar"
      },
      "filter": {
        "addExpression": "Adicionar Expressão",
        "addGroup": "Adicionar grupo",
        "afterOperator": "É depois",
        "afterOrEqualOperator": "É posterior ou igual a",
        "andLogic": "E",
        "beforeOperator": "É antes",
        "beforeOrEqualOperator": "É anterior ou igual a",
        "close": "Fechar",
        "containsOperator": "Contém",
        "endsWithOperator": "Termina com",
        "eqOperator": "É igual a",
        "gtOperator": "É melhor que",
        "gteOperator": "É maior que ou igual a",
        "isEmptyOperator": "Está vazia",
        "isFalse": "É não",
        "isNotEmptyOperator": "Não está vazio",
        "isNotNullOperator": "Não é nulo",
        "isNullOperator": "É nulo",
        "isTrue": "É sim",
        "ltOperator": "É menos do que",
        "lteOperator": "É menor ou igual a",
        "notContainsOperator": "Não contém",
        "notEqOperator": "Não é igual a",
        "orLogic": "Ou",
        "startsWithOperator": "Começa com"
      },
      "grid": {
        "filterAfterOperator": "É depois",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "filterBetweenOperator": "Está entre",
        "filterBooleanAll": "(Todos)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contém",
        "filterEndsWithOperator": "Termina com",
        "filterEqOperator": "É igual a",
        "filterGtOperator": "É melhor que",
        "filterGteOperator": "É maior que ou igual a",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsFalse": "É não",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsNullOperator": "É nulo",
        "filterIsTrue": "É sim",
        "filterLtOperator": "É menos do que",
        "filterLteOperator": "É menor ou igual a",
        "filterNotContainsOperator": "Não contém",
        "filterNotEqOperator": "Não é igual a",
        "filterStartsWithOperator": "Começa com",
        "filterSubmitButton": "Filtro",
        "filterTitle": "Filtro",
        "groupPanelEmpty": "Arraste um cabeçalho de coluna e solte-o aqui para agrupar por essa coluna",
        "noRecords": "Nenhum registro disponível.",
        "pagerFirstPage": "Vá para a primeira página",
        "pagerInfo": "{0} - {1} de {2} itens",
        "pagerItemsPerPage": "itens por página",
        "pagerLastPage": "Vá para a última página",
        "pagerNextPage": "Vá para a página seguinte",
        "pagerOf": "do",
        "pagerPage": "Página",
        "pagerPreviousPage": "Vá para a página anterior"
      },
      "numerictextbox": {
        "decrement": "Diminuir valor",
        "increment": "Aumente o valor"
      },
      "pager": {
        "firstPage": "Vá para a primeira página",
        "info": "{0} - {1} de {2} itens",
        "itemsPerPage": "itens por página",
        "lastPage": "Vá para a última página",
        "nextPage": "Vá para a página seguinte",
        "of": "do",
        "page": "Página",
        "previousPage": "Vá para a página anterior"
      },
      "scheduler": {
        "agendaViewTitle": "Agenda",
        "allDay": "dia todo",
        "allEvents": "Todos os eventos",
        "dateTitle": "Data",
        "dayViewTitle": "Dia",
        "deleteConfirmation": "Tem certeza que deseja deletar este evento?",
        "deleteDialogTitle": "Apagar Evento",
        "deleteOccurrence": "Apagar a ocorrência atual",
        "deleteRecurringConfirmation": "Quer excluir apenas esta ocorrência de evento ou toda a série?",
        "deleteRecurringDialogTitle": "Excluir item recorrente",
        "deleteSeries": "Exclua a série",
        "deleteTitle": "Excluir",
        "editorCancel": "Cancelar",
        "editorDelete": "Excluir",
        "editorEventAllDay": "Evento de dia inteiro",
        "editorEventDescription": "Descrição",
        "editorEventEnd": "Fim",
        "editorEventEndTimeZone": "Fuso Horário Final",
        "editorEventSeparateTimeZones": "Termina em fuso horário diferente",
        "editorEventStart": "Começar",
        "editorEventStartTimeZone": "Fuso horário inicial",
        "editorEventTimeZone": "Especifique o fuso horário",
        "editorEventTitle": "Título",
        "editorOccurrence": "Editar ocorrência atual",
        "editorRecurringConfirmation": "Quer editar apenas esta ocorrência de evento ou toda a série?",
        "editorRecurringDialogTitle": "Editar Item Recorrente",
        "editorSave": "Salve ",
        "editorSeries": "Edite a série",
        "editorTitle": "Evento",
        "editorValidationEnd": "O horário de término deve ser posterior ao horário de início.",
        "editorValidationRequired": "Campo é obrigatório.",
        "editorValidationStart": "O horário de início deve ser anterior ao horário de término.",
        "eventTitle": "Evento",
        "monthViewTitle": "Mês",
        "nextTitle": "Próximo",
        "noEvents": "Sem eventos",
        "previousTitle": "Anterior",
        "recurrenceEditorDailyInterval": "dias)",
        "recurrenceEditorDailyRepeatEvery": "Repita a cada",
        "recurrenceEditorEndAfter": "Depois de",
        "recurrenceEditorEndLabel": "Fim",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOccurrence": "ocorrência (s)",
        "recurrenceEditorEndOn": "Em",
        "recurrenceEditorFrequenciesDaily": "Diariamente",
        "recurrenceEditorFrequenciesMonthly": "Por mês",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanal",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorMonthlyDay": "Dia",
        "recurrenceEditorMonthlyInterval": "mês (es)",
        "recurrenceEditorMonthlyRepeatEvery": "Repita a cada",
        "recurrenceEditorMonthlyRepeatOn": "Repita",
        "recurrenceEditorOffsetPositionsFirst": "Primeiro",
        "recurrenceEditorOffsetPositionsFourth": "Quarto",
        "recurrenceEditorOffsetPositionsLast": "Último",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Terceiro",
        "recurrenceEditorRepeat": "Repetir",
        "recurrenceEditorWeekdaysDay": "Dia",
        "recurrenceEditorWeekdaysWeekday": "Dia da semana",
        "recurrenceEditorWeekdaysWeekendday": "Dia de fim de semana",
        "recurrenceEditorWeeklyInterval": "semana (s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repita a cada",
        "recurrenceEditorWeeklyRepeatOn": "Repita",
        "recurrenceEditorYearlyInterval": "anos)",
        "recurrenceEditorYearlyOf": "do",
        "recurrenceEditorYearlyRepeatEvery": "Repita a cada",
        "recurrenceEditorYearlyRepeatOn": "Repita",
        "showFullDay": "Mostrar o dia inteiro",
        "showWorkDay": "Mostrar horário comercial",
        "timeTitle": "Tempo",
        "timelineViewTitle": "Linha do tempo",
        "today": "Hoje",
        "weekViewTitle": "Semana",
        "workWeekViewTitle": "Semana de trabalho"
      },
      "sortable": {
        "noData": "Sem dados"
      },
      "timepicker": {
        "cancel": "Cancelar",
        "now": "AGORA",
        "selectNow": "Selecione agora",
        "set": "Aplicar",
        "toggleClock": "Alternar relógio",
        "toggleTimeSelector": "Alternar Seletor"
      },
      "treelist": {
        "filterAfterOperator": "É depois",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "filterBooleanAll": "(Todos)",
        "filterClearButton": "Limpar",
        "filterContainsOperator": "Contém",
        "filterEndsWithOperator": "Termina com",
        "filterEqOperator": "É igual a",
        "filterGtOperator": "É melhor que",
        "filterGteOperator": "É maior que ou igual a",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsFalse": "É não",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsNullOperator": "É nulo",
        "filterIsTrue": "É sim",
        "filterLtOperator": "É menos do que",
        "filterLteOperator": "É menor ou igual a",
        "filterNotContainsOperator": "Não contém",
        "filterNotEqOperator": "Não é igual a",
        "filterStartsWithOperator": "Começa com",
        "noRecords": "Nenhum registro disponível"
      },
      "upload": {
        "cancel": "Cancelar",
        "clearSelectedFiles": "Limpar",
        "dropFilesHere": "ou solte o arquivo aqui",
        "files": "arquivos",
        "headerStatusUploaded": "Feito",
        "headerStatusUploading": "Enviando...",
        "invalidFileExtension": "Tipo de arquivo não permitido.",
        "invalidFiles": "Arquivo (s) inválido (s). Verifique os requisitos de upload de arquivo.",
        "invalidMaxFileSize": "O tamanho do arquivo é muito grande.",
        "invalidMinFileSize": "Tamanho do arquivo muito pequeno.",
        "remove": "Remover",
        "retry": "Tentar novamente",
        "select": "Selecione o arquivo",
        "total": "Total",
        "uploadSelectedFiles": "Envio"
      }
    },
    "loading_batch_entities": {
      "page_of": "Página %{page} de %{total}",
      "title": "Buscando páginas"
    },
    "login": {
      "again": "Para continuar, faça o login novamente",
      "continue_mykaefer": "Continue com MyKaefer",
      "copyright": "®%{year} Todos os direitos reservados. Kaefer®",
      "forgot_password": "Esqueceu a senha?",
      "iframe_info": "Solicitando acesso à página: %{link}",
      "log_in": "Conecte-se",
      "password": "Senha",
      "title_1": "Uma ferramenta poderosa",
      "title_2": "para gerenciamento de projetos",
      "username": "Nome do usuário",
      "welcome_back": "Bem vindo de volta"
    },
    "main_dialog": {
      "failure_message": "sua solicitação não pode ser concluida.",
      "failure_title": "Falha",
      "loading_message": "Aguarde enquanto preparamos as coisas para você!",
      "loading_title": "Carregando...",
      "service_title": "Aguardando o término do trabalho...",
      "success_message": "Sua solicitação foi bem sucedida! Agora você pode continuar seu trabalho.",
      "success_title": "Bem sucedido!"
    },
    "notification": {
      "access_denied": "Acesso negado",
      "access_denied_message": "Você não tem permissão para realizar esta ação. Entre em contato com seu administrador para obter mais informações.",
      "action_success": "%{model} %{id} foi %{action}",
      "bad_formula_setup": "Há um problema de configuração com a fórmula '%{title}', por favor verifique sua configuração!",
      "correct_subproject": "Selecione o subprojeto correto deste item",
      "dismiss_all": "Fechar todos",
      "error": "Erro",
      "error_cookies": "Não foi possível estabelecer comunicação entre fontes cruzadas. Revise as configurações de cookies em seu navegador e desative o 'bloqueio de cookies de terceiros'.",
      "error_job": "Falhou! Verifique o motivo na página de tafefas em segundo plano.",
      "error_msg": "Desculpe! Algo deu errado!",
      "info": "Informações",
      "large_file_body": "Aplique alguns filtros para tornar o arquivo de saída menor.",
      "large_file_title": "A tabela tem mais de 1000 registros",
      "page_denied": "A página não pôde ser carregada.",
      "please": "Por favor",
      "select_subproject": "Selecione um projeto e subprojeto para continuar",
      "success": "Sucesso",
      "success_msg": "Ação executada com sucesso!",
      "warning": "Atenção"
    },
    "orders": {
      "add_evidence": "Adicione evidência da autorização do cliente",
      "attachment": "Anexo",
      "comments": "Comentários",
      "date": "Data",
      "drag_and_drop": "Adicione arquivos arrastando-os",
      "recipient": "Destinatário",
      "responsible": "Responsável",
      "title": "Pedidos"
    },
    "performance": {
      "title": "Construção"
    },
    "performances": {
      "title": "Construções"
    },
    "performances_wip": {
      "performance_item": "Item de construção",
      "title": "Construção"
    },
    "planning": {
      "item_no": "Nº do Item",
      "service_no": "Nº do serviço",
      "title": "Planejamento",
      "view": "Visão de planejamento"
    },
    "plannings": {
      "title": "Planejamento"
    },
    "print_grid": {
      "title": "Imprimir a grade"
    },
    "print_list": {
      "loading": "Gerando seu documento. Por favor, aguarde um momento.",
      "title": "Imprimir lista"
    },
    "progress": {
      "statuses": {
        "canceled": "Cancelado",
        "done": "Feito",
        "extra": "Extra",
        "in_progress": "Em andamento",
        "pending": "Pendente",
        "revised": "Revisado"
      }
    },
    "progress_histories": {
      "add_new_progress": "Adicionar um novo progresso",
      "empty_history": "Não há nenhum item de histórico associado e novos itens não podem ser criados",
      "hours": "Horas",
      "production_factor": "PF",
      "progress": "Progresso",
      "progress_badge": "Item %{number}",
      "progress_history": "Histórico do item",
      "progress_service_badge": "Serviço %{number}",
      "progress_service_history": "Histórico do serviço",
      "progress_service_title": "Históricos do serviço",
      "progress_title": "Históricos do item",
      "quantity": "Quantidade",
      "quantity_empty_history": "Não há item de histórico e não é possível criar históricos quando a quantidade é zero",
      "subtitle": "Gerencie o progresso diário com base nas atividades planejadas por sua equipe."
    },
    "progress_services": {
      "actual_div_hours": "Horas reais / Meta de horas da equipe",
      "actual_div_quantity": "Quantidade real / planejada",
      "actual_hours": "Horas realizadas",
      "actual_quantity": "Quantidade realizada",
      "application_price": "Preço da Aplicação",
      "back": "Todas as construções",
      "budget_target_hours": "Meta de horas do orçamento",
      "canceled": "Cancelado",
      "choose_modal": {
        "package": "Pacote de detalhe único",
        "package_description": "Criação de vários serviços baseados no mesmo detalhe",
        "single": "Detalhe único",
        "single_description": "Criação de apenas um detalhe",
        "title": "Crie um novo"
      },
      "contract_service": "Contrato de Serviço",
      "contribution": "Contribuição",
      "contribution_info": "Valores calculados automaticamente",
      "crew": "equipe",
      "crew_size": "Tamanho da Equipe",
      "crews": "equipes",
      "date_filter": {
        "all": "Todos",
        "to": "à",
        "today": "Hoje",
        "tomorrow": "Amanhã",
        "week": "Semana",
        "yesterday": "Ontem"
      },
      "date_filter_info": "Filtro de data",
      "delta_hours": "Delta Horas",
      "delta_qtt": "Quantidade Delta",
      "description": "Serviço de Construção",
      "discipline": "Disciplina",
      "end_date": "Data final",
      "equipment_price": "Preço do equipamento",
      "factor": "Fator",
      "flag": "Sinalização",
      "id": "ID",
      "materials_price": "Preço dos materiais",
      "no_crews": "Nº de Equipes",
      "no_workers": "Nº de Trabalhadores",
      "norm_hours": "Horário normal",
      "not_planned": "Não planejado",
      "other_price": "Outro Preço",
      "package_factor": "Fator",
      "people": "pessoas",
      "person": "pessoa",
      "pf": "PF",
      "pf_doing_bad": "Você não está indo bem",
      "pf_doing_good": "Você está indo bem!",
      "pf_doing_neutral": "Não foi possível calcular o pf",
      "planned": "Planejado",
      "planning_chart": {
        "actual": "Realizado",
        "actual_qty": "Qtd realizada",
        "date": "Data",
        "forecasted": "Previsto",
        "forecasted_qty": "Qtd Prevista",
        "target": "Meta",
        "target_qty": "Qtd meta",
        "title": "Gráfico de Planejamento"
      },
      "planning_timeline": {
        "day": "dia",
        "day_advance": "dia de antecedência",
        "day_delayed": "dia atrasado",
        "days": "dias",
        "days_advance": "dias de antecedência",
        "days_delayed": "dias atrasados",
        "end_date": "Data final",
        "forecasted": "Previsto",
        "on_time": "Na hora",
        "remaining_days": "Dias restantes",
        "start_date": "Data de início",
        "title": "Cronograma de Planejamento"
      },
      "progress": "Progresso",
      "progress_services_detail": {
        "side_panel_title": "Detalhes do serviço"
      },
      "quantity": "Quantidade",
      "responsible": "Responsável",
      "scoped_quantity": "Quantidade Preparada",
      "service": "Serviço",
      "service_package": "Pacote de serviço",
      "service_planning": "Planejamento de Serviços",
      "service_progress": "Progresso do Serviço",
      "service_weight": "Peso de Serviço",
      "start_date": "Data de início",
      "statuses": {
        "canceled": "Cancelado",
        "done": "Concluído",
        "extra": "Extra",
        "in_planning": "Não planejado",
        "inspected_flag": "Inspeção Pendente",
        "planned": "Planejado"
      },
      "team_target_hours": "Meta de horas da equipe",
      "title": "Serviços de construção",
      "title_packageable": "Serviços de construção por pacote de serviços",
      "total": "Total",
      "total_hours": "Total de Horas",
      "unit": "Unidade",
      "worker": "trabalhador",
      "workers": "trabalhadores",
      "working_hours": "Jornada de Trabalho"
    },
    "progresses": {
      "actual_hours": "Horas reais",
      "cancel": "Cancelar",
      "choose_modal": {
        "single": "Outro item de construção",
        "single_description": "Criação de apenas um item de construção"
      },
      "complete": "Completar",
      "complete_services": "Serviços Completos",
      "current_hours": "Hora atual",
      "current_qty": "Quantidade atual",
      "date_filter": {
        "all": "Todos",
        "current_week": "Semana atual",
        "previous_week": "Semana anterior",
        "today": "Hoje"
      },
      "date_filter_info": "Filtro de data",
      "delta_hours": "Δ Horas",
      "delta_qty": "Δ Quantidade",
      "description": "Descrição",
      "edit_work_description": "Editar Descrição do Trabalho",
      "extra": "Extra",
      "flag": "Sinalização",
      "history": "Adicionar novo progresso",
      "id": "Id",
      "item": "Item",
      "label": "Rótulo",
      "more_details": "ver mais",
      "pf": "PF",
      "planned_hours": "Horas planejadas",
      "planned_qty": "Quantidade planejada",
      "preview_unavailable": "Nenhuma pré-visualização disponível",
      "progress": "Progresso",
      "progress_status_restriction": "O progresso não deve ter status Cancelado ou Concluído",
      "progresses_detail": {
        "side_panel_title": "Detalhes de construção"
      },
      "remaining_hours": "Horas restantes",
      "request_status_restriction": "A Ordem de Serviço deve ter status Em Planejamento ou Em Construção",
      "revised": "Revisado",
      "success_work_description_edition": "A descrição do trabalho foi editada com sucesso",
      "title": "Construções",
      "work_description": "Descrição do trabalho"
    },
    "projects": {
      "workdays": {
        "fri": "Sex",
        "mon": "Seg",
        "sat": "Sáb",
        "sun": "Dom",
        "thu": "Qui",
        "tue": "Ter",
        "wed": "Qua"
      }
    },
    "requests": {
      "actual_hours": "Horas reais",
      "actual_progress": "Progresso real",
      "all": "Todos",
      "are_you_sure_complete": "Tem certeza de que concluiu esta ordem de serviço?",
      "are_you_sure_complete_no_history": "Tem certeza de que concluiu uma ordem de serviço sem nenhum progresso?",
      "are_you_sure_deletion": "Tem certeza de que deseja excluir a ordem de serviço?",
      "are_you_sure_revertion": "Tem certeza de que deseja reverter e assim perder os dados que foram inseridos neste módulo?",
      "area": "Área",
      "authorization_pending": "Autorização pendente",
      "authorize_form": {
        "modal_title": "Autorização",
        "subtitle": "Forneça algumas informações sobre a ordem de serviço para prosseguir com a aprovação",
        "title": "Autorizar ordem de serviço"
      },
      "canceled": "Cancelado",
      "clustered": "Agrupamento",
      "comments": "Comentários",
      "confirmation_modal": {
        "cancel_description": "A ordem de serviço será cancelada",
        "cancel_title": "Cancelar ordem de serviço?",
        "complete_description": "Esta ordem de serviço será concluída",
        "complete_title": "Ordem de serviço completa?",
        "confirm_action": "Prossiga para os preparativos do trabalho de importação",
        "confirm_cancel": "Agora não",
        "confirm_confirmation": "Sim, quero confirmar",
        "confirm_description": "Deseja confirmar a ordem de serviço?",
        "confirm_title": "Confirmação de ordem de serviço",
        "delete_description": "A ação de remoção não pode ser desfeita. Você tem certeza?",
        "delete_title": "Excluir ordem de serviço?",
        "reject_description": "Este item retornará ao status Em Preparação",
        "reject_title": "Rejeitar ordem de serviço?",
        "remove_recycle_description": "Este item retornará ao status Em Escopo e perderá o sinalizador de Reciclagem.",
        "remove_recycle_title": "Limpar Reciclagem?",
        "revert_description": "A ação de reversão não pode ser desfeita. Você tem certeza?",
        "revert_title": "Reverter ordem de serviço?"
      },
      "confirmed": "Confirmada",
      "create_inspection_modal": {
        "category_subtitle": "Selecione uma opção da categoria %{category_name}",
        "loading": "Carregando modelos de inspeção...",
        "no_templates": "Não há modelos de inspeção.",
        "plural_title": "Criar inspeções",
        "standalone_subtitle": "Selecione uma das opções abaixo para continuar",
        "title": "Escolha o modelo de inspeção"
      },
      "creation_form": {
        "proceed": "Prossiga para a Etapa 2"
      },
      "creation_page": {
        "add_template": "Adicionar modelo",
        "first_step": "Criar ordem de serviço",
        "second_step": "Preparações de trabalho de importação (opcional)",
        "third_step": "Aplicar pacotes de serviço (opcional)"
      },
      "description": "Ordem de Serviço",
      "discipline": "Disciplina",
      "discipline_check": {
        "check_terms": "Termos de verificação disciplinar",
        "perform": "Realizar Verificação Disciplinar",
        "ready_for_discipline": "Pronto para verificação disciplinar",
        "ready_for_review": "Ordem de serviço pronta para revisão disciplinar",
        "set_as_ready": "Definir a Ordem de Serviço como pronta para verificação disciplinar",
        "title": "Verificação de Disciplina"
      },
      "discipline_check_item": "WO01 – Pronto para Verificação Disciplinar",
      "discipline_check_no_comment": "Nenhum comentário adicionado",
      "done": "Finalizado",
      "eav_template": "Modelo",
      "eav_template_id": "ID do modelo",
      "edit_dialog": {
        "reason": "Nome da Sub-requisição"
      },
      "elevation": "Elevação",
      "errors": {
        "not_found": "Não foi possível encontrar a Ordem de Serviço com ID: %{id}"
      },
      "estimates_authorization": "É necessária autorização de estimativa",
      "flags": {
        "discipline_check": "WO01 - Verificação de Disciplina",
        "discipline_check_done": "WO01 – Verificação Disciplinar: Aprovado",
        "discipline_check_pending": "WO01 – Verificação Disciplinar: Pendente",
        "discipline_check_rejected": "WO01 – Verificação Disciplinar: Rejeitado",
        "recycle": "WO03 - Reciclar"
      },
      "forecast_end_date": "Data de término prevista",
      "id": "DPMSID",
      "in_performance": "Em construção",
      "in_planning": "Em planejamento",
      "in_scoping": "Em preparação",
      "information": "Informação adicional",
      "inspection_date": "Data de inspecção",
      "no_comment": "Sem comentários",
      "notification": {
        "authorization_success": "A ordem de serviço foi autorizada com sucesso",
        "deleting_sub_request": "A Sub Requisição %{id} está sendo excluída. Verifique a página de Trabalhos para saber mais sobre seu progresso",
        "discipline_check_approved": "Verificação de Disciplina aprovada com sucesso",
        "discipline_check_rejected": "Verificação de disciplina rejeitada com sucesso",
        "discipline_check_success": "A ordem de serviço está pronta com sucesso para revisão disciplinar",
        "missing_order_error": "Não há nenhum pedido pendente associado a esta ordem de serviço",
        "recycle_success": "A ordem de serviço foi reciclada com sucesso"
      },
      "on_hold": "Em Espera",
      "progress": "Progresso",
      "progress_end_date": "Data final do Avanço",
      "progress_responsible": "Responsável pelo Avanço",
      "progress_responsible_id": "Responsável pelo Avanço",
      "progress_start_date": "Data de início do Avanço",
      "reason": "Referência",
      "recycle_form": {
        "label": "Razão - Disciplina",
        "modal_title": "Reciclar",
        "subtitle": "Forneça informações para sinalizar esta Ordem de Serviço para Reciclagem",
        "title": "Solicitação de reciclagem"
      },
      "recycling_item": "WO03 - Reciclar para Cliente",
      "reference": "Referência",
      "registered": "Registrada",
      "remove_recycle_modal": {
        "cancel_description": "Não, manter sinalização",
        "confirm_description": "Sim, claro",
        "success_notification": "A sinalização para Reciclagem foi removida em %{reason} - %{discipline}"
      },
      "request_date": "Data da ordem de serviço",
      "request_detail": {
        "edit": "Editar",
        "estimates": "Estimativas",
        "inspections": "Inspeções",
        "performance": "Construção",
        "planning": "Planejamento",
        "scopes": "Preparativos para o Trabalho",
        "show": "Mostrar",
        "side_panel_title": "Detalhes da requisição"
      },
      "request_end_date": "Data final da ordem de serviço",
      "request_responsible": "Responsável pela Ordem de Serviço",
      "request_responsible_id": "Responsável pela Ordem de Serviço",
      "request_start_date": "Data de início da ordem de serviço",
      "responsible": "Responsável",
      "schedule": "Cronograma de Ordem de Serviço",
      "schedule_form": {
        "date": "Data",
        "days": "%{number} dias",
        "performance": "Construção",
        "progress": "Progresso",
        "request": "Ordem de Serviço",
        "responsible": "Responsável",
        "schedule": "Cronograma",
        "scoping": "Preparação para o Trabalho",
        "subtitle": "Para criar um cronograma para esta ordem de serviço, primeiro precisamos de algumas informações adicionais.",
        "timecode": "Código de tempo",
        "title": "Cronograma de ordem de serviço"
      },
      "scope_end_date": "Data final da preparação do trabalho",
      "scope_responsible": "Responsável pela Preparação do Trabalho",
      "scope_responsible_id": "Responsável pela Preparação do Trabalho",
      "scope_start_date": "Data de início da preparação do trabalho",
      "scopes": "Preparativos para o Trabalho",
      "service_desired_date": "Data desejada",
      "skip_scoping": "Pular fase de preparação para o trabalho",
      "status": "Status",
      "statuses": {
        "authorization_pending": "Pendente",
        "canceled": "Cancelado",
        "clustered": "Agrupamento",
        "confirmed": "Em preparação",
        "deleting": "Excluindo",
        "done": "Feito",
        "in_performance": "Em construção",
        "in_planning": "Em construção",
        "in_scoping": "Em preparação",
        "on_hold": "Em espera",
        "registered": "Criada"
      },
      "sub_request": "Sub-requisição",
      "sub_requests": "Sub-requisições",
      "subarea": "Subárea",
      "team_target_hours": "Meta de horas da equipe",
      "title": "Ordens de trabalho",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - %{revision} revisado.pdf"
    },
    "revert_modal": {
      "cancel": "Não, cancelar",
      "description": "Tem certeza de que deseja reverter a inspeção \"%{id}\"?",
      "ok": "Sim, reverter para pendente.",
      "success_description": "Revertido para pendente com sucesso.",
      "success_title": "A inspeção foi revertida",
      "title": "Reverter item?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "Texto alternativo",
        "by_url": "Por URL",
        "height": "Altura (px)",
        "image": "Imagem",
        "title": "Título",
        "upload": "Carregar",
        "web_address": "Endereço da web",
        "width": "Largura (px)",
        "window_title": "Edição de imagem"
      }
    },
    "scaffolding_part_action": {
      "comment": "Comente",
      "date": "Data",
      "height": "Altura",
      "length": "Comprimento",
      "modification_type": {
        "dismantle": "Desmantelar",
        "erect": "Ereto",
        "erectDismantle": "Erguer/Desmontar"
      },
      "singular": "Ação da peça do andaime",
      "title": "Ação da peça do andaime",
      "total": "Total",
      "width": "Largura",
      "work_dismantle": "Desmontar em outra ordem de serviço",
      "work_order": {
        "choose_wo": "Escolha a ordem de serviço"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "Construído",
        "created_by": "Criado por",
        "dismantle": "Dimensão removida",
        "erect": "Dimensão adicionada",
        "modify": "Modificado",
        "total": "Total",
        "total_dismantle": "Andaime Desmontado"
      },
      "comment": "Comente",
      "confirmation_modal": {
        "delete_cancel": "Não, cancelar",
        "delete_confirmation": "Sim, excluir",
        "delete_description": "Tem certeza de que deseja excluir a dimensão %{total} %{dimension}?",
        "delete_title": "Excluir ação?"
      },
      "date": "Data",
      "duplicate_title": "Ação duplicada",
      "edit_title": "Editar ação",
      "feed": {
        "empty": "Nenhuma ação criada para esta peça de andaime"
      },
      "height": "Altura",
      "length": "Comprimento",
      "modification_type": {
        "dismantle": "Desmantelar",
        "erect": "Ereto",
        "erectDismantle": "Erguer/Desmontar"
      },
      "singular": "Ação da peça do andaime",
      "timestamp_badges": {
        "new": "Novo",
        "updated": "Atualizada"
      },
      "title": "Ação da peça do andaime",
      "total": "Total",
      "width": "Largura",
      "work_dismantle": "Desmontar em outra ordem de serviço",
      "work_order": {
        "choose_wo": "Escolha a ordem de serviço"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "Ação do total %{total}%{dimension} removido da lista",
        "title": "Adicionar uma dimensão"
      },
      "back": "Todas as peças de andaimes",
      "comment": "Comentário",
      "confirmation_modal": {
        "delete_cancel": "Não, cancelar",
        "delete_confirmation": "Sim, excluir",
        "delete_description": "Deseja excluir o %{type} com %{total} %{dimension}?",
        "delete_title": "Excluir parte %{type}?"
      },
      "description": "Descrição",
      "dismantle_modal": {
        "cancel": "Não, cancelar",
        "confirm": "Sim, desmontar",
        "confirmation_message": "Você quer desmontar esta parte?",
        "date": "Data",
        "errors": {
          "date_greater_max": "A data de desmontagem deve ser hoje ou antes"
        },
        "success_message": "A parte do andaime foi desmontada com sucesso",
        "title": "Parte de desmontagem",
        "work_order": "Ordem de Serviço"
      },
      "erect_method": "Método",
      "erect_methods": {
        "os": "Sobre o mar",
        "standard": "Padrão",
        "tt": "Acesso por corda sobre o mar"
      },
      "id": "ID",
      "initial_date": "Data Inicial",
      "menu_items": {
        "dimensions": "Adicionar uma dimensão",
        "dismantle": "Desmantelar",
        "modifications": "Modifique esta parte"
      },
      "operation_dismantle": "Desmontagem da operação",
      "operation_erect": "Montagem da operação",
      "scaffolding_part_detail": {
        "actions": "Ações",
        "side_panel_title": "Detalhes da peça do andaime"
      },
      "scaffolding_type_id": "Tipo do andaime",
      "scaffolding_types": {
        "dop": "DOP",
        "hanging": "Andaime suspenso",
        "lifting_rig": "Plataforma de elevação",
        "mobile": "Andaime móvel",
        "patch_boards": "Tábuas de distribuição",
        "roof_over_roof": "Telhado sobre telhado",
        "scaffolding_net": "Rede de andaime",
        "shelf": "Prateleira",
        "staircase_tower": "Torre de escada",
        "standard": "Andaime padrão",
        "stool": "Banquinho",
        "support": "Suporte",
        "system_covering": "Cobertura modular",
        "tarpaulin": "Lona",
        "temporary_railing": "Guarda-corpo temporário",
        "tent_frame": "Estrutura de tenda"
      },
      "singular": "Parte de andaime",
      "title": "Partes do Andaime",
      "total": "Total",
      "type_filter": "Filtro de tipo",
      "weight": "Peso"
    },
    "scaffoldings": {
      "area_id": "Área",
      "area_noise_id": "Ruído de área",
      "area_noises": {
        "db_100_105": "100-105dB - Tempo de residência de 2 horas",
        "db_105_110": "105-110dB - Tempo de residência de 1/2 hora",
        "db_85_90": "85-90dB - Tempo de residência de 12 horas",
        "db_90_100": "90-100dB - Tempo de residência de 6 horas",
        "db_less_85": "< 85dB - Sem restrições"
      },
      "back": "Todos os andaimes",
      "buttons_form": {
        "create_part": "Criar uma parte"
      },
      "client": "Cliente",
      "comment": "Comente",
      "confirmation_modal": {
        "delete_cancel": "Não, cancelar",
        "delete_confirmation": "Sim, apagar",
        "delete_description": "Você deseja excluir o TAG do Andaime %{tag}?",
        "delete_title": "Apagar andaimes",
        "dismantle_cancel": "Agora não",
        "dismantle_confirmation": "Sim, eu quero desmontar",
        "dismantle_description": "Você quer desmontar o andaime TAG %{tag}?",
        "dismantle_title": "Desmontagem de andaimes",
        "duplicate_with_parts_cancel": "Agora não",
        "duplicate_with_parts_confirmation": "Sim, quero duplicar com as peças",
        "duplicate_with_parts_description": "Você quer duplicar o Andaime do TAG %{tag} com suas partes?",
        "duplicate_with_parts_title": "Andaime duplicado com peças",
        "ready_to_dismantle_cancel": "Agora não",
        "ready_to_dismantle_confirmation": "Sim, eu quero mudar",
        "ready_to_dismantle_description": "Deseja alterar o status do TAG %{tag} para pronto para desmontar?",
        "ready_to_dismantle_title": "Andaime pronto para desmontar",
        "revert_cancel": "Agora não",
        "revert_confirmation": "Sim, eu quero mudar",
        "revert_description": "Você deseja alterar o status do TAG %{tag} para erguido?",
        "revert_title": "Reverter status do andaime para erguido"
      },
      "contact_person": "Pessoa de contato",
      "description": "Descrição",
      "description_of_deviation": "Descrição do Desvio",
      "deviation_improved": "Data de melhoria do desvio",
      "dismantle_compensation_format": "Desmantelar formato de compensação",
      "dismantle_date": "Data de desmontagem",
      "dismantle_modal": {
        "cancel": "Não, cancelar",
        "confirm": "Sim, desmontar",
        "confirmation_message": "Quer desmontar este andaime?",
        "date": "Data",
        "errors": {
          "date_greater_max": "A data de desmontagem deve ser hoje ou antes"
        },
        "success_message": "O andaime foi desmontado com sucesso",
        "title": "Desmontagem de andaimes",
        "work_order": "Ordem de Serviço"
      },
      "erect_compensation_format": "Formato de compensação ereto",
      "erect_date": "Data de montagem",
      "erect_with_deviation": "Ereto com Desvio",
      "id": "ID",
      "inspection": {
        "delayed": "Atrasado",
        "missing_initial": "Ausente"
      },
      "inspection_control": "Inspeção",
      "installation": "Instalação",
      "load_class_id": "Classe de carga",
      "load_classes": {
        "kg_150": "2 - 150 kg/m2",
        "kg_200": "3 - 200 kg/m2",
        "kg_300": "4 - 300 kg/m2",
        "kg_450": "5 - 450 kg/m2",
        "kg_600": "6 - 600 kg/m2",
        "kg_75": "1 - 75 kg/m2",
        "not_applicable": "0 - Não aplicável"
      },
      "main_work_order_dismantle_id": "Desmontagem de ordem de serviço",
      "main_work_order_erect_id": "Ordem de Serviço Ereta",
      "missing_initial_control": "Controle Inicial",
      "missing_request": "Ordem de serviço ausente",
      "number_of_tags": "Número de tags",
      "operation_dismantle": "Operação Desmantelar",
      "operation_erect": "Operação Ereta",
      "pictures": "Fotos)",
      "project_number": "Projeto de referência",
      "qr_code": "Código QR",
      "reason": "Motivo da Ordem de Serviço",
      "scaffolding_detail": {
        "side_panel_title": "Detalhes do andaime"
      },
      "scaffolding_status_id": "Status",
      "sections": {
        "details": "Detalhes",
        "deviations": "Desvios",
        "general": "Informações gerais",
        "pictures": "Fotos)"
      },
      "singular": "Andaimes",
      "statuses": {
        "created": "Criada",
        "dismantled": "Desmontado",
        "erected": "Erguido",
        "ready_to_dismantle": "Pronto para desmontar"
      },
      "subarea_id": "Subárea",
      "tag": "MARCAÇÃO",
      "tag_number": "Número da etiqueta",
      "tag_removed": "Marca removida",
      "telephone_radio": "Telefone - Rádio",
      "title": "Registro de andaime",
      "total_m": "Total m",
      "total_m2": "Total de m2",
      "total_m3": "Total de m3",
      "total_weight_message": "Peso total do andaime erguido",
      "user_of_scaffolding": "Usuário de andaime",
      "weight": "Peso",
      "wind_exposed": "Vento exposto"
    },
    "scopes": {
      "add": "Adicionar item de preparação de trabalho",
      "choose_modal": {
        "single": "Outro item de preparação para o trabalho",
        "single_description": "Criação de apenas um item de preparação de trabalho"
      },
      "clusters": {
        "title": "Clusters de preparação para o trabalho"
      },
      "count": "Contagem",
      "crew_size": "Tamanho da equipe",
      "crews": "Número de equipes",
      "date": "Data",
      "description": "Preparação para o Trabalho",
      "duplicate_services": {
        "other_request": "Outra Ordem de Serviço",
        "other_request_description": "Duplicar para um item de preparação de trabalho em outra ordem de serviço",
        "same_request": "Mesma Ordem de Serviço",
        "same_request_description": "Selecione o item de preparação do trabalho",
        "success_notification": "Os serviços de Preparação de Trabalho foram duplicados com sucesso",
        "title": "Serviços Duplicados"
      },
      "edit_work_description": "Editar descrição do trabalho",
      "hours": "Horas (h)",
      "id": "ID",
      "item": "Item",
      "label": "Marcador",
      "label_assigner": {
        "success": "Suas etiquetas de preparação para o trabalho foram atribuídas com sucesso! Você pode continuar seu plano agora."
      },
      "label_error": "Não foi possível buscar os marcadores",
      "labels_info": "Etiquetas de preparação para o trabalho",
      "more_details": "ver mais",
      "popup_label": {
        "create_label": "Criar etiqueta",
        "labels": "Marcadores:",
        "name_label": "Nome da etiqueta",
        "new_label": "Nova etiqueta"
      },
      "preview_unavailable": "Nenhuma visualização disponível",
      "quantity": "Qtd",
      "remaining_hours": "Horas Restantes",
      "remaining_quantity": "Qtd Restante",
      "request_status_restriction": "A Ordem de Serviço deve ter status Confirmado ou Em Preparação",
      "scopes_detail": {
        "side_panel_title": "Detalhes de preparação de trabalho"
      },
      "success_work_description_edition": "A descrição do trabalho foi editada com sucesso",
      "title": "Preparativos para o Trabalho",
      "unit": "Unidade",
      "work_description": "Descrição do Trabalho",
      "working_hours": "Jornada de trabalho"
    },
    "search": {
      "loading": "Carregando...",
      "no_result_found": "Nenhum resultado encontrado",
      "placeholder": "Filtro..."
    },
    "service_description": "Descrição do Serviço",
    "settings": {
      "title": "Configurações"
    },
    "settings_shortcuts": "Atalhos de configurações",
    "shortcuts": {
      "access_control": "Controle de acesso",
      "areas": "Áreas e subáreas",
      "areas_subareas": "Áreas e subáreas",
      "assets": "Ativos",
      "background_jobs": "Trabalhos em segundo plano",
      "branches": "Filiais",
      "cities": "Cidades",
      "client_sites": "Plantas do cliente",
      "clients": "Clientes",
      "clients_info": "Informações de clientes",
      "columns_renaming": "Renomeação de Colunas",
      "companies": "Empresas",
      "contract_services": "Serviços do contrato",
      "contracts": "Contratos",
      "countries": "Países",
      "customer_sites": "Sites de clientes",
      "customers": "Clientes",
      "disciplines": "Disciplinas",
      "employees": "Funcionários",
      "equipaments": "Equipamentos",
      "equipments": "Equipamentos",
      "formulas": "Fórmulas",
      "location": "Localização",
      "materials": "Materiais",
      "matrices": "Matrizes",
      "measures": "Medidas",
      "mobile_details": "Detalhes mobile",
      "position_names": "Cargos",
      "preferences": "Preferências",
      "project_assets": "Ativos",
      "project_contracts": "Contratos",
      "projects": "Projetos e subprojetos",
      "projects_contracts": "Projetos e contratos",
      "projects_subprojects": "Projetos e subprojetos",
      "provinces": "Estados / Províncias",
      "regions": "Regiões",
      "routes": "Rotas",
      "specialities": "Especialidades",
      "staff": "Funcionários",
      "states_provinces": "Estados",
      "templates": "Modelos",
      "translations": "Renomeação de colunas",
      "users": "Usuários",
      "visible_fields": "Detalhes mobile",
      "work_positions": "Nomes de Cargos"
    },
    "side_menu": {
      "collapse": "Contrair",
      "contracts": "Contratos",
      "datasheets": "Planilhas",
      "expand": "Expandir",
      "report": "Relatórios",
      "search": "Procurar",
      "settings": "Configurações",
      "template": {
        "asset": "Ativos",
        "contract_service": "Serviços do contrato",
        "datasheet": "Planilhas",
        "estimate_service": "Serviços de estimativa",
        "inspection": "Inspeções",
        "performance": "Construção",
        "report": "Relatórios",
        "request": "Ordens de trabalho",
        "scoping": "Preparação para o Trabalho",
        "valuation": "Medição"
      },
      "templates": "Modelos"
    },
    "side_panel": {
      "close": "Fechar painel lateral",
      "collapse": "Recolher painel lateral",
      "expand": "Expandir painel lateral",
      "tabs": {
        "category_filter": "Filtro de categoria",
        "gallery": "Galeria",
        "info": "Informações",
        "inspections": "Inspeções",
        "no_categories": "Não há categorias disponíveis",
        "no_templates": "Não há modelos disponíveis",
        "preview_details": "Detalhes da visualização",
        "progress": "Progresso",
        "template_filter": "Filtro de modelo"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "Todas as Sub Requisições",
        "back": "Todos os preparativos para o trabalho",
        "title": "sub requisições"
      },
      "left_panel": {
        "add_scoping": "Adicionar item de preparação de trabalho",
        "allocation": "Alocação",
        "cards_header_title": "Preparativos para o Trabalho",
        "summary": "Resumo",
        "total_allocation": "Alocação total"
      },
      "scope_card": {
        "scope": "Preparação para o Trabalho"
      }
    },
    "tabs": {
      "error": "Impossível carregar abas"
    },
    "templates": {
      "asset": "Ativos",
      "contract_service": "Serviços do contrato",
      "datasheet": "Planilhas",
      "estimate_service": "Serviços de Estimativa",
      "inspection": "Inspeções",
      "no_template": "Você precisa criar um modelo",
      "progress": "Construção",
      "progress_service": "Medição",
      "report": "Relatórios",
      "request": "Ordens de trabalho",
      "scoping": "Preparação para o Trabalho",
      "title": "Modelos para %{type}"
    },
    "tickets": {
      "icon_alt": "Um ícone de bilhete",
      "title": "Tickets"
    },
    "timeline": {
      "created": "Criada",
      "day_ago_singular": "%{daysAgo} dia atrás",
      "day_until_singular": "Dentro de %{daysUntil} dia",
      "days_ago": "%{daysAgo} dias atrás",
      "days_until": "Dentro de %{daysUntil} dias",
      "discipline_check_done": "Verificação de Disciplina: Concluído",
      "discipline_check_pending": "Verificação de Disciplina: Pendente",
      "discipline_check_rejected": "Verificação de Disciplina: Rejeitado",
      "estimate_authorized": "Estimativa: Autorizada",
      "estimate_done": "Estimativa: concluída",
      "estimate_on_hold": "Estimativa: em espera",
      "estimate_pending": "Estimativa: pendente",
      "estimate_pending_authorization": "Estimativa: autorização pendente",
      "estimate_progress": "Estimativa: em andamento",
      "performance": "Construção",
      "progress": "Progresso",
      "recycle_done": "Reciclar: Concluído",
      "recycle_recycling": "Reciclar: Reciclagem",
      "recycling": "Reciclando",
      "rejected": "Rejeitado",
      "request": "Ordem de Serviço",
      "responsible": "Responsável",
      "schedule": "Agendar",
      "scoping": "Preparação para o Trabalho",
      "title": "Cronograma da Ordem de Serviço",
      "today": "Hoje"
    },
    "top_menu": {
      "authorization": "Autorizações",
      "datasheets": "Folhas de dados",
      "eav_templates": "Modelos",
      "estimates": "Estimativas",
      "inspections": "Inspeções",
      "no_datasheets": "Nenhuma folha de dados encontrada",
      "no_reports": "Nenhum relatório encontrado",
      "performances": "Construção",
      "plannings": "Planejamento",
      "reports": "Relatórios",
      "requests": "Ordens de trabalho",
      "scaffoldings": "Registro de andaime",
      "scopes": "Preparação para o Trabalho",
      "workflow": "Fluxo de trabalho"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "Sobre a versão",
      "clean_preferences": "Limpar preferências",
      "logout": "Sair",
      "notification": {
        "clean_preferences": "Preferências limpas",
        "error": "Erro ao tentar limpar as preferências"
      },
      "preferences": "Preferências de Grade",
      "profile": "Perfil"
    },
    "users": {
      "edit_title": "Atualizar perfil"
    },
    "work_order_attachments": {
      "subtitle": "Consulte os documentos relacionados a esta ordem de serviço.",
      "title": "Anexos de ordem de serviço"
    }
  },
  "ru": {
    "i18n_tasks": {
      "add_missing": {
        "added": "Добавлены ключи (%{count})"
      },
      "cmd": {
        "args": {
          "default_text": "По умолчанию: %{value}",
          "desc": {
            "all_locales": "Не ожидать, что маски ключа начинаются с локали. Применять маски ко всем локалям.",
            "config": "Путь к файлу конфигурации",
            "confirm": "Подтвердить автоматом",
            "data_format": "Формат данных: %{valid_text}.",
            "keep_order": "Keep the order of the keys",
            "key_pattern": "Маска ключа (например, common.*)",
            "locale": "Язык. По умолчанию: base",
            "locale_to_translate_from": "Язык, с которого переводить (по умолчанию: base)",
            "locales_filter": "Список языков для обработки, разделенный запятыми (,). По умолчанию: все. Специальное значение: base.",
            "missing_types": "Типы недостающих переводов: %{valid}. По умолчанию: все",
            "nostdin": "Не читать дерево из стандартного ввода",
            "out_format": "Формат вывода: %{valid_text}.",
            "pattern_router": "Использовать pattern_router: ключи распределятся по файлам согласно data.write",
            "strict": "Не угадывать динамические использования ключей, например `t(\"category.#{category.key}\")`",
            "translation_backend": "Движок перевода (google или deepl)",
            "value": "Значение, интерполируется с %%{value}, %%{human_key}, %%{key}, %%{default}, %%{value_or_human_key}, %%{value_or_default_or_human_key}"
          }
        },
        "desc": {
          "add_missing": "добавить недостающие ключи к переводам",
          "check_consistent_interpolations": "убедитесь, что во всех переводах используются правильные интерполяционные переменные",
          "check_normalized": "проверить, что все файлы переводов нормализованы",
          "config": "показать конфигурацию",
          "cp": "скопируйте ключи в данных локали, соответствующие заданному шаблону",
          "data": "показать данные переводов",
          "data_merge": "добавить дерево к переводам",
          "data_remove": "удалить ключи, которые есть в дереве, из данных",
          "data_write": "заменить переводы деревом",
          "eq_base": "показать переводы, равные значениям в основном языке",
          "find": "показать, где ключи используются в коде",
          "gem_path": "показать путь к ruby gem",
          "health": "Всё ОК?",
          "irb": "начать REPL сессию в контексте i18n-tasks",
          "missing": "показать недостающие переводы",
          "mv": "переименовать / объединить ключи, которые соответствуют заданному шаблону",
          "normalize": "нормализовать файлы переводов (сортировка и распределение)",
          "remove_unused": "удалить неиспользуемые ключи",
          "rm": "удалить ключи, которые соответствуют заданному шаблону",
          "translate_missing": "перевести недостающие переводы с Google Translate / DeepL Pro",
          "tree_convert": "преобразовать дерево между форматами",
          "tree_filter": "фильтровать дерево по ключу",
          "tree_merge": "объединенить деревья",
          "tree_mv_key": "переименованить / объединить / удалить ключи соответствующие заданному шаблону",
          "tree_set_value": "заменить значения ключей",
          "tree_subtract": "дерево A минус ключи в дереве B",
          "tree_translate": "Перевести дерево при помощи Google Translate на язык корневых узлов",
          "unused": "показать неиспользуемые переводы"
        },
        "encourage": [
          "Хорошая работа!",
          "Отлично!",
          "Прекрасно!"
        ],
        "enum_list_opt": {
          "invalid": "%{invalid} не в: %{valid}."
        },
        "enum_opt": {
          "invalid": "%{invalid} не является одним из: %{valid}."
        },
        "errors": {
          "invalid_format": "Неизвестный формат %{invalid}. Форматы: %{valid}.",
          "invalid_locale": "Неверный язык %{invalid}",
          "invalid_missing_type": {
            "few": "Неизвестные типы: %{invalid}. Типы: %{valid}.",
            "many": "Неизвестные типы: %{invalid}. Типы: %{valid}.",
            "one": "Неизвестный тип %{invalid}. Типы: %{valid}.",
            "other": "Неизвестные типы: %{invalid}. Типы: %{valid}."
          },
          "pass_forest": "Передайте дерево"
        }
      },
      "common": {
        "continue_q": "Продолжить?",
        "key": "Ключ",
        "locale": "Язык",
        "n_more": "ещё %{count}",
        "value": "Значение"
      },
      "data_stats": {
        "text": "%{key_count} ключей в %{locale_count} языках. В среднем, длина строки: %{value_chars_avg}, сегменты ключей: %{key_segments_avg}, ключей в языке %{per_locale_avg}.",
        "text_single_locale": "%{key_count} ключей. В среднем, длина строки: %{value_chars_avg}, сегменты ключей: %{key_segments_avg}.",
        "title": "Данные (%{locales}):"
      },
      "deepl_translate": {
        "errors": {
          "no_api_key": "Задайте ключ API DeepL через переменную окружения DEEPL_AUTH_KEY или translation.deepl_api_key Получите ключ через https://www.deepl.com/pro.",
          "no_results": "DeepL не дал результатов.",
          "specific_target_missing": "You must supply a specific variant for the given target language e.g. en-us instead of en."
        }
      },
      "google_translate": {
        "errors": {
          "no_api_key": "Задайте ключ API Google через переменную окружения GOOGLE_TRANSLATE_API_KEY или translation.google_translate_api_key в config/i18n-tasks.yml. Получите ключ через https://code.google.com/apis/console.",
          "no_results": "Google Translate не дал результатов. Убедитесь в том, что платежная информация добавлена в https://code.google.com/apis/console."
        }
      },
      "health": {
        "no_keys_detected": "Ключи не обнаружены. Проверьте data.read в config/i18n-tasks.yml."
      },
      "inconsistent_interpolations": {
        "none": "Не найдено несогласованных интерполяций."
      },
      "missing": {
        "details_title": "На других языках или в коде",
        "none": "Всё переведено."
      },
      "openai_translate": {
        "errors": {
          "no_api_key": "Установить ключ API Яндекса с помощью переменной среды OPENAI_API_KEY или translation.openai_api_key\n в config / i18n-tasks.yml. Получите ключ по адресу https://openai.com/.",
          "no_results": "Яндекс не дал результатов."
        }
      },
      "remove_unused": {
        "confirm": {
          "few": "Переводы (%{count}) будут удалены из %{locales}.",
          "many": "Переводы (%{count}) будут удалены из %{locales}.",
          "one": "%{count} перевод будут удалён из %{locales}.",
          "other": "Переводы (%{count}) будут удалены из %{locales}."
        },
        "noop": "Нет неиспользуемых ключей",
        "removed": "Удалены ключи (%{count})"
      },
      "translate_missing": {
        "translated": "Переведены ключи (%{count})"
      },
      "unused": {
        "none": "Все переводы используются."
      },
      "usages": {
        "none": "Не найдено использований."
      },
      "yandex_translate": {
        "errors": {
          "no_api_key": "Установить ключ API Яндекса с помощью переменной среды YANDEX_API_KEY или translation.yandex_api_key\n в config / i18n-tasks.yml. Получите ключ по адресу https://tech.yandex.com/translate/.",
          "no_results": "Яндекс не дал результатов."
        }
      }
    }
  },
  "th": {
    "actions": {
      "alternate_view": "มุมมองสำรอง",
      "approve": "อนุมัติ",
      "back_to": "กลับไปที่ %{page}",
      "bulk_editing": "การแก้ไขจำนวนมาก",
      "cancel": "ยกเลิก",
      "choose_entity": "เลือก %{entity}",
      "clear": "ชัดเจน",
      "clear_all_filters": "ล้างตัวกรองทั้งหมด",
      "confirm": "ยืนยัน",
      "create": "สร้าง",
      "create_inspection": "สร้างการตรวจสอบ",
      "delete": "ลบ",
      "discard": "ทิ้ง",
      "dismantle": "รื้อ",
      "done": "เสร็จแล้ว",
      "duplicate": "ทำซ้ำ",
      "duplicate_services": "บริการซ้ำซ้อน",
      "duplicate_with_parts": "ทำซ้ำด้วยชิ้นส่วน",
      "edit": "แก้ไข",
      "export": "ส่งออกเป็น .xls",
      "export_template": "ดาวน์โหลดเทมเพลต .xls",
      "filter_pinned_work_orders": "กรองใบสั่งงานที่ปักหมุด",
      "flag_filter": "กรองธง",
      "generate_work_package": "สร้างแพ็คเกจงาน",
      "import": "นำเข้าจาก .xls",
      "import_file": "นำเข้าไฟล์",
      "loading": "กำลังโหลด",
      "more": "มากกว่า",
      "new": "สร้างใหม่",
      "next": "ต่อไป",
      "no": "ไม่",
      "past": {
        "cancel": "ยกเลิก",
        "complete": "สมบูรณ์",
        "confirm": "ยืนยัน",
        "delete": "ลบแล้ว",
        "destroy": "ลบแล้ว",
        "dismantle": "รื้อถอน",
        "duplicate_with_parts": "ทำซ้ำด้วยชิ้นส่วนของมัน",
        "ready_to_dismantle": "พร้อมที่จะรื้อ",
        "reject": "ถูกปฏิเสธ",
        "revert": "หวนกลับ",
        "update": "อัพเดท"
      },
      "perform_discipline_check": "ดำเนินการตรวจสอบวินัย",
      "pin_request": "กรองตามคำขอที่เลือก",
      "prev": "ก่อนหน้า",
      "ready": "พร้อม",
      "ready_for_discipline": "พร้อมตรวจวินัย",
      "ready_to_dismantle": "พร้อมรื้อ",
      "recycle": "รีไซเคิล",
      "reject": "ปฏิเสธ",
      "remove_recycle": "ลบการรีไซเคิล",
      "reset": "รีเซ็ต",
      "retrieving": "กำลังดึงข้อมูล...",
      "revert": "ย้อนกลับ",
      "revert_to_erected": "กลับไปเป็นตั้งตรง",
      "save": "บันทึก",
      "save_button_keyboard": "บันทึก (Ctrl + Enter)",
      "save_exit": "บันทึกและออก",
      "save_new": "เพิ่มอีก",
      "saving": "ประหยัด...",
      "schedule": "กำหนดการ",
      "show_all_work_orders": "แสดงใบสั่งงานทั้งหมด",
      "skip": "ข้าม",
      "status_filter": "ตัวกรองสถานะ",
      "unpin_request": "ลบตัวกรองตามคำขอ",
      "unpin_selected_request": "ลบตัวกรองของ %{numSelectedRequests} คำขอที่เลือก",
      "unsaved_changes": "แถวที่ยังไม่ได้บันทึก",
      "upload_file": "อัพโหลดไฟล์",
      "upload_picture": "อัพโหลดรูปภาพ",
      "yes": "ใช่"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "ไม่สามารถทำซ้ำคำสั่งงานแบบคลัสเตอร์ได้",
      "cant_duplicate_progress": "ไม่สามารถทำซ้ำบริการได้เนื่องจากข้อผิดพลาดดังต่อไปนี้...",
      "cant_duplicate_request": "ไม่สามารถทำซ้ำการเตรียม การประมาณการ และบริการได้ เนื่องจากข้อผิดพลาดต่อไปนี้...",
      "cant_duplicate_scope": "ไม่สามารถทำซ้ำบริการได้เนื่องจากข้อผิดพลาดดังต่อไปนี้...",
      "only_estimate_service_copy": "เฉพาะรายการ Estimate Detail เท่านั้นที่พยายามจะคัดลอก",
      "only_progress_copy": "เฉพาะความคืบหน้าเท่านั้นที่พยายามจะคัดลอก",
      "only_request_copy": "มีเพียงใบสั่งงานเท่านั้นที่พยายามคัดลอก",
      "only_scope_copy": "เฉพาะรายการการเตรียมงานเท่านั้นที่พยายามคัดลอก"
    },
    "cancel_modal": {
      "cancel": "ไม่ใช่ตอนนี้",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิก %{model} \"X_1\"",
      "ok": "ใช่ ยกเลิก",
      "title": "ยกเลิกรายการ?"
    },
    "clear_records": {
      "modal": {
        "cancel": "ไม่ ยกเลิก",
        "description": "คุณแน่ใจหรือไม่ว่าต้องการล้างระเบียน %{model} ทั้งหมด",
        "ok": "ใช่ ล้างทั้งหมด",
        "title": "ล้าง %{model}?"
      },
      "success_message": "เคลียร์ X_0 สำเร็จแล้ว!",
      "title": "ล้างบันทึก"
    },
    "clear_template_preferences": {
      "cancel": "ไม่ ยกเลิก",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการล้างการตั้งค่ากริดทั้งหมดสำหรับเทมเพลตนี้",
      "ok": "ใช่ ล้างการตั้งค่ากริด",
      "title": "ล้างค่ากำหนดของกริด"
    },
    "collections": {
      "clusters": "กลุ่ม",
      "create_by_package": "สร้างโดยแพ็คเกจ",
      "duplicate": "ทำซ้ำ",
      "edit": "แก้ไข",
      "estimate": "ประมาณการ",
      "new": "ใหม่",
      "schedule": "กำหนดการ",
      "sub_requests": "คำขอย่อย",
      "view": "ดู"
    },
    "complete_modal": {
      "cancel": "ไม่ใช่ตอนนี้",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการทำเครื่องหมาย %{model} ว่าเสร็จสิ้น",
      "ok": "ใช่ ทำเครื่องหมายว่าเสร็จแล้ว",
      "title": "ทำเครื่องหมายว่าเสร็จแล้ว?"
    },
    "complete_progress_modal": {
      "cancel": "ยกเลิก",
      "complete": "ทำให้สมบูรณ์",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการทำรายการก่อสร้างนี้ให้เสร็จสิ้น การดำเนินการนี้ไม่สามารถยกเลิกได้",
      "title": "ยืนยันการเสร็จสิ้นรายการก่อสร้าง"
    },
    "confirmation_modal": {
      "cancel": "ยกเลิก",
      "description": "การดำเนินการนี้ไม่สามารถยกเลิกได้!",
      "ok": "ตกลง",
      "title": "คุณแน่ใจไหม?"
    },
    "contract_services": {
      "title": "บริการทำสัญญา"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "เอกสารข้อมูลรายละเอียด"
      },
      "description": "แผ่นข้อมูล",
      "id": "ไอดี",
      "item": "สิ่งของ",
      "title": "แผ่นข้อมูล"
    },
    "delete_attachment_modal": {
      "cancel": "ไม่ เก็บเอกสารแนบไว้",
      "delete_successful": "ลบไฟล์แนบเรียบร้อยแล้ว",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการลบเอกสารแนบนี้",
      "ok": "ใช่ ลบไฟล์แนบ",
      "title": "ลบไฟล์แนบใช่ไหม"
    },
    "delete_modal": {
      "cancel": "ไม่ ยกเลิก",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการลบ %{model} \"X_1\"",
      "ok": "ใช่ ลบ",
      "title": "ลบรายการ?"
    },
    "delete_picture_modal": {
      "cancel": "ไม่ เก็บภาพไว้",
      "delete_successful": "ลบรูปภาพเรียบร้อยแล้ว",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการลบภาพนี้?",
      "ok": "ใช่ ลบภาพออก",
      "title": "ลบรูปภาพ"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "การยอมรับ"
      },
      "column_comment": {
        "add_comment": "คลิกเพื่อเพิ่มความคิดเห็น",
        "cancel": "ยกเลิก",
        "comment": "ความคิดเห็น",
        "subtitle": "เพิ่ม/แก้ไขความคิดเห็น"
      },
      "columns_batch_assigner": {
        "actions": "การกระทำ",
        "add_action": "เพิ่มการกระทำ",
        "add_to_column": "เพิ่มลงในคอลัมน์",
        "add_value_to": "เพิ่ม %{value} ไปยัง",
        "column": "คอลัมน์",
        "done": "บันทึกการดำเนินการ",
        "duplicate_services": "คัดลอกบริการ",
        "header": "แก้ไขแบทช์",
        "multiple_success": "อัปเดต X_0 รายการแล้ว",
        "new_value": "ค่าใหม่",
        "single_success": "อัปเดต 1 รายการแล้ว",
        "subtitle": "กำหนดค่าให้กับคอลัมน์ที่เลือก %{numberOfColumns} รายการ",
        "title": "สร้างการกระทำ",
        "update": "อัปเดต"
      },
      "configure_print_grid_file": {
        "error_max_columns": "คุณสามารถเลือกได้สูงสุด %{maxColumns} คอลัมน์เท่านั้น",
        "generate": "สร้างไฟล์",
        "header": "พิมพ์ตาราง",
        "paper_size_title": "ขนาดกระดาษ",
        "pdf_file": {
          "information": "ข้อมูล",
          "pictures": "รูปภาพ",
          "title": "ตาราง %{modelName} - %{numItems} รายการ - หน้า %{pageNum} จาก %{totalPages}"
        },
        "select_columns_title": "คอลัมน์ที่จะพิมพ์",
        "selected_columns": "คุณได้เลือก %{numSelectedColumns} จาก %{maxColumns}",
        "subtitle": "เลือกได้สูงสุด %{maxColumns} คอลัมน์ที่จะพิมพ์",
        "title": "กำหนดค่าไฟล์ที่จะพิมพ์"
      },
      "configure_print_list_file": {
        "big_picture_title": "พิมพ์ภาพโดยใช้ความกว้างของหน้า?",
        "font_size_title": "เลือกขนาดตัวอักษร",
        "header": "พิมพ์รายการ",
        "hide_columns_title": "รวมคอลัมน์ที่ถูกซ่อนไว้",
        "large": "ใหญ่",
        "medium": "ปานกลาง",
        "page_break_title": "พิมพ์ 1 รายการต่อหน้า?",
        "small": "เล็ก"
      },
      "configure_work_package_file": {
        "attached_documents": "เอกสารที่แนบมาด้วย",
        "attachments": "ไฟล์แนบ",
        "estimates": "การประมาณการ",
        "estimates_price": "ประมาณการราคา",
        "generate": "สร้าง",
        "header": "สร้างแพ็คเกจงาน",
        "hse_r": "การประเมิน HSE-R",
        "inspection_page_breaker": "พิมพ์การตรวจสอบต่อหน้า",
        "inspection_pictures": "รวมรูปภาพและลายเซ็น",
        "inspections": "การตรวจสอบที่เกี่ยวข้องทั้งหมด",
        "subtitle": "เลือกส่วนที่คุณต้องการพิมพ์",
        "title": "กำหนดค่าไฟล์แพ็คเกจงาน",
        "work_description": "รายละเอียดงาน"
      },
      "duplicate_services": {
        "title": "คัดลอกบริการ"
      }
    },
    "discard_modal": {
      "cancel": "แก้ไขต่อไป",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเปลี่ยนแปลงของคุณ",
      "ok": "ใช่ ทิ้ง",
      "title": "ยกเลิกการเปลี่ยนแปลง"
    },
    "eav_templates": {
      "title": "แม่แบบ"
    },
    "empty_state": {
      "columns": "คอลัมน์",
      "empty": "ไม่มี %{model}",
      "no_attachments": "คุณยังไม่ได้อัปโหลดเอกสารแนบใดๆ",
      "no_columns": "ไม่มีคอลัมน์รูปภาพ",
      "no_discipline_template": "ไม่พบเทมเพลตที่มีการกรองระเบียบวินัยของใบสั่งงาน",
      "no_estimate_service_template": "ไม่พบเทมเพลตบริการประมาณการสำหรับเทมเพลตการเตรียมงานที่เลือก",
      "no_pictures": "คุณยังไม่ได้อัปโหลดรูปภาพใดๆ ในคอลัมน์นี้",
      "no_unselect": "ไม่สามารถยกเลิกการเลือกคำขอที่ปักหมุดไว้ในหน้านี้ได้ คุณสามารถทำได้เฉพาะในหน้าใบสั่งงานหรือโดยการออกจากระบบเท่านั้น",
      "not_created": "คุณยังไม่ได้สร้าง %{model} เลย",
      "pictures": "รูปภาพ"
    },
    "environment": {
      "project": {
        "select_project": "เลือกโครงการ..."
      },
      "select_project_subproject": "เลือกโครงการและโครงการย่อย",
      "subproject": {
        "select_subproject": "เลือกโครงการย่อย..."
      }
    },
    "estimate": {
      "accept": "ยอมรับการประมาณการ",
      "all_estimates_approved": "การประมาณการทั้งหมดได้รับการอนุมัติเรียบร้อยแล้ว",
      "application": "ค่าสมัคร",
      "back": "สั่งงานทั้งหมด",
      "budget_hours": "ชั่วโมงงบประมาณ",
      "confirmation": {
        "cancel": "ไม่ ยกเลิก",
        "confirm": "ใช่ ยืนยัน",
        "description": "คุณแน่ใจหรือไม่ว่าต้องการอนุมัติการประมาณการนี้สำหรับใบสั่งงานนี้",
        "title": "ยืนยันรายการ"
      },
      "description": "คุณมีบริการ %{number} สำหรับการชำระเงิน กรุณาตรวจสอบรายละเอียดด้านล่าง",
      "equipment": "ค่าอุปกรณ์",
      "estimate_details": "รายละเอียดการประมาณการ",
      "estimate_success": "อนุมัติประมาณการเรียบร้อยแล้ว",
      "info_message": "การประเมินนี้ได้รับการอนุมัติแล้ว มีให้อ่านเท่านั้น",
      "material": "ค่าวัสดุ",
      "no_estimate_message": "ไม่มีการประมาณการที่เกี่ยวข้องกับใบสั่งงานนี้",
      "norm_hours": "ชั่วโมงปกติ",
      "others": "ค่าใช้จ่ายอื่นๆ",
      "pdf_file": {
        "title": "รายละเอียดการประมาณการ - หน้า %{pageNum} จาก %{totalPages}"
      },
      "pdf_header": {
        "discipline": "การลงโทษ",
        "functional_location": "ตำแหน่งหน้าที่การงาน",
        "jobcard_status": "สถานะบัตรงาน",
        "ke_priority": "ลำดับความสำคัญของ KE",
        "main_area_location": "พื้นที่หลักและที่ตั้ง",
        "main_work_center": "ศูนย์งานหลัก",
        "maintenance_plant": "โรงงานซ่อมบำรุง",
        "not_defined": "ยังไม่ได้กำหนด",
        "order_finish": "สั่งซื้อเสร็จสิ้น",
        "order_start": "เริ่มสั่ง",
        "priority_sequence": "ลำดับลำดับความสำคัญ",
        "project": "โครงการ",
        "project_type": "ประเภทโครงการ",
        "report": "รายงาน",
        "report_date": "วันที่รายงาน",
        "responsible_engineer": "วิศวกรที่รับผิดชอบ",
        "status_completed": "สมบูรณ์",
        "status_issued_for_approval": "ออกเพื่อขออนุมัติ",
        "status_issued_for_construction": "ออกเพื่อการก่อสร้าง",
        "subproject": "โครงการย่อย",
        "system": "ระบบ",
        "template": "เทมเพลต",
        "title": "ประมาณการสั่งงาน",
        "work_order": "สั่งงาน"
      },
      "pdf_summary": {
        "title": "สรุปประมาณการ"
      },
      "save_draft": "บันทึกร่าง",
      "service_details": "รายละเอียดบริการ",
      "some_estimates_not_approved": "การประมาณการบางอย่างไม่สามารถอนุมัติได้ ตรวจสอบบันทึกข้อผิดพลาด",
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการชำระเงิน",
      "tooltip": {
        "application": "แอปพลิเคชัน",
        "equipment": "อุปกรณ์",
        "material": "วัสดุ",
        "others": "คนอื่น"
      },
      "total_approved": "ได้รับการอนุมัติทั้งหมด",
      "total_pending": "รวมยอดคงเหลือ",
      "total_request": "ค่าใช้จ่ายทั้งหมด",
      "use_construction_prices": "รวมราคาก่อสร้างด้วยไหมคะ?"
    },
    "estimate_services": {
      "application_price": "ราคาสมัคร",
      "automatically_prices": "ราคาที่คำนวณโดยอัตโนมัติ",
      "back": "การเตรียมงานทั้งหมด",
      "budget_target_hours": "งบประมาณเป้าหมายชั่วโมง",
      "choose_modal": {
        "package": "แพ็คเกจรายละเอียดเดียว",
        "package_description": "การสร้างบริการหลายอย่างโดยใช้รายละเอียดเดียวกัน",
        "single": "รายละเอียดเดียว",
        "single_description": "การสร้างรายละเอียดเพียงหนึ่งเดียว",
        "title": "สร้างใหม่"
      },
      "contract_service": "บริการทำสัญญา",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "จำนวนลูกเรือ",
      "description": "บริการประเมินราคา",
      "description_label": "คำอธิบาย",
      "discipline": "การลงโทษ",
      "equipment_price": "ราคาอุปกรณ์",
      "estimate": "ประมาณการ",
      "estimate_services_detail": {
        "side_panel_title": "รายละเอียดบริการ"
      },
      "factor": "ปัจจัย",
      "flag": "ธง",
      "id": "ไอดี",
      "item_no": "หมายเลขสินค้า",
      "materials_price": "ราคาวัสดุ",
      "norm_hours": "ชั่วโมงปกติ",
      "other_price": "ราคาอื่นๆ",
      "prices": "ราคา",
      "print": "พิมพ์",
      "quantity": "ปริมาณ",
      "service": "บริการ",
      "service_no": "หมายเลขบริการ",
      "service_package": "แพ็คเกจบริการ",
      "statuses": {
        "inspected_flag": "กำลังตรวจสอบอยู่"
      },
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการประเมินราคา",
      "title_packageable": "ประเมินบริการตามแพ็คเกจบริการ",
      "total": "ทั้งหมด",
      "unit": "หน่วย",
      "weight": "น้ำหนัก",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "fields": {
      "created_at": "สร้าง",
      "image_not_found": "ไม่พบภาพ",
      "na": "ไม่มี",
      "signature": "Signature.jpg",
      "updated_at": "อัปเดต"
    },
    "filter_steps_modal": {
      "filter_result": "ผลการกรอง",
      "result_subtitle": "สรุปการเลือกของคุณ",
      "step": "ขั้นตอน",
      "subtitle": "กรุณาเลือก %{title}"
    },
    "filtering": {
      "between": " นั่นคือระหว่าง",
      "contains": " ที่ประกอบด้วย",
      "eq": " ที่เท่ากับ",
      "invalid_date": "ในการกรองอย่างถูกต้อง ต้องกรอกวันที่เริ่มต้นและวันที่สิ้นสุด",
      "invalid_value": "ค่าไม่ถูกต้อง",
      "neq": " ที่ไม่เท่ากับ"
    },
    "forgot_password": {
      "back_to_login": "กลับสู่การเข้าสู่ระบบ",
      "button": "ส่งคำแนะนำการรีเซ็ตรหัสผ่านมาให้ฉัน",
      "feedback": "ข้อความพร้อมคำแนะนำในการรีเซ็ตรหัสผ่านของคุณได้ถูกส่งไปยังอีเมลที่ให้ไว้",
      "placeholder": "อีเมลของคุณ",
      "subtitle": "กรุณากรอกที่อยู่อีเมลของคุณเพื่อรับคำแนะนำในการรีเซ็ตรหัสผ่าน",
      "title": "ลืมรหัสผ่านหรือไม่?"
    },
    "form": {
      "back": "ทั้งหมด %{entity}",
      "cancel": "ยกเลิก",
      "create_entity": "สร้าง %{entity}",
      "create_error": "ไม่สามารถสร้าง %{entity} ได้",
      "create_success": "สร้าง %{entity} สำเร็จแล้ว",
      "duplicate_entity": "ซ้ำ %{entity}",
      "duplicate_success": "%{entity} และการขึ้นต่อกันถูกทำซ้ำสำเร็จ!",
      "general_information": "ข้อมูลทั่วไป",
      "hide": "ซ่อน",
      "inputs": {
        "attachment": {
          "add_error": "เกิดข้อผิดพลาดในการเพิ่มไฟล์",
          "add_evidence": "เพิ่มหลักฐานการอนุญาตลูกค้า",
          "allowed_extensions": "นามสกุลที่อนุญาตคือ",
          "drag_drop": "เพิ่มไฟล์โดยใช้การลากและวาง",
          "invalid_extension": "ไม่อนุญาตประเภทไฟล์ชนิดนี้.",
          "max_size": "ไฟล์ต้องน้อยกว่า %{max_size}",
          "maximum_num_uploads_error": "คุณสามารถโหลดไฟล์ได้สูงสุด %{max_num} ไฟล์ต่อคอลัมน์แบบยืดหยุ่น",
          "one_file": "อัพโหลดทีละไฟล์",
          "or_drop_file_here": "หรือวางไฟล์ที่นี่",
          "remove_error": "ไม่สามารถลบออก",
          "remove_success": "ลบเรียบร้อยแล้ว",
          "select_files": "เลือกไฟล์",
          "submit": "ส่งเอกสารแนบ",
          "upload_error": "การอัพโหลดล้มเหลว",
          "upload_success": "อัพโหลดสำเร็จ"
        },
        "contract_service": {
          "add": "เพิ่มบริการทำสัญญา",
          "subtitle": "บริการทำสัญญา"
        },
        "datasheet_filter": {
          "add_filter": "เพิ่มตัวกรอง",
          "title": "ตัวกรองแผ่นข้อมูล"
        },
        "date_range": {
          "between": "%{start} ถึง X_1",
          "end": "วันที่สิ้นสุด",
          "end_simplified": "จบ",
          "start": "วันที่เริ่มต้น",
          "start_simplified": "จุดเริ่มต้น"
        },
        "drop_formula": {
          "formula_select": "กรุณาเลือกสูตร..."
        },
        "errors": {
          "date_greater_than_max": "วันที่ควรเป็นวันที่ %{max} หรือก่อนหน้านั้น",
          "date_less_than_min": "วันที่ควรเป็นวันที่ %{min} หรือหลังวันที่ %{min}",
          "drop_formula_result": "%{field} ผลลัพธ์สูตรคำนวณ",
          "mandatory": "ต้องระบุ %{field}",
          "not_found": "ไม่พบ '%{value}' ในข้อมูลอ้างอิง เลือกอันใหม่"
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "หรือลากและวาง",
              "select_file": "คลิกเพื่ออัพโหลด"
            },
            "note": {
              "extensions": "ไฟล์จะต้องอยู่ในรูปแบบ %{extensions}",
              "max_size": "ขนาดไฟล์สูงสุด %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "รูปภาพ"
          },
          "upload": {
            "error": {
              "invalid_picture": "ชนิด/ขนาดไม่ถูกต้อง",
              "multiple_fails": "ไฟล์ที่แนบมาทั้งหมดมีประเภท/ขนาดที่ไม่ถูกต้อง",
              "multiple_files": "รูปภาพที่แนบมาบางรูปมีประเภท/ขนาดไม่ถูกต้อง กำลังอัปโหลดรูปภาพที่ถูกต้อง",
              "title": "การอัพโหลดล้มเหลว"
            },
            "success": {
              "multiple_files": "อัพโหลดไฟล์หลายไฟล์สำเร็จแล้ว",
              "title": "อัพโหลดสำเร็จ"
            }
          }
        },
        "formula": {
          "description": "นี่คือคอลัมน์สูตร ผลลัพธ์จะถูกรีเฟรชอัตโนมัติ",
          "icon": "คลิกเพื่อดูสมการ",
          "result": "ผลลัพธ์"
        },
        "gps": {
          "save_location": "บันทึกตำแหน่ง",
          "search": "ค้นหาตำแหน่ง...",
          "without_location": "ไม่มีที่ตั้ง"
        },
        "multiple_entries": {
          "add": "เพิ่มอีก",
          "empty_entry": {
            "scaffolding_type_id": "เลือกประเภทนั่งร้านเพื่อเพิ่มการกระทำ"
          },
          "empty_inputs": "จำเป็นต้องมีอินพุตของ %{input} ใน %{model}",
          "required": "ช่อง \"%{field}\" จำเป็นต้องกรอกข้อมูล"
        },
        "numeric": {
          "between": "ค่าควรอยู่ระหว่าง %{min} ถึง %{max}",
          "greater": "ค่าควรมากกว่า %{min}",
          "lower": "ค่าควรต่ำกว่า %{max}"
        },
        "picture": {
          "comment_placeholder": "เขียนความคิดเห็น (ไม่บังคับ)",
          "edition_only": "ใช้ได้เฉพาะเมื่อแก้ไข",
          "no_image_on_clipboard": "ไม่พบข้อมูลรูปภาพในคลิปบอร์ดของคุณ กรุณาคัดลอกรูปภาพก่อน",
          "remove_error": "ไม่สามารถลบออก",
          "remove_success": "ลบเรียบร้อยแล้ว",
          "upload_error": "การอัพโหลดล้มเหลว",
          "upload_success": "อัพโหลดสำเร็จ"
        },
        "placeholder": "ป้อน %{field} ที่นี่",
        "value_selector": {
          "apply": "นำมาใช้",
          "done": "เสร็จแล้ว",
          "search": "ค้นหาบางอย่างที่นี่...",
          "waiting": "... รอ"
        }
      },
      "items": "รายการ",
      "loading": "กำลังโหลด...",
      "loading_calculations": "การคำนวณการมองเห็น คอลัมน์ที่จำเป็น และค่าเริ่มต้น",
      "loading_contract": "กำลังโหลดข้อมูลสัญญา...",
      "schedule_entity": "กำหนดการ %{entity}",
      "schedule_success": "คำขอกำหนดเวลา %{entity} ของคุณเสร็จสิ้นแล้ว",
      "show": "แสดง",
      "subtitle": "โปรดให้ข้อมูลพื้นฐานเกี่ยวกับ %{entity}",
      "subtitle_error": "ไม่สามารถโหลดแบบฟอร์มได้ โปรดตรวจสอบ %{entity} หรือข้อมูลเทมเพลต",
      "update_entity": "แก้ไข %{entity}",
      "update_error": "ไม่สามารถอัปเดต %{entity} ได้",
      "update_success": "อัปเดต %{entity} เรียบร้อยแล้ว"
    },
    "formula_modal": {
      "back_button_text": "กลับไปที่แบบฟอร์ม",
      "calculate": "คำนวณ",
      "cancel": "ยกเลิก",
      "mandatory": "คุณต้องกรอกค่าสำหรับฟิลด์ต่อไปนี้: %{fields}",
      "ok": "บันทึกสูตร"
    },
    "grid": {
      "all": "ทั้งหมด",
      "clear_filters": "ล้างตัวกรอง",
      "editable": {
        "error": {
          "mandatory": "เว้นช่องที่ต้องกรอกอย่างน้อยหนึ่งช่อง"
        },
        "missing_required": "%{number} ขาดเซลล์บังคับในแถวนี้",
        "title": "โหมดแก้ไข",
        "update": {
          "error": "ไม่สามารถบันทึก %{quantity} ได้ ตรวจสอบเหตุผลทางด้านซ้ายของแต่ละแถวที่เน้น",
          "success": "บันทึก %{quantity} สำเร็จแล้ว"
        }
      },
      "empty_after_filtering": "ไม่มีบันทึกสำหรับตัวกรองที่ใช้",
      "failed_saving_preferences": "ไม่ได้บันทึกค่ากำหนด!",
      "flag_filter": "ตัวกรองธง",
      "grid_filters": {
        "menu_title": "ตัวกรองแบบกำหนดเอง"
      },
      "menu": {
        "visibility": "คอลัมน์"
      },
      "of": "ของ",
      "other": "คนอื่น",
      "productivity_factor": "ปัจจัยการผลิต",
      "quick_pin_popup": {
        "all_work_orders": "OT ทั้งหมด",
        "clear_all": "ล้างทั้งหมด",
        "no_pinned_requests": "คุณยังไม่ได้ปักหมุดใบสั่งงาน",
        "pinned_work_orders": "OTs ที่ติดหมุด",
        "quick_pin": "ปักหมุดด่วน",
        "search_and_pin": "ค้นหาและปักหมุด OT"
      },
      "saved_preferences": "รอดแล้ว!",
      "saving_preferences": "กำลังบันทึกค่ากำหนด...",
      "select_items": "เลือกรายการ",
      "selected": "เลือกแล้ว",
      "selecteds": "เลือกแล้ว",
      "status_filter": "ตัวกรองสถานะ",
      "totals": "ยอดรวม"
    },
    "history_form": {
      "create_title": "เพิ่มความคืบหน้าใหม่",
      "edit_title": "แก้ไขความคืบหน้าของคุณ",
      "subtitle": "แทรกความคืบหน้าตามปริมาณหรือเปอร์เซ็นต์สำหรับกิจกรรมของคุณ"
    },
    "history_popup": {
      "back": "กลับ",
      "comments": "ความคิดเห็น",
      "crew_size": "ขนาดลูกเรือ",
      "daily_hours": "ชั่วโมงรายวัน",
      "date": "วันที่",
      "form_create_progress_service_title": "สร้างประวัติรายการบริการก่อสร้าง",
      "form_create_progress_title": "สร้างประวัติรายการความคืบหน้า",
      "form_edit_progress_service_title": "แก้ไขประวัติรายการบริการก่อสร้าง",
      "form_edit_progress_title": "แก้ไขประวัติรายการความคืบหน้า",
      "form_error_body": "ต้องกรอกข้อมูลในช่องที่ต้องกรอกทั้งหมดในแบบฟอร์ม",
      "form_error_title": "ไม่สามารถบันทึกแบบฟอร์มประวัติได้",
      "form_subtitle": "แทรกประวัติตามปริมาณหรือเปอร์เซ็นต์สำหรับกิจกรรมของคุณ",
      "modal_create_title": "สร้างประวัติศาสตร์",
      "modal_edit_title": "แก้ไขประวัติ",
      "number_of_crews": "จำนวนลูกเรือ",
      "number_of_workers": "จำนวนคนงาน",
      "other_value": "ค่าอื่นๆ",
      "percentage": "เปอร์เซ็นต์",
      "progress_by": "ความคืบหน้าโดย",
      "quantity": "ปริมาณ",
      "singular": "ประวัติศาสตร์",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "home": {
      "available_on": "มีจำหน่ายที่",
      "banner_description": "จัดการงาน ติดตามกระบวนการ และรับผลลัพธ์ที่ดีที่สุดโดยใช้แพลตฟอร์มเดียว",
      "create_request": {
        "text": "ก่อนอื่น คุณต้องสร้างใบสั่งงานจากลูกค้าใน DPMS พร้อมรายละเอียดเกี่ยวกับคำสั่งซื้อของคุณ",
        "title": "สร้างใบสั่งงาน"
      },
      "dpms_mobile": "แอพมือถือ DPMS",
      "getting_started": "เริ่มต้น",
      "home_title": "บ้าน",
      "inspection": {
        "text": "ดำเนินการตรวจสอบคุณภาพในพื้นที่และบันทึกอุปสรรค",
        "title": "การตรวจสอบ"
      },
      "inspection_calendar": {
        "calendar": "ปฏิทิน",
        "table": "โต๊ะ",
        "title": "กำหนดการตรวจสอบ"
      },
      "performance": {
        "text": "บันทึกความคืบหน้าและเวลาตามกิจกรรมที่วางแผนไว้ของแต่ละทีมและวัดประสิทธิภาพการทำงาน",
        "title": "การก่อสร้าง"
      },
      "scoping": {
        "text": "เตรียมใบสั่งงานจากลูกค้า กำหนดบริการและแพ็คเกจบริการจากสัญญาของคุณ",
        "title": "การเตรียมงาน"
      },
      "welcome_user": "ยินดีต้อนรับ %{user}"
    },
    "importation_drop": {
      "choose_error": "เลือกรายการ"
    },
    "importation_popup": {
      "file_upload_message": "คลิกหรือลากไฟล์มาที่บริเวณนี้เพื่ออัปโหลด",
      "importation_error": "คุณสามารถตรวจสอบข้อความนี้บนหน้างาน",
      "importation_success": "ความสำเร็จในการนำเข้า",
      "importation_timeout": "การนำเข้าใช้เวลานานเกินไป ตรวจสอบสถานะในหน้างาน",
      "instructions": "ไฟล์ต้องเป็น .xls และไม่เกิน xxmb<br> ไฟล์ .xls ของคุณควรมีคอลัมน์ - Date, Client, Project, Task, Notes....<br> ลำดับของคอลัมน์ควรเหมือนกับ ลำดับของเทมเพลต<br> วันที่ควรเป็น - YYYY/MM/DD",
      "requirements": "ความต้องการ",
      "submit_spreadsheet": "ส่งสเปรดชีตของคุณ"
    },
    "inspections": {
      "acceptance": "การยอมรับ",
      "acceptance_error": {
        "error_description": "การตรวจสอบ - %{item} ไม่สามารถอัปเดตได้เนื่องจาก %{reason}",
        "title": "เราไม่สามารถดำเนินการให้เสร็จสิ้นได้"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "คุณสามารถกลับคืนได้ในภายหลังหากจำเป็น",
          "reject": "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธการตรวจสอบนี้/เหล่านี้"
        },
        "inspection_acceptance": "การยอมรับการตรวจสอบ",
        "items": "%{items} รายการ",
        "reject": "ปฏิเสธ",
        "subtitle": "ให้ข้อมูลเกี่ยวกับการตรวจสอบเพื่อดำเนินการต่อไปพร้อมกับการยอมรับ"
      },
      "actions": {
        "approve": "อนุมัติ",
        "more": "มากกว่า",
        "reject": "ปฏิเสธ"
      },
      "choose_modal": {
        "schedule": "รายการตรวจสอบกำหนดการ",
        "schedule_description": "กำหนดการตรวจสอบหนึ่งหรือหลายรายการตามช่วงวันที่",
        "single": "รายการตรวจสอบเดียว",
        "single_description": "สร้างการตรวจสอบเพียงหนึ่งเดียว",
        "title": "สร้างการตรวจสอบเป็น:"
      },
      "description": "การตรวจสอบ",
      "description_title": "คำอธิบาย",
      "every": "ทั้งหมด",
      "id": "ไอดี",
      "inspect_date": "วันที่ตรวจสอบ",
      "inspect_status_id": "สถานะ",
      "inspected": "ตรวจสอบแล้ว",
      "inspected_detail": "ตรวจสอบรายละเอียด",
      "inspected_item": "รายการที่ตรวจสอบแล้ว",
      "inspected_modules": {
        "estimate_services": "รายละเอียดประมาณการ",
        "performance": "การก่อสร้าง",
        "progress": "การก่อสร้าง",
        "progress_services": "บริการด้านการก่อสร้าง",
        "request": "สั่งงาน",
        "scaffolding": "นั่งร้าน",
        "scope": "การเตรียมงาน"
      },
      "inspected_services": "บริการตรวจสอบ",
      "inspected_track": {
        "area": "พื้นที่",
        "comments": "ความคิดเห็น",
        "description": "คำอธิบาย",
        "item": "รายการ %{item}",
        "no_load": "ไม่สามารถโหลดบล็อกแทร็กที่ได้รับการตรวจสอบ: ข้อมูลขาดหายไป",
        "number_of_tags": "จำนวนแท็ก",
        "reason": "เหตุผล",
        "subarea": "พื้นที่ย่อย"
      },
      "inspection_block": {
        "inspection_date": "วันที่ตรวจสอบ",
        "missing_date": "วันที่หายไป",
        "not_selected_yet": "ยังไม่ได้เลือก",
        "responsible": "รับผิดชอบ"
      },
      "inspections_detail": {
        "side_panel_title": "รายละเอียดการตรวจสอบ"
      },
      "module": "โมดูล",
      "notifications": {
        "approve_successful": "การตรวจสอบได้รับการอนุมัติเรียบร้อยแล้ว",
        "reject_successful": "การตรวจสอบถูกปฏิเสธเรียบร้อยแล้ว"
      },
      "pending_inspections": "การตรวจสอบที่รอดำเนินการ",
      "recurrency_interval": "ช่วงเวลาการเกิดซ้ำ",
      "related_inspections": {
        "missing_date": "วันที่หายไป",
        "no_related": "ไม่พบการตรวจสอบที่เกี่ยวข้อง",
        "no_responsible": "ไม่มีความรับผิดชอบ",
        "title": "การตรวจสอบที่เกี่ยวข้อง"
      },
      "repeat": "ทำซ้ำ",
      "repeat_every_value": {
        "day": "วัน",
        "month": "เดือน",
        "week": "สัปดาห์",
        "year": "ปี"
      },
      "repeats_on": "ทำซ้ำใน",
      "request_id": "DPMSID",
      "responsible_id": "รับผิดชอบ",
      "schedule_detail": "รายละเอียดกำหนดการ",
      "schedule_form": {
        "acceptance_step": "กำหนดการยอมรับ (ไม่บังคับ)",
        "detailed_item_step": "เลือกการเตรียมการตรวจสอบ/รายการก่อสร้าง",
        "detailed_template_step": "เลือกเทมเพลตการเตรียมการตรวจสอบ/การก่อสร้าง",
        "further_step_information": "ฟิลด์ที่ยืดหยุ่นพร้อมที่จะกรอกข้อมูลทีละรายการหลังจากขั้นตอนการสร้างซ้ำ",
        "general_step": "กำหนดการตรวจสอบ ข้อมูลทั่วไป",
        "hints": "สำหรับการเกิดซ้ำรายเดือนและรายปี ระบบจะกำหนดเวลาการตรวจสอบที่ตรงกับวันที่เริ่มต้นที่ให้ไว้ใน Interval Field",
        "monthly_hint": "ซ้ำทุกเดือน ทุกวัน %{day}",
        "recurrence_step": "การเกิดซ้ำ"
      },
      "scheduled_exception": "กำหนดการ",
      "select_module_item": "เลือกรายการโมดูลเพื่อดูรายละเอียดที่นี่",
      "statuses": {
        "approved": "ที่ได้รับการอนุมัติ",
        "completed": "สมบูรณ์",
        "overdue": "ค้างชำระ",
        "pending": "รอดำเนินการ",
        "progress": "กำลังดำเนินการ",
        "rejected": "ถูกปฏิเสธ",
        "scheduled": "กำหนดการ"
      },
      "template": "เทมเพลต",
      "title": "การตรวจสอบ"
    },
    "inspects": {
      "statuses": {
        "approved": "ที่ได้รับการอนุมัติ",
        "completed": "สมบูรณ์",
        "pending": "รอดำเนินการ",
        "progress": "กำลังดำเนินการ",
        "rejected": "ถูกปฏิเสธ"
      }
    },
    "kendo": {
      "upload": "คลิกหรือลากไฟล์มาที่นี่เพื่ออัปโหลด"
    },
    "kendo_translations": {
      "calendar": {
        "today": "วันนี้"
      },
      "dateinput": {
        "decrement": "ลดค่า",
        "increment": "เพิ่มมูลค่า"
      },
      "datepicker": {
        "toggleCalendar": "สลับปฏิทิน"
      },
      "daterangepicker": {
        "end": "จบ",
        "separator": 0,
        "start": "เริ่ม",
        "swapStartEnd": "สลับค่าเริ่มต้นและสิ้นสุด"
      },
      "datetimepicker": {
        "cancel": "ยกเลิก",
        "date": "วันที่",
        "set": "ชุด",
        "time": "เวลา",
        "toggleDateTimeSelector": "สลับตัวเลือกวันที่-เวลา"
      },
      "dropdowns": {
        "clear": "ชัดเจน",
        "nodata": "ไม่พบข้อมูล"
      },
      "editor": {
        "addColumnAfter": "เพิ่มคอลัมน์ทางด้านขวา",
        "addColumnBefore": "เพิ่มคอลัมน์ทางด้านซ้าย",
        "addRowAfter": "เพิ่มแถวด้านล่าง",
        "addRowBefore": "เพิ่มแถวด้านบน",
        "alignCenter": "ข้อความตรงกลาง",
        "alignJustify": "ให้เหตุผล",
        "alignLeft": "จัดข้อความชิดซ้าย",
        "alignRight": "จัดข้อความชิดขวา",
        "bold": "ตัวหนา",
        "bulletList": "แทรกรายการที่ไม่เรียงลำดับ",
        "createTable": "สร้างตาราง",
        "deleteColumn": "ลบคอลัมน์",
        "deleteRow": "ลบแถว",
        "deleteTable": "ลบตาราง",
        "fontName": "ชื่อแบบอักษร",
        "fontSize": "ขนาดตัวอักษร",
        "format": "รูปแบบ",
        "hyperlink": "แทรกไฮเปอร์ลิงก์",
        "hyperlink-dialog-cancel": "ยกเลิก",
        "hyperlink-dialog-content-address": "ที่อยู่เว็บ",
        "hyperlink-dialog-content-newwindow": "เปิดลิงก์ในหน้าต่างใหม่",
        "hyperlink-dialog-content-title": "ชื่อ",
        "hyperlink-dialog-insert": "แทรก",
        "hyperlink-dialog-title": "แทรกไฮเปอร์ลิงก์",
        "image": "แทรกรูปภาพ",
        "image-address": "ที่อยู่เว็บ",
        "image-altText": "ข้อความสำรอง",
        "image-cancel": "ยกเลิก",
        "image-dialog-title": "แทรกรูปภาพ",
        "image-height": "ความสูง (px)",
        "image-insert": "แทรก",
        "image-title": "ชื่อ",
        "image-width": "ความกว้าง (px)",
        "indent": "เยื้อง",
        "insertTableHint": "สร้างตาราง 0 x 1",
        "italic": "ตัวเอียง",
        "mergeCells": "ผสานเซลล์",
        "orderedList": "แทรกรายการสั่งซื้อ",
        "outdent": "Outdent",
        "redo": "ทำซ้ำ",
        "splitCell": "แยกเซลล์",
        "strikethrough": "ขีดฆ่า",
        "subscript": "สมัครสมาชิก",
        "superscript": "ตัวยก",
        "underline": "ขีดเส้นใต้",
        "undo": "เลิกทำ",
        "unlink": "ลบไฮเปอร์ลิงก์",
        "viewHtml": "ดู HTML",
        "viewHtml-cancel": "ยกเลิก",
        "viewHtml-dialog-title": "ดู HTML",
        "viewHtml-update": "อัปเดต"
      },
      "filter": {
        "addExpression": "เพิ่มนิพจน์",
        "addGroup": "เพิ่มกลุ่ม",
        "afterOperator": "คือหลังจาก",
        "afterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "andLogic": "และ",
        "beforeOperator": "มาก่อน",
        "beforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "close": "ปิด I",
        "containsOperator": "ประกอบด้วย",
        "endsWithOperator": "ลงท้ายด้วย",
        "eqOperator": "เท่ากับ",
        "gtOperator": "มีค่ามากกว่า",
        "gteOperator": "มากกว่าหรือเท่ากับ",
        "isEmptyOperator": "มันว่างเปล่า",
        "isFalse": "คือไม่มี",
        "isNotEmptyOperator": "ไม่ว่าง",
        "isNotNullOperator": "ไม่เป็นโมฆะ",
        "isNullOperator": "เป็นโมฆะ",
        "isTrue": "คือใช่",
        "ltOperator": "น้อยกว่า",
        "lteOperator": "น้อยกว่าหรือเท่ากับ",
        "notContainsOperator": "ไม่มี",
        "notEqOperator": "ไม่เท่ากับ",
        "orLogic": "หรือ",
        "startsWithOperator": "เริ่มต้นด้วย"
      },
      "grid": {
        "filterAfterOperator": "คือหลังจาก",
        "filterAfterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "filterBeforeOperator": "มาก่อน",
        "filterBeforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "filterBetweenOperator": "อยู่ระหว่าง",
        "filterBooleanAll": "(ทั้งหมด)",
        "filterClearButton": "ชัดเจน",
        "filterContainsOperator": "ประกอบด้วย",
        "filterEndsWithOperator": "ลงท้ายด้วย",
        "filterEqOperator": "เท่ากับ",
        "filterGtOperator": "มีค่ามากกว่า",
        "filterGteOperator": "มากกว่าหรือเท่ากับ",
        "filterIsEmptyOperator": "มันว่างเปล่า",
        "filterIsFalse": "คือไม่มี",
        "filterIsNotEmptyOperator": "ไม่ว่าง",
        "filterIsNotNullOperator": "ไม่เป็นโมฆะ",
        "filterIsNullOperator": "เป็นโมฆะ",
        "filterIsTrue": "คือใช่",
        "filterLtOperator": "น้อยกว่า",
        "filterLteOperator": "น้อยกว่าหรือเท่ากับ",
        "filterNotContainsOperator": "ไม่มี",
        "filterNotEqOperator": "ไม่เท่ากับ",
        "filterStartsWithOperator": "เริ่มต้นด้วย",
        "filterSubmitButton": "กรอง",
        "filterTitle": "กรอง",
        "groupPanelEmpty": "ลากส่วนหัวของคอลัมน์แล้ววางที่นี่เพื่อจัดกลุ่มตามคอลัมน์นั้น",
        "noRecords": "ไม่มีบันทึก",
        "pagerFirstPage": "ไปที่หน้าแรก",
        "pagerInfo": "0 - 1 จาก 2 รายการ",
        "pagerItemsPerPage": "รายการต่อหน้า",
        "pagerLastPage": "ไปที่หน้าสุดท้าย",
        "pagerNextPage": "ไปหน้าถัดไป",
        "pagerOf": "ของ",
        "pagerPage": "หน้าหนังสือ",
        "pagerPreviousPage": "ไปหน้าที่แล้ว"
      },
      "numerictextbox": {
        "decrement": "ลดค่า",
        "increment": "เพิ่มมูลค่า"
      },
      "pager": {
        "firstPage": "ไปที่หน้าแรก",
        "info": "0 - 1 จาก 2 รายการ",
        "itemsPerPage": "รายการต่อหน้า",
        "lastPage": "ไปที่หน้าสุดท้าย",
        "nextPage": "ไปหน้าถัดไป",
        "of": "ของ",
        "page": "หน้าหนังสือ",
        "previousPage": "ไปหน้าที่แล้ว"
      },
      "scheduler": {
        "agendaViewTitle": "กำหนดการ",
        "allDay": "ทั้งวัน",
        "allEvents": "งานกิจกรรมทั้งหมด",
        "dateTitle": "วันที่",
        "dayViewTitle": "วัน",
        "deleteConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบกิจกรรมนี้",
        "deleteDialogTitle": "ลบกิจกรรม",
        "deleteOccurrence": "ลบเหตุการณ์ปัจจุบัน",
        "deleteRecurringConfirmation": "คุณต้องการลบเฉพาะเหตุการณ์ที่เกิดขึ้นนี้หรือทั้งชุด?",
        "deleteRecurringDialogTitle": "ลบรายการที่เกิดซ้ำ",
        "deleteSeries": "ลบซีรีส์",
        "deleteTitle": "ลบ",
        "editorCancel": "ยกเลิก",
        "editorDelete": "ลบ",
        "editorEventAllDay": "กิจกรรมทั้งวัน",
        "editorEventDescription": "คำอธิบาย",
        "editorEventEnd": "จบ",
        "editorEventEndTimeZone": "โซนเวลาสิ้นสุด",
        "editorEventSeparateTimeZones": "สิ้นสุดในโซนเวลาที่แตกต่างกัน",
        "editorEventStart": "เริ่ม",
        "editorEventStartTimeZone": "เริ่มเขตเวลา",
        "editorEventTimeZone": "ระบุโซนเวลา",
        "editorEventTitle": "ชื่อ",
        "editorOccurrence": "แก้ไขเหตุการณ์ปัจจุบัน",
        "editorRecurringConfirmation": "คุณต้องการแก้ไขเฉพาะเหตุการณ์ที่เกิดขึ้นหรือทั้งชุด?",
        "editorRecurringDialogTitle": "แก้ไขรายการที่เกิดซ้ำ",
        "editorSave": "บันทึก",
        "editorSeries": "แก้ไขซีรีส์",
        "editorTitle": "เหตุการณ์",
        "editorValidationEnd": "เวลาสิ้นสุดต้องอยู่หลังเวลาเริ่มต้น",
        "editorValidationRequired": "ต้องระบุฟิลด์",
        "editorValidationStart": "เวลาเริ่มต้นต้องมาก่อนเวลาสิ้นสุด",
        "eventTitle": "เหตุการณ์",
        "monthViewTitle": "เดือน",
        "nextTitle": "ต่อไป",
        "noEvents": "ไม่มีเหตุการณ์",
        "previousTitle": "ก่อนหน้า",
        "recurrenceEditorDailyInterval": "วัน",
        "recurrenceEditorDailyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorEndAfter": "หลังจาก",
        "recurrenceEditorEndLabel": "จบ",
        "recurrenceEditorEndNever": "ไม่เคย",
        "recurrenceEditorEndOccurrence": "เหตุการณ์ที่เกิดขึ้น",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "รายวัน",
        "recurrenceEditorFrequenciesMonthly": "รายเดือน",
        "recurrenceEditorFrequenciesNever": "ไม่เคย",
        "recurrenceEditorFrequenciesWeekly": "รายสัปดาห์",
        "recurrenceEditorFrequenciesYearly": "รายปี",
        "recurrenceEditorMonthlyDay": "วัน",
        "recurrenceEditorMonthlyInterval": "เดือน",
        "recurrenceEditorMonthlyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorMonthlyRepeatOn": "ทำซ้ำใน",
        "recurrenceEditorOffsetPositionsFirst": "อันดับแรก",
        "recurrenceEditorOffsetPositionsFourth": "ที่สี่",
        "recurrenceEditorOffsetPositionsLast": "ล่าสุด",
        "recurrenceEditorOffsetPositionsSecond": "ที่สอง",
        "recurrenceEditorOffsetPositionsThird": "ที่สาม",
        "recurrenceEditorRepeat": "ทำซ้ำ",
        "recurrenceEditorWeekdaysDay": "วัน",
        "recurrenceEditorWeekdaysWeekday": "วันธรรมดา",
        "recurrenceEditorWeekdaysWeekendday": "วันหยุดสุดสัปดาห์",
        "recurrenceEditorWeeklyInterval": "สัปดาห์",
        "recurrenceEditorWeeklyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorWeeklyRepeatOn": "ทำซ้ำใน",
        "recurrenceEditorYearlyInterval": "ปี)",
        "recurrenceEditorYearlyOf": "ของ",
        "recurrenceEditorYearlyRepeatEvery": "ทำซ้ำทุก ๆ",
        "recurrenceEditorYearlyRepeatOn": "ทำซ้ำใน",
        "showFullDay": "แสดงเต็มวัน",
        "showWorkDay": "แสดงเวลาทำการ",
        "timeTitle": "เวลา",
        "timelineViewTitle": "เส้นเวลา",
        "today": "วันนี้",
        "weekViewTitle": "สัปดาห์",
        "workWeekViewTitle": "สัปดาห์การทำงาน"
      },
      "sortable": {
        "noData": "ไม่มีข้อมูล"
      },
      "timepicker": {
        "cancel": "ยกเลิก",
        "now": "ตอนนี้",
        "selectNow": "เลือกเลย",
        "set": "ชุด",
        "toggleClock": "สลับนาฬิกา",
        "toggleTimeSelector": "Toggle TimeSelector"
      },
      "treelist": {
        "filterAfterOperator": "คือหลังจาก",
        "filterAfterOrEqualOperator": "อยู่หลังหรือเท่ากับ",
        "filterBeforeOperator": "มาก่อน",
        "filterBeforeOrEqualOperator": "อยู่ก่อนหรือเท่ากับ",
        "filterBooleanAll": "(ทั้งหมด)",
        "filterClearButton": "ชัดเจน",
        "filterContainsOperator": "ประกอบด้วย",
        "filterEndsWithOperator": "ลงท้ายด้วย",
        "filterEqOperator": "เท่ากับ",
        "filterGtOperator": "มีค่ามากกว่า",
        "filterGteOperator": "มากกว่าหรือเท่ากับ",
        "filterIsEmptyOperator": "มันว่างเปล่า",
        "filterIsFalse": "คือไม่มี",
        "filterIsNotEmptyOperator": "ไม่ว่าง",
        "filterIsNotNullOperator": "ไม่เป็นโมฆะ",
        "filterIsNullOperator": "เป็นโมฆะ",
        "filterIsTrue": "คือใช่",
        "filterLtOperator": "น้อยกว่า",
        "filterLteOperator": "น้อยกว่าหรือเท่ากับ",
        "filterNotContainsOperator": "ไม่มี",
        "filterNotEqOperator": "ไม่เท่ากับ",
        "filterStartsWithOperator": "เริ่มต้นด้วย",
        "noRecords": "ไม่มีบันทึก"
      },
      "upload": {
        "cancel": "ยกเลิก",
        "clearSelectedFiles": "ชัดเจน",
        "dropFilesHere": "หรือวางไฟล์ที่นี่",
        "files": "ไฟล์",
        "headerStatusUploaded": "เสร็จแล้ว",
        "headerStatusUploading": "กำลังอัปโหลด...",
        "invalidFileExtension": "ไม่อนุญาตประเภทไฟล์ชนิดนี้.",
        "invalidFiles": "ไฟล์ไม่ถูกต้อง โปรดตรวจสอบข้อกำหนดในการอัปโหลดไฟล์",
        "invalidMaxFileSize": "ขนาดไฟล์ใหญ่เกินไป",
        "invalidMinFileSize": "ขนาดไฟล์เล็กเกินไป",
        "remove": "ลบ",
        "retry": "ลองอีกครั้ง",
        "select": "เลือกไฟล์",
        "total": "ทั้งหมด",
        "uploadSelectedFiles": "ที่อัพโหลด"
      }
    },
    "loading_batch_entities": {
      "page_of": "หน้า %{page} จาก X_1",
      "title": "กำลังดึงหน้า"
    },
    "login": {
      "again": "หากต้องการดำเนินการต่อ โปรดเข้าสู่ระบบอีกครั้ง",
      "continue_mykaefer": "ดำเนินการต่อด้วย MyKaefer",
      "copyright": "®%{year} สงวนลิขสิทธิ์ Kaefer®",
      "forgot_password": "ลืมรหัสผ่าน?",
      "iframe_info": "กำลังขอเข้าถึงหน้า: %{link}",
      "log_in": "เข้าสู่ระบบ",
      "password": "รหัสผ่าน",
      "title_1": "เครื่องมืออันทรงพลัง",
      "title_2": "สำหรับการบริหารโครงการ",
      "username": "ชื่อผู้ใช้",
      "welcome_back": "ยินดีต้อนรับกลับ"
    },
    "main_dialog": {
      "failure_message": "คำขอของคุณไม่เสร็จสมบูรณ์",
      "failure_title": "ความล้มเหลว",
      "loading_message": "โปรดรอสักครู่ในขณะที่เราจัดเตรียมสิ่งต่างๆ ให้คุณ!",
      "loading_title": "กำลังโหลด...",
      "service_title": "รอจบงาน...",
      "success_message": "คำขอของคุณสำเร็จแล้ว! ตอนนี้คุณสามารถทำงานของคุณต่อไปได้",
      "success_title": "ประสบความสำเร็จ!"
    },
    "notification": {
      "access_denied": "ปฏิเสธการเข้าใช้",
      "access_denied_message": "คุณไม่ได้รับอนุญาตให้ดำเนินการนี้ ติดต่อผู้ดูแลระบบของคุณสำหรับข้อมูลเพิ่มเติม",
      "action_success": "%{model} X_1 เป็น %{action}",
      "bad_formula_setup": "มีปัญหาในการตั้งค่ากับสูตร '%{title}' โปรดตรวจสอบการตั้งค่าของคุณ!",
      "correct_subproject": "เลือกโปรเจ็กต์ย่อยที่ถูกต้องของรายการนี้",
      "dismiss_all": "ปิดทั้งหมด",
      "error": "ข้อผิดพลาด",
      "error_cookies": "เราไม่สามารถสร้างการสื่อสารระหว่างแหล่งที่มาข้ามได้ ตรวจสอบการตั้งค่าคุกกี้ในเบราว์เซอร์ของคุณและปิดใช้งาน 'การบล็อกคุกกี้ของบุคคลที่สาม'",
      "error_job": "ล้มเหลว! ตรวจสอบเหตุผลในหน้างาน",
      "error_msg": "เสียใจ! บางอย่างผิดพลาด!",
      "info": "ข้อมูล",
      "large_file_body": "โปรดใช้ตัวกรองเพื่อทำให้ไฟล์ที่ส่งออกมีขนาดเล็กลง",
      "large_file_title": "กริดมีมากกว่า 1,000 รายการ",
      "page_denied": "ไม่สามารถโหลดหน้านี้ได้",
      "please": "โปรด",
      "select_subproject": "เลือกโครงการและโครงการย่อยเพื่อดำเนินการต่อ",
      "success": "ความสำเร็จ",
      "success_msg": "ลงมือทำสำเร็จ!",
      "warning": "คำเตือน"
    },
    "orders": {
      "add_evidence": "เพิ่มหลักฐานการอนุญาตลูกค้า",
      "attachment": "เอกสารแนบ",
      "comments": "ความคิดเห็น",
      "date": "วันที่",
      "drag_and_drop": "เพิ่มไฟล์โดยใช้การลากและวาง",
      "recipient": "ผู้รับ",
      "responsible": "รับผิดชอบ",
      "title": "คำสั่งซื้อ"
    },
    "performance": {
      "title": "การก่อสร้าง"
    },
    "performances": {
      "title": "การก่อสร้าง"
    },
    "performances_wip": {
      "performance_item": "รายการก่อสร้าง",
      "title": "การก่อสร้าง"
    },
    "planning": {
      "item_no": "หมายเลขสินค้า",
      "service_no": "หมายเลขบริการ",
      "title": "การวางแผน",
      "view": "มุมมองการวางแผน"
    },
    "plannings": {
      "title": "การวางแผน"
    },
    "print_grid": {
      "title": "พิมพ์ตาราง"
    },
    "print_list": {
      "loading": "กำลังสร้างเอกสารของคุณ กรุณารอสักครู่",
      "title": "พิมพ์รายการ"
    },
    "progress": {
      "statuses": {
        "canceled": "ยกเลิก",
        "done": "เสร็จแล้ว",
        "extra": "พิเศษ",
        "in_progress": "อยู่ระหว่างดำเนินการ",
        "pending": "รอดำเนินการ",
        "revised": "แก้ไขแล้ว"
      }
    },
    "progress_histories": {
      "add_new_progress": "เพิ่มความคืบหน้าใหม่",
      "empty_history": "ไม่มีรายการประวัติที่เกี่ยวข้อง และไม่สามารถสร้างรายการใหม่ได้",
      "hours": "ชั่วโมง",
      "production_factor": "PF",
      "progress": "ความคืบหน้า",
      "progress_badge": "รายการ %{number}",
      "progress_history": "ประวัติความก้าวหน้า",
      "progress_service_badge": "บริการ %{number}",
      "progress_service_history": "ประวัติการให้บริการ",
      "progress_service_title": "ประวัติการให้บริการ",
      "progress_title": "ประวัติรายการ",
      "quantity": "ปริมาณ",
      "quantity_empty_history": "ไม่มีรายการประวัติและไม่สามารถสร้างประวัติเมื่อปริมาณเป็นศูนย์",
      "subtitle": "จัดการความคืบหน้ารายวันตามกิจกรรมที่วางแผนโดยทีมของคุณ"
    },
    "progress_services": {
      "actual_div_hours": "ชั่วโมงเป้าหมายจริง / ทีม",
      "actual_div_quantity": "จำนวนจริง / เป้าหมาย",
      "actual_hours": "ชั่วโมงจริง",
      "actual_quantity": "ปริมาณจริง",
      "application_price": "ราคาสมัคร",
      "back": "การก่อสร้างทั้งหมด",
      "budget_target_hours": "งบประมาณเป้าหมายชั่วโมง",
      "canceled": "ยกเลิก",
      "choose_modal": {
        "package": "แพ็คเกจรายละเอียดเดียว",
        "package_description": "การสร้างบริการหลายอย่างโดยใช้รายละเอียดเดียวกัน",
        "single": "รายละเอียดเดียว",
        "single_description": "การสร้างรายละเอียดเพียงหนึ่งเดียว",
        "title": "สร้างใหม่"
      },
      "contract_service": "บริการทำสัญญา",
      "contribution": "ผลงาน",
      "contribution_info": "ค่าที่คำนวณโดยอัตโนมัติ",
      "crew": "ลูกทีม",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "ลูกเรือ",
      "date_filter": {
        "all": "ทั้งหมด",
        "to": "ถึง",
        "today": "วันนี้",
        "tomorrow": "พรุ่งนี้",
        "week": "สัปดาห์",
        "yesterday": "เมื่อวาน"
      },
      "date_filter_info": "ตัวกรองวันที่",
      "delta_hours": "ชั่วโมงเดลต้า",
      "delta_qtt": "ปริมาณเดลต้า",
      "description": "บริการรับเหมาก่อสร้าง",
      "discipline": "การลงโทษ",
      "end_date": "วันที่สิ้นสุด",
      "equipment_price": "ราคาอุปกรณ์",
      "factor": "ปัจจัย",
      "flag": "ธง",
      "id": "ไอดี",
      "materials_price": "ราคาวัสดุ",
      "no_crews": "จำนวนลูกเรือ",
      "no_workers": "จำนวนคนงาน",
      "norm_hours": "ชั่วโมงปกติ",
      "not_planned": "ไม่ได้วางแผน",
      "other_price": "ราคาอื่นๆ",
      "package_factor": "ปัจจัย",
      "people": "ผู้คน",
      "person": "บุคคล",
      "pf": "PF",
      "pf_doing_bad": "คุณทำได้ไม่ดี",
      "pf_doing_good": "คุณทำได้ดีมาก!",
      "pf_doing_neutral": "ไม่สามารถคำนวณ pf",
      "planned": "วางแผน",
      "planning_chart": {
        "actual": "แท้จริง",
        "actual_qty": "จำนวนจริง",
        "date": "วันที่",
        "forecasted": "พยากรณ์",
        "forecasted_qty": "จำนวนที่คาดการณ์",
        "target": "เป้า",
        "target_qty": "จำนวนเป้าหมาย",
        "title": "แผนผังการวางแผน"
      },
      "planning_timeline": {
        "day": "วัน",
        "day_advance": "วันล่วงหน้า",
        "day_delayed": "วันล่าช้า",
        "days": "วัน",
        "days_advance": "วันล่วงหน้า",
        "days_delayed": "วันล่าช้า",
        "end_date": "วันที่สิ้นสุด",
        "forecasted": "พยากรณ์",
        "on_time": "ตรงเวลา",
        "remaining_days": "วันที่เหลืออยู่",
        "start_date": "วันที่เริ่มต้น",
        "title": "ไทม์ไลน์การวางแผน"
      },
      "progress": "ความคืบหน้า",
      "progress_services_detail": {
        "side_panel_title": "รายละเอียดบริการ"
      },
      "quantity": "ปริมาณ",
      "responsible": "รับผิดชอบ",
      "scoped_quantity": "ปริมาณที่เตรียมไว้",
      "service": "บริการ",
      "service_package": "แพ็คเกจบริการ",
      "service_planning": "การวางแผนการบริการ",
      "service_progress": "ความก้าวหน้าของการบริการ",
      "service_weight": "น้ำหนักบริการ",
      "start_date": "วันที่เริ่มต้น",
      "statuses": {
        "canceled": "ยกเลิก",
        "done": "สมบูรณ์",
        "extra": "พิเศษ",
        "in_planning": "ไม่ได้วางแผน",
        "inspected_flag": "กำลังตรวจสอบอยู่",
        "planned": "วางแผน"
      },
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "บริการรับเหมาก่อสร้าง",
      "title_packageable": "บริการก่อสร้างตามแพ็คเกจบริการ",
      "total": "ทั้งหมด",
      "total_hours": "รวมชั่วโมง",
      "unit": "หน่วย",
      "worker": "คนงาน",
      "workers": "คนงาน",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "progresses": {
      "actual_hours": "เวลาจริง",
      "cancel": "ยกเลิก",
      "choose_modal": {
        "single": "อีกหนึ่งรายการก่อสร้าง",
        "single_description": "การสร้างรายการก่อสร้างเพียงรายการเดียว"
      },
      "complete": "สมบูรณ์",
      "complete_services": "บริการที่สมบูรณ์",
      "current_hours": "เวลาทำการปัจจุบัน",
      "current_qty": "ปริมาณปัจจุบัน",
      "date_filter": {
        "all": "ทั้งหมด",
        "current_week": "สัปดาห์ปัจจุบัน",
        "previous_week": "สัปดาห์ที่แล้ว",
        "today": "วันนี้"
      },
      "date_filter_info": "ตัวกรองวันที่",
      "delta_hours": "Δ ชั่วโมง",
      "delta_qty": "Δ ปริมาณ",
      "description": "คำอธิบาย",
      "edit_work_description": "แก้ไขคำอธิบายงาน",
      "extra": "พิเศษ",
      "flag": "ธง",
      "history": "เพิ่มความคืบหน้าใหม่",
      "id": "ไอดี",
      "item": "สิ่งของ",
      "label": "ฉลาก",
      "more_details": "ดูเพิ่มเติม",
      "pf": "PF",
      "planned_hours": "ชั่วโมงที่วางแผนไว้",
      "planned_qty": "ปริมาณที่วางแผนไว้",
      "preview_unavailable": "ไม่มีการแสดงตัวอย่าง",
      "progress": "ความคืบหน้า",
      "progress_status_restriction": "ความคืบหน้าไม่ควรมีสถานะยกเลิกหรือเสร็จสิ้น",
      "progresses_detail": {
        "side_panel_title": "รายละเอียดการก่อสร้าง"
      },
      "remaining_hours": "ชั่วโมงที่เหลือ",
      "request_status_restriction": "ใบสั่งงานควรมีสถานะอยู่ในการวางแผนหรืออยู่ระหว่างการก่อสร้าง",
      "revised": "แก้ไข",
      "success_work_description_edition": "คำอธิบายงานได้รับการแก้ไขเรียบร้อยแล้ว",
      "title": "การก่อสร้าง",
      "work_description": "คำอธิบายงาน"
    },
    "projects": {
      "workdays": {
        "fri": "ศ",
        "mon": "จันทร์",
        "sat": "นั่ง",
        "sun": "ดวงอาทิตย์",
        "thu": "พฤ",
        "tue": "อ.",
        "wed": "พุธ"
      }
    },
    "requests": {
      "actual_hours": "ชั่วโมงจริง",
      "actual_progress": "ความคืบหน้าที่แท้จริง",
      "all": "ทั้งหมด",
      "are_you_sure_complete": "คุณแน่ใจหรือว่าจะเสร็จสิ้นใบสั่งงานนี้?",
      "are_you_sure_complete_no_history": "คุณแน่ใจหรือว่าจะดำเนินการตามใบสั่งงานโดยไม่มีความคืบหน้าใดๆ?",
      "are_you_sure_deletion": "คุณแน่ใจหรือไม่ว่าต้องการลบใบสั่งงาน?",
      "are_you_sure_revertion": "คุณแน่ใจหรือว่าต้องการเปลี่ยนกลับและทำให้ข้อมูลที่ป้อนในโมดูลนี้หายไป",
      "area": "พื้นที่",
      "authorization_pending": "อยู่ระหว่างรอการอนุมัติ",
      "authorize_form": {
        "modal_title": "การอนุญาต",
        "subtitle": "ให้ข้อมูลบางอย่างเกี่ยวกับใบสั่งงานเพื่อดำเนินการอนุมัติต่อไป",
        "title": "อนุมัติการสั่งงาน"
      },
      "canceled": "ยกเลิก",
      "clustered": "คลัสเตอร์",
      "comments": "ความคิดเห็น",
      "confirmation_modal": {
        "cancel_description": "ใบสั่งงานจะถูกยกเลิก",
        "cancel_title": "ยกเลิกคำสั่งงาน?",
        "complete_description": "ใบสั่งงานนี้จะเสร็จสมบูรณ์",
        "complete_title": "สั่งงานเสร็จหรือยัง?",
        "confirm_action": "ดำเนินการนำเข้าการเตรียมงาน",
        "confirm_cancel": "ไม่ใช่ตอนนี้",
        "confirm_confirmation": "ใช่ ฉันต้องการยืนยัน",
        "confirm_description": "คุณต้องการยืนยันใบสั่งงานหรือไม่?",
        "confirm_title": "การยืนยันการสั่งงาน",
        "delete_description": "การดำเนินการนำออกไม่สามารถยกเลิกได้ คุณแน่ใจไหม?",
        "delete_title": "ลบคำสั่งงาน?",
        "reject_description": "รายการนี้จะกลับสู่สถานะกำลังเตรียมการ",
        "reject_title": "ปฏิเสธคำสั่งงาน?",
        "remove_recycle_description": "รายการนี้จะกลับสู่สถานะอยู่ในขอบเขตและสูญเสียค่าสถานะการรีไซเคิล",
        "remove_recycle_title": "ล้างการรีไซเคิล?",
        "revert_description": "การดำเนินการเปลี่ยนกลับไม่สามารถยกเลิกได้ คุณแน่ใจไหม?",
        "revert_title": "ยกเลิกคำสั่งงาน?"
      },
      "confirmed": "ยืนยันแล้ว",
      "create_inspection_modal": {
        "category_subtitle": "เลือกหนึ่งตัวเลือกจากหมวดหมู่ %{category_name}",
        "loading": "กำลังโหลดเทมเพลตการตรวจสอบ...",
        "no_templates": "ไม่มีแม่แบบการตรวจสอบ",
        "plural_title": "สร้างการตรวจสอบ",
        "standalone_subtitle": "เลือกหนึ่งในตัวเลือกด้านล่างเพื่อดำเนินการต่อ",
        "title": "เลือกเทมเพลตการตรวจสอบ"
      },
      "creation_form": {
        "proceed": "ดำเนินการตามขั้นตอนที่ 2"
      },
      "creation_page": {
        "add_template": "เพิ่มเทมเพลต",
        "first_step": "สร้างใบสั่งงาน",
        "second_step": "การเตรียมงานนำเข้า (ไม่บังคับ)",
        "third_step": "ใช้แพ็คเกจบริการ (ไม่บังคับ)"
      },
      "description": "สั่งงาน",
      "discipline": "การลงโทษ",
      "discipline_check": {
        "check_terms": "เงื่อนไขการตรวจสอบวินัย",
        "perform": "ดำเนินการตรวจสอบวินัย",
        "ready_for_discipline": "พร้อมตรวจวินัย",
        "ready_for_review": "คำสั่งงานพร้อมสำหรับการพิจารณาทางวินัย",
        "set_as_ready": "ตั้งค่าการสั่งงานให้พร้อมสำหรับการตรวจสอบวินัย",
        "title": "การตรวจสอบวินัย"
      },
      "discipline_check_item": "WO01 – พร้อมตรวจวินัย",
      "discipline_check_no_comment": "ไม่มีความคิดเห็นเพิ่ม",
      "done": "เสร็จแล้ว",
      "eav_template": "แม่แบบ",
      "eav_template_id": "รหัสเทมเพลต",
      "edit_dialog": {
        "reason": "ชื่อคำขอย่อย"
      },
      "elevation": "ระดับความสูง",
      "errors": {
        "not_found": "ไม่พบใบสั่งงานที่มี id: %{id}"
      },
      "estimates_authorization": "ประมาณการการอนุมัติที่จำเป็น",
      "flags": {
        "discipline_check": "WO01 - การตรวจสอบวินัย",
        "discipline_check_done": "WO01 – การตรวจสอบวินัย : อนุมัติ",
        "discipline_check_pending": "WO01 – การตรวจสอบวินัย : รอดำเนินการ",
        "discipline_check_rejected": "WO01 – การตรวจสอบวินัย : ถูกปฏิเสธ",
        "recycle": "WO03 - รีไซเคิล"
      },
      "forecast_end_date": "วันที่สิ้นสุดที่คาดการณ์ไว้",
      "id": "DPMSID",
      "in_performance": "ในการก่อสร้าง",
      "in_planning": "ในการวางแผน",
      "in_scoping": "ในการเตรียมการ",
      "information": "ข้อมูลเพิ่มเติม",
      "inspection_date": "วันที่ตรวจสอบ",
      "no_comment": "ไม่มีความเห็น",
      "notification": {
        "authorization_success": "ใบสั่งงานได้รับการอนุมัติเรียบร้อยแล้ว",
        "deleting_sub_request": "กำลังลบคำขอย่อย %{id} โปรดตรวจสอบหน้างานเพื่อเรียนรู้เกี่ยวกับความคืบหน้า",
        "discipline_check_approved": "อนุมัติการตรวจสอบวินัยสำเร็จแล้ว",
        "discipline_check_rejected": "ปฏิเสธการตรวจสอบวินัยเรียบร้อยแล้ว",
        "discipline_check_success": "ใบสั่งงานพร้อมสำหรับการตรวจสอบทางวินัยเรียบร้อยแล้ว",
        "missing_order_error": "ไม่มีใบสั่งที่รอดำเนินการใดๆ ที่เกี่ยวข้องกับใบสั่งงานนี้",
        "recycle_success": "ใบสั่งงานถูกรีไซเคิลสำเร็จแล้ว"
      },
      "on_hold": "พักไว้",
      "progress": "ความคืบหน้า",
      "progress_end_date": "วันที่สิ้นสุดความคืบหน้า",
      "progress_responsible": "รับผิดชอบความก้าวหน้า",
      "progress_responsible_id": "รับผิดชอบความก้าวหน้า",
      "progress_start_date": "วันที่เริ่มต้นความคืบหน้า",
      "reason": "อ้างอิง",
      "recycle_form": {
        "label": "เหตุผล-วินัย",
        "modal_title": "รีไซเคิล",
        "subtitle": "ให้ข้อมูลเพื่อทำเครื่องหมายคำสั่งงานนี้สำหรับการรีไซเคิล",
        "title": "คำขอรีไซเคิล"
      },
      "recycling_item": "WO03 - รีไซเคิลให้กับลูกค้า",
      "reference": "อ้างอิง",
      "registered": "ลงทะเบียน",
      "remove_recycle_modal": {
        "cancel_description": "ไม่ เก็บธงไว้",
        "confirm_description": "ใช่ชัดเจน",
        "success_notification": "ค่าสถานะสำหรับการรีไซเคิลถูกลบออกเมื่อ %{reason} - %{discipline}"
      },
      "request_date": "วันที่สั่งงาน",
      "request_detail": {
        "edit": "แก้ไข",
        "estimates": "ประมาณการ",
        "inspections": "การตรวจสอบ",
        "performance": "การก่อสร้าง",
        "planning": "การวางแผน",
        "scopes": "การเตรียมงาน",
        "show": "แสดง",
        "side_panel_title": "ขอรายละเอียด"
      },
      "request_end_date": "วันที่สิ้นสุดการสั่งงาน",
      "request_responsible": "รับผิดชอบงานสั่งทำ",
      "request_responsible_id": "รับผิดชอบงานสั่งทำ",
      "request_start_date": "วันที่เริ่มสั่งงาน",
      "responsible": "รับผิดชอบ",
      "schedule": "ตารางการสั่งงาน",
      "schedule_form": {
        "date": "วันที่",
        "days": "%{number} วัน",
        "performance": "การก่อสร้าง",
        "progress": "ความคืบหน้า",
        "request": "สั่งงาน",
        "responsible": "รับผิดชอบ",
        "schedule": "กำหนดการ",
        "scoping": "การเตรียมงาน",
        "subtitle": "หากต้องการสร้างกำหนดการสำหรับใบสั่งงานนี้ เราต้องการข้อมูลเพิ่มเติมก่อน",
        "timecode": "ไทม์โค้ด",
        "title": "ตารางการสั่งงาน"
      },
      "scope_end_date": "วันที่สิ้นสุดการเตรียมงาน",
      "scope_responsible": "รับผิดชอบการเตรียมงาน",
      "scope_responsible_id": "รับผิดชอบการเตรียมงาน",
      "scope_start_date": "วันที่เริ่มเตรียมงาน",
      "scopes": "การเตรียมงาน",
      "service_desired_date": "วันที่ต้องการ",
      "skip_scoping": "ข้ามขั้นตอนการเตรียมงาน",
      "status": "สถานะ",
      "statuses": {
        "authorization_pending": "รอดำเนินการ",
        "canceled": "ยกเลิก",
        "clustered": "คลัสเตอร์",
        "confirmed": "ในการเตรียมการ",
        "deleting": "กำลังลบ",
        "done": "เสร็จแล้ว",
        "in_performance": "ในการก่อสร้าง",
        "in_planning": "ในการก่อสร้าง",
        "in_scoping": "ในการเตรียมการ",
        "on_hold": "พักไว้",
        "registered": "สร้าง"
      },
      "sub_request": "คำขอย่อย",
      "sub_requests": "คำขอย่อย",
      "subarea": "พื้นที่ย่อย",
      "team_target_hours": "ชั่วโมงเป้าหมายของทีม",
      "title": "สั่งงาน",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - ปรับปรุง %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "ไม่ ยกเลิก",
      "description": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการตรวจสอบ \"%{id}\"",
      "ok": "ใช่ เปลี่ยนกลับเป็นรอดำเนินการ",
      "success_description": "เปลี่ยนกลับเป็นรอดำเนินการเรียบร้อยแล้ว",
      "success_title": "การตรวจสอบถูกย้อนกลับ",
      "title": "เปลี่ยนกลับรายการ?"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "ข้อความสำรอง",
        "by_url": "ตาม URL",
        "height": "ความสูง (พิกเซล)",
        "image": "ภาพ",
        "title": "ชื่อ",
        "upload": "ที่อัพโหลด",
        "web_address": "ที่อยู่เว็บ",
        "width": "ความกว้าง (พิกเซล)",
        "window_title": "ฉบับรูปภาพ"
      }
    },
    "scaffolding_part_action": {
      "comment": "ความคิดเห็น",
      "date": "วันที่",
      "height": "ความสูง",
      "length": "ความยาว",
      "modification_type": {
        "dismantle": "รื้อ",
        "erect": "ตั้งตรง",
        "erectDismantle": "ติดตั้ง/รื้อ"
      },
      "singular": "การดำเนินการส่วนนั่งร้าน",
      "title": "การดำเนินการส่วนนั่งร้าน",
      "total": "ทั้งหมด",
      "width": "ความกว้าง",
      "work_dismantle": "รื้อในใบสั่งงานอื่น",
      "work_order": {
        "choose_wo": "เลือกสั่งงาน"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "สร้าง",
        "created_by": "สร้างโดย",
        "dismantle": "มิติข้อมูลถูกลบออก",
        "erect": "เพิ่มมิติแล้ว",
        "modify": "ดัดแปลง",
        "total": "ทั้งหมด",
        "total_dismantle": "นั่งร้านถูกรื้อถอน"
      },
      "comment": "ความคิดเห็น",
      "confirmation_modal": {
        "delete_cancel": "ไม่ ยกเลิก",
        "delete_confirmation": "ใช่ ลบ",
        "delete_description": "คุณแน่ใจหรือไม่ว่าต้องการลบมิติของ %{total} %{dimension}",
        "delete_title": "ลบการดำเนินการใช่ไหม"
      },
      "date": "วันที่",
      "duplicate_title": "การกระทำที่ซ้ำกัน",
      "edit_title": "แก้ไขการดำเนินการ",
      "feed": {
        "empty": "ไม่มีการสร้างการกระทำสำหรับชิ้นส่วนนั่งร้านนี้"
      },
      "height": "ความสูง",
      "length": "ความยาว",
      "modification_type": {
        "dismantle": "รื้อ",
        "erect": "ตั้งตรง",
        "erectDismantle": "ติดตั้ง/รื้อ"
      },
      "singular": "การดำเนินการส่วนนั่งร้าน",
      "timestamp_badges": {
        "new": "ใหม่",
        "updated": "อัปเดตแล้ว"
      },
      "title": "การดำเนินการส่วนนั่งร้าน",
      "total": "ทั้งหมด",
      "width": "ความกว้าง",
      "work_dismantle": "รื้อในใบสั่งงานอื่น",
      "work_order": {
        "choose_wo": "เลือกสั่งงาน"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "การดำเนินการของ %{total}%{dimension} ทั้งหมดถูกลบออกจากรายการ",
        "title": "เพิ่มมิติ"
      },
      "back": "ชิ้นส่วนนั่งร้านทั้งหมด",
      "comment": "ความคิดเห็น",
      "confirmation_modal": {
        "delete_cancel": "ไม่ ยกเลิก",
        "delete_confirmation": "ใช่ ลบ",
        "delete_description": "คุณต้องการลบ %{type} ที่มี %{total} %{dimension} หรือไม่",
        "delete_title": "ลบ %{type} ส่วนใช่ไหม"
      },
      "description": "คำอธิบาย",
      "dismantle_modal": {
        "cancel": "ไม่ ยกเลิก",
        "confirm": "ใช่ รื้อ",
        "confirmation_message": "คุณต้องการรื้อส่วนนี้หรือไม่?",
        "date": "วันที่",
        "errors": {
          "date_greater_max": "วันที่รื้อถอนต้องเป็นวันนี้หรือเร็วกว่านั้น"
        },
        "success_message": "ส่วนนั่งร้านถูกรื้อออกด้วยความสำเร็จ",
        "title": "ส่วนการรื้อถอน",
        "work_order": "สั่งงาน"
      },
      "erect_method": "วิธี",
      "erect_methods": {
        "os": "เหนือทะเล",
        "standard": "มาตรฐาน",
        "tt": "การเข้าถึงเชือกเหนือทะเล"
      },
      "id": "บัตรประจำตัวประชาชน",
      "initial_date": "วันที่เริ่มต้น",
      "menu_items": {
        "dimensions": "เพิ่มมิติข้อมูล",
        "dismantle": "รื้อ",
        "modifications": "ปรับเปลี่ยนส่วนนี้"
      },
      "operation_dismantle": "การดำเนินการรื้อถอน",
      "operation_erect": "ดำเนินการตั้งตรง",
      "scaffolding_part_detail": {
        "actions": "การดำเนินการ",
        "side_panel_title": "รายละเอียดชิ้นส่วนนั่งร้าน"
      },
      "scaffolding_type_id": "ประเภทนั่งร้าน",
      "scaffolding_types": {
        "covering": "ผ้าใบกันน้ำ",
        "dop": "ดีโอพี",
        "hanging_scaffolding": "การแขวน",
        "patch_boards": "แพทช์บอร์ด",
        "railing": "ราวกันตกชั่วคราว",
        "rolling_scaffolding": "มือถือ",
        "roof_over_roof": "หลังคาทับหลังคา",
        "scaffolding_for_lift": "แท่นยก",
        "scaffolding_net": "ตาข่ายนั่งร้าน",
        "shelf": "ชั้นวาง",
        "staircase_tower": "หอบันได",
        "standard_scaffolding": "มาตรฐาน",
        "stool": "อุจจาระ",
        "support": "สนับสนุน",
        "system_covering": "การครอบคลุมระบบ",
        "tent": "เต็นท์",
        "tent_frame": "โครงเต็นท์"
      },
      "singular": "ส่วนนั่งร้าน",
      "title": "ส่วนนั่งร้าน",
      "total": "ทั้งหมด",
      "type_filter": "ประเภทตัวกรอง",
      "weight": "น้ำหนัก"
    },
    "scaffoldings": {
      "area_id": "พื้นที่",
      "area_noise_id": "เสียงรบกวนในพื้นที่",
      "area_noises": {
        "db_100_105": "100-105dB - ระยะเวลาพัก 2 ชั่วโมง",
        "db_105_110": "105-110dB - ระยะเวลาพัก 1/2 ชั่วโมง",
        "db_85_90": "85-90dB - ระยะเวลาพัก 12 ชั่วโมง",
        "db_90_100": "90-100dB - ระยะเวลาพัก 6 ชั่วโมง",
        "db_less_85": "< 85dB - ไม่มีข้อจำกัด"
      },
      "back": "นั่งร้านทั้งหมด",
      "buttons_form": {
        "create_part": "สร้างส่วนหนึ่ง"
      },
      "client": "ลูกค้า",
      "comment": "ความคิดเห็น",
      "confirmation_modal": {
        "delete_cancel": "ไม่ยกเลิก",
        "delete_confirmation": "ใช่ ลบ",
        "delete_description": "คุณต้องการลบ Scaffolding TAG %{tag} หรือไม่?",
        "delete_title": "นั่งร้านลบ",
        "dismantle_cancel": "ไม่ใช่ตอนนี้",
        "dismantle_confirmation": "ใช่ครับ ผมต้องการจะรื้อถอน",
        "dismantle_description": "ต้องการที่จะรื้อนั่งร้าน TAG %{tag} หรือไม่?",
        "dismantle_title": "การรื้อนั่งร้าน",
        "duplicate_with_parts_cancel": "ไม่ใช่ตอนนี้",
        "duplicate_with_parts_confirmation": "ใช่ครับ ผมต้องการทำสำเนาส่วนต่างๆ",
        "duplicate_with_parts_description": "คุณต้องการทำซ้ำโครงสร้างของ TAG %{tag} พร้อมกับส่วนต่างๆ ของมันหรือไม่?",
        "duplicate_with_parts_title": "นั่งร้านจำลองพร้อมชิ้นส่วน",
        "ready_to_dismantle_cancel": "ไม่ใช่ตอนนี้",
        "ready_to_dismantle_confirmation": "ใช่ ฉันต้องการเปลี่ยน",
        "ready_to_dismantle_description": "คุณต้องการเปลี่ยนสถานะของ TAG %{tag} เป็นพร้อมที่จะถอดประกอบหรือไม่?",
        "ready_to_dismantle_title": "นั่งร้านพร้อมรื้อถอน",
        "revert_cancel": "ไม่ใช่ตอนนี้",
        "revert_confirmation": "ใช่ ฉันต้องการเปลี่ยน",
        "revert_description": "คุณต้องการเปลี่ยนสถานะของ TAG %{tag} เป็นสร้างขึ้นหรือไม่?",
        "revert_title": "เปลี่ยนสถานะนั่งร้านเป็นแบบตั้งตรง"
      },
      "contact_person": "บุคคลที่ติดต่อ",
      "description": "คำอธิบาย",
      "description_of_deviation": "คำอธิบายของการเบี่ยงเบน",
      "deviation_improved": "วันที่ปรับปรุงส่วนเบี่ยงเบน",
      "dismantle_compensation_format": "รื้อรูปแบบการชดเชย",
      "dismantle_date": "วันที่รื้อถอน",
      "dismantle_modal": {
        "cancel": "ไม่ ยกเลิก",
        "confirm": "ใช่ รื้อ",
        "confirmation_message": "คุณต้องการรื้อนั่งร้านนี้ออกหรือไม่?",
        "date": "วันที่",
        "errors": {
          "date_greater_max": "วันที่รื้อถอนต้องเป็นวันนี้หรือเร็วกว่านั้น"
        },
        "success_message": "นั่งร้านถูกรื้อออกด้วยความสำเร็จ",
        "title": "การรื้อนั่งร้าน",
        "work_order": "สั่งงาน"
      },
      "erect_compensation_format": "รูปแบบการชดเชยการแข็งตัว",
      "erect_date": "วันที่ตั้ง",
      "erect_with_deviation": "สร้างขึ้นด้วยความเบี่ยงเบน",
      "id": "บัตรประจำตัวประชาชน",
      "inspection": {
        "delayed": "ล่าช้า",
        "missing_initial": "หายไป"
      },
      "inspection_control": "การตรวจสอบ",
      "installation": "การติดตั้ง",
      "load_class_id": "โหลดคลาส",
      "load_classes": {
        "kg_150": "2 - 150 กก./ตร.ม",
        "kg_200": "3 - 200 กก./ตร.ม",
        "kg_300": "4 - 300 กก./ตร.ม",
        "kg_450": "5 - 450 กก./ตร.ม",
        "kg_600": "6 - 600 กก./ตร.ม",
        "kg_75": "1 - 75 กก./ตร.ม",
        "not_applicable": "0 - ไม่เกี่ยวข้อง"
      },
      "main_work_order_dismantle_id": "รื้อคำสั่งงาน",
      "main_work_order_erect_id": "สั่งงานสร้าง",
      "missing_initial_control": "การควบคุมเบื้องต้น",
      "missing_request": "สั่งงานหาย",
      "number_of_tags": "จำนวนแท็ก",
      "operation_dismantle": "การดำเนินการรื้อถอน",
      "operation_erect": "ปฏิบัติการตั้ง",
      "pictures": "รูปภาพ)",
      "project_number": "อ้างอิงโครงการ",
      "qr_code": "คิวอาร์โค้ด",
      "reason": "เหตุผลในการสั่งงาน",
      "scaffolding_detail": {
        "side_panel_title": "รายละเอียดนั่งร้าน"
      },
      "scaffolding_status_id": "สถานะ",
      "sections": {
        "details": "รายละเอียด",
        "deviations": "การเบี่ยงเบน",
        "general": "ข้อมูลทั่วไป",
        "pictures": "รูปภาพ)"
      },
      "singular": "นั่งร้าน",
      "statuses": {
        "created": "สร้าง",
        "dismantled": "รื้อถอน",
        "erected": "สร้างขึ้น",
        "ready_to_dismantle": "พร้อมรื้อ"
      },
      "subarea_id": "พื้นที่ย่อย",
      "tag": "แท็ก",
      "tag_number": "หมายเลขแท็ก",
      "tag_removed": "ลบแท็กแล้ว",
      "telephone_radio": "โทรศัพท์-วิทยุ",
      "title": "บันทึกนั่งร้าน",
      "total_m": "รวม ม",
      "total_m2": "รวม ตร.ม",
      "total_m3": "ลบ.ม. ทั้งหมด",
      "total_weight_message": "น้ำหนักรวมของนั่งร้านที่ติดตั้ง",
      "user_of_scaffolding": "ผู้ใช้นั่งร้าน",
      "weight": "น้ำหนัก",
      "wind_exposed": "ลมสัมผัส"
    },
    "scopes": {
      "add": "เพิ่มรายการเตรียมงาน",
      "choose_modal": {
        "single": "อีกหนึ่งรายการเตรียมงาน",
        "single_description": "การสร้างรายการเตรียมงานเพียงรายการเดียว"
      },
      "clusters": {
        "title": "กลุ่มเตรียมงาน"
      },
      "count": "นับ",
      "crew_size": "ขนาดลูกเรือ",
      "crews": "จำนวนลูกเรือ",
      "date": "วันที่",
      "description": "การเตรียมงาน",
      "duplicate_services": {
        "other_request": "ใบสั่งงานอื่น ๆ",
        "other_request_description": "ทำซ้ำกับรายการการเตรียมงานในใบสั่งงานอื่น",
        "same_request": "ใบสั่งงานเดียวกัน",
        "same_request_description": "เลือกรายการการเตรียมงาน",
        "success_notification": "บริการการเตรียมงานได้ถูกจำลองสำเร็จแล้ว",
        "title": "บริการซ้ำซ้อน"
      },
      "edit_work_description": "แก้ไขรายละเอียดงาน",
      "hours": "ชั่วโมง (ซ)",
      "id": "ไอดี",
      "item": "สิ่งของ",
      "label": "ฉลาก",
      "label_assigner": {
        "success": "มอบหมายป้ายกำกับการเตรียมงานของคุณสำเร็จแล้ว! คุณสามารถดำเนินการตามแผนของคุณต่อไปได้แล้ว"
      },
      "label_error": "ไม่สามารถเรียกป้ายกำกับ",
      "labels_info": "ป้ายเตรียมงาน",
      "more_details": "ดูเพิ่มเติม",
      "popup_label": {
        "create_label": "สร้างฉลาก",
        "labels": "ป้ายกำกับ:",
        "name_label": "ชื่อฉลาก",
        "new_label": "ป้ายใหม่"
      },
      "preview_unavailable": "ไม่มีการแสดงตัวอย่าง",
      "quantity": "จำนวน",
      "remaining_hours": "ชั่วโมงที่เหลือ",
      "remaining_quantity": "จำนวนคงเหลือ",
      "request_status_restriction": "ใบสั่งงานควรมีสถานะ ยืนยันแล้ว หรือ อยู่ระหว่างการเตรียมการ",
      "scopes_detail": {
        "side_panel_title": "รายละเอียดการเตรียมงาน"
      },
      "success_work_description_edition": "แก้ไขคำอธิบายงานเรียบร้อยแล้ว",
      "title": "การเตรียมงาน",
      "unit": "หน่วย",
      "work_description": "รายละเอียดงาน",
      "working_hours": "ชั่วโมงทำงาน"
    },
    "search": {
      "loading": "กำลังโหลด...",
      "no_result_found": "ไม่พบผลลัพธ์",
      "placeholder": "กรอง..."
    },
    "service_description": "รายละเอียดการบริการ",
    "settings": {
      "title": "การตั้งค่า"
    },
    "settings_shortcuts": "ทางลัดการตั้งค่า",
    "shortcuts": {
      "access_control": "การควบคุมการเข้าถึง",
      "areas": "พื้นที่และพื้นที่ย่อย",
      "areas_subareas": "พื้นที่และพื้นที่ย่อย",
      "assets": "ทรัพย์สิน",
      "background_jobs": "งานเบื้องหลัง",
      "branches": "สาขา",
      "cities": "เมือง",
      "client_sites": "ไซต์ลูกค้า",
      "clients": "ลูกค้า",
      "clients_info": "ข้อมูลลูกค้า",
      "columns_renaming": "การเปลี่ยนชื่อคอลัมน์",
      "companies": "บริษัท",
      "contract_services": "บริการทำสัญญา",
      "contracts": "สัญญา",
      "countries": "ประเทศ",
      "customer_sites": "ไซต์ลูกค้า",
      "customers": "ลูกค้า",
      "disciplines": "วินัย",
      "employees": "พนักงาน",
      "equipaments": "อุปกรณ์",
      "equipments": "อุปกรณ์",
      "formulas": "สูตร",
      "location": "ที่ตั้ง",
      "materials": "วัสดุ",
      "matrices": "เมทริกซ์",
      "measures": "มาตรการ",
      "mobile_details": "รายละเอียดมือถือ",
      "position_names": "ชื่อตำแหน่ง",
      "preferences": "การตั้งค่า",
      "project_assets": "ทรัพย์สิน",
      "project_contracts": "สัญญา",
      "projects": "โครงการและโครงการย่อย",
      "projects_contracts": "โครงการและสัญญา",
      "projects_subprojects": "โครงการและโครงการย่อย",
      "provinces": "รัฐ / จังหวัด",
      "regions": "ภูมิภาค",
      "routes": "เส้นทาง",
      "specialities": "ความชำนาญพิเศษ",
      "staff": "พนักงาน",
      "states_provinces": "รัฐ / จังหวัด",
      "templates": "เทมเพลต",
      "translations": "การเปลี่ยนชื่อคอลัมน์",
      "users": "ผู้ใช้",
      "visible_fields": "รายละเอียดมือถือ",
      "work_positions": "ชื่อตำแหน่ง"
    },
    "side_menu": {
      "collapse": "ทรุด",
      "contracts": "สัญญา",
      "datasheets": "แผ่นข้อมูล",
      "expand": "ขยาย",
      "report": "รายงาน",
      "search": "ค้นหา",
      "settings": "การตั้งค่า",
      "template": {
        "asset": "ทรัพย์สิน",
        "contract_service": "บริการทำสัญญา",
        "datasheet": "แผ่นข้อมูล",
        "estimate_service": "บริการประเมินราคา",
        "inspection": "การตรวจสอบ",
        "performance": "การก่อสร้าง",
        "report": "รายงาน",
        "request": "สั่งงาน",
        "scoping": "การเตรียมงาน",
        "valuation": "การประเมินค่า"
      },
      "templates": "แม่แบบ"
    },
    "side_panel": {
      "close": "ปิดแผงด้านข้าง",
      "collapse": "ยุบแผงด้านข้าง",
      "expand": "ขยายแผงด้านข้าง",
      "tabs": {
        "category_filter": "ตัวกรองหมวดหมู่",
        "gallery": "แกลเลอรี่",
        "info": "ข้อมูล",
        "inspections": "การตรวจสอบ",
        "no_categories": "ไม่มีหมวดหมู่ให้เลือก",
        "no_templates": "ไม่มีเทมเพลตที่พร้อมใช้งาน",
        "preview_details": "ดูตัวอย่างรายละเอียด",
        "progress": "ความคืบหน้า",
        "template_filter": "ตัวกรองเทมเพลต"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "คำขอย่อยทั้งหมด",
        "back": "การเตรียมงานทั้งหมด",
        "title": "คำขอย่อย"
      },
      "left_panel": {
        "add_scoping": "เพิ่มรายการเตรียมงาน",
        "allocation": "การจัดสรร",
        "cards_header_title": "การเตรียมงาน",
        "summary": "สรุป",
        "total_allocation": "การจัดสรรทั้งหมด"
      },
      "scope_card": {
        "scope": "การเตรียมงาน"
      }
    },
    "tabs": {
      "error": "โหลดแท็บไม่ได้"
    },
    "templates": {
      "asset": "ทรัพย์สิน",
      "contract_service": "บริการทำสัญญา",
      "datasheet": "แผ่นข้อมูล",
      "estimate_service": "บริการประเมินราคา",
      "inspection": "การตรวจสอบ",
      "no_template": "คุณต้องสร้างเทมเพลต",
      "progress": "การก่อสร้าง",
      "progress_service": "การประเมินค่า",
      "report": "รายงาน",
      "request": "สั่งงาน",
      "scoping": "การเตรียมงาน",
      "title": "เทมเพลตสำหรับ %{type}"
    },
    "tickets": {
      "icon_alt": "ไอคอนตั๋ว",
      "title": "ตั๋ว"
    },
    "timeline": {
      "created": "สร้าง",
      "day_ago_singular": "%{daysAgo} วันที่ผ่านมา",
      "day_until_singular": "ภายใน %{daysUntil} วัน",
      "days_ago": "%{daysAgo} วันที่ผ่านมา",
      "days_until": "ภายใน %{daysUntil} วัน",
      "discipline_check_done": "การตรวจสอบวินัย: เสร็จสิ้น",
      "discipline_check_pending": "การตรวจสอบวินัย: รอดำเนินการ",
      "discipline_check_rejected": "การตรวจสอบวินัย: ถูกปฏิเสธ",
      "estimate_authorized": "การประมาณการ: ได้รับอนุญาต",
      "estimate_done": "ประมาณการ: เสร็จสมบูรณ์",
      "estimate_on_hold": "การประมาณการ: ระงับไว้",
      "estimate_pending": "ประมาณการ: รอดำเนินการ",
      "estimate_pending_authorization": "การประมาณการ: รอการอนุมัติ",
      "estimate_progress": "การประมาณการ: อยู่ระหว่างดำเนินการ",
      "performance": "การก่อสร้าง",
      "progress": "ความคืบหน้า",
      "recycle_done": "รีไซเคิล: เสร็จสิ้น",
      "recycle_recycling": "รีไซเคิล: การรีไซเคิล",
      "recycling": "การรีไซเคิล",
      "rejected": "ถูกปฏิเสธ",
      "request": "สั่งงาน",
      "responsible": "รับผิดชอบ",
      "schedule": "กำหนดการ",
      "scoping": "การเตรียมงาน",
      "title": "เส้นเวลาการสั่งงาน",
      "today": "วันนี้"
    },
    "top_menu": {
      "authorization": "การอนุญาต",
      "datasheets": "แผ่นข้อมูล",
      "eav_templates": "แม่แบบ",
      "estimates": "ประมาณการ",
      "inspections": "การตรวจสอบ",
      "no_datasheets": "ไม่พบเอกสารข้อมูลทางเทคนิค",
      "no_reports": "ไม่พบรายงาน",
      "performances": "การก่อสร้าง",
      "plannings": "การวางแผน",
      "reports": "รายงาน",
      "requests": "สั่งงาน",
      "scaffoldings": "บันทึกนั่งร้าน",
      "scopes": "การเตรียมงาน",
      "workflow": "ขั้นตอนการทำงาน"
    },
    "track_block": {
      "id": "DPMSID"
    },
    "user_menu": {
      "about_version": "เกี่ยวกับเวอร์ชั่น",
      "clean_preferences": "การตั้งค่าที่สะอาด",
      "logout": "ออกจากระบบ",
      "notification": {
        "clean_preferences": "ล้างค่ากำหนดแล้ว",
        "error": "เกิดข้อผิดพลาดขณะพยายามล้างค่ากำหนด"
      },
      "preferences": "การตั้งค่ากริด",
      "profile": "ประวัติโดยย่อ"
    },
    "users": {
      "edit_title": "อัพเดทโปรไฟล์"
    },
    "work_order_attachments": {
      "subtitle": "ดูเอกสาร Work Order ที่เกี่ยวข้องได้ที่",
      "title": "เอกสารแนบการสั่งงาน"
    }
  },
  "zh-CN": {
    "actions": {
      "alternate_view": "替代视图",
      "approve": "批准",
      "back_to": "返回 %{page}",
      "bulk_editing": "批量编辑",
      "cancel": "取消",
      "choose_entity": "选%{entity}",
      "clear": "清除",
      "clear_all_filters": "清除所有筛选条件",
      "confirm": "确定",
      "create": "创建",
      "create_inspection": "创建检查",
      "delete": "删除",
      "discard": "丢弃",
      "dismantle": "拆除",
      "done": "完成了",
      "duplicate": "复制",
      "duplicate_services": "重复服务",
      "duplicate_with_parts": "复制零件",
      "edit": "编辑",
      "export": "导出 .xls",
      "export_template": "下载模版 .xls",
      "filter_pinned_work_orders": "过滤固定工作订单",
      "flag_filter": "标记过滤器",
      "generate_work_package": "生成工作包",
      "import": "导入 .xls",
      "import_file": "导入文件",
      "loading": "加载中",
      "more": "更多",
      "new": "创建新的",
      "next": "下一个",
      "no": "不是",
      "past": {
        "cancel": "取消",
        "complete": "已完成",
        "confirm": "已确定",
        "delete": "已删除",
        "destroy": "已删除",
        "dismantle": "拆除的",
        "duplicate_with_parts": "及其部件重复",
        "ready_to_dismantle": "准备拆除",
        "reject": "已拒绝",
        "revert": "还原的",
        "update": "更新"
      },
      "perform_discipline_check": "执行纪律检查",
      "pin_request": "按选定的请求过滤",
      "prev": "先前",
      "ready": "准备好",
      "ready_for_discipline": "准备接受纪律检查",
      "ready_to_dismantle": "准备拆卸",
      "recycle": "回收",
      "reject": "拒绝",
      "remove_recycle": "删除回收站",
      "reset": "重启",
      "retrieving": "正在检索...",
      "revert": "还原",
      "revert_to_erected": "恢复竖立",
      "save": "保存",
      "save_button_keyboard": "保存 (Ctrl + Enter)",
      "save_exit": "保存并退出",
      "save_new": "添加另一个",
      "saving": "保存中",
      "schedule": "时间表",
      "show_all_work_orders": "显示所有工作订单",
      "skip": "跳跃",
      "status_filter": "状态过滤器",
      "unpin_request": "按请求删除过滤器",
      "unpin_selected_request": "删除 %{numSelectedRequests} 选定请求的过滤器",
      "unsaved_changes": "未保存的行",
      "upload_file": "上传文件",
      "upload_picture": "上传图片",
      "yes": "是"
    },
    "api_internal_messages": {
      "cant_duplicate_clustered_request": "无法复制集群工单",
      "cant_duplicate_progress": "无法复杂",
      "cant_duplicate_request": "由于以下错误，无法复制准备工作、估算和服务...",
      "cant_duplicate_scope": "无法复杂",
      "only_estimate_service_copy": "试图复制仅评估服务项目。",
      "only_progress_copy": "仅尝试复制进度。",
      "only_request_copy": "仅尝试复制工单。",
      "only_scope_copy": "仅尝试复制工作准备项目。"
    },
    "cancel_modal": {
      "cancel": "现在不要",
      "description": "您确定要取消%{model}“ %{id}”吗？",
      "ok": "是的，取消",
      "title": "取消项目？"
    },
    "clear_records": {
      "modal": {
        "cancel": "不，取消",
        "description": "您确定要清除所有%{model}记录吗？",
        "ok": "是的，清除所有",
        "title": "清除%{model}？"
      },
      "success_message": "%{model}成功清除！",
      "title": "清除记录"
    },
    "clear_template_preferences": {
      "cancel": "不，取消",
      "description": "您确定要清除此模板的所有网格首选项吗？",
      "ok": "是的，清除网格偏好",
      "title": "清除网格首选项"
    },
    "collections": {
      "clusters": "集群",
      "create_by_package": "按包创建",
      "duplicate": "复制",
      "edit": "编辑",
      "estimate": "估计",
      "new": "新的",
      "schedule": "日程",
      "sub_requests": "子请求",
      "view": "看法"
    },
    "complete_modal": {
      "cancel": "现在不要",
      "description": "您确定要将 %{model} 标记为已完成吗？",
      "ok": "是，标记为已完成",
      "title": "标记为完成？"
    },
    "complete_progress_modal": {
      "cancel": "取消",
      "complete": "去完成",
      "description": "您确定要完成此建造项目吗？此操作无法撤消。",
      "title": "确认施工项目完成"
    },
    "confirmation_modal": {
      "cancel": "取消",
      "description": "无法撤销此行动",
      "ok": "Ok",
      "title": "请确定"
    },
    "contract_services": {
      "title": "合约服务"
    },
    "datasheets": {
      "datasheets_detail": {
        "side_panel_title": "数据表详细信息"
      },
      "description": "数据表",
      "id": "ID",
      "item": "物品",
      "title": "数据表"
    },
    "delete_attachment_modal": {
      "cancel": "否，保留附件",
      "delete_successful": "附件删除成功",
      "description": "您确实要删除该附件吗？",
      "ok": "是的，删除附件",
      "title": "删除附件？"
    },
    "delete_modal": {
      "cancel": "取消",
      "description": "请确定是否删除%{model} \"%{id}\"?",
      "ok": "是，删除",
      "title": "删除吗?"
    },
    "delete_picture_modal": {
      "cancel": "不，保留图片",
      "delete_successful": "图片删除成功",
      "description": "您确定要删除这张照片吗？",
      "ok": "是的，删除图片",
      "title": "删除图片"
    },
    "dialogs": {
      "batch_acceptance": {
        "title": "验收"
      },
      "column_comment": {
        "add_comment": "点击添加评论",
        "cancel": "取消",
        "comment": "评论",
        "subtitle": "添加/编辑评论"
      },
      "columns_batch_assigner": {
        "actions": "行动",
        "add_action": "添加",
        "add_to_column": "添加到列",
        "add_value_to": "添加 %{value}  到",
        "column": "柱",
        "done": "保存操作",
        "duplicate_services": "复制服务",
        "header": "批量编辑",
        "multiple_success": "总共更新了%{numberOfItems}",
        "new_value": "新数据",
        "single_success": "1 项目已更新",
        "subtitle": "为 %{numberOfColumns} 选定的列分配值",
        "title": "创建操作",
        "update": "更新"
      },
      "configure_print_grid_file": {
        "error_max_columns": "您最多只能选择 %{maxColumns} 列。",
        "generate": "生成文件",
        "header": "打印网格",
        "paper_size_title": "纸张尺寸",
        "pdf_file": {
          "information": "信息",
          "pictures": "图片",
          "title": "网格 %{modelName} - %{numItems} 项 - 第 %{pageNum} 页，共 %{totalPages} 页"
        },
        "select_columns_title": "要打印的列",
        "selected_columns": "您从 %{maxColumns} 中选择了 %{numSelectedColumns}。",
        "subtitle": "选择最多 %{maxColumns} 列进行打印",
        "title": "配置要打印的文件"
      },
      "configure_print_list_file": {
        "big_picture_title": "使用页面宽度打印图像？",
        "font_size_title": "选择字体大小",
        "header": "打印列表",
        "hide_columns_title": "包括隐藏列",
        "large": "大的",
        "medium": "中等的",
        "page_break_title": "每页打印 1 个项目？",
        "small": "小的"
      },
      "configure_work_package_file": {
        "attached_documents": "附加文件",
        "attachments": "附件",
        "estimates": "估计",
        "estimates_price": "预估价格",
        "generate": "产生",
        "header": "生成工作包",
        "hse_r": "HSE-R 评估",
        "inspection_page_breaker": "每页打印一次检查",
        "inspection_pictures": "包括图片和签名",
        "inspections": "所有相关检查",
        "subtitle": "选择要打印的部分",
        "title": "配置工作包文件",
        "work_description": "工作描述"
      },
      "duplicate_services": {
        "title": "复制服务"
      }
    },
    "discard_modal": {
      "cancel": "继续修改",
      "description": "请确定是否废弃您的更改",
      "ok": "是",
      "title": "废弃"
    },
    "eav_templates": {
      "title": "范本"
    },
    "empty_state": {
      "columns": "列",
      "empty": "没有 %{model}",
      "no_attachments": "您尚未上传任何附件",
      "no_columns": "没有可用的图片栏",
      "no_discipline_template": "未找到已过滤工作订单规则的模板。",
      "no_estimate_service_template": "未找到所选工作准备模板的估算服务模板",
      "no_pictures": "您尚未上传此栏目的图片",
      "no_unselect": "无法在此页面取消选择已固定的请求。您只能在“工作订单”页面或通过注销来取消选择。",
      "not_created": "您还没创建任何%{model}",
      "pictures": "图片"
    },
    "environment": {
      "project": {
        "select_project": "选择项目"
      },
      "select_project_subproject": "选择项目",
      "subproject": {
        "select_subproject": "选择子项目"
      }
    },
    "estimate": {
      "accept": "接受估计",
      "all_estimates_approved": "所有估算均已成功获得批准。",
      "application": "申请费用",
      "back": "所有工单",
      "budget_hours": "预算时间",
      "confirmation": {
        "cancel": "取消",
        "confirm": "是，确定",
        "description": "您确定要授权此工单的估算吗？",
        "title": "确定"
      },
      "description": "您有 %{number} 个服务等待结帐。 请在下面查看详细信息。",
      "equipment": "设备费用",
      "estimate_details": "估算细节",
      "estimate_success": "成功批准估算",
      "info_message": "这个估算已经被批准。它是只读不能修改。",
      "material": "材料费用",
      "no_estimate_message": "没有与此工单相关的估算。",
      "norm_hours": "规范时间",
      "others": "其他费用",
      "pdf_file": {
        "title": "估算详细信息 - 第 %{pageNum} 页（共 %{totalPages} 页）"
      },
      "pdf_header": {
        "discipline": "纪律",
        "functional_location": "功能位置",
        "jobcard_status": "工卡状态",
        "ke_priority": "知识共享优先级",
        "main_area_location": "主要区域及位置",
        "main_work_center": "主要工作中心",
        "maintenance_plant": "维护工厂",
        "not_defined": "尚未定义",
        "order_finish": "订单完成",
        "order_start": "订单开始",
        "priority_sequence": "优先级顺序",
        "project": "项目",
        "project_type": "项目类型",
        "report": "报告",
        "report_date": "报告日期",
        "responsible_engineer": "责任工程师",
        "status_completed": "完全的",
        "status_issued_for_approval": "核准发证",
        "status_issued_for_construction": "施工用",
        "subproject": "子项目",
        "system": "系统",
        "template": "模板",
        "title": "工单估算",
        "work_order": "工作指示"
      },
      "pdf_summary": {
        "title": "估算摘要"
      },
      "save_draft": "保存草稿",
      "service_details": "服务详情",
      "some_estimates_not_approved": "有些估计无法获得批准。检查错误日志。",
      "team_target_hours": "团队目标时间",
      "title": "结帐服务",
      "tooltip": {
        "application": "应用",
        "equipment": "设备",
        "material": "材料",
        "others": "其他"
      },
      "total_approved": "批准总数",
      "total_pending": "未偿还总额",
      "total_request": "总花费",
      "use_construction_prices": "包括建筑价格吗？"
    },
    "estimate_services": {
      "application_price": "申请价格",
      "automatically_prices": "自动计算的价格",
      "back": "所有工作准备",
      "budget_target_hours": "预算目标时间",
      "choose_modal": {
        "package": "单一细节包",
        "package_description": "基于相同细节创建多个服务",
        "single": "单一细节",
        "single_description": "只创建一个细节",
        "title": "创建新的"
      },
      "contract_service": "合同服务",
      "crew_size": "员工人数",
      "crews": "员工人数",
      "description": "估算服务",
      "description_label": "描述",
      "discipline": "专业",
      "equipment_price": "设备价格",
      "estimate": "结帐服务",
      "estimate_services_detail": {
        "side_panel_title": "服务详情"
      },
      "factor": "因子",
      "flag": "旗帜",
      "id": "编号",
      "item_no": "货号",
      "materials_price": "材料价格",
      "norm_hours": "规范时间",
      "other_price": "其他价格",
      "prices": "价格",
      "print": "打印",
      "quantity": "数量",
      "service": "服务",
      "service_no": "服务编号",
      "service_package": "服务包",
      "statuses": {
        "inspected_flag": "正在等待检验"
      },
      "team_target_hours": "团队目标时间",
      "title": "估算服务",
      "title_packageable": "通过服务包估算服务",
      "total": "合计",
      "unit": "单元",
      "weight": "重量",
      "working_hours": "工作小时"
    },
    "fields": {
      "created_at": "已创建",
      "image_not_found": "找不到图片",
      "na": "不适用",
      "signature": "签名",
      "updated_at": "更新"
    },
    "filter_steps_modal": {
      "filter_result": "筛选结果",
      "result_subtitle": "您的选择摘要",
      "step": "步骤",
      "subtitle": "请选择 %{title}"
    },
    "filtering": {
      "between": " 那是在",
      "contains": " 包含",
      "eq": " 等于",
      "invalid_date": "要正确过滤，必须填写开始日期和结束日期",
      "invalid_value": "无效值",
      "neq": " 那不等于"
    },
    "forgot_password": {
      "back_to_login": "回到登入",
      "button": "向我发送重置密码说明",
      "feedback": "包含重置密码说明的消息已发送至您提供的电子邮件。",
      "placeholder": "你的邮件",
      "subtitle": "请输入您的电子邮件地址以接收密码重置说明。",
      "title": "忘记密码了吗？"
    },
    "form": {
      "back": "背部",
      "cancel": "取消",
      "create_entity": "创建 %{entity}",
      "create_error": "无法创建%{entity}",
      "create_success": "成功创建%{entity}",
      "duplicate_entity": "复制%{entity}",
      "duplicate_success": "%{entity} 和其依赖项已成功复制！",
      "general_information": "一般信息",
      "hide": "隐藏",
      "inputs": {
        "attachment": {
          "add_error": "添加文件时出错",
          "add_evidence": "添加业主授权的证据",
          "allowed_extensions": "允许的文件类型是",
          "drag_drop": "使用拖放来添加文件",
          "invalid_extension": "不允许的文件类型",
          "max_size": "该文件必须小于 %{max_size}",
          "maximum_num_uploads_error": "每个灵活列最多只能加载 %{max_num} 个文件。",
          "one_file": "一次上传一个文件",
          "or_drop_file_here": "或将档案放在这里",
          "remove_error": "删除失败",
          "remove_success": "已成功删除",
          "select_files": "选择文件",
          "submit": "提交您的附件",
          "upload_error": "上传失败",
          "upload_success": "成功上传"
        },
        "contract_service": {
          "add": "添加合同服务",
          "subtitle": "合约服务"
        },
        "datasheet_filter": {
          "add_filter": "添加筛选",
          "title": "数据表筛选"
        },
        "date_range": {
          "between": "%{start} 直到 %{end}",
          "end": "结束日期",
          "end_simplified": "结尾",
          "start": "开始日期",
          "start_simplified": "开始"
        },
        "drop_formula": {
          "formula_select": "请选择一个公式..."
        },
        "errors": {
          "date_greater_than_max": "日期应在 %{max} 或之前",
          "date_less_than_min": "日期应在 %{min} 或之后",
          "drop_formula_result": "%{field} 计算公式结果",
          "mandatory": "请填字%{field}",
          "not_found": "在参考文献中找不到“ %{value}”。选择一个新的。"
        },
        "fixed_picture": {
          "drop_zone": {
            "hint": {
              "drag_and_drop": "或拖放",
              "select_file": "点击上传"
            },
            "note": {
              "extensions": "文件必须位于 %{extensions}",
              "max_size": "最大文件大小 %{max_size}mb"
            }
          },
          "progress": {
            "attachment_type": "图片"
          },
          "upload": {
            "error": {
              "invalid_picture": "类型/大小无效",
              "multiple_fails": "所有附加文件的类型/大小均无效",
              "multiple_files": "部分附件图片类型/尺寸无效，请上传有效图片",
              "title": "上传失败"
            },
            "success": {
              "multiple_files": "已成功上传多个文件",
              "title": "上传成功"
            }
          }
        },
        "formula": {
          "description": "这是公式列，结果自动刷新",
          "icon": "点击查看方程式",
          "result": "结果"
        },
        "gps": {
          "save_location": "保存地点",
          "search": "搜寻位置",
          "without_location": "没有地点"
        },
        "multiple_entries": {
          "add": "添加另一个",
          "empty_entry": {
            "scaffolding_type_id": "选择脚手架类型来添加操作"
          },
          "empty_inputs": "%{input} 的输入是 %{model} 所必需的",
          "required": "字段“%{field}”为必填项，必须填写"
        },
        "numeric": {
          "between": "值应介于 %{min} 和 %{max} 之间",
          "greater": "值应大于 %{min}",
          "lower": "值应小于 %{max}"
        },
        "picture": {
          "comment_placeholder": "写评论（可选）",
          "edition_only": "仅在编辑时可用",
          "no_image_on_clipboard": "找不到图像数据。 请先复制图像。",
          "remove_error": "删除失败",
          "remove_success": "已成功删除",
          "upload_error": "上传失败",
          "upload_success": "成功上传"
        },
        "placeholder": "这里输入%{field}",
        "value_selector": {
          "apply": "应用",
          "done": "完成了",
          "search": "搜寻",
          "waiting": "… 稍等"
        }
      },
      "items": "项目",
      "loading": "加载中",
      "loading_calculations": "计算可见性、所需列和默认值",
      "loading_contract": "正在加载合同信息...",
      "schedule_entity": "时间表 %{entity}",
      "schedule_success": "您安排%{entity} 的请求已成功完成",
      "show": "显示",
      "subtitle": "请为 %{entity} 提供基本的信息。",
      "subtitle_error": "无法加载表单，请检查您的%{entity}或模板数据。",
      "update_entity": "编辑%{entity}",
      "update_error": "无法更新%{entity}",
      "update_success": "成功更新%{entity}"
    },
    "formula_modal": {
      "back_button_text": "返回表格",
      "calculate": "计算",
      "cancel": "取消",
      "mandatory": "您必须填写以下字段的值：%{fields}",
      "ok": "保存公式"
    },
    "grid": {
      "all": "全部",
      "clear_filters": "清楚筛选",
      "editable": {
        "error": {
          "mandatory": "至少有一个必填字段为空"
        },
        "missing_required": "此行缺少 %{number} 个必填单元格",
        "title": "编辑模式",
        "update": {
          "error": "%{quantity} 无法保存。检查突出显示的每一行左侧的原因",
          "success": "%{quantity} 条记录保存成功"
        }
      },
      "empty_after_filtering": "筛选找不到任何记录",
      "failed_saving_preferences": "首选项未保存！",
      "flag_filter": "标志过滤器",
      "grid_filters": {
        "menu_title": "自定义过滤器"
      },
      "menu": {
        "visibility": "列"
      },
      "of": "的",
      "other": "其他",
      "productivity_factor": "生产率系数",
      "quick_pin_popup": {
        "all_work_orders": "所有 OTs",
        "clear_all": "清除全部",
        "no_pinned_requests": "您尚未置顶工作订单",
        "pinned_work_orders": "固定 OTs",
        "quick_pin": "快速固定",
        "search_and_pin": "搜索并锁定 OT"
      },
      "saved_preferences": "已保存！",
      "saving_preferences": "正在保存首选项...",
      "select_items": "选择",
      "selected": "选择的",
      "selecteds": "选择的",
      "status_filter": "状态过滤器",
      "totals": "合计"
    },
    "history_form": {
      "create_title": "添加新进度",
      "edit_title": "編輯你的進度",
      "subtitle": "插入您活动的数量或百分比进度。"
    },
    "history_popup": {
      "back": "后退",
      "comments": "注释",
      "crew_size": "船员人数",
      "daily_hours": "每日营业时间",
      "date": "日期",
      "form_create_progress_service_title": "创建施工服务项目历史记录",
      "form_create_progress_title": "创建进度项目历史记录",
      "form_edit_progress_service_title": "编辑施工服务项目历史记录",
      "form_edit_progress_title": "编辑进度项目历史",
      "form_error_body": "必须填写表格上的所有必填字段。",
      "form_error_title": "无法保存历史表单",
      "form_subtitle": "按数量或百分比为您的活动插入历史记录。",
      "modal_create_title": "创造历史",
      "modal_edit_title": "编辑历史",
      "number_of_crews": "船员人数",
      "number_of_workers": "工人数量",
      "other_value": "其他价值",
      "percentage": "百分比",
      "progress_by": "进度",
      "quantity": "数量",
      "singular": "历史",
      "working_hours": "工作时间"
    },
    "home": {
      "available_on": "可用",
      "banner_description": "使用单个平台管理任务，跟踪流程并获得最佳结果",
      "create_request": {
        "text": "首先，您需要在 DPMS 中从客户创建一个包含订单详细信息的工作订单。",
        "title": "创建工单"
      },
      "dpms_mobile": "DPMS移动应用",
      "getting_started": "入门",
      "home_title": "家",
      "inspection": {
        "text": "在现场进行质量检查并记录障碍。",
        "title": "检查"
      },
      "inspection_calendar": {
        "calendar": "日历",
        "table": "桌子",
        "title": "检查时间表"
      },
      "performance": {
        "text": "根据每个团队的计划活动记录进度和时间，并衡量生产力。",
        "title": "建造"
      },
      "scoping": {
        "text": "准备客户的工单。从合同中分配服务和服务包。",
        "title": "工作准备"
      },
      "welcome_user": "欢迎您，%{user}"
    },
    "importation_drop": {
      "choose_error": "选择一个项目"
    },
    "importation_popup": {
      "file_upload_message": "单击或拖动文件到这里上传",
      "importation_error": "您可以在工作页面上查看此消息。",
      "importation_success": "成功导入",
      "importation_timeout": "进口时间太长，请查其状态。",
      "instructions": "文件需要是 .xls 此外不能大过 xx兆.<br>",
      "requirements": "要求",
      "submit_spreadsheet": "提交表格"
    },
    "inspections": {
      "acceptance": "接受",
      "acceptance_error": {
        "error_description": "检查 - %{item} 无法更新，因为 %{reason}",
        "title": "我们无法完成"
      },
      "acceptance_modal": {
        "confirmation": {
          "description": "如果需要的话，您可以稍后恢复。",
          "reject": "您确定要拒绝此项/这些检查吗？"
        },
        "inspection_acceptance": "检查验收",
        "items": "%{items} 件",
        "reject": "拒绝",
        "subtitle": "提供有关检查的信息以继续接受。"
      },
      "actions": {
        "approve": "批准",
        "more": "更多",
        "reject": "拒绝"
      },
      "choose_modal": {
        "schedule": "计划检查项目",
        "schedule_description": "根据日期间隔安排一次或多次检查",
        "single": "单项检查项目",
        "single_description": "仅创建一个检查",
        "title": "将检查创建为："
      },
      "description": "检查",
      "description_title": "描述",
      "every": "每",
      "id": "编号",
      "inspect_date": "检查日期",
      "inspect_status_id": "状态",
      "inspected": "已检查",
      "inspected_detail": "检查细节",
      "inspected_item": "受检商品",
      "inspected_modules": {
        "estimate_services": "估价详情",
        "performance": "建造",
        "progress": "建造",
        "progress_services": "建筑服务",
        "request": "工作指示",
        "scaffolding": "脚手架",
        "scope": "工作准备"
      },
      "inspected_services": "已检查服务",
      "inspected_track": {
        "area": "区域",
        "comments": "评论",
        "description": "描述",
        "item": "项目 %{item}",
        "no_load": "无法加载已检查的轨迹块：缺少数据",
        "number_of_tags": "标签数量",
        "reason": "原因",
        "subarea": "子区域"
      },
      "inspection_block": {
        "inspection_date": "检验日期",
        "missing_date": "缺失日期",
        "not_selected_yet": "尚未选定",
        "responsible": "负责任的"
      },
      "inspections_detail": {
        "side_panel_title": "检查细节"
      },
      "module": "模块",
      "notifications": {
        "approve_successful": "检验成功通过",
        "reject_successful": "检验被成功拒绝"
      },
      "pending_inspections": "待检查",
      "recurrency_interval": "复发间隔",
      "related_inspections": {
        "missing_date": "缺失日期",
        "no_related": "未找到相关检查",
        "no_responsible": "不负责",
        "title": "相关检查"
      },
      "repeat": "重复",
      "repeat_every_value": {
        "day": "日",
        "month": "月",
        "week": "星期",
        "year": "年"
      },
      "repeats_on": "重复",
      "request_id": "DPMS编号",
      "responsible_id": "负责人",
      "schedule_detail": "时间表细节",
      "schedule_form": {
        "acceptance_step": "定义接受（可选）",
        "detailed_item_step": "选择检查准备/施工项目",
        "detailed_template_step": "选择检查准备/施工模板",
        "further_step_information": "在重复创建步骤之后，可以单独填充弹性域",
        "general_step": "创建审查",
        "hints": "关于每月定期和每年定期，系统将自动匹配审查时间表",
        "monthly_hint": "每个月的%{day}重复",
        "recurrence_step": "复发率"
      },
      "scheduled_exception": "定于",
      "select_module_item": "选择一个模块项以在此处查看其详细信息",
      "statuses": {
        "approved": "得到正式认可的",
        "completed": "完全的",
        "overdue": "逾期",
        "pending": "待办的",
        "progress": "进行中",
        "rejected": "被拒绝",
        "scheduled": "已预定"
      },
      "template": "模板",
      "title": "检查"
    },
    "inspects": {
      "statuses": {
        "approved": "得到正式认可的",
        "completed": "完全的",
        "pending": "待办的",
        "progress": "进行中",
        "rejected": "被拒绝"
      }
    },
    "kendo": {
      "upload": "单击或拖动文件到这里上传"
    },
    "kendo_translations": {
      "calendar": {
        "today": "今天"
      },
      "dateinput": {
        "decrement": "降低价值",
        "increment": "增加价值"
      },
      "datepicker": {
        "toggleCalendar": "切换日历"
      },
      "daterangepicker": {
        "end": "结尾",
        "separator": 0,
        "start": "开始",
        "swapStartEnd": "交换开始和结束值"
      },
      "datetimepicker": {
        "cancel": "取消",
        "date": "日期",
        "set": "放",
        "time": "时间",
        "toggleDateTimeSelector": "切换日期时间选择器"
      },
      "dropdowns": {
        "clear": "清除",
        "nodata": "没有找到数据"
      },
      "editor": {
        "addColumnAfter": "在右侧添加列",
        "addColumnBefore": "在左侧添加列",
        "addRowAfter": "在下面添加行",
        "addRowBefore": "在上方添加行",
        "alignCenter": "中心文字",
        "alignJustify": "证明合法",
        "alignLeft": "左对齐文字",
        "alignRight": "右对齐文字",
        "bold": "胆大",
        "bulletList": "插入无序列表",
        "createTable": "建立表格",
        "deleteColumn": "删除栏",
        "deleteRow": "删除行",
        "deleteTable": "删除表格",
        "fontName": "字体名称",
        "fontSize": "字体大小",
        "format": "格式",
        "hyperlink": "插入超链接",
        "hyperlink-dialog-cancel": "取消",
        "hyperlink-dialog-content-address": "网址",
        "hyperlink-dialog-content-newwindow": "在新窗口中打开链接",
        "hyperlink-dialog-content-title": "标题",
        "hyperlink-dialog-insert": "插入",
        "hyperlink-dialog-title": "插入超链接",
        "image": "插入图片",
        "image-address": "网址",
        "image-altText": "替代文字",
        "image-cancel": "取消",
        "image-dialog-title": "插入图片",
        "image-height": "身高（px）",
        "image-insert": "插入",
        "image-title": "标题",
        "image-width": "宽度（像素）",
        "indent": "缩进",
        "insertTableHint": "创建一个0 x 1表",
        "italic": "斜体",
        "mergeCells": "合并单元格",
        "orderedList": "插入订购清单",
        "outdent": "突出",
        "redo": "重做",
        "splitCell": "分裂细胞",
        "strikethrough": "删除线",
        "subscript": "下标",
        "superscript": "上标",
        "underline": "强调",
        "undo": "撤消",
        "unlink": "删除超链接",
        "viewHtml": "查看HTML",
        "viewHtml-cancel": "取消",
        "viewHtml-dialog-title": "查看HTML",
        "viewHtml-update": "更新"
      },
      "filter": {
        "addExpression": "添加表达",
        "addGroup": "新增群组",
        "afterOperator": "是之后",
        "afterOrEqualOperator": "在之后或等于",
        "andLogic": "和",
        "beforeOperator": "是之前",
        "beforeOrEqualOperator": "在之前或等于",
        "close": "关闭",
        "containsOperator": "包含",
        "endsWithOperator": "以。。结束",
        "eqOperator": "等于",
        "gtOperator": "大于",
        "gteOperator": "大于或等于",
        "isEmptyOperator": "是空的",
        "isFalse": "没有",
        "isNotEmptyOperator": "不为空",
        "isNotNullOperator": "不为空",
        "isNullOperator": "一片空白",
        "isTrue": "是是的",
        "ltOperator": "小于",
        "lteOperator": "小于或等于",
        "notContainsOperator": "不含",
        "notEqOperator": "不等于",
        "orLogic": "或者",
        "startsWithOperator": "以。。开始"
      },
      "grid": {
        "filterAfterOperator": "是之后",
        "filterAfterOrEqualOperator": "在之后或等于",
        "filterBeforeOperator": "是之前",
        "filterBeforeOrEqualOperator": "在之前或等于",
        "filterBetweenOperator": "在。。。之间",
        "filterBooleanAll": "（全部）",
        "filterClearButton": "清除",
        "filterContainsOperator": "包含",
        "filterEndsWithOperator": "以。。结束",
        "filterEqOperator": "等于",
        "filterGtOperator": "大于",
        "filterGteOperator": "大于或等于",
        "filterIsEmptyOperator": "是空的",
        "filterIsFalse": "没有",
        "filterIsNotEmptyOperator": "不为空",
        "filterIsNotNullOperator": "不为空",
        "filterIsNullOperator": "一片空白",
        "filterIsTrue": "是是的",
        "filterLtOperator": "小于",
        "filterLteOperator": "小于或等于",
        "filterNotContainsOperator": "不含",
        "filterNotEqOperator": "不等于",
        "filterStartsWithOperator": "以。。开始",
        "filterSubmitButton": "筛选",
        "filterTitle": "筛选",
        "groupPanelEmpty": "拖动列标题并将其放在此处以按该列分组",
        "noRecords": "没有可用的记录。",
        "pagerFirstPage": "转到第一页",
        "pagerInfo": "0-1的2项目",
        "pagerItemsPerPage": "每页项目",
        "pagerLastPage": "转到最后一页",
        "pagerNextPage": "转到下一页",
        "pagerOf": "的",
        "pagerPage": "页",
        "pagerPreviousPage": "转到上一页"
      },
      "numerictextbox": {
        "decrement": "降低价值",
        "increment": "增加价值"
      },
      "pager": {
        "firstPage": "转到第一页",
        "info": "0-1的2项目",
        "itemsPerPage": "每页项目",
        "lastPage": "转到最后一页",
        "nextPage": "转到下一页",
        "of": "的",
        "page": "页",
        "previousPage": "转到上一页"
      },
      "scheduler": {
        "agendaViewTitle": "议程",
        "allDay": "一整天",
        "allEvents": "所有活动",
        "dateTitle": "日期",
        "dayViewTitle": "日",
        "deleteConfirmation": "您确定要删除此事件吗？",
        "deleteDialogTitle": "删除活动",
        "deleteOccurrence": "删除当前事件",
        "deleteRecurringConfirmation": "您只想删除此事件发生还是整个系列？",
        "deleteRecurringDialogTitle": "删除重复项目",
        "deleteSeries": "删除系列",
        "deleteTitle": "删除",
        "editorCancel": "取消",
        "editorDelete": "删除",
        "editorEventAllDay": "全天的活动",
        "editorEventDescription": "描述",
        "editorEventEnd": "结尾",
        "editorEventEndTimeZone": "结束时区",
        "editorEventSeparateTimeZones": "在不同时区结束",
        "editorEventStart": "开始",
        "editorEventStartTimeZone": "开始时区",
        "editorEventTimeZone": "指定时区",
        "editorEventTitle": "标题",
        "editorOccurrence": "编辑当前事件",
        "editorRecurringConfirmation": "您只想编辑此事件发生还是整个系列？",
        "editorRecurringDialogTitle": "编辑定期项目",
        "editorSave": "救",
        "editorSeries": "编辑系列",
        "editorTitle": "事件",
        "editorValidationEnd": "结束时间必须晚于开始时间。",
        "editorValidationRequired": "必填项。",
        "editorValidationStart": "开始时间必须早于结束时间。",
        "eventTitle": "事件",
        "monthViewTitle": "月",
        "nextTitle": "下一个",
        "noEvents": "没有事件",
        "previousTitle": "以前的",
        "recurrenceEditorDailyInterval": "天）",
        "recurrenceEditorDailyRepeatEvery": "重复每一个",
        "recurrenceEditorEndAfter": "后",
        "recurrenceEditorEndLabel": "结尾",
        "recurrenceEditorEndNever": "绝不",
        "recurrenceEditorEndOccurrence": "发生",
        "recurrenceEditorEndOn": true,
        "recurrenceEditorFrequenciesDaily": "日常",
        "recurrenceEditorFrequenciesMonthly": "每月一次",
        "recurrenceEditorFrequenciesNever": "绝不",
        "recurrenceEditorFrequenciesWeekly": "每周",
        "recurrenceEditorFrequenciesYearly": "每年",
        "recurrenceEditorMonthlyDay": "日",
        "recurrenceEditorMonthlyInterval": "一个月",
        "recurrenceEditorMonthlyRepeatEvery": "重复每一个",
        "recurrenceEditorMonthlyRepeatOn": "重复上",
        "recurrenceEditorOffsetPositionsFirst": "第一的",
        "recurrenceEditorOffsetPositionsFourth": "第四",
        "recurrenceEditorOffsetPositionsLast": "最后的",
        "recurrenceEditorOffsetPositionsSecond": "第二",
        "recurrenceEditorOffsetPositionsThird": "第三",
        "recurrenceEditorRepeat": "重复",
        "recurrenceEditorWeekdaysDay": "日",
        "recurrenceEditorWeekdaysWeekday": "平日",
        "recurrenceEditorWeekdaysWeekendday": "周末日",
        "recurrenceEditorWeeklyInterval": "周",
        "recurrenceEditorWeeklyRepeatEvery": "重复每一个",
        "recurrenceEditorWeeklyRepeatOn": "重复上",
        "recurrenceEditorYearlyInterval": "年）",
        "recurrenceEditorYearlyOf": "的",
        "recurrenceEditorYearlyRepeatEvery": "重复每一个",
        "recurrenceEditorYearlyRepeatOn": "重复上",
        "showFullDay": "显示全天",
        "showWorkDay": "显示营业时间",
        "timeTitle": "时间",
        "timelineViewTitle": "时间线",
        "today": "今天",
        "weekViewTitle": "星期",
        "workWeekViewTitle": "工作周"
      },
      "sortable": {
        "noData": "没有数据"
      },
      "timepicker": {
        "cancel": "取消",
        "now": "现在",
        "selectNow": "立即选择",
        "set": "放",
        "toggleClock": "切换时钟",
        "toggleTimeSelector": "切换时间选择器"
      },
      "treelist": {
        "filterAfterOperator": "是之后",
        "filterAfterOrEqualOperator": "在之后或等于",
        "filterBeforeOperator": "是之前",
        "filterBeforeOrEqualOperator": "在之前或等于",
        "filterBooleanAll": "（全部）",
        "filterClearButton": "清除",
        "filterContainsOperator": "包含",
        "filterEndsWithOperator": "以。。结束",
        "filterEqOperator": "等于",
        "filterGtOperator": "大于",
        "filterGteOperator": "大于或等于",
        "filterIsEmptyOperator": "是空的",
        "filterIsFalse": "没有",
        "filterIsNotEmptyOperator": "不为空",
        "filterIsNotNullOperator": "不为空",
        "filterIsNullOperator": "一片空白",
        "filterIsTrue": "是是的",
        "filterLtOperator": "小于",
        "filterLteOperator": "小于或等于",
        "filterNotContainsOperator": "不含",
        "filterNotEqOperator": "不等于",
        "filterStartsWithOperator": "以。。开始",
        "noRecords": "无可用记录"
      },
      "upload": {
        "cancel": "取消",
        "clearSelectedFiles": "清除",
        "dropFilesHere": "或将档案放在这里",
        "files": "档案",
        "headerStatusUploaded": "完毕",
        "headerStatusUploading": "上载中...",
        "invalidFileExtension": "不允许的文件类型。",
        "invalidFiles": "无效的文件。请检查文件上传要求。",
        "invalidMaxFileSize": "档案太大。",
        "invalidMinFileSize": "文件大小太小。",
        "remove": "去掉",
        "retry": "重试",
        "select": "选择文件",
        "total": "全部的",
        "uploadSelectedFiles": "上载"
      }
    },
    "loading_batch_entities": {
      "page_of": "第 %{page} 页，共 %{total} 页",
      "title": "获取页面"
    },
    "login": {
      "again": "要继续，请重新登录",
      "continue_mykaefer": "用mykaefer",
      "copyright": "®%{year}保留所有权利。凯发®",
      "forgot_password": "忘记密码",
      "iframe_info": "要求访问页面：%{link}",
      "log_in": "登录",
      "password": "密码",
      "title_1": "强大的软件系统",
      "title_2": "项目管理",
      "username": "用户名",
      "welcome_back": "欢迎回来"
    },
    "main_dialog": {
      "failure_message": "您的要求无法设为“完成”",
      "failure_title": "失败",
      "loading_message": "请稍等",
      "loading_title": "加载中",
      "service_title": "等待作业完成...",
      "success_message": "您的要求已成功！ 您现在可以继续了。",
      "success_title": "成功"
    },
    "notification": {
      "access_denied": "拒绝访问",
      "access_denied_message": "您无权执行此操作。请联系您的管理员以获取更多信息。",
      "action_success": "%{model} %{id} 是 %{action}",
      "bad_formula_setup": "公式“ %{title}”存在设置问题，请检查您的设置！",
      "correct_subproject": "选择此项的正确子项目",
      "dismiss_all": "关闭所有",
      "error": "错误",
      "error_cookies": "该页面无法启用跨源通信",
      "error_job": "失败了！请查看检查原因。",
      "error_msg": "不好意思，出现了一些问题",
      "info": "资料",
      "large_file_body": "请应用一些过滤器以使输出文件更小。",
      "large_file_title": "网格有1000多个记录",
      "page_denied": "无法加载该页面。",
      "please": "请",
      "select_subproject": "选择一个项目和子项目以继续",
      "success": "成功",
      "success_msg": "成功执行",
      "warning": "提醒,"
    },
    "orders": {
      "add_evidence": "添加客户授权的证据",
      "attachment": "附件",
      "comments": "注释",
      "date": "日期",
      "drag_and_drop": "使用拖放添加文件",
      "recipient": "接受者",
      "responsible": "负责任的",
      "title": "订单"
    },
    "performance": {
      "title": "建造"
    },
    "performances": {
      "title": "建筑"
    },
    "performances_wip": {
      "performance_item": "建设项目",
      "title": "建造"
    },
    "planning": {
      "item_no": "编号。",
      "service_no": "服务编号",
      "title": "规划",
      "view": "规划视图"
    },
    "plannings": {
      "title": "规划"
    },
    "print_grid": {
      "title": "打印网格"
    },
    "print_list": {
      "loading": "正在生成您的文档。请稍候。",
      "title": "打印列表"
    },
    "progress": {
      "statuses": {
        "canceled": "取消",
        "done": "完毕",
        "extra": "额外的",
        "in_progress": "进行中",
        "pending": "待办的",
        "revised": "修改"
      }
    },
    "progress_histories": {
      "add_new_progress": "添加新进度",
      "empty_history": "没有关联的历史记录项，并且无法创建新项",
      "hours": "小时",
      "production_factor": "PF",
      "progress": "进步",
      "progress_badge": "项目 %{number}",
      "progress_history": "进展历史",
      "progress_service_badge": "服务 %{number}",
      "progress_service_history": "服务历史",
      "progress_service_title": "服务历史",
      "progress_title": "物品历史",
      "quantity": "数量",
      "quantity_empty_history": "没有历史项目，数量为零时无法创建历史",
      "subtitle": "根据团队计划的活动管理每日进度。"
    },
    "progress_services": {
      "actual_div_hours": "实际/团队目标小时数",
      "actual_div_quantity": "实际/目标数量",
      "actual_hours": "实际小时数",
      "actual_quantity": "实际数量",
      "application_price": "申请价格",
      "back": "所有建筑",
      "budget_target_hours": "预算目标小时数",
      "canceled": "取消",
      "choose_modal": {
        "package": "单一细节包",
        "package_description": "基于相同细节创建多个服务",
        "single": "单一细节",
        "single_description": "只创建一个细节",
        "title": "创建新的"
      },
      "contract_service": "合约服务",
      "contribution": "贡献",
      "contribution_info": "自动计算的值",
      "crew": "全体人员",
      "crew_size": "船员人数",
      "crews": "船员",
      "date_filter": {
        "all": "全部",
        "to": "到",
        "today": "今天",
        "tomorrow": "明天",
        "week": "星期",
        "yesterday": "昨天"
      },
      "date_filter_info": "日期过滤器",
      "delta_hours": "三角洲时间",
      "delta_qtt": "增量数量",
      "description": "施工服务",
      "discipline": "纪律",
      "end_date": "结束日期",
      "equipment_price": "设备价格",
      "factor": "因素",
      "flag": "旗帜",
      "id": "ID",
      "materials_price": "材料价格",
      "no_crews": "船员人数",
      "no_workers": "工人数量",
      "norm_hours": "正常工作时间",
      "not_planned": "未计划",
      "other_price": "其他价格",
      "package_factor": "因素",
      "people": "人们",
      "person": "人",
      "pf": "PF",
      "pf_doing_bad": "你做得不好",
      "pf_doing_good": "你做得很好！",
      "pf_doing_neutral": "无法计算 pf",
      "planned": "计划",
      "planning_chart": {
        "actual": "实际的",
        "actual_qty": "实际数量",
        "date": "日期",
        "forecasted": "预测",
        "forecasted_qty": "预测数量",
        "target": "目标",
        "target_qty": "目标数量",
        "title": "规划图"
      },
      "planning_timeline": {
        "day": "日",
        "day_advance": "提前一天",
        "day_delayed": "延迟了一天",
        "days": "天",
        "days_advance": "提前几天",
        "days_delayed": "延迟天数",
        "end_date": "结束日期",
        "forecasted": "预测",
        "on_time": "准时",
        "remaining_days": "剩余天数",
        "start_date": "开始日期",
        "title": "规划时间表"
      },
      "progress": "进步",
      "progress_services_detail": {
        "side_panel_title": "服务详情"
      },
      "quantity": "数量",
      "responsible": "负责任的",
      "scoped_quantity": "准备数量",
      "service": "服务",
      "service_package": "服务包",
      "service_planning": "服务规划",
      "service_progress": "服务进度",
      "service_weight": "服务权重",
      "start_date": "开始日期",
      "statuses": {
        "canceled": "取消",
        "done": "完全的",
        "extra": "额外的",
        "in_planning": "未计划",
        "inspected_flag": "正在等待检验",
        "planned": "计划"
      },
      "team_target_hours": "团队目标时间",
      "title": "建筑服务",
      "title_packageable": "按服务包划分的建筑服务",
      "total": "全部的",
      "total_hours": "全部小时数",
      "unit": "单元",
      "worker": "工人",
      "workers": "工人",
      "working_hours": "工作时间"
    },
    "progresses": {
      "actual_hours": "实际小时数",
      "cancel": "取消",
      "choose_modal": {
        "single": "另一个建设项目",
        "single_description": "仅创建一个建筑项目"
      },
      "complete": "完全的",
      "complete_services": "完善的服务",
      "current_hours": "当前时间",
      "current_qty": "現有數量",
      "date_filter": {
        "all": "全部",
        "current_week": "本周",
        "previous_week": "上周",
        "today": "今天"
      },
      "date_filter_info": "日期筛选器",
      "delta_hours": "Δ 小时",
      "delta_qty": "Δ 数量",
      "description": "描述",
      "edit_work_description": "编辑工作描述",
      "extra": "额外的",
      "flag": "旗帜",
      "history": "添加新进度",
      "id": "ID",
      "item": "物品",
      "label": "标签",
      "more_details": "查看更多",
      "pf": "PF",
      "planned_hours": "计划时间",
      "planned_qty": "计划数量",
      "preview_unavailable": "没有可用的预览",
      "progress": "进步",
      "progress_status_restriction": "进度不应具有“已取消”或“已完成”状态",
      "progresses_detail": {
        "side_panel_title": "施工细节"
      },
      "remaining_hours": "剩余时间",
      "request_status_restriction": "工单应具有“计划中”或“施工中”状态",
      "revised": "修改",
      "success_work_description_edition": "作品描述已成功编辑",
      "title": "建筑",
      "work_description": "工作描述"
    },
    "projects": {
      "workdays": {
        "fri": "星期五",
        "mon": "星期一",
        "sat": "星期六",
        "sun": "星期天",
        "thu": "星期四",
        "tue": "星期二",
        "wed": "星期三"
      }
    },
    "requests": {
      "actual_hours": "实际小时",
      "actual_progress": "实际进度",
      "all": "全部",
      "are_you_sure_complete": "您确定完成此工单吗？",
      "are_you_sure_complete_no_history": "您确定要在没有任何给定进度的情况下完成工单吗？",
      "are_you_sure_deletion": "您确定要删除该工单吗？",
      "are_you_sure_revertion": "您确定要还原并失去数据吗？",
      "area": "区",
      "authorization_pending": "待授权",
      "authorize_form": {
        "modal_title": "授权",
        "subtitle": "提供有关工单的一些信息以继续审批",
        "title": "授权工单"
      },
      "canceled": "取消的",
      "clustered": "集群",
      "comments": "评论",
      "confirmation_modal": {
        "cancel_description": "工单将被取消",
        "cancel_title": "取消工单？",
        "complete_description": "此工单将完成",
        "complete_title": "完整的工单？",
        "confirm_action": "继续导入工作准备",
        "confirm_cancel": "现在不要",
        "confirm_confirmation": "是的，我要确认",
        "confirm_description": "您想确认工单吗？",
        "confirm_title": "工单确认",
        "delete_description": "删除操作无法撤消。你确定吗？",
        "delete_title": "删除工单？",
        "reject_description": "该项目将返回到“准备中”状态",
        "reject_title": "拒绝工单？",
        "remove_recycle_description": "该项目将返回到 In Scoping 状态并失去 Recycle 标志。",
        "remove_recycle_title": "清除回收？",
        "revert_description": "还原操作无法撤消，您确定吗？",
        "revert_title": "恢复工单？"
      },
      "confirmed": "已确定",
      "create_inspection_modal": {
        "category_subtitle": "从类别 %{category_name} 中选择一个选项",
        "loading": "正在加载检查模板...",
        "no_templates": "没有检查模板。",
        "plural_title": "创建检查",
        "standalone_subtitle": "选择以下选项之一以继续",
        "title": "选择检验模板"
      },
      "creation_form": {
        "proceed": "到第二步"
      },
      "creation_page": {
        "add_template": "添加模版",
        "first_step": "创建工单",
        "second_step": "导入工作准备（可选）",
        "third_step": "应用服务包（可选）"
      },
      "description": "工作指示",
      "discipline": "专业",
      "discipline_check": {
        "check_terms": "纪律检查条款",
        "perform": "执行纪律检查",
        "ready_for_discipline": "准备接受纪律检查",
        "ready_for_review": "工单已准备好接受纪律审查",
        "set_as_ready": "将工单设置为准备进行纪律检查",
        "title": "纪律检查"
      },
      "discipline_check_item": "WO01 – 准备接受纪律检查",
      "discipline_check_no_comment": "没有添加评论",
      "done": "完成了",
      "eav_template": "模板",
      "eav_template_id": "模板编号",
      "edit_dialog": {
        "reason": "子要求名称"
      },
      "elevation": "海拔",
      "errors": {
        "not_found": "无法找到 ID 为 %{id} 的工单"
      },
      "estimates_authorization": "估算需要授权",
      "flags": {
        "discipline_check": "WO01 - 纪律检查",
        "discipline_check_done": "WO01 – 纪律检查: 已批准",
        "discipline_check_pending": "WO01 – 纪律检查: 待处理",
        "discipline_check_rejected": "WO01 – 纪律检查：被拒绝",
        "recycle": "WO03 - 回收"
      },
      "forecast_end_date": "预计结束日期",
      "id": "DPMS编号",
      "in_performance": "建设中",
      "in_planning": "规划中",
      "in_scoping": "在准备",
      "information": "附加信息",
      "inspection_date": "检验日期",
      "no_comment": "没有意见",
      "notification": {
        "authorization_success": "工单授权成功",
        "deleting_sub_request": "子请求%{id}被删除。请检查作业页面以了解进度",
        "discipline_check_approved": "纪律检查成功通过",
        "discipline_check_rejected": "纪律检查成功拒绝",
        "discipline_check_success": "工单已成功准备好进行纪律审查",
        "missing_order_error": "没有任何与此工作订单相关的待处理订单",
        "recycle_success": "工单已成功回收"
      },
      "on_hold": "等候",
      "progress": "进步",
      "progress_end_date": "进度结束日期",
      "progress_responsible": "负责进度",
      "progress_responsible_id": "负责进度",
      "progress_start_date": "进度开始日期",
      "reason": "参考",
      "recycle_form": {
        "label": "理由-纪律",
        "modal_title": "回收",
        "subtitle": "提供信息以标记此工单以进行回收",
        "title": "回收请求"
      },
      "recycling_item": "WO03 - 回收给客户",
      "reference": "参考",
      "registered": "注册",
      "remove_recycle_modal": {
        "cancel_description": "不，保留旗帜",
        "confirm_description": "是的，清楚",
        "success_notification": "回收标志已在 %{reason} - %{discipline} 上删除"
      },
      "request_date": "工单日期",
      "request_detail": {
        "edit": "编辑",
        "estimates": "估算",
        "inspections": "检查验收",
        "performance": "建造",
        "planning": "规划",
        "scopes": "工作准备",
        "show": "显示",
        "side_panel_title": "细节"
      },
      "request_end_date": "工单结束日期",
      "request_responsible": "负责工单",
      "request_responsible_id": "负责工单",
      "request_start_date": "工单开始日期",
      "responsible": "负责任的",
      "schedule": "工单计划",
      "schedule_form": {
        "date": "日期",
        "days": "%{number} 天",
        "performance": "建造",
        "progress": "进度",
        "request": "工作指示",
        "responsible": "负责",
        "schedule": "时间表",
        "scoping": "工作准备",
        "subtitle": "要为此工单创建计划，我们首先需要一些附加信息。",
        "timecode": "时间码",
        "title": "工单时间表"
      },
      "scope_end_date": "工作准备结束日期",
      "scope_responsible": "负责工作准备",
      "scope_responsible_id": "负责工作准备",
      "scope_start_date": "工作准备开始日期",
      "scopes": "工作准备",
      "service_desired_date": "所需日期",
      "skip_scoping": "跳过工作准备阶段",
      "status": "状态",
      "statuses": {
        "authorization_pending": "待办的",
        "canceled": "取消",
        "clustered": "聚类",
        "confirmed": "在准备",
        "deleting": "删除中",
        "done": "完毕",
        "in_performance": "建设中",
        "in_planning": "建设中",
        "in_scoping": "在准备",
        "on_hold": "等候接听",
        "registered": "已建立"
      },
      "sub_request": "子要求",
      "sub_requests": "子要求",
      "subarea": "分区",
      "team_target_hours": "团队目标时间",
      "title": "工作订单",
      "work_package_file_name": "%{reason} - %{disciplineDescription} - %{comments} - 修订版 %{revision}.pdf"
    },
    "revert_modal": {
      "cancel": "不，取消",
      "description": "您确定要恢复检查“%{id}”吗？",
      "ok": "是的，恢复待处理状态。",
      "success_description": "已成功恢复待处理状态。",
      "success_title": "检查已恢复",
      "title": "恢复项目？"
    },
    "rich_text": {
      "upload_dialog": {
        "alternate_text": "替代文本",
        "by_url": "通过 URL",
        "height": "高度 (px)",
        "image": "图像",
        "title": "标题",
        "upload": "上传",
        "web_address": "网址",
        "width": "宽度 (px)",
        "window_title": "图片版"
      }
    },
    "scaffolding_part_action": {
      "comment": "评论",
      "date": "日期",
      "height": "高度",
      "length": "长度",
      "modification_type": {
        "dismantle": "拆除",
        "erect": "直立",
        "erectDismantle": "安装/拆除"
      },
      "singular": "脚手架部分作用",
      "title": "脚手架部分作用",
      "total": "全部的",
      "width": "宽度",
      "work_dismantle": "根据另一项工作订单进行拆除",
      "work_order": {
        "choose_wo": "选择工单"
      }
    },
    "scaffolding_part_actions": {
      "card": {
        "build": "建造",
        "created_by": "创建者",
        "dismantle": "尺寸已移除",
        "erect": "新增维度",
        "modify": "修改的",
        "total": "全部的",
        "total_dismantle": "脚手架拆除"
      },
      "comment": "评论",
      "confirmation_modal": {
        "delete_cancel": "否，取消",
        "delete_confirmation": "是，删除",
        "delete_description": "您确定要删除 %{total} %{dimension} 维度吗？",
        "delete_title": "删除操作？"
      },
      "date": "日期",
      "duplicate_title": "重复操作",
      "edit_title": "编辑操作",
      "feed": {
        "empty": "未为此脚手架部件创建任何操作"
      },
      "height": "高度",
      "length": "长度",
      "modification_type": {
        "dismantle": "拆除",
        "erect": "直立",
        "erectDismantle": "安装/拆除"
      },
      "singular": "脚手架部分作用",
      "timestamp_badges": {
        "new": "新的",
        "updated": "更新"
      },
      "title": "脚手架部分作用",
      "total": "全部的",
      "width": "宽度",
      "work_dismantle": "根据另一项工作订单进行拆除",
      "work_order": {
        "choose_wo": "选择工单"
      }
    },
    "scaffolding_parts": {
      "action_section": {
        "action_removed": "总计 %{total}%{dimension} 的操作已从列表中删除",
        "title": "添加维度"
      },
      "back": "所有脚手架零件",
      "comment": "评论",
      "confirmation_modal": {
        "delete_cancel": "否，取消",
        "delete_confirmation": "是，删除",
        "delete_description": "是否要删除带有 %{total} %{dimension} 的 %{type}？",
        "delete_title": "删除 %{type} 部分？"
      },
      "description": "描述",
      "dismantle_modal": {
        "cancel": "否，取消",
        "confirm": "是的，拆除",
        "confirmation_message": "您想拆除这个部件吗？",
        "date": "日期",
        "errors": {
          "date_greater_max": "拆除日期必须是今天或更早"
        },
        "success_message": "脚手架部分成功拆除",
        "title": "拆解部分",
        "work_order": "工作指示"
      },
      "erect_method": "方法",
      "erect_methods": {
        "os": "海上",
        "standard": "标准",
        "tt": "海上绳索作业"
      },
      "id": "ID",
      "initial_date": "初始日期",
      "menu_items": {
        "dimensions": "添加维度",
        "dismantle": "拆除",
        "modifications": "修改此部分"
      },
      "operation_dismantle": "拆除作业",
      "operation_erect": "直立操作",
      "scaffolding_part_detail": {
        "actions": "行动",
        "side_panel_title": "脚手架部分细节"
      },
      "scaffolding_type_id": "脚手架类型",
      "scaffolding_types": {
        "covering": "篷布",
        "dop": "邻苯二甲酸二辛酯",
        "hanging_scaffolding": "绞刑",
        "patch_boards": "配线板",
        "railing": "临时栏杆",
        "rolling_scaffolding": "移动的",
        "roof_over_roof": "屋顶上还有屋顶",
        "scaffolding_for_lift": "起重设备",
        "scaffolding_net": "脚手架网",
        "shelf": "架子",
        "staircase_tower": "楼梯塔",
        "standard_scaffolding": "标准",
        "stool": "凳子",
        "support": "支持",
        "system_covering": "系统覆盖",
        "tent": "帐篷",
        "tent_frame": "帐篷框架"
      },
      "singular": "脚手架部分",
      "title": "脚手架部分",
      "total": "全部的",
      "type_filter": "类型过滤器",
      "weight": "重量"
    },
    "scaffoldings": {
      "area_id": "区域",
      "area_noise_id": "区域噪声",
      "area_noises": {
        "db_100_105": "100-105dB - 2小时停留时间",
        "db_105_110": "105-110dB - 1/2 小时停留时间",
        "db_85_90": "85-90dB - 12小时停留时间",
        "db_90_100": "90-100dB - 6小时停留时间",
        "db_less_85": "< 85dB - 无限制"
      },
      "back": "所有脚手架",
      "buttons_form": {
        "create_part": "创建零件"
      },
      "client": "客户",
      "comment": "评论",
      "confirmation_modal": {
        "delete_cancel": "否，取消",
        "delete_confirmation": "是，删除",
        "delete_description": "是否删除脚手架标签 %{tag}？",
        "delete_title": "删除脚手架",
        "dismantle_cancel": "现在不要",
        "dismantle_confirmation": "是的，我想拆除",
        "dismantle_description": "是否要拆除脚手架 TAG %{tag}？",
        "dismantle_title": "脚手架拆除",
        "duplicate_with_parts_cancel": "现在不要",
        "duplicate_with_parts_confirmation": "是的，我想复制零件",
        "duplicate_with_parts_description": "您想要复制 TAG %{tag} 的脚手架及其各个部件吗？",
        "duplicate_with_parts_title": "带零件的复制脚手架",
        "ready_to_dismantle_cancel": "现在不要",
        "ready_to_dismantle_confirmation": "是的，我想改变",
        "ready_to_dismantle_description": "您是否要将标签 %{tag} 的状态更改为准备拆除？",
        "ready_to_dismantle_title": "准备拆除的脚手架",
        "revert_cancel": "现在不要",
        "revert_confirmation": "是的，我想改变",
        "revert_description": "是否要将标签 %{tag} 的状态更改为已竖立？",
        "revert_title": "将脚手架状态恢复为已搭建"
      },
      "contact_person": "联络人",
      "description": "描述",
      "description_of_deviation": "偏差描述",
      "deviation_improved": "偏差改善日期",
      "dismantle_compensation_format": "拆迁补偿格式",
      "dismantle_date": "拆除日期",
      "dismantle_modal": {
        "cancel": "否，取消",
        "confirm": "是的，拆除",
        "confirmation_message": "您要拆除这个脚手架吗？",
        "date": "日期",
        "errors": {
          "date_greater_max": "拆除日期必须是今天或更早"
        },
        "success_message": "脚手架成功拆除",
        "title": "拆除脚手架",
        "work_order": "工作指示"
      },
      "erect_compensation_format": "直立补偿格式",
      "erect_date": "直立日期",
      "erect_with_deviation": "直立有偏差",
      "id": "ID",
      "inspection": {
        "delayed": "延迟",
        "missing_initial": "丢失的"
      },
      "inspection_control": "检查",
      "installation": "安装",
      "load_class_id": "负载等级",
      "load_classes": {
        "kg_150": "2 - 150 公斤/平方米",
        "kg_200": "3 - 200 公斤/平方米",
        "kg_300": "4 - 300 公斤/平方米",
        "kg_450": "5 - 450 公斤/平方米",
        "kg_600": "6 - 600 公斤/平方米",
        "kg_75": "1 - 75 公斤/平方米",
        "not_applicable": "0 - 不适用"
      },
      "main_work_order_dismantle_id": "工单拆除",
      "main_work_order_erect_id": "工单已建立",
      "missing_initial_control": "初始控制",
      "missing_request": "缺少工单",
      "number_of_tags": "标签数量",
      "operation_dismantle": "拆除行动",
      "operation_erect": "直立行动",
      "pictures": "图片）",
      "project_number": "项目参考",
      "qr_code": "二维码",
      "reason": "工单原因",
      "scaffolding_detail": {
        "side_panel_title": "脚手架细节"
      },
      "scaffolding_status_id": "地位",
      "sections": {
        "details": "细节",
        "deviations": "偏差",
        "general": "一般信息",
        "pictures": "图片）"
      },
      "singular": "脚手架",
      "statuses": {
        "created": "已创建",
        "dismantled": "已拆解",
        "erected": "已竖立",
        "ready_to_dismantle": "准备拆除"
      },
      "subarea_id": "分区",
      "tag": "标签",
      "tag_number": "标签号码",
      "tag_removed": "标签已删除",
      "telephone_radio": "电话 - 收音机",
      "title": "脚手架原木",
      "total_m": "总米",
      "total_m2": "总平方米",
      "total_m3": "总立方米",
      "total_weight_message": "搭建脚手架的总重量",
      "user_of_scaffolding": "脚手架使用者",
      "weight": "重量",
      "wind_exposed": "风吹"
    },
    "scopes": {
      "add": "添加工作准备项",
      "choose_modal": {
        "single": "另一个工作准备项目",
        "single_description": "仅创建一项工作准备项目"
      },
      "clusters": {
        "title": "工作准备集群"
      },
      "count": "计数",
      "crew_size": "船员人数",
      "crews": "船员人数",
      "date": "日期",
      "description": "工作准备",
      "duplicate_services": {
        "other_request": "另一份工作订单",
        "other_request_description": "复制到另一个工作订单中的工作准备项目",
        "same_request": "同一工单",
        "same_request_description": "选择工作准备项目",
        "success_notification": "工作准备服务成功复制",
        "title": "重复服务"
      },
      "edit_work_description": "编辑工作描述",
      "hours": "小时",
      "id": "编号",
      "item": "选项",
      "label": "标签",
      "label_assigner": {
        "success": "您的工作准备标签已成功分配！你现在可以继续你的计划了。"
      },
      "label_error": "无法获取标签",
      "labels_info": "工作准备标签",
      "more_details": "查看更多",
      "popup_label": {
        "create_label": "创建标签",
        "labels": "标签：",
        "name_label": "标签名字",
        "new_label": "新标签"
      },
      "preview_unavailable": "预览不可用",
      "quantity": "数量",
      "remaining_hours": "剩下小时",
      "remaining_quantity": "剩下的数量",
      "request_status_restriction": "工作订单的状态应为“已确认”或“准备中”",
      "scopes_detail": {
        "side_panel_title": "工作准备细节"
      },
      "success_work_description_edition": "作品描述已成功编辑",
      "title": "工作准备",
      "unit": "单元",
      "work_description": "工作描述",
      "working_hours": "工作时间"
    },
    "search": {
      "loading": "加载中",
      "no_result_found": "没有找到相关的",
      "placeholder": "筛选"
    },
    "service_description": "服务说明",
    "settings": {
      "title": "设定值"
    },
    "settings_shortcuts": "设置快捷方式",
    "shortcuts": {
      "access_control": "访问控制",
      "areas": "区域和分区",
      "areas_subareas": "区域和分区",
      "assets": "资产",
      "background_jobs": "后台工作",
      "branches": "分行",
      "cities": "城市",
      "client_sites": "业主",
      "clients": "业主",
      "clients_info": "业主的资料",
      "columns_renaming": "列重命名",
      "companies": "公司",
      "contract_services": "合同服务",
      "contracts": "合同",
      "countries": "国家",
      "customer_sites": "客户网站",
      "customers": "客户群",
      "disciplines": "纪律处分",
      "employees": "职员",
      "equipaments": "配套设备",
      "equipments": "配套设备",
      "formulas": "公式",
      "location": "地方",
      "materials": "材料",
      "matrices": "矩阵",
      "measures": "措施",
      "mobile_details": "移动的细节",
      "position_names": "头衔名称",
      "preferences": "选项",
      "project_assets": "资产",
      "project_contracts": "合约书",
      "projects": "项目和子项目",
      "projects_contracts": "项目和合同",
      "projects_subprojects": "项目和子项目",
      "provinces": "州/省",
      "regions": "区域",
      "routes": "路线",
      "specialities": "特色菜",
      "staff": "员工",
      "states_provinces": "州/省",
      "templates": "模板",
      "translations": "列重命名",
      "users": "用户名",
      "visible_fields": "行动详细资料",
      "work_positions": "职位名称"
    },
    "side_menu": {
      "collapse": "坍塌",
      "contracts": "合同",
      "datasheets": "数据表",
      "expand": "扩张",
      "report": "报告",
      "search": "搜寻",
      "settings": "设置",
      "template": {
        "asset": "资产",
        "contract_service": "合同服务",
        "datasheet": "数据表",
        "estimate_service": "估算服务",
        "inspection": "检查验收",
        "performance": "建造",
        "report": "报告",
        "request": "工作订单",
        "scoping": "工作准备",
        "valuation": "估价"
      },
      "templates": "模板"
    },
    "side_panel": {
      "close": "关闭侧面板",
      "collapse": "折叠侧面板",
      "expand": "展开侧面板",
      "tabs": {
        "category_filter": "类别过滤器",
        "gallery": "画廊",
        "info": "信息",
        "inspections": "检查",
        "no_categories": "没有可用的类别",
        "no_templates": "没有可用的模板",
        "preview_details": "预览详情",
        "progress": "进步",
        "template_filter": "模板过滤器"
      }
    },
    "sub_requests": {
      "creation_page": {
        "all": "所有的子要求",
        "back": "所有工作准备",
        "title": "子要求"
      },
      "left_panel": {
        "add_scoping": "添加工作准备项",
        "allocation": "分配",
        "cards_header_title": "工作准备",
        "summary": "总结",
        "total_allocation": "总分配"
      },
      "scope_card": {
        "scope": "工作准备"
      }
    },
    "tabs": {
      "error": "无法加载标签"
    },
    "templates": {
      "asset": "资产",
      "contract_service": "合约服务",
      "datasheet": "数据表",
      "estimate_service": "估算服务",
      "inspection": "视察",
      "no_template": "您需要创建模板",
      "progress": "建造",
      "progress_service": "估价",
      "report": "报告",
      "request": "工作订单",
      "scoping": "工作准备",
      "title": "%{type}的模板"
    },
    "tickets": {
      "icon_alt": "门票图标",
      "title": "门票"
    },
    "timeline": {
      "created": "创建",
      "day_ago_singular": "%{daysAgo}天前",
      "day_until_singular": "%{daysUntil}天内",
      "days_ago": "%{daysAgo}天前",
      "days_until": "%{daysUntil} 天内",
      "discipline_check_done": "纪律检查：已完成",
      "discipline_check_pending": "纪律检查：待定",
      "discipline_check_rejected": "纪律检查：拒绝",
      "estimate_authorized": "估计：已授权",
      "estimate_done": "预计：已完工",
      "estimate_on_hold": "估计：暂停",
      "estimate_pending": "估价：待定",
      "estimate_pending_authorization": "估计：授权待定",
      "estimate_progress": "估计：进行中",
      "performance": "建造",
      "progress": "进步",
      "recycle_done": "回收：完成",
      "recycle_recycling": "回收：回收利用",
      "recycling": "回收利用",
      "rejected": "拒绝",
      "request": "工作指示",
      "responsible": "负责任的",
      "schedule": "日程",
      "scoping": "工作准备",
      "title": "工作订单时间表",
      "today": "今天"
    },
    "top_menu": {
      "authorization": "授权",
      "datasheets": "数据表",
      "eav_templates": "范本",
      "estimates": "估算",
      "inspections": "检查验收",
      "no_datasheets": "未找到数据表",
      "no_reports": "没有找到报告",
      "performances": "建造",
      "plannings": "规划",
      "reports": "报告",
      "requests": "工作订单",
      "scaffoldings": "脚手架原木",
      "scopes": "工作准备",
      "workflow": "工作流程"
    },
    "track_block": {
      "id": "用户名"
    },
    "user_menu": {
      "about_version": "关于版本",
      "clean_preferences": "清理首选项",
      "logout": "推出",
      "notification": {
        "clean_preferences": "首选项已清理",
        "error": "尝试清除首选项时出错"
      },
      "preferences": "网格偏好",
      "profile": "轮廓"
    },
    "users": {
      "edit_title": "更新配置文件"
    },
    "work_order_attachments": {
      "subtitle": "参见相关工作指令文件",
      "title": "工作单附件"
    }
  }
}

export default I18n
