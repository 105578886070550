import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'
import {
  removeSelectedFilter,
  addFilter,
  statusDescription,
  getFilteredStatusesNames,
  handleSpecialFilters,
  resetFilters,
  clearFilters
} from '/src/utils/filter_status_utils'

const STATUS_BLACKLIST = ['in_performance', 'on_hold']

const extra = { id: 8, active: true, description: 'Extra', filtered: false, i18n_id: 'extra' }
const pendingInspection = { id: 9, active: true, description: 'Pending Inspection', filtered: false, i18n_id: 'inspected_flag' }

const statusDesc = (status) => statusDescription(status, 'progress_services')
const getFilteredNames = (statuses) => getFilteredStatusesNames(statuses, 'progress_services')

export default function useProgressServicesStatusFilter(filter, onFilter, onSelectedOptionChange) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [statuses, setStatuses] = useState([])

  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const allowedStatuses = statuses.filter((status) => status.id && !STATUS_BLACKLIST.includes(status.i18n_id))

  useEffect(() => {
    if (!progressServiceStatuses || progressServiceStatuses.length === 0) return

    const statusList = Object.values(progressServiceStatuses).map((status) => ({ ...status, filtered: false }))
    setStatuses([...statusList, extra, pendingInspection])
  }, [progressServiceStatuses])

  const changeFilter = useCallback(
    (selectedStatus) => {
      if (!popupOpened) return

      setPopupOpened(false)

      const updatedStatuses = statuses.map((status) => status)
      updatedStatuses.find((status) => status.id === selectedStatus.id).filtered = !selectedStatus.filtered

      let filterIds = updatedStatuses.filter((status) => status.filtered).map((status) => status.id)
      if (!filterIds) return


      const filterObject = { type: 'where', column: 'progress_service_status_id', operator: IsEqualOperator, value: filterIds }
      const extraFilterObject = { type: 'where', column: 'estimate_service_id', operator: IsEqualOperator, value: null }
      const pendingInspectionFilterObject = { type: 'where', column: 'inspected_flag', operator: IsEqualOperator, value: 'inspected_flag' }

      let updatedFilter = removeSelectedFilter(filter, filterObject)

      // Aplicar filtros especiais
      const resultForExtra = handleSpecialFilters(filterIds, extra, extraFilterObject, selectedStatus, updatedFilter)
      filterIds = resultForExtra.filterIds
      updatedFilter = resultForExtra.updatedFilter

      const resultForPendingInspection = handleSpecialFilters(filterIds, pendingInspection, pendingInspectionFilterObject, selectedStatus, updatedFilter)
      filterIds = resultForPendingInspection.filterIds
      updatedFilter = resultForPendingInspection.updatedFilter

      if (filterIds.length > 0) {
        updatedFilter = addFilter(filterObject, updatedFilter)
      }

      onFilter(updatedFilter)
      setStatuses(updatedStatuses)
      onSelectedOptionChange(getFilteredNames(updatedStatuses))
    },
    [popupOpened, statuses, filter, onFilter, onSelectedOptionChange]
  )

  const resetStatusFilters = useCallback(() => {
    resetFilters(statuses, [extra, pendingInspection], onSelectedOptionChange, setStatuses)
  }, [statuses, onSelectedOptionChange, setStatuses])

  const clearStatusFilters = useCallback(() => {
    clearFilters(filter, ['progress_service_status_id', 'estimate_service_id', 'inspected_flag'], resetStatusFilters, onFilter)
  }, [filter, resetStatusFilters, onFilter])

  const hasStatusFilter = useMemo(() => {
    return filter.some((filter) => ['progress_service_status_id', 'estimate_service_id', 'inspected_flag'].includes(filter.column))
  }, [filter])

  const statusFilterButton = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={allowedStatuses}
        statusDescription={statusDesc}
        isStatusSelected={(status) => status.filtered}
        onChangeFilter={changeFilter}
        onClearFilters={clearStatusFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [allowedStatuses, changeFilter, clearStatusFilters, popupOpened]
  )

  return {
    statusFilterButton,
    hasStatusFilter,
    resetStatusFilters,
    clearStatusFilters
  }
}
