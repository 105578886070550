import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { MdCheck, MdClose } from 'react-icons/md'
import ScaffoldingPartModel from '/src/models/scaffolding_part'

const classNameByType = (type) => (type.filtered ? 'type-selected' : 'type-not-selected')

export default function ScaffoldingTypeFilterPopup({
  types,
  typeDescription,
  isTypeSelected,
  setPopupOpened,
  onClearFilters,
  onChangeFilter,
  children
}) {
  const clearFilters = () => {
    setPopupOpened(false)
    onClearFilters()
  }

  const changeFilter = (type) => {
    setPopupOpened(false)
    onChangeFilter(type)
  }

  return (
    <React.Fragment>
      <div id="popup-type-filter" data-testid="popup-type-filter" className="popup-type-filter">
        {types.map((type) => {
            return (
              <div key={type.id} className={classNameByType(type)}>
                <span role="button" onClick={() => changeFilter(type)} tabIndex={0}>
                  {typeDescription(type)}
                  {isTypeSelected(type) && <MdCheck />}
                </span>
              </div>
            )
          })}

        {children}
      </div>
      <button id="clear-filters" className="clear-filters clear-service-statuses" type="button" onClick={clearFilters}>
        <MdClose />
        {I18n.t(`grid.clear_filters`)}
      </button>
    </React.Fragment>
  )
}

ScaffoldingTypeFilterPopup.propTypes = {
  types: PropTypes.oneOfType([PropTypes.array]),
  typeDescription: PropTypes.func,
  isTypeSelected: PropTypes.func,
  onClearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  setPopupOpened: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array])
}

ScaffoldingTypeFilterPopup.defaultProps = {
  types: [],
  typeDescription: () => '',
  isTypeSelected: () => false,
  onClearFilters: () => {},
  onChangeFilter: () => {},
  setPopupOpened: () => {},
  children: null
}
