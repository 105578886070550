import Colors from '/src/utils/constants/colors'

/**
 * @returns {function(*, *): {children: *, style: *}} - return row render function to be passed
 * to the grid and to style or change grid row structure on grid mount
 */
export default function useRowRenderScaffoldingPartGrids() {
  return (trElement, dataItem) => {
    let style = { ...trElement.props.style }

    if (dataItem.dataItem.total === 0) {
      style = { backgroundColor: Colors.KAEFER_RED_LIGHT }
    }

    const { children } = trElement.props

    return { style, children }
  }
}
