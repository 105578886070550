import { byString } from '/src/utils/object'

export const DIMENSION = 'dimension'
export const MODIFICATION = 'modification'

export const isScaffoldingRowEditable = (dataItem) => byString(dataItem, 'scaffolding_status.i18n_id') !== 'dismantled'

export const isScaffoldingDismantled = (statuses, scaffolding) => {
  if (!statuses || !scaffolding || !Object.keys(statuses).length) return false
  const status = statuses[scaffolding.scaffolding_status_id]
  return status && status.i18n_id === 'dismantled'
}
