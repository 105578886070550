import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'
import {
  removeSelectedFilter,
  addFilter,
  statusDescription,
  getFilteredStatusesNames,
  handleSpecialFilters,
  resetFilters,
  clearFilters
} from '/src/utils/filter_status_utils'

const extraFilter = { id: 8, active: true, description: 'Extra', filtered: false, i18n_id: 'extra' }
const revisedFilter = { id: 9, active: true, description: 'Revised', filtered: false, i18n_id: 'revised' }

const statusDesc = (status) => statusDescription(status, 'progress')
const getFilteredNames = (statuses) => getFilteredStatusesNames(statuses, 'progress')

export default function useProgressStatusFilter(filter, onFilter, onSelectedOptionChange) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [progressStatuses] = useStore('progress_statuses')

  const formattedStatuses = [
    ...Object.values(progressStatuses ?? {}).map((status) => ({
      ...status,
      filtered: false
    })),
    extraFilter,
    revisedFilter
  ]

  const [statuses, setStatuses] = useState(formattedStatuses)

  useEffect(() => {
    if (!progressStatuses || progressStatuses.length === 0) return

    setStatuses(formattedStatuses)
  }, [progressStatuses])

  const changeFilter = useCallback(
    (selectedStatus) => {
      if (!popupOpened) return

      setPopupOpened(false)
      const updatedStatuses = statuses.map((status) => status)
      const { filtered } = selectedStatus
      updatedStatuses.filter((status) => selectedStatus.id === status.id).forEach((status) => (status.filtered = !filtered))

      let filterIds = updatedStatuses.filter((status) => status.filtered).map((status) => status.id)
      if (!filterIds) return

      const progressStatusFilterObject = {
        type: 'where',
        column: 'progress_status_id',
        operator: IsEqualOperator,
        value: filterIds
      }

      const extraFilterObject = {
        type: 'where',
        column: 'scope_id',
        operator: IsEqualOperator,
        value: null
      }

      const revisedFilterObject = {
        type: 'where',
        column: 'scope_revision',
        operator: IsEqualOperator,
        value: 'revised'
      }

      const removeExtraForRevised = {
        type: 'not',
        column: 'scope_id',
        operator: IsEqualOperator,
        value: null
      }

      let updatedFilter = removeSelectedFilter(filter, progressStatusFilterObject)

      let resultForRevised
      const isExtraSelected = filterIds.includes(extraFilter.id)
      const isRevisedSelected = filterIds.includes(revisedFilter.id)

      if (isRevisedSelected) {
        updatedFilter = addFilter(revisedFilterObject, updatedFilter)

        if (isExtraSelected) {
          updatedFilter = removeSelectedFilter(updatedFilter, extraFilterObject)
          updatedFilter = removeSelectedFilter(updatedFilter, removeExtraForRevised)
        } else {
          updatedFilter = addFilter(removeExtraForRevised, updatedFilter)
        }
      }

      if (!isRevisedSelected && isExtraSelected) {
        updatedFilter = addFilter(extraFilterObject, updatedFilter)
      }

      resultForRevised = handleSpecialFilters(
        filterIds,
        revisedFilter,
        revisedFilterObject,
        selectedStatus,
        updatedFilter
      )

      filterIds = resultForRevised.filterIds
      updatedFilter = resultForRevised.updatedFilter

      const resultForExtra = handleSpecialFilters(
        filterIds,
        extraFilter,
        extraFilterObject,
        selectedStatus,
        updatedFilter
      )

      filterIds = resultForExtra.filterIds
      updatedFilter = resultForExtra.updatedFilter

      if (filterIds.length > 0) {
        updatedFilter = addFilter(progressStatusFilterObject, updatedFilter)
      }

      onFilter(updatedFilter)
      setStatuses(updatedStatuses)
      onSelectedOptionChange(getFilteredNames(updatedStatuses))
    },
    [popupOpened, statuses, filter, onFilter, onSelectedOptionChange]
  )

  const resetStatusFilters = useCallback(() => {
    resetFilters(statuses, [revisedFilter, extraFilter], onSelectedOptionChange, setStatuses)
  }, [statuses, onSelectedOptionChange, setStatuses])

  const clearStatusFilters = useCallback(() => {
    clearFilters(filter, ['progress_status_id', 'scope_revision', 'scope_id'], resetStatusFilters, onFilter)
  }, [filter, resetStatusFilters, onFilter])

  const hasStatusFilter = useMemo(() => {
    return filter.some(
      (filterItem) =>
        filterItem.column === 'progress_status_id' ||
        filterItem.column === 'scope_revision' ||
        filterItem.column === 'scope_id'
    )
  }, [filter])

  const statusFilterButton = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={statuses}
        statusDescription={statusDesc}
        isStatusSelected={(status) => status.filtered}
        onChangeFilter={changeFilter}
        onClearFilters={clearStatusFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [statuses, changeFilter, clearStatusFilters, popupOpened]
  )

  return {
    statusFilterButton,
    hasStatusFilter,
    resetStatusFilters,
    clearStatusFilters
  }
}
