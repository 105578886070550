const sanitizeFilterValue = (value) => {
  if (value === true) return 1
  if (value === false) return 0
  return encodeURIComponent(value)
}

export const filterToQuery = (filter) => {
  switch (filter.type) {
    case 'between': {
      const value1 = sanitizeFilterValue(filter.value[0] || filter.value.start)
      const value2 = sanitizeFilterValue(filter.value[1] || filter.value.end)
      return `query[where][${filter.column}]=${value1}..${value2}`
    }

    case 'less_than_or_equal_to': {
      const lowerValue = typeof filter.value === 'string' ? '1970-01-01' : -2147483648
      return `query[where][${filter.column}]=${lowerValue}..${sanitizeFilterValue(filter.value)}`
    }

    case 'more_than_or_equal_to': {
      const maxValue = typeof filter.value === 'string' ? '3000-12-31' : 2147483647
      return `query[where][${filter.column}]=${sanitizeFilterValue(filter.value)}..${maxValue}`
    }

    case 'where_distinct': {
      return `query[where_distinct]=${encodeURIComponent(filter.column)}`
    }

    case 'where_progress': {
      if (typeof filter.value === 'string') {
        return `query[where_progress]=${sanitizeFilterValue(filter.value)}`
      } else {
        return `query[where_progress]=${sanitizeFilterValue(filter.value[0])}..${sanitizeFilterValue(filter.value[1])}`
      }
    }

    case 'sync_from': {
      return `query[sync_from]=${encodeURIComponent(filter.value)}`
    }

    default: {
      if (filter.value === null) {
        return `query[${filter.type}][${filter.column}]`
      }
      return `query[${filter.type}][${filter.column}]=${sanitizeFilterValue(filter.value)}`
    }
  }
}

const parseKeyValueQuery = (name, filters, queries) => {
  Object.keys(filters).forEach((column) => {
    queries.push(
      filterToQuery({
        type: name,
        column,
        value: filters[column]
      })
    )
  })
}

const pushSimpleQuery = (name, filter, queries) => {
  queries.push(`query[${name}]=${encodeURIComponent(filter)}`)
}

const parseSimpleQuery = (name, filters, queries) => {
  filters.forEach((filter) => pushSimpleQuery(name, filter, queries))
}

const pushPlainQuery = (name, filter, queries) => {
  queries.push(`${name}=${encodeURIComponent(filter)}`)
}

const parseQueryFilters = (
  {
    where,
    whereSkip,
    whereEq,
    contains,
    containsOr,
    inspected,
    not,
    order,
    distinct,
    progress,
    blank,
    syncFrom,
    lessThanOrEqualTo,
    moreThanOrEqualTo,
    between,
    whereOr,
    whereBlank,
    group,
    withValue,
    exclude
  },
  queries
) => {
  if (where) parseKeyValueQuery('where', where, queries)
  if (whereSkip) parseKeyValueQuery('where_skip', whereSkip, queries)
  if (whereEq) parseKeyValueQuery('where_eq', whereEq, queries)
  if (contains) parseKeyValueQuery('contains', contains, queries)
  if (containsOr) parseKeyValueQuery('contains_or', containsOr, queries)
  if (inspected) parseKeyValueQuery('inspected', inspected, queries)
  if (not) parseKeyValueQuery('where_not', not, queries)
  if (order) parseKeyValueQuery('order', order, queries)
  if (lessThanOrEqualTo) parseKeyValueQuery('less_than_or_equal_to', lessThanOrEqualTo, queries)
  if (moreThanOrEqualTo) parseKeyValueQuery('more_than_or_equal_to', moreThanOrEqualTo, queries)
  if (between) parseKeyValueQuery('between', between, queries)
  if (syncFrom) pushSimpleQuery('sync_from', syncFrom, queries)
  if (distinct) parseSimpleQuery('where_distinct', distinct, queries)
  if (progress) parseKeyValueQuery('where_progress', progress, queries)
  if (whereOr) parseKeyValueQuery('where_or', whereOr, queries)
  if (whereBlank) parseSimpleQuery('where_blank', whereBlank, queries)
  if (withValue) parseKeyValueQuery('with', withValue, queries)
  if (group) pushSimpleQuery('group][', group, queries)
  if (exclude) pushPlainQuery('exclude', exclude, queries)

  if (blank) {
    blank.forEach((column) => {
      queries.push(`query[where][${column}]`)
    })
  }
}

const parseProjectFilters = ({ projectId, subprojectId, paging }, queries) => {
  if (projectId) queries.push(`project_id=${projectId}`)
  if (subprojectId) queries.push(`subproject_id=${subprojectId}`)

  if (paging) {
    const { size, pageSize, skip } = paging
    if (size || pageSize) queries.push(`page_size=${size || pageSize}`)
    if (skip !== undefined) queries.push(`skip=${skip}`)
  }
}

export const query = (params) => {
  const queries = []

  parseQueryFilters(params, queries)
  parseProjectFilters(params, queries)

  if (queries.length === 0) return ''

  return `?${queries.join('&')}`
}
