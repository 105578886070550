import React from 'react'
import PropTypes from 'prop-types'
import ScaffoldingModel from '/src/models/scaffolding'
import FieldFactory from '/src/ui/core/fields/field_factory'
import DecimalUnitColumn from '/src/ui/core/grid/column_cell_factory/decimal_unit_column'
import AreaNoiseColumn from '/src/ui/domain/scaffoldings/area_noise_column'
import LoadClassColumn from '/src/ui/domain/scaffoldings/load_class_column'
import WorkOrderColumn from '/src/ui/domain/scaffoldings/work_order_column'
import ScaffoldingInspectionLabel from '/src/ui/domain/scaffoldings/scaffolding_inspection_label'
import ScaffoldingStatusLabel from '/src/ui/domain/scaffoldings/scaffolding_status_label'
import ShadowColumn from '/src/ui/core/grid/column_cell_factory/shadow_column'

export default function ScaffoldingCellFactory({ field, dataItem, value }) {
  const { columns } = new ScaffoldingModel()
  const column = columns.find((c) => c.description == field)
  const { weight_m, weight_m2, weight_m3 } = dataItem
  const totalWeight = (weight_m + weight_m2 + weight_m3) / 1000

  switch (field) {
    case 'scaffolding_status':
      return <ScaffoldingStatusLabel dataItem={dataItem} />
    case 'main_work_order_erect_id':
      return <WorkOrderColumn type={'erect'} value={dataItem.main_work_order_erect} />
    case 'main_work_order_dismantle_id':
      return <WorkOrderColumn type={'dismantle'} value={dataItem.main_work_order_dismantle} />
    case 'inspection_control':
      return <ScaffoldingInspectionLabel dataItem={dataItem} />
    case 'area_noise_id':
      return <AreaNoiseColumn value={dataItem.area_noise} />
    case 'load_class_id':
      return <LoadClassColumn value={dataItem.load_class} />
    case 'area.description':
      return <ShadowColumn value={dataItem.area} />
    case 'subarea.description':
      return <ShadowColumn value={dataItem.subarea} />
    case 'total_m3':
      return <DecimalUnitColumn value={value || dataItem[field]} unit="m³" />
    case 'total_m2':
      return <DecimalUnitColumn value={value || dataItem[field]} unit="m²" />
    case 'total_m':
      return <DecimalUnitColumn value={value || dataItem[field]} unit="m" />
    case 'weight':
      return <DecimalUnitColumn value={totalWeight ?? 0} unit="t" />
    default:
      return <FieldFactory value={value || dataItem[field]} type={column ? column.type : 'text'} />
  }
}

ScaffoldingCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.shape()])
}

ScaffoldingCellFactory.defaultProps = {
  field: '',
  dataItem: undefined,
  value: undefined
}
