import React from 'react'
import { IoPersonCircleOutline } from 'react-icons/io5'
import { MdInfoOutline , MdEditCalendar } from 'react-icons/md'
import { PropTypes } from 'prop-types'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'
import { getInspectionInfo } from '/src/utils/pdf_utils'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'

const iconColor = '#607d8b'

export default function PDFTitleInspection({ item, templateTitle, inspectStatuses, firstFlexibleColumnDescription }) {
  const {
    dpmsId,
    itemNumber,
    inspectDate,
    inspectedType,
    scaffoldingTag,
    responsibleName,
    inspectionNumber,
    description,
    area,
    subarea
  } = getInspectionInfo(item)

  const firstFlexibleColumnValue = firstFlexibleColumnDescription && item[firstFlexibleColumnDescription]

  return (
    <div className='print-list-pdf-title'>
      <div className='print-list-pdf-title__id'>
        <div className='print-list-pdf-title__inspection-number'>{`#${inspectionNumber}`}</div>
        <div className='print-list-pdf-title__id-title'>{` - ${templateTitle}`}</div>
        <div className='print-list-pdf-title__inspection-status-label'>
          <InspectionStatusLabel dataItem={item} statuses={inspectStatuses} />
        </div>
      </div>

      <div className='print-list-pdf-title__sub-title'>
        <div>{I18n.t(`inspections.inspected_modules.${inspectedType.toLowerCase()}`)}</div>
        {dpmsId && <div>{` - DPMSID #${dpmsId}`}</div>}
        {scaffoldingTag && <div>{` - TAG #${scaffoldingTag}`}</div>}
        {itemNumber && <div>{` - Item ${itemNumber}`}</div>}
      </div>

      <div className='print-list-pdf-title__info-rows'>
        {(
          <div className="print-list-pdf-title__info print-list-pdf-title__info--gap">
            {description ? <span>{description}</span> : <span>{I18n.t('fields.na')}</span>}
            {area ? <span>{area}</span> : <span>{I18n.t('fields.na')}</span>}
            {subarea ? <span>{subarea}</span> : <span>{I18n.t('fields.na')}</span>}
          </div>
        )}

        {responsibleName && (
          <div className='print-list-pdf-title__info'>
            <IoPersonCircleOutline size={15} style={{ color: iconColor }} />
            <span>{responsibleName}</span>
          </div>
        )}

        {inspectDate && (
          <div className='print-list-pdf-title__info'>
            <MdEditCalendar size={15} style={{ color: iconColor }} />
            <span>{inspectDate}</span>
          </div>
        )}
        
        {!isBlank(firstFlexibleColumnValue) && (
          <div className='print-list-pdf-title__info'>
            <MdInfoOutline size={15} style={{ color: iconColor }} />
            <span>{firstFlexibleColumnValue}</span>
          </div>
        )}
      </div>
    </div>
  )
}

PDFTitleInspection.propTypes = {
  item: PropTypes.shape({
    inspect_date: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inspect_status: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
    inspected_type: PropTypes.string.isRequired,
    responsible: PropTypes.shape({
      full_name: PropTypes.string,
    }),
    request_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tag_number: PropTypes.number,
    inspected: PropTypes.shape({
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  templateTitle: PropTypes.string.isRequired,
  inspectStatuses: PropTypes.oneOfType([PropTypes.object]),
  firstFlexibleColumnDescription: PropTypes.string
}

PDFTitleInspection.defaultProps = {
  firstFlexibleColumnDescription: undefined,
  inspectStatuses: undefined
}
