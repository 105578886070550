import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import useSyncBatch from '/src/hooks/api/sync_batch'
import EstimateServicesList from '/src/ui/domain/estimates/estimate_services_list'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import { getColumnsByTemplate } from '/src/models/concerns/eav_column'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import '/src/static/css/domain/estimates/estimate_services_list.css'

export default function EstimateDetailsContent({
  requests,
  estimates,
  estimateServices,
  statuses,
  expanded,
  employees,
  onLoadedServices,
  abbreviatedCard,
  constructionServices,
  useConstructionPrices
}) {
  const [templates, setTemplates] = useState({})
  const [columns, setColumns] = useState()
  const [columnTypes, setColumnTypes] = useState()
  const [units, setUnits] = useState()
  const [estimatesLog, setEstimatesLog] = useState({})

  useBus(BusEvents.POLLING_DATA_SUBMITTED, ({ payload }) => setEstimatesLog(payload), [])

  const batchedEntities = useMemo(() => {
    const servicesArray = [...Object.values(estimateServices).flat(), ...Object.values(constructionServices).flat()]
    const templateIds = servicesArray.map((service) => service.eav_template_id)
    const distinctTemplateIds = [...new Set(templateIds)]

    const queryTemplates = { where: { id: distinctTemplateIds } }
    const queryColumns = { where: { eav_template_id: distinctTemplateIds } }
    return {
      eav_templates: {
        get: templates,
        set: setTemplates,
        query: queryTemplates
      },
      eav_columns: {
        get: columns,
        set: setColumns,
        query: queryColumns
      },
      column_types: { get: columnTypes, set: setColumnTypes },
      units: { get: units, set: setUnits }
    }
  }, [templates, units])

  const { loading } = useSyncBatch(batchedEntities)

  const columnsByTemplate = useMemo(() => {
    if (!columns || !columnTypes) return {}

    return getColumnsByTemplate(Object.values(columns), (column) => {
      const updatedColumn = { ...column }
      updatedColumn.column_type = columnTypes[column.column_type_id]
      return updatedColumn
    })
  }, [columns, columnTypes])

  const isBatchLoaded = templates && units && !loading

  useEffect(() => {
    if (isBatchLoaded) onLoadedServices()
  }, [isBatchLoaded, onLoadedServices])

  const getServices = (estimateId, requestId, requestStatusId) => {
    if (useConstructionPrices && [6, 7, 8].includes(requestStatusId)) {
      return constructionServices[requestId] || []
    }

    return estimateServices[estimateId] || []
  }

  if (!isBatchLoaded) return <ThreeDotsLoader />

  return requests.map((request, index) => {
    const estimate = estimates[request.id] || {}
    const estimateLog = estimatesLog[estimate.id] || {}
    const employee = employees[estimate.responsible_id]
    
    const services = getServices(estimate.id, request.id, request.request_status_id)

    return (
      <React.Fragment key={request.id}>
        <EstimateServicesList
          key={request.id}
          index={index}
          request={request}
          estimate={estimate}
          estimateLog={estimateLog}
          estimateServices={services}
          templates={templates}
          columnsByTemplate={columnsByTemplate}
          units={units}
          statuses={statuses}
          expanded={expanded}
          employee={employee}
          abbreviatedCard={abbreviatedCard}
        />
      </React.Fragment>
    )
  })
}

EstimateDetailsContent.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      reason: PropTypes.string,
      comments: PropTypes.string
    })
  ).isRequired,
  estimates: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          estimate_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
      )
    ])
  ),
  estimateServices: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    ])
  ).isRequired,
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  expanded: PropTypes.bool,
  employees: PropTypes.object,
  onLoadedServices: PropTypes.func,
  abbreviatedCard: PropTypes.bool
}

EstimateDetailsContent.defaultProps = {
  estimates: {},
  statuses: {},
  expanded: false,
  employees: {},
  onLoadedServices: () => {},
  abbreviatedCard: true
}
