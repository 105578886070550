import React, { useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useGetPinnedRequests from '/src/hooks/get_pinned_requests'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import InputSearch from '../inputs/input_search'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import { MdPushPin, MdClose } from 'react-icons/md'
import '/src/static/css/quick_pin_popup.css'

export default function QuickPinPopup({ children, setPopupOpen, popupButtonRef }) {
  const [activeTab, setActiveTab] = useState(0)
  const [disciplines] = useStore('disciplines')
  const [fetchedRequests, setFetchedRequests] = useState([])
  const { selectedRequests } = useGetPinnedRequests()
  const { unpinRequests, setPinnedRequests } = useRequestsPinned()

  const handlePinClick = (request) => {
    if (selectedRequests[request.id]) {
      unpinRequests([request.id])
    } else {
      setPinnedRequests([{ id: request.id }])
    }
  }

  const handleClearAllClick = () => {
    if (Object.keys(selectedRequests).length > 0) {
      unpinRequests(Object.keys(selectedRequests))
    }
  }

  const formatRequestText = (request) => {
    if (!request) return ''
    const discipline = disciplines[request.discipline_id]?.description
    const parts = [request.reason, discipline, request.comments].filter(Boolean)
    let formattedText = parts.join(' - ') || ''

    return formattedText.length > 78
      ? formattedText.substring(0, 75).replace(/\s-\s?$/, '') + '...'
      : formattedText
  }

  const onFetch = (fetchedData) => {
    setFetchedRequests(fetchedData)
  }

  const inputProps = {
    keyField: 'id',
    className: 'quick-pin-popup__search',
    popupClassName: 'quick-pin__combobox-list',
    inputClass: 'quick-pin-popup__search',
    iconClassName: 'k-i-search',
    searchRoute: 'requests',
    searchFields: ['id', 'discipline][description', 'reason', 'comments'],
    textDisplayFields: ['id', 'reason', 'discipline', 'comments'],
    placeholder: I18n.t('grid.quick_pin_popup.search_and_pin'),
    type: 'search',
    onFetch
  }

  const popupBody = (
    <div className='quick-pin-popup'>
      <TabStrip
        className='quick-pin-popup__tab-header'
        selected={activeTab}
        onSelect={(e) => setActiveTab(e.selected)}
        animation={false}
      >
        <TabStripTab title={I18n.t('grid.quick_pin_popup.pinned_work_orders')}>
          <div className='quick-pin-popup__tab-content quick-pin-popup__tab-content--pinned'>
            {Object.keys(selectedRequests).length > 0 ? (
              <>
                <div className='quick-pin-popup__list-container'>
                  <ul className='quick-pin-popup__items-list'>
                    {Object.values(selectedRequests).map((request) => (
                      <li key={request.id} className='quick-pin-popup__item'>
                        {formatRequestText(request)}
                        <MdClose
                          className='quick-pin-popup__icon quick-pin-popup__icon--close'
                          onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation()
                            handlePinClick(request)
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <button
                  className='quick-pin-popup__clear-button'
                  onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    handleClearAllClick()
                  }}
                >
                  {I18n.t('grid.quick_pin_popup.clear_all')}
                </button>
              </>
            ) : (
              <p className='quick-pin-popup__empty-message'>
                {I18n.t("grid.quick_pin_popup.no_pinned_requests")}
              </p>
            )}
          </div>
        </TabStripTab>

        <TabStripTab title={I18n.t('grid.quick_pin_popup.all_work_orders')}>
          <div className='quick-pin-popup__tab-content'>
            <InputSearch inputProps={inputProps} />
            <div className='quick-pin-popup__list-container'>
              <ul className='quick-pin-popup__items-list'>
                {fetchedRequests.map((request) => (
                  <li key={request.id} className='quick-pin-popup__item'>
                    {formatRequestText(request)}
                    <MdPushPin
                      className={`quick-pin-popup__icon quick-pin-popup__icon--pin ${selectedRequests[request.id] ? 'quick-pin-popup__icon--pin--selected' : ''}`}
                      onClick={() => handlePinClick(request)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </TabStripTab>
      </TabStrip>
    </div>
  )

  return (
    <PopupAnchored
      persistent
      popupButtonRef={popupButtonRef}
      setPopupOpen={setPopupOpen}
      body={popupBody}
      popupAlign={{ vertical: 'top', horizontal: 'center' }}
    >
      {children}
    </PopupAnchored>
  )
}

QuickPinPopup.propTypes = {
  children: PropTypes.node,
  setPopupOpen: PropTypes.func,
  popupItemRef: PropTypes.object
}
