import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { Tooltip } from '@progress/kendo-react-tooltip'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { status } from '/src/utils/constants/request'
import DisciplineCheckIcon from '/src/ui/domain/requests/discipline_check_icon'
import { isInScopingOrConfirmed, isRecyclingRequest } from '/src/models/request'
import RecycleCheckIcon from '/src/ui/domain/requests/recycle_check_icon'
import '/src/static/css/core/grid/request_status_label.css'

export default function RequestStatusLabel({ dataItem }) {
  const { fetch } = useFetch()
  const showIcons = isInScopingOrConfirmed(dataItem, status)
  const recyclingStatus = isRecyclingRequest(dataItem, status)
  const [disciplineCheckComment, setDisciplineCheckComment] = useState()
  const [statuses] = useStore(`request_statuses`)
  const requestStatus = statuses && statuses.length !== 0 && statuses[dataItem.request_status_id]
  const timeoutRef = useRef(null)

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      handleFetchComment()
    }, 1000)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current)
  }

  const handleFetchComment = () => {
    if (
      disciplineCheckComment ||
      !isInScopingOrConfirmed(dataItem, status) ||
      dataItem.discipline_check_status !== 'pending'
    )
      return
    const commentaryFetchParams = {
      requestAction: 'READ',
      httpAction: 'get',
      dataOptions: { paging: { pageSize: 1 } },
      query: { where: { request_id: dataItem.id }, order: { id: 'desc' } }
    }

    fetch('discipline_checks', commentaryFetchParams, {
      onSuccess: ({ data }) => {
        if (data.data && data.data.length > 0 && data.data[0].comments != null) {
          setDisciplineCheckComment(data.data[0].comments)
        } else {
          setDisciplineCheckComment(I18n.t('requests.discipline_check_no_comment'))
        }
      }
    })
  }

  if (!requestStatus) return null

  return (
    <CustomTooltip maxLength={14}>
      <div
        className={`request label workflow-status ${requestStatus.i18n_id.replaceAll('_', '-')}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showIcons && <RecycleCheckIcon recyclingStatus={recyclingStatus} />}
        {showIcons && (
          <Tooltip style={{ maxWidth: '250px' }} position="top" parentTitle anchorElement="target">
            <DisciplineCheckIcon
              keyProp={disciplineCheckComment ? 'discipline-check-loaded' : 'discipline-check-loading'}
              title={disciplineCheckComment || I18n.t('actions.loading')}
              disciplineCheckStatus={dataItem.discipline_check_status}
            />
          </Tooltip>
        )}
        <span className="status-circle" />
        <span className="request-status-description">{I18n.t(`requests.statuses.${requestStatus.i18n_id}`)}</span>
      </div>
    </CustomTooltip>
  )
}

RequestStatusLabel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
